/* stylelint-disable color-no-hex */
/* stylelint-disable scss/dollar-variable-colon-space-after */

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #27ae60;


// Layout
$maxW:        80.625%;
$maxWR-85:    85%;
$maxWR-90:    90%;
$maxWc:       52%;

$radius:      7px;


/*
 * JS media queries
 ****************************************************************/

// Add pseudo element to body to be able to sync breakpoint with JS
body::before {
  content: 'none';
  display: none; /* Prevent from displaying. */


  @include media-breakpoint-down(xl) { // 1200 +
    content: 'xl';
  }

  @include media-breakpoint-down(lg) { // 992 to 1199
    content: 'lg';
  }

  @include media-breakpoint-down(md) { // 768 to 991
    content: 'md';
  }

  @include media-breakpoint-down(sm) { // 576 to 990
    content: 'sm';
  }

  @include media-breakpoint-down(xs) { // 575 -
    content: 'xs';
  }
}


/*
 * Transition
 ****************************************************************/
    $time:      0.35s;

/*
 * Fonts
 ****************************************************************/

    $rbold:    'Roboto-Bold';
    $rlight:   'Roboto-Light';
    $rmed:     'Roboto-Medium';
    $rreg:     'Roboto-Regular';
    $wbold:    'WorkSans-Bold';
    $wlight:   'WorkSans-Light';
    $wmed:     'WorkSans-Medium';
    $wreg:     'WorkSans-Regular';


 /*
 * Colors
 ****************************************************************/
    
    $grey_light_1:          #E6E9F1;
    $grey_light_2:          #EEF0F6;
    $grey_light_3:          #979797;
    $grey:                  #A6B4C2;
    $blue_navy_1:           #002452;
    $blue_navy_2:           #34396B;
    $blue_navy_3:           #15142F;
    $blue:                  #0080CC;
    $green:                 #009D4E;

    // Canadian Pond
    $red:                   #E6261C;
    $blue2:                 #114277;
    $blue3:                 #009ADA;
    $lightblue:             #9ACDEB;

/*
 * CSS3 transitions
 ****************************************************************/

    $easeInCubic:        cubic-bezier(0.550, 0.055, 0.675, 0.190);
    $easeOutCubic:       cubic-bezier(.215,.61,.355,1);
    $easeInOutCubic:     cubic-bezier(.645,.045,.355,1);

    $easeInCirc:         cubic-bezier(.6,.04,.98,.335);
    $easeOutCirc:        cubic-bezier(.075,.82,.165,1);
    $easeInOutCirc:      cubic-bezier(.785,.135,.15,.86);

    $easeInExpo:         cubic-bezier(.95,.05,.795,.035);
    $easeOutExpo:        cubic-bezier(.19,1,.22,1);
    $easeInOutExpo:      cubic-bezier(1,0,0,1);

    $easeInQuad:         cubic-bezier(.55,.085,.68,.53);
    $easeOutQuad:        cubic-bezier(.25,.46,.45,.94);
    $easeInOutQuad:      cubic-bezier(.455,.03,.515,.955);

    $easeInQuart:        cubic-bezier(.895,.03,.685,.22);
    $easeOutQuart:       cubic-bezier(.165,.84,.44,1);
    $easeInOutQuart:     cubic-bezier(.77,0,.175,1);

    $easeInQuint:        cubic-bezier(.755,.05,.855,.06);
    $easeOutQuint:       cubic-bezier(.23,1,.32,1);
    $easeInOutQuint:     cubic-bezier(.86,0,.07,1);

    $easeInSine:         cubic-bezier(.47,0,.745,.715);
    $easeOutSine:        cubic-bezier(.39,.575,.565,1);
    $easeInOutSine:      cubic-bezier(.445,.05,.55,.95);

    $easeInBack:         cubic-bezier(.6,-.28,.735,.045);
    $easeOutBack:        cubic-bezier(.175, .885,.32,1.275);
    $easeInOutBack:      cubic-bezier(.68,-.55,.265,1.55);


    $Power1EaseIn: cubic-bezier(0.550, 0.085, 0.680, 0.530);
    $Power1EaseOut: cubic-bezier(0.250, 0.460, 0.450, 0.940);
    $Power1EaseInOut: cubic-bezier(0.455, 0.030, 0.515, 0.955);

    $Power2EaseIn: cubic-bezier(0.550, 0.055, 0.675, 0.190);
    $Power2EaseOut: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    $Power2EaseInOut: cubic-bezier(0.645, 0.045, 0.355, 1.000);

    $Power3EaseIn: cubic-bezier(0.895, 0.030, 0.685, 0.220);
    $Power3EaseOut: cubic-bezier(0.165, 0.840, 0.440, 1.000);
    $Power3EaseInOut: cubic-bezier(0.770, 0.000, 0.175, 1.000);

    $Power4EaseIn: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    $Power4EaseOut: cubic-bezier(0.230, 1.000, 0.320, 1.000);
    $Power4EaseInOut: cubic-bezier(0.860, 0.000, 0.070, 1.000);


 /*
 * Media Queries
 ****************************************************************/

    $cell:                  "only screen and (max-width:480px)";
    $mobile:                "only screen and (max-width:768px)";
    $tabmini:               "only screen and (max-width:960px)";
    $tablet:                "only screen and (max-width:1024px)";
    $laptop:                "only screen and (max-width:1280px)";
    $large:                 "only screen and (max-width:1440px)";
    $desktop:               "only screen and (min-width:1441px)";













