.acc__item{
	border-top: 1px solid $grey;
	position: relative;
	&:last-child{
		border-bottom: 1px solid $grey;
	}
	&_title{
		padding: 20px 45px 20px 0;
		position: relative;
		cursor: pointer;
		overflow: hidden;
		&.js-toggle-acc{
			~ .acc__item_content{
				// max-height: 1000px;
				max-height: 200vh;
			}
			.plus-minus-toggle {
				&:after {
					transform: rotate(0);
				}
				&:before {
					transform: rotate(0);
				}
			}
		}
		h2{
		    font-family: $wlight;
		    font-size: 42px;
		    line-height: 1.4;
		    pointer-events: none;
		    @include media-breakpoint-down(lg) {
                font-size: 32px;
            }
		    @include media-breakpoint-down(md) {
		        font-size: 24px;
		    }
		    @include media-breakpoint-down(xs) {
		       	font-size: 18px;
		    }
		}
		.plus-minus-toggle{
			position: absolute;
			right: 0;
			top: 50%;
		}
	}
	&_content{
		position: relative;
		max-height: 0;
		overflow: hidden;
		overflow-y: auto;
		transition: max-height $time $Power2EaseInOut;
		&_wrapper{
			padding-bottom: 60px;
		}
	}
}