@charset "UTF-8";
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; } }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #868e96; }

/**
 *  -----------------------------------------------
 *  FONTS
 *  -----------------------------------------------
 */
@font-face {
  font-family: 'Roboto-Bold';
  src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype"), url("../fonts/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Medium';
  src: url("../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto-Medium.ttf") format("truetype"), url("../fonts/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Light';
  src: url("../fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Light.woff") format("woff"), url("../fonts/Roboto-Light.ttf") format("truetype"), url("../fonts/Roboto-Light.svg#Roboto-Light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Regular';
  src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype"), url("../fonts/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'WorkSans-Bold';
  src: url("../fonts/WorkSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/WorkSans-Bold.woff") format("woff"), url("../fonts/WorkSans-Bold.ttf") format("truetype"), url("../fonts/WorkSans-Bold.svg#WorkSans-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'WorkSans-Light';
  src: url("../fonts/WorkSans-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/WorkSans-Light.woff") format("woff"), url("../fonts/WorkSans-Light.ttf") format("truetype"), url("../fonts/WorkSans-Light.svg#WorkSans-Light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'WorkSans-Medium';
  src: url("../fonts/WorkSans-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/WorkSans-Medium.woff") format("woff"), url("../fonts/WorkSans-Medium.ttf") format("truetype"), url("../fonts/WorkSans-Medium.svg#WorkSans-Medium") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'WorkSans-Regular';
  src: url("../fonts/WorkSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/WorkSans-Regular.woff") format("woff"), url("../fonts/WorkSans-Regular.ttf") format("truetype"), url("../fonts/WorkSans-Regular.svg#WorkSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

/* stylelint-disable color-no-hex */
/* stylelint-disable scss/dollar-variable-colon-space-after */
/*
 * JS media queries
 ****************************************************************/
body::before {
  content: 'none';
  display: none;
  /* Prevent from displaying. */
  content: 'xl'; }
  @media (max-width: 1199px) {
    body::before {
      content: 'lg'; } }
  @media (max-width: 991px) {
    body::before {
      content: 'md'; } }
  @media (max-width: 767px) {
    body::before {
      content: 'sm'; } }
  @media (max-width: 575px) {
    body::before {
      content: 'xs'; } }

/*
 * Transition
 ****************************************************************/
/*
 * Fonts
 ****************************************************************/
/*
 * Colors
 ****************************************************************/
/*
 * CSS3 transitions
 ****************************************************************/
/*
 * Media Queries
 ****************************************************************/
/* stylelint-disable */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
 *  -----------------------------------------------
 *  module/MIXINS AND UTILITIES
 *
 *  1. Transitions
 *  2. Placeholders
 *  3. Absolute centering
 *  4. Triangle
 *  5. Lines
 *  6. Vertical-line
 *  -----------------------------------------------
 */
/*
 * 1. Transitions
 ****************************************************************/
/*
 * 2. Placeholders
 ****************************************************************/
/* stylelint-disable selector-no-vendor-prefix */
/* stylelint-enable selector-no-vendor-prefix */
/*
 * 3. Absolute centering
 ****************************************************************/
/*
 * 4. Triangle
 ****************************************************************/
/*
 * 5. Lines
 ****************************************************************/
/*
 * 6. Vertical-line
 ****************************************************************/
/*
 * 7. Mobile toggle button
 ****************************************************************/
/*
 * 8. Tab character (+ / -)
 ****************************************************************/
/*
 * 8. Keep Ratio (+ / -)
 ****************************************************************/
/**
 *  -----------------------------------------------
 *  module/UTILITIES
 *
 *  1. Flex
 *  2. Padding / margin utilities
 *  3. Animations
 *  -----------------------------------------------
 */
/*
 * 1. Flex
 ****************************************************************/
.flex-c {
  align-items: center;
  display: flex;
  justify-content: center; }

/*
 * 2. Padding / margin utilities
 ****************************************************************/
.pt-0 {
  padding-top: 0; }

.pb-0 {
  padding-bottom: 0; }

.mt-0 {
  margin-top: 0; }

.mb-0 {
  margin-bottom: 0; }

/*
 * 3. Animations
 ****************************************************************/
@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

@keyframes translateYIn {
  from {
    transform: translateY(100%); }
  to {
    transform: translateY(0); } }

.fade-in {
  animation: fadeIn 0.4s;
  animation-fill-mode: forwards; }

.fade-out {
  animation: fadeOut 0.4s;
  animation-fill-mode: forwards; }

/**
 *  -----------------------------------------------
 *  GLOBAL
 *
 *  1. Wrappers
 *  2. Custom resets
 *  3. JavaScript
 *  -----------------------------------------------
 */
/*
 * 1. Wrappers
 ****************************************************************/
html {
  font-family: "Roboto-Regular";
  font-weight: 100;
  color: #34396B; }
  html.scroll-disabled {
    overflow: hidden; }
    html.scroll-disabled body {
      overflow: hidden; }

body {
  position: relative;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  line-height: normal;
  margin: 0;
  padding: 0;
  transition: opacity 0.35s ease; }
  body.js-freeze {
    position: fixed;
    overflow: hidden; }
    body.js-freeze .overlay-mobile {
      opacity: 0.6;
      z-index: 750; }

.main {
  position: relative;
  overflow: hidden; }

.overlay-mobile {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #34396B;
  opacity: 0;
  z-index: -100; }
  @media (max-width: 1199px) {
    .overlay-mobile {
      display: block; } }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: none; }

.overlay-green {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #009D4E;
  opacity: 0.9;
  transition: opacity 0.35s ease; }

.overlay-blue {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0080CC;
  opacity: 0.9;
  transition: opacity 0.35s ease; }

.container {
  position: relative;
  width: 100%;
  max-width: 80.625%;
  margin: 0 auto;
  padding: 0 20px; }
  @media screen and (min-width: 1200px) and (max-width: 1350px) {
    .container {
      max-width: 85%; } }
  @media (max-width: 1199px) {
    .container {
      max-width: 90%; } }

.wrap {
  padding-top: 96px;
  position: relative;
  width: 100%;
  overflow-x: hidden; }
  @media (max-width: 991px) {
    .wrap {
      padding-top: 0;
      margin-top: 93px; } }

img {
  max-width: 100%; }

.scroll-indicator {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  background-color: #f1f1f1; }
  .scroll-indicator .progress-container {
    width: 100%;
    height: 8px;
    background-color: #ccc; }
    .scroll-indicator .progress-container .progress-bar {
      height: 8px;
      background-color: #E6261C;
      transition: width 0.35s ease;
      width: 0%; }

.tweenX, .tweenY {
  opacity: 0; }

.wysiwyg h2 {
  font-family: "WorkSans-Medium";
  font-size: 42px;
  margin-bottom: 20px;
  line-height: 1.2; }
  @media (max-width: 1199px) {
    .wysiwyg h2 {
      font-size: 32px; } }
  @media (max-width: 991px) {
    .wysiwyg h2 {
      font-size: 24px; } }

.wysiwyg h3 {
  font-family: "Roboto-Regular";
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 1.4; }
  @media (max-width: 1199px) {
    .wysiwyg h3 {
      font-size: 18px; } }

.wysiwyg h4 {
  font-family: "Roboto-Bold";
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.4; }
  @media (max-width: 991px) {
    .wysiwyg h4 {
      font-size: 16px; } }

.wysiwyg h5 {
  font-family: "Roboto-Bold";
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.4; }
  @media (max-width: 991px) {
    .wysiwyg h5 {
      font-size: 14px; } }

.wysiwyg h6 {
  font-family: "Roboto-Bold";
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 1.4; }
  @media (max-width: 991px) {
    .wysiwyg h6 {
      font-size: 12px; } }

.wysiwyg p {
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 1px; }
  @media (max-width: 991px) {
    .wysiwyg p {
      font-size: 13px; } }
  .wysiwyg p + * {
    margin-top: 30px; }
  .wysiwyg p a {
    text-decoration: underline; }

.wysiwyg strong, .wysiwyg b {
  font-family: "Roboto-Bold"; }

.wysiwyg ul:not(.slick-dots) {
  position: relative; }
  .wysiwyg ul:not(.slick-dots) li {
    background-image: url("../images/icons/icon-blue-bullet.svg");
    background-repeat: no-repeat;
    background-position: 0 6px;
    font-size: 16px;
    padding-left: 20px;
    color: #34396B;
    width: 50%;
    margin-top: 15px; }
    @media (max-width: 991px) {
      .wysiwyg ul:not(.slick-dots) li {
        width: 100%;
        font-size: 13px; } }

.wysiwyg ul:not(.slick-dots), .wysiwyg ol {
  font-family: "Roboto-Regular";
  letter-spacing: 1px;
  line-height: 1.4; }
  @media (max-width: 991px) {
    .wysiwyg ul:not(.slick-dots), .wysiwyg ol {
      font-size: 13px; } }
  .wysiwyg ul:not(.slick-dots) + *, .wysiwyg ol + * {
    margin-top: 20px; }
  .wysiwyg ul:not(.slick-dots) li, .wysiwyg ol li {
    line-height: 1.4; }
    .wysiwyg ul:not(.slick-dots) li + li, .wysiwyg ol li + li {
      margin-top: 10px; }

.wysiwyg ol {
  list-style: none;
  counter-reset: li; }
  .wysiwyg ol > li {
    position: relative;
    counter-increment: li;
    padding-left: 40px;
    margin-bottom: 40px; }
    .wysiwyg ol > li:before {
      position: absolute;
      top: 2px;
      left: 10px;
      content: counter(li) ".";
      font-family: "Roboto-Regular";
      color: #0080CC;
      margin: 0;
      padding-left: 0; }
    .wysiwyg ol > li ul:not(.slick-dots) {
      counter-reset: li;
      /* Reset counter for nested ul */
      padding-left: 20px;
      /* Reset padding for nested ul */ }
      .wysiwyg ol > li ul:not(.slick-dots) li {
        counter-reset: none;
        /* Reset counter for nested li */
        position: relative;
        padding-left: 20px;
        background-image: url(../images/icons/icon-blue-bullet.svg);
        background-repeat: no-repeat;
        background-position: 0 6px;
        font-size: 16px;
        color: #34396b;
        width: 85%; }
        .wysiwyg ol > li ul:not(.slick-dots) li:before {
          content: ''; }

.wysiwyg img.alignleft {
  margin: 20px 20px 20px 0;
  float: left; }

.wysiwyg img.alignright {
  margin: 20px 0 20px 20px;
  float: right; }

.wysiwyg img.aligncenter {
  display: table;
  margin: 20px auto; }

.wysiwyg blockquote {
  position: relative;
  margin-bottom: 30px;
  display: flex;
  font-family: "WorkSans-Light";
  line-height: 1.2;
  color: #34396B;
  text-align: center;
  border: 1px solid #979797;
  quotes: "«" "»" "‘" "’";
  font-size: 42px; }
  .wysiwyg blockquote:before {
    position: absolute;
    top: 80px;
    left: 70px;
    content: '';
    height: auto;
    content: open-quote;
    font-family: "WorkSans-Light";
    font-size: 42px; }
    @media (max-width: 991px) {
      .wysiwyg blockquote:before {
        font-size: 24px;
        top: 40px;
        left: 30px; } }
    @media (max-width: 575px) {
      .wysiwyg blockquote:before {
        left: 20px;
        top: 20px; } }
  .wysiwyg blockquote:after {
    position: absolute;
    right: 70px;
    bottom: 80px;
    content: '';
    height: auto;
    content: close-quote;
    font-family: "WorkSans-Light";
    font-size: 42px; }
    @media (max-width: 991px) {
      .wysiwyg blockquote:after {
        font-size: 24px;
        right: 30px;
        bottom: 40px; } }
    @media (max-width: 575px) {
      .wysiwyg blockquote:after {
        right: 20px;
        bottom: 20px; } }
  .wysiwyg blockquote p {
    font-family: "WorkSans-Light";
    color: #34396B;
    line-height: 1.2;
    font-size: 42px;
    padding: 80px; }
    @media (max-width: 991px) {
      .wysiwyg blockquote p {
        font-size: 24px;
        padding: 40px; } }
    @media (max-width: 575px) {
      .wysiwyg blockquote p {
        font-size: 13px;
        padding: 20px 40px; } }
  .wysiwyg blockquote cite {
    display: block;
    text-align: right;
    margin-top: 20px;
    right: 0;
    bottom: -25px;
    justify-content: flex-end;
    font-family: "Roboto-Regular"; }
    .wysiwyg blockquote cite:before {
      margin-right: 5px;
      content: '-'; }

.wysiwyg q {
  display: block;
  padding: 20px 40px;
  color: #34396B; }

.wysiwyg iframe, .wysiwyg video {
  width: 100%; }

.wysiwyg .iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin-bottom: 20px; }
  .wysiwyg .iframe-container iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.wysiwyg a:not(.btn-fx) {
  font-family: "Roboto-Regular";
  color: #0080CC;
  transition: color 0.35s ease; }
  .wysiwyg a:not(.btn-fx):hover {
    color: #0080CC; }

.wysiwyg.wys-acc {
  width: 70%; }
  @media (max-width: 991px) {
    .wysiwyg.wys-acc {
      width: 100%; } }
  .wysiwyg.wys-acc ul:not(.slick-dots) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px; }
    .wysiwyg.wys-acc ul:not(.slick-dots) li {
      background-image: url("../images/icons/icon-blue-bullet.svg");
      font-size: 16px;
      padding-left: 20px;
      color: #34396B;
      width: 50%;
      margin-top: 15px; }
      @media (max-width: 991px) {
        .wysiwyg.wys-acc ul:not(.slick-dots) li {
          width: 100%;
          font-size: 13px; } }
      .wysiwyg.wys-acc ul:not(.slick-dots) li.pdf {
        background-image: none;
        padding-left: 0; }
        .wysiwyg.wys-acc ul:not(.slick-dots) li.pdf a {
          display: flex;
          align-items: center;
          color: #0080CC;
          font-size: 16px;
          transition: opacity 0.35s ease;
          opacity: 1; }
          @media (max-width: 991px) {
            .wysiwyg.wys-acc ul:not(.slick-dots) li.pdf a {
              font-size: 13px; } }
          .wysiwyg.wys-acc ul:not(.slick-dots) li.pdf a:before {
            content: url("../images/icons/icon-blue-pdf.svg");
            display: inline-block;
            padding-right: 15px; }
          .wysiwyg.wys-acc ul:not(.slick-dots) li.pdf a:hover {
            opacity: 0.8; }
          .wysiwyg.wys-acc ul:not(.slick-dots) li.pdf a + a {
            margin-top: 15px; }
  .wysiwyg.wys-acc ol {
    position: relative; }
    .wysiwyg.wys-acc ol li {
      font-size: 16px;
      padding-left: 20px;
      color: #34396B; }
      @media (max-width: 991px) {
        .wysiwyg.wys-acc ol li {
          font-size: 13px; } }
      .wysiwyg.wys-acc ol li + li {
        margin-top: 15px; }

.scrb::-webkit-scrollbar {
  height: 3px;
  padding: 2px; }

.scrb::-webkit-scrollbar-track {
  border-radius: 10px; }

.scrb::-webkit-scrollbar-thumb {
  background-color: #E6261C;
  border-radius: 10px; }

/*
 * 2. Custom resets
 ****************************************************************/
*, *::before, *::after {
  box-sizing: border-box; }

h1, h2, h3, h4, h5 {
  outline: none; }

a {
  color: inherit; }
  a, a:hover, a:focus {
    outline: none;
    text-decoration: none; }
  a:hover {
    cursor: pointer; }

input:focus, textarea:focus, select:focus, button:focus {
  outline: none; }

input, textarea {
  appearance: none;
  border-radius: 0; }

/* Remove yellow autocomplete color */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset; }

button {
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.14;
  outline: 0;
  padding: 0;
  text-align: left; }

strong {
  font-weight: 700; }

em {
  font-style: italic; }

sup,
sub {
  font-size: 0.7em;
  position: relative; }

sup {
  top: -0.7em; }

sub {
  bottom: -0.2em; }

::-ms-clear {
  display: none; }

/*
 * 3. JavaScript
 ****************************************************************/
.js-hide {
  display: none; }

.js-hidden {
  visibility: hidden; }

[data-module='ajax-form'],
.js-article-wrap,
.js-button-wrap {
  position: relative; }

[data-module='ajax-form'] .js-main-messages-form {
  display: none; }

.js-form-message-wrapper {
  align-items: center;
  display: flex;
  justify-content: center; }
  .js-form-message-wrapper > div {
    width: 100%; }

.js-prevent-default {
  cursor: default; }

.js-block-link {
  cursor: pointer; }

/**
 *  -----------------------------------------------
 *  TITLES
 *  -----------------------------------------------
 */
.btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 30px;
  font-family: "Roboto-Medium";
  font-size: 16px;
  transition: background-color 0.35s ease, border 0.35s ease; }
  .btn.btn_green {
    border: 2px solid #009D4E;
    background-color: #009D4E;
    color: white; }
    .btn.btn_green:hover {
      background-color: white;
      color: #009D4E; }
  .btn.btn_white {
    border: 2px solid white;
    background-color: white;
    color: #002452; }
    .btn.btn_white:hover {
      background-color: transparent;
      color: white; }
  .btn.btn_border_green {
    border: 2px solid #009D4E;
    background-color: transparent;
    color: #009D4E; }
    .btn.btn_border_green:hover {
      background-color: #009D4E;
      color: white; }
  .btn.btn_border_white {
    border: 2px solid white;
    background-color: transparent;
    color: white; }
    .btn.btn_border_white:hover {
      background-color: white;
      color: #15142F; }
  .btn.btn_green_form {
    border: 2px solid #009D4E;
    background-color: #009D4E;
    color: white; }
    .btn.btn_green_form:hover {
      border: 2px solid #15142F;
      background-color: #15142F;
      color: white; }

.btn-fx {
  display: inline-block;
  padding: 12px 20px;
  font-family: "Roboto-Medium";
  font-size: 16px;
  border-radius: 30px;
  color: #E6261C;
  transition: all 0.5s;
  position: relative;
  text-align: center;
  z-index: 1; }
  @media (max-width: 575px) {
    .btn-fx.prefix-hide {
      text-transform: capitalize; }
      .btn-fx.prefix-hide span {
        display: none; } }
  .btn-fx:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 30px;
    border: 2px solid #E6261C;
    transition: all 0.3s; }
  .btn-fx:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    border-radius: 30px;
    transition: all 0.3s;
    background-color: #E6261C;
    transform: scale(1.1, 1.1); }
  .btn-fx:hover {
    color: white; }
    .btn-fx:hover:before {
      opacity: 0;
      transform: scale(0.5, 0.5); }
    .btn-fx:hover:after {
      opacity: 1;
      transform: scale(1, 1); }
  .btn-fx.white {
    color: white; }
    .btn-fx.white:before {
      border: 2px solid white; }
    .btn-fx.white:after {
      background-color: white; }
    .btn-fx.white:hover {
      color: #15142F; }
  .btn-fx.green {
    color: #009D4E; }
    .btn-fx.green:before {
      border: 2px solid #009D4E; }
    .btn-fx.green:after {
      background-color: #009D4E; }
    .btn-fx.green:hover {
      color: white; }
  .btn-fx.blue {
    color: #0080CC; }
    .btn-fx.blue:before {
      border: 2px solid #0080CC; }
    .btn-fx.blue:after {
      background-color: #0080CC; }
    .btn-fx.blue:hover {
      color: white; }
  .btn-fx.bg-green {
    color: white; }
    .btn-fx.bg-green:before {
      background-color: #009D4E;
      border: none; }
    .btn-fx.bg-green:after {
      background-color: transparent;
      border: 2px solid #009D4E; }
    .btn-fx.bg-green:hover {
      color: #009D4E; }
  .btn-fx.bg-red {
    color: white; }
    .btn-fx.bg-red:before {
      background-color: #E6261C;
      border: none; }
    .btn-fx.bg-red:after {
      background-color: transparent;
      border: 2px solid #E6261C; }
    .btn-fx.bg-red:hover {
      color: #E6261C; }
  .btn-fx.bg-white {
    color: #E6261C; }
    .btn-fx.bg-white:before {
      background-color: white;
      border: none; }
    .btn-fx.bg-white:after {
      background-color: transparent;
      border: 2px solid white; }
    .btn-fx.bg-white:hover {
      color: white; }

.btn-page {
  border-radius: 7px;
  border: 1px solid #979797;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease; }
  .btn-page svg g {
    stroke: #979797; }
  .btn-page:hover {
    border: 1px solid #009D4E; }
    .btn-page:hover svg g {
      stroke: #009D4E; }
  .btn-page.page-number {
    border: 1px solid #15142F;
    color: #15142F; }

.plus-minus-toggle {
  cursor: pointer;
  height: 100%;
  position: relative;
  width: 25px;
  pointer-events: none; }
  @media (max-width: 575px) {
    .plus-minus-toggle {
      width: 20px; } }
  .plus-minus-toggle:before, .plus-minus-toggle:after {
    background: #E6261C;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;
    transition: transform 500ms ease; }
    @media (max-width: 575px) {
      .plus-minus-toggle:before, .plus-minus-toggle:after {
        width: 20px; } }
  .plus-minus-toggle:after {
    transform-origin: center;
    transform: rotate(90deg); }
  .plus-minus-toggle:before {
    transform: rotate(180deg); }

.icon-burger-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #E6261C;
  width: 42px;
  height: 42px; }
  .icon-burger-circle #icon-burger {
    width: 20px;
    height: 16px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    pointer-events: none; }
    .icon-burger-circle #icon-burger span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #E6261C;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out; }
      .icon-burger-circle #icon-burger span:nth-child(1) {
        top: 0px; }
      .icon-burger-circle #icon-burger span:nth-child(2) {
        top: 7px; }
      .icon-burger-circle #icon-burger span:nth-child(3) {
        top: 7px; }
      .icon-burger-circle #icon-burger span:nth-child(4) {
        top: 14px; }
  .icon-burger-circle.js-open-burger #icon-burger span:nth-child(1) {
    top: 7px;
    width: 0%;
    left: 50%; }
  .icon-burger-circle.js-open-burger #icon-burger span:nth-child(2) {
    transform: rotate(45deg); }
  .icon-burger-circle.js-open-burger #icon-burger span:nth-child(3) {
    transform: rotate(-45deg); }
  .icon-burger-circle.js-open-burger #icon-burger span:nth-child(4) {
    top: 7px;
    width: 0%;
    left: 50%; }

.form__field {
  font-family: inherit;
  font-size: inherit; }

/**
 *  -----------------------------------------------
 *  LOADER
 *  -----------------------------------------------
 */
.loader {
  transition: opacity 0.4s;
  transition-delay: 0.2s; }
  .loader.is-ajax {
    z-index: 21;
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0; }
    .loader.is-ajax:not(.is-active) {
      opacity: 0;
      pointer-events: none; }
    .loader.is-ajax.is-active {
      opacity: 1; }
  .loader.is-form .loading-effect {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0; }

/**
 *  -----------------------------------------------
 *  components/SOCIAL
 *
 *  1. 
 *  2. 
 *  3. 
 *  -----------------------------------------------
 */
/*
 * Social
 ****************************************************************/
/*
 * Buttons
 ****************************************************************/
/**
 *  -----------------------------------------------
 *  HEADER
 *
 *  1. Wrappers
 *  -----------------------------------------------
 */
header.header__desktop {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 1000;
  transform: translateZ(0); }
  @media (max-width: 991px) {
    header.header__desktop {
      display: none; } }
  header.header__desktop.sticky {
    position: fixed;
    top: -94px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.05); }
    header.header__desktop.sticky ~ .header__submenu {
      position: fixed;
      top: 72px; }
  header.header__desktop .header__link {
    background-color: #15142F;
    height: 36px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    header.header__desktop .header__link > a {
      color: white;
      font-family: "Roboto-Medium";
      display: flex;
      align-items: center;
      font-size: 15px;
      transition: opacity 0.35s ease; }
      header.header__desktop .header__link > a:hover {
        opacity: 0.7; }
      header.header__desktop .header__link > a img {
        width: 30px;
        height: 20px;
        padding-right: 10px; }
  header.header__desktop .header__wrapper {
    position: relative;
    width: 100%;
    max-width: 80.625%;
    margin: 0 auto;
    padding: 0 15px; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      header.header__desktop .header__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      header.header__desktop .header__wrapper {
        max-width: 90%; } }
    header.header__desktop .header__wrapper .header__menu_top {
      background-color: white;
      display: flex;
      width: 100%;
      height: 55px;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      z-index: 1000; }
      @media (max-width: 991px) {
        header.header__desktop .header__wrapper .header__menu_top {
          max-width: 100%;
          padding: 0 76px; } }
      @media (max-width: 767px) {
        header.header__desktop .header__wrapper .header__menu_top {
          padding: 0 36px; } }
      @media (max-width: 575px) {
        header.header__desktop .header__wrapper .header__menu_top {
          padding: 0 20px; } }
      header.header__desktop .header__wrapper .header__menu_top a {
        font-family: "Roboto-Regular";
        font-size: 14px;
        display: block;
        padding-bottom: 5px;
        transition: color 0.35s ease;
        color: #0080CC; }
        header.header__desktop .header__wrapper .header__menu_top a:hover {
          color: #E6261C; }
        header.header__desktop .header__wrapper .header__menu_top a + a {
          margin-right: 20px; }
      header.header__desktop .header__wrapper .header__menu_top > div > ul.nav {
        display: flex;
        align-items: center; }
        header.header__desktop .header__wrapper .header__menu_top > div > ul.nav > li {
          position: relative;
          margin-right: 40px; }
          header.header__desktop .header__wrapper .header__menu_top > div > ul.nav > li.menu-item-has-children > a {
            padding: 0 20px 5px 15px; }
          header.header__desktop .header__wrapper .header__menu_top > div > ul.nav > li.menu-item-has-children::before {
            border-style: solid;
            border-width: 1px 1px 0 0;
            content: '';
            display: inline-block;
            position: absolute;
            top: 3px;
            right: 2px;
            transform: rotate(135deg) translateZ(0);
            width: 7px;
            height: 7px;
            color: #0080CC; }
          header.header__desktop .header__wrapper .header__menu_top > div > ul.nav > li.menu-item-has-children:hover::before {
            top: 6px;
            transform: rotate(315deg); }
          header.header__desktop .header__wrapper .header__menu_top > div > ul.nav > li.menu-item-has-children:hover > a ~ .sub-menu {
            max-height: 100vh;
            transition: max-height 0.7s cubic-bezier(0.645, 0.045, 0.355, 1); }
          header.header__desktop .header__wrapper .header__menu_top > div > ul.nav > li .sub-menu {
            background-color: white;
            border-radius: 7px;
            position: absolute;
            top: 100%;
            width: 200px;
            max-height: 0;
            overflow: hidden;
            z-index: 100;
            transition: max-height 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.05);
            padding: 0 20px; }
            header.header__desktop .header__wrapper .header__menu_top > div > ul.nav > li .sub-menu > li {
              position: relative; }
              header.header__desktop .header__wrapper .header__menu_top > div > ul.nav > li .sub-menu > li > a {
                padding: 15px 0;
                color: #0080CC;
                transition: all 0.35s ease; }
                header.header__desktop .header__wrapper .header__menu_top > div > ul.nav > li .sub-menu > li > a:hover {
                  background-color: white;
                  color: #E6261C; }
              header.header__desktop .header__wrapper .header__menu_top > div > ul.nav > li .sub-menu > li + li {
                border-top: 1px solid #EEF0F6; }
      header.header__desktop .header__wrapper .header__menu_top .header__lang {
        margin-right: 40px;
        text-transform: uppercase; }
      header.header__desktop .header__wrapper .header__menu_top .header__account svg path {
        transition: fill 0.35s ease;
        fill: #0080CC; }
      header.header__desktop .header__wrapper .header__menu_top .header__account svg:hover path {
        fill: #E6261C; }
      header.header__desktop .header__wrapper .header__menu_top .header__cart svg path, header.header__desktop .header__wrapper .header__menu_top .header__cart svg g {
        transition: stroke 0.35s ease;
        stroke: #0080CC; }
      header.header__desktop .header__wrapper .header__menu_top .header__cart:hover svg path, header.header__desktop .header__wrapper .header__menu_top .header__cart:hover svg g {
        stroke: #E6261C; }
      header.header__desktop .header__wrapper .header__menu_top .header__search {
        display: flex;
        align-items: center;
        justify-content: center; }
        header.header__desktop .header__wrapper .header__menu_top .header__search a.header__search_btn {
          display: flex;
          align-items: center;
          justify-content: center; }
          header.header__desktop .header__wrapper .header__menu_top .header__search a.header__search_btn.js-toggle-search svg path, header.header__desktop .header__wrapper .header__menu_top .header__search a.header__search_btn.js-toggle-search svg g {
            stroke: #E6261C; }
          header.header__desktop .header__wrapper .header__menu_top .header__search a.header__search_btn.js-toggle-search ~ .search__form {
            width: 200px; }
          header.header__desktop .header__wrapper .header__menu_top .header__search a.header__search_btn:hover svg path, header.header__desktop .header__wrapper .header__menu_top .header__search a.header__search_btn:hover svg g {
            stroke: #E6261C; }
          header.header__desktop .header__wrapper .header__menu_top .header__search a.header__search_btn svg {
            width: 18px;
            height: 18px;
            pointer-events: none; }
            header.header__desktop .header__wrapper .header__menu_top .header__search a.header__search_btn svg path, header.header__desktop .header__wrapper .header__menu_top .header__search a.header__search_btn svg g {
              transition: stroke 0.35s ease;
              stroke: #0080CC; }
        header.header__desktop .header__wrapper .header__menu_top .header__search .search__form {
          position: relative;
          overflow: hidden;
          width: 0; }
          header.header__desktop .header__wrapper .header__menu_top .header__search .search__form .input-group {
            margin-left: 10px;
            padding-bottom: 5px;
            width: auto; }
            header.header__desktop .header__wrapper .header__menu_top .header__search .search__form .input-group input {
              border-radius: 40px;
              border: 1px solid #0080CC;
              height: 25px;
              width: 100%;
              padding: 5px 10px; }
    header.header__desktop .header__wrapper .header__menu_main {
      background-color: white;
      display: flex;
      width: 100%;
      height: auto;
      align-items: center;
      justify-content: space-between;
      position: relative; }
      @media (max-width: 991px) {
        header.header__desktop .header__wrapper .header__menu_main {
          max-width: 100%;
          padding: 0 76px; } }
      @media (max-width: 767px) {
        header.header__desktop .header__wrapper .header__menu_main {
          padding: 0 36px; } }
      @media (max-width: 575px) {
        header.header__desktop .header__wrapper .header__menu_main {
          padding: 0 20px; } }
      header.header__desktop .header__wrapper .header__menu_main a:not(.btn-fx) {
        font-family: "Roboto-Regular";
        font-size: 15px;
        display: block;
        color: #15142F; }
        header.header__desktop .header__wrapper .header__menu_main a:not(.btn-fx).header__menu_logo {
          opacity: 1;
          transition: opacity 0.35s ease;
          padding-right: 20px;
          max-width: 225px;
          width: 100%; }
          header.header__desktop .header__wrapper .header__menu_main a:not(.btn-fx).header__menu_logo:hover {
            opacity: 0.5; }
          @media (max-width: 1199px) {
            header.header__desktop .header__wrapper .header__menu_main a:not(.btn-fx).header__menu_logo {
              max-width: 200px; } }
      header.header__desktop .header__wrapper .header__menu_main_right {
        display: flex;
        align-items: center; }
        header.header__desktop .header__wrapper .header__menu_main_right > div + a.btn-menu {
          margin-left: 60px;
          white-space: nowrap; }
          @media screen and (min-width: 1200px) and (max-width: 1375px) {
            header.header__desktop .header__wrapper .header__menu_main_right > div + a.btn-menu {
              margin-left: 20px;
              font-size: 14px; } }
          @media (max-width: 1199px) {
            header.header__desktop .header__wrapper .header__menu_main_right > div + a.btn-menu {
              margin-left: 10px;
              font-size: 14px; } }
        header.header__desktop .header__wrapper .header__menu_main_right > div > ul.nav {
          display: flex;
          align-items: center; }
          header.header__desktop .header__wrapper .header__menu_main_right > div > ul.nav > li {
            position: relative;
            cursor: pointer; }
            header.header__desktop .header__wrapper .header__menu_main_right > div > ul.nav > li + li {
              margin-left: 20px; }
              @media (max-width: 1199px) {
                header.header__desktop .header__wrapper .header__menu_main_right > div > ul.nav > li + li {
                  margin-left: 10px; } }
            header.header__desktop .header__wrapper .header__menu_main_right > div > ul.nav > li > a {
              display: flex;
              align-items: center;
              height: 75px;
              pointer-events: none;
              font-size: 16px; }
              @media screen and (min-width: 1200px) and (max-width: 1375px) {
                header.header__desktop .header__wrapper .header__menu_main_right > div > ul.nav > li > a {
                  font-size: 14px; } }
              @media (max-width: 1199px) {
                header.header__desktop .header__wrapper .header__menu_main_right > div > ul.nav > li > a {
                  font-size: 14px; } }
            header.header__desktop .header__wrapper .header__menu_main_right > div > ul.nav > li::after {
              position: absolute;
              bottom: 0;
              content: '';
              display: block;
              height: 5px;
              width: 0;
              left: 50%;
              right: 50%;
              background-color: #E6261C;
              transition: all 0.35s ease; }
            header.header__desktop .header__wrapper .header__menu_main_right > div > ul.nav > li.btn-menu {
              background-color: blue; }
              header.header__desktop .header__wrapper .header__menu_main_right > div > ul.nav > li.btn-menu::after {
                content: none; }
            header.header__desktop .header__wrapper .header__menu_main_right > div > ul.nav > li.current_page_item::after {
              width: 100%;
              left: 0;
              right: 0; }
            header.header__desktop .header__wrapper .header__menu_main_right > div > ul.nav > li:hover::after, header.header__desktop .header__wrapper .header__menu_main_right > div > ul.nav > li.js-active-menu::after {
              width: 100%;
              left: 0;
              right: 0; }

.sub__container {
  position: absolute;
  top: 130px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
  z-index: 0; }
  .sub__container .sub__container-bg {
    background: rgba(52, 57, 107, 0.6);
    height: 100vh;
    left: 50%;
    position: absolute;
    opacity: 0;
    top: 0;
    transform: translate3d(-50%, 0, 0);
    width: 100vw;
    z-index: 0; }
  .sub__container .sub__container-inner {
    height: 0;
    position: relative;
    overflow: hidden;
    width: 100%;
    will-change: height; }
  .sub__container .header__submenu {
    position: relative;
    width: calc(100% - 40px);
    height: auto;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 900;
    margin: 0 20px;
    display: block;
    will-change: opacity;
    transition: opacity 0.35s ease; }
    @media (max-width: 991px) {
      .sub__container .header__submenu {
        display: none; } }
    .sub__container .header__submenu.js-show-submenu {
      opacity: 1;
      transform: translateY(0);
      z-index: 900; }
    .sub__container .header__submenu_wrapper {
      background-color: white;
      position: relative;
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      overflow: hidden; }
      .sub__container .header__submenu_wrapper .inner {
        position: relative; }
        .sub__container .header__submenu_wrapper .inner > div {
          padding: 0;
          background-color: white;
          border-radius: 7px;
          box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.05);
          position: relative;
          width: 100%;
          height: auto;
          display: none; }
          .sub__container .header__submenu_wrapper .inner > div.js-active-tab, .sub__container .header__submenu_wrapper .inner > div.js-active-sub {
            display: flex; }
          .sub__container .header__submenu_wrapper .inner > div .column {
            opacity: 0; }
          .sub__container .header__submenu_wrapper .inner > div .column-wrapper {
            display: flex;
            width: 100%;
            padding: 40px 0; }
            @media only screen and (max-width: 1440px) {
              .sub__container .header__submenu_wrapper .inner > div .column-wrapper {
                padding: 20px 0; } }
            .sub__container .header__submenu_wrapper .inner > div .column-wrapper .column {
              width: 33.33%;
              height: auto; }
          .sub__container .header__submenu_wrapper .inner > div .sub_cta {
            max-width: 80%; }
            @media only screen and (max-width: 1440px) {
              .sub__container .header__submenu_wrapper .inner > div .sub_cta {
                max-width: 100%; } }
          .sub__container .header__submenu_wrapper .inner > div .sub_cta_image {
            width: 100%;
            display: flex;
            flex-direction: column; }
            .sub__container .header__submenu_wrapper .inner > div .sub_cta_image .image {
              width: 100%;
              height: 226px;
              border-top-right-radius: 10px;
              border-top-left-radius: 10px;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
              background-color: #979797; }
              @media only screen and (max-width: 1440px) {
                .sub__container .header__submenu_wrapper .inner > div .sub_cta_image .image {
                  height: 146px; } }
            .sub__container .header__submenu_wrapper .inner > div .sub_cta_image .caption {
              width: 100%;
              height: 20%;
              padding: 20px;
              background-color: #E6E9F1;
              border-bottom-right-radius: 10px;
              border-bottom-left-radius: 10px; }
              .sub__container .header__submenu_wrapper .inner > div .sub_cta_image .caption h4 {
                font-size: 16px;
                text-transform: uppercase;
                letter-spacing: 1px;
                color: #0080CC; }
              .sub__container .header__submenu_wrapper .inner > div .sub_cta_image .caption p {
                margin-top: 10px;
                margin-bottom: 0; }
          .sub__container .header__submenu_wrapper .inner > div h2 {
            font-size: 32px;
            font-family: "Roboto-Regular";
            margin-bottom: 20px;
            max-width: 100%; }
            @media only screen and (max-width: 1440px) {
              .sub__container .header__submenu_wrapper .inner > div h2 {
                font-size: 28px; } }
            @media (max-width: 991px) {
              .sub__container .header__submenu_wrapper .inner > div h2 {
                font-size: 24px; } }
          .sub__container .header__submenu_wrapper .inner > div h3 {
            font-size: 24px;
            margin-bottom: 20px; }
          .sub__container .header__submenu_wrapper .inner > div p {
            font-family: "Roboto-Regular";
            font-size: 16px;
            margin-bottom: 20px;
            line-height: 1.2; }
          .sub__container .header__submenu_wrapper .inner > div ul li {
            margin-top: 20px;
            font-size: 16px;
            display: flex;
            align-items: center; }
            @media only screen and (max-width: 1440px) {
              .sub__container .header__submenu_wrapper .inner > div ul li {
                margin-top: 15px; } }
            .sub__container .header__submenu_wrapper .inner > div ul li img {
              width: 25px;
              margin-right: 20px; }
          .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_left {
            position: relative;
            width: 33.33%;
            height: auto;
            display: flex;
            flex-direction: column; }
            .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_left > a {
              display: flex;
              align-items: center;
              width: 100%;
              flex: 1;
              padding: 10px 40px;
              font-size: 20px;
              background-color: white;
              transition: background-color 0.35s ease; }
              @media only screen and (max-width: 1440px) {
                .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_left > a {
                  flex: auto;
                  padding: 10px 20px;
                  font-size: 16px; } }
              .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_left > a:hover, .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_left > a.js-active-tab {
                background-color: #E6E9F1; }
              .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_left > a img {
                width: 20px;
                margin-right: 20px;
                pointer-events: none; }
              .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_left > a + a {
                border-top: 1px solid #E6E9F1; }
          .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right {
            position: relative;
            width: 66.66%;
            height: auto;
            background-color: #E6E9F1; }
            .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content {
              position: relative;
              width: 100%;
              display: none;
              min-height: 427px; }
              @media only screen and (max-width: 1440px) {
                .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content {
                  min-height: 327px; } }
              .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content.js-active-content {
                display: flex; }
              .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content > div {
                display: flex;
                width: 50%;
                flex-direction: column;
                justify-content: center;
                font-family: "Roboto-Regular";
                padding: 0 40px;
                margin: 40px 0;
                overflow: hidden;
                overflow-y: auto; }
                .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content > div.subtab_right {
                  justify-content: center; }
                .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content > div::-webkit-scrollbar {
                  width: 3px;
                  padding: 2px; }
                .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content > div::-webkit-scrollbar-track {
                  border-radius: 10px; }
                .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content > div::-webkit-scrollbar-thumb {
                  background-color: #9ACDEB;
                  border-radius: 10px; }
                @media only screen and (max-width: 1440px) {
                  .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content > div {
                    padding: 0 20px;
                    margin: 20px 0; } }
                .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content > div + div {
                  border-left: 1px solid #979797; }
                .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content > div .sublink {
                  margin-top: 40px;
                  display: flex;
                  align-items: center;
                  font-family: "Roboto-Bold";
                  transition: opacity 0.35s ease;
                  font-size: 16px; }
                  @media only screen and (max-width: 1440px) {
                    .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content > div .sublink {
                      margin-top: 20px; } }
                  .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content > div .sublink:hover {
                    opacity: 0.6; }
                  .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content > div .sublink img {
                    margin-right: 10px; }
                .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content > div a:not(.btn-fx) {
                  transition: color 0.35s ease; }
                  .sub__container .header__submenu_wrapper .inner > div.tab__section .sub_right .tech_content > div a:not(.btn-fx):hover {
                    color: #E6261C; }
          .sub__container .header__submenu_wrapper .inner > div.sub__section:not(.tab__section) .column {
            padding: 0 40px; }
            @media only screen and (max-width: 1440px) {
              .sub__container .header__submenu_wrapper .inner > div.sub__section:not(.tab__section) .column {
                padding: 0 20px; } }
            .sub__container .header__submenu_wrapper .inner > div.sub__section:not(.tab__section) .column + .column {
              border-left: 1px solid #979797; }
            .sub__container .header__submenu_wrapper .inner > div.sub__section:not(.tab__section) .column a:not(.btn-fx) {
              transition: color 0.35s ease; }
              .sub__container .header__submenu_wrapper .inner > div.sub__section:not(.tab__section) .column a:not(.btn-fx):hover {
                color: #0080CC; }

header.header__mobile {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 1000; }
  @media (max-width: 991px) {
    header.header__mobile {
      display: block; } }
  header.header__mobile .header__mobile_link {
    padding: 5px 20px 8px;
    background-color: #15142F;
    text-align: center; }
    header.header__mobile .header__mobile_link > a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-family: "Roboto-Medium";
      font-size: 14px; }
      header.header__mobile .header__mobile_link > a img {
        width: 20px;
        margin-right: 7px; }
  header.header__mobile .header__mobile_wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column; }
    header.header__mobile .header__mobile_wrapper .menu__mobile_top {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media (max-width: 991px) {
        header.header__mobile .header__mobile_wrapper .menu__mobile_top {
          max-width: 100%;
          padding: 10px 76px; } }
      @media (max-width: 767px) {
        header.header__mobile .header__mobile_wrapper .menu__mobile_top {
          padding: 10px 36px; } }
      @media (max-width: 575px) {
        header.header__mobile .header__mobile_wrapper .menu__mobile_top {
          padding: 10px 20px; } }
      header.header__mobile .header__mobile_wrapper .menu__mobile_top .menu__mobile_logo {
        position: relative;
        max-width: 160px; }
        header.header__mobile .header__mobile_wrapper .menu__mobile_top .menu__mobile_logo img, header.header__mobile .header__mobile_wrapper .menu__mobile_top .menu__mobile_logo svg {
          width: 100%;
          padding-right: 10px; }
      header.header__mobile .header__mobile_wrapper .menu__mobile_top_right {
        display: flex; }
        header.header__mobile .header__mobile_wrapper .menu__mobile_top_right .bg-red {
          margin-right: 10px;
          white-space: nowrap; }
    header.header__mobile .header__mobile_wrapper .menu__mobile {
      background-color: white;
      position: relative;
      max-height: 0;
      overflow: hidden;
      overflow-y: auto;
      transition: max-height 0.35s cubic-bezier(0.645, 0.045, 0.355, 1); }
      header.header__mobile .header__mobile_wrapper .menu__mobile.js-open-menu {
        max-height: calc(90vh - 85px); }
      header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile {
        position: relative;
        padding: 0 40px; }
        header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile .nav__mobile_wc {
          display: flex;
          align-items: center;
          justify-content: flex-end; }
          header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile .nav__mobile_wc > a {
            padding: 15px 0;
            display: block;
            font-family: "Roboto-Regular";
            color: #15142F;
            font-size: 14px;
            text-align: right;
            text-transform: uppercase; }
            header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile .nav__mobile_wc > a + a {
              margin-left: 10px; }
            header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile .nav__mobile_wc > a svg {
              height: 14px;
              color: #15142F;
              font-weight: 100; }
            header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile .nav__mobile_wc > a.header__account svg path {
              fill: #15142F; }
            header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile .nav__mobile_wc > a.header__cart svg g {
              stroke: #15142F; }
        header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div {
          position: relative; }
          header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul {
            position: relative;
            background-color: white; }
            header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul.nav_mobile_menu li + li, header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul.nav_mobile_top li + li {
              border-top: 1px solid #E6E9F1; }
            header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul.nav_mobile_menu li > a, header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul.nav_mobile_top li > a {
              color: #15142F;
              font-size: 14px; }
              header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul.nav_mobile_menu li > a.current, header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul.nav_mobile_top li > a.current {
                color: #E6261C; }
            header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul.nav_mobile_top {
              border-bottom: 1px solid #E6E9F1; }
            header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul li {
              position: relative;
              border-top: 1px solid #E6E9F1; }
              header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul li.menu-item-has-children > a::before {
                border-style: solid;
                border-color: #E6261C;
                border-width: 1px 1px 0 0;
                content: '';
                display: inline-block;
                position: absolute;
                top: 18px;
                right: 10px;
                transform: rotate(135deg) translateZ(0);
                width: 7px;
                height: 7px; }
              header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul li.menu-item-has-children > a.js-toggle-sub::before {
                top: 22px;
                transform: rotate(315deg); }
              header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul li.menu-item-has-children > a.js-toggle-sub ~ .sub-menu {
                max-height: 100vh; }
              header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul li > a {
                padding: 15px 0;
                display: block;
                font-family: "Roboto-Regular";
                color: #15142F;
                font-size: 14px; }
              header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul li .sub-menu {
                position: relative;
                background-color: white;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.35s cubic-bezier(0.645, 0.045, 0.355, 1); }
                header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul li .sub-menu > li {
                  position: relative; }
                  header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul li .sub-menu > li.sub-menu-item {
                    color: #34396B;
                    font-size: 14px;
                    display: flex;
                    align-items: center; }
                    header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul li .sub-menu > li.sub-menu-item .sub-icon {
                      height: 20px;
                      margin-right: 20px; }
                    header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul li .sub-menu > li.sub-menu-item > a {
                      display: flex;
                      width: 100%;
                      align-items: center;
                      padding: 12px 20px 12px 0; }
                  header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul li .sub-menu > li > a {
                    padding: 15px 20px;
                    color: #34396B;
                    transition: all 0.35s ease; }
                    header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile > div > ul li .sub-menu > li > a:hover {
                      background-color: white;
                      color: #E6261C; }
        header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile .search__form_mobile {
          position: relative;
          padding: 40px 0;
          display: flex;
          align-items: center;
          width: 100%; }
          header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile .search__form_mobile.focused {
            background: rgba(52, 57, 107, 0.8);
            position: fixed;
            align-items: flex-start;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 5;
            padding: 150px 50px;
            transition: all 0.35s cubic-bezier(0.645, 0.045, 0.355, 1); }
          header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile .search__form_mobile .input-group {
            position: relative;
            width: 100%; }
            header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile .search__form_mobile .input-group input {
              border-radius: 40px;
              border: 1px solid #E6E9F1;
              width: 100%;
              padding: 15px; }
          header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile .search__form_mobile button {
            position: absolute;
            right: 0;
            top: 1px;
            background-color: #E6261C;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center; }
            header.header__mobile .header__mobile_wrapper .menu__mobile .nav__mobile .search__form_mobile button svg {
              width: 18px;
              height: 18px; }

/**
 *  -----------------------------------------------
 *  FOOTER
 *
 *  1. Wrappers
 *  -----------------------------------------------
 */
footer {
  position: relative;
  background-color: #15142F;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column; }
  @media (max-width: 991px) {
    footer {
      height: auto; } }
  footer .footer__wrapper {
    position: relative;
    width: 100%;
    max-width: 80.625%;
    margin: 80px auto 0;
    padding: 0 20px; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      footer .footer__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      footer .footer__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      footer .footer__wrapper {
        max-width: 100%;
        margin: 20px auto 0;
        padding: 20px; } }
    footer .footer__wrapper .footer__top {
      display: flex;
      align-items: flex-end;
      height: auto;
      margin-bottom: 40px; }
      @media (max-width: 991px) {
        footer .footer__wrapper .footer__top {
          height: auto;
          flex-direction: column; } }
      footer .footer__wrapper .footer__top .footer__top_wrapper {
        display: table;
        width: 100%; }
        @media (max-width: 1199px) {
          footer .footer__wrapper .footer__top .footer__top_wrapper {
            display: flex;
            flex-direction: column; } }
        footer .footer__wrapper .footer__top .footer__top_wrapper .column {
          display: table-cell;
          vertical-align: top; }
          @media (max-width: 991px) {
            footer .footer__wrapper .footer__top .footer__top_wrapper .column {
              display: flex;
              text-align: center;
              justify-content: center;
              margin-bottom: 40px; } }
          footer .footer__wrapper .footer__top .footer__top_wrapper .column p {
            font-family: "Roboto-Regular";
            color: white;
            font-size: 24px;
            line-height: 1.4;
            margin-bottom: 20px; }
            @media (max-width: 1199px) {
              footer .footer__wrapper .footer__top .footer__top_wrapper .column p {
                font-size: 18px; } }
          footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left {
            width: 40%;
            padding-right: 40px; }
            @media (max-width: 991px) {
              footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left {
                width: 100%;
                padding-right: 0; } }
            footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_top form {
              position: relative; }
              footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_top form.js-hidden {
                display: none; }
              footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_top form fieldset {
                position: relative;
                width: 440px; }
                @media (max-width: 991px) {
                  footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_top form fieldset {
                    width: 100%; } }
                footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_top form fieldset input[type="email"] {
                  border: none;
                  height: 50px;
                  border-radius: 40px;
                  padding: 10px 155px 10px 25px;
                  width: 100%;
                  font-size: 16px; }
                footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_top form fieldset input[type="submit"], footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_top form fieldset button {
                  position: absolute;
                  top: 0;
                  right: -1px;
                  height: 50px;
                  border-radius: 40px;
                  width: 140px;
                  text-align: center;
                  font-size: 16px;
                  cursor: pointer; }
            footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_top .message-loader {
              display: block;
              font-size: 16px;
              color: white;
              width: 100%;
              margin-top: 20px; }
              footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_top .message-loader.js-hidden {
                display: none; }
              footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_top .message-loader.js-success {
                color: #009D4E; }
              footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_top .message-loader.js-error {
                color: red; }
            footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_bottom {
              display: flex;
              position: relative;
              margin-top: 40px; }
              @media (max-width: 1199px) {
                footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_bottom {
                  flex-direction: column;
                  margin-top: 20px; } }
              @media (max-width: 991px) {
                footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_bottom {
                  margin-top: 40px;
                  align-items: center; } }
              footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_bottom p {
                margin-bottom: 0;
                margin-right: 20px; }
                @media (max-width: 1199px) {
                  footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_bottom p {
                    margin-bottom: 10px; } }
                @media (max-width: 991px) {
                  footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_bottom p {
                    margin-right: 0;
                    margin-bottom: 10px; } }
              footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_bottom .footer__socials {
                display: flex; }
                footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_bottom .footer__socials > a {
                  display: flex;
                  align-items: center; }
                  footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_bottom .footer__socials > a:hover svg .icon-color {
                    fill: white; }
                  footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_bottom .footer__socials > a + a {
                    margin-left: 20px; }
                  footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_bottom .footer__socials > a svg {
                    max-width: 100%;
                    width: 30px;
                    height: 22px; }
                    footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__left .column__left_bottom .footer__socials > a svg .icon-color {
                      transition: fill 0.35s ease;
                      fill: #5A637A; }
          footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__mid {
            width: 30%; }
            @media (max-width: 1199px) {
              footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__mid {
                margin-top: 20px; } }
            @media (max-width: 991px) {
              footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__mid {
                margin-top: 0;
                width: 100%; } }
            footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__mid .column__wrapper {
              display: flex;
              flex-direction: column; }
              footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__mid .column__wrapper .footer__number {
                display: flex;
                flex-direction: column; }
                footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__mid .column__wrapper .footer__number a {
                  display: block;
                  color: white;
                  font-size: 36px;
                  font-family: "WorkSans-Light";
                  transition: opacity 0.35s ease; }
                  @media screen and (max-width: 1350px) {
                    footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__mid .column__wrapper .footer__number a {
                      font-size: 28px; } }
                  @media (max-width: 1199px) {
                    footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__mid .column__wrapper .footer__number a {
                      font-size: 24px; } }
                  footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__mid .column__wrapper .footer__number a:hover {
                    opacity: 0.5; }
                footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__mid .column__wrapper .footer__number span {
                  display: block;
                  font-size: 14px;
                  color: #979797; }
                footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__mid .column__wrapper .footer__number + .footer__number {
                  margin-top: 40px; }
                  @media (max-width: 1199px) {
                    footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__mid .column__wrapper .footer__number + .footer__number {
                      margin-top: 20px; } }
          footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__right {
            width: 30%; }
            @media (max-width: 1199px) {
              footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__right {
                margin-top: 20px; } }
            @media (max-width: 991px) {
              footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__right {
                margin-top: 0;
                width: 100%; } }
            footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__right .column__wrapper {
              display: table;
              margin: 0 auto; }
              @media (max-width: 1199px) {
                footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__right .column__wrapper {
                  margin: 0; } }
              @media (max-width: 991px) {
                footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__right .column__wrapper {
                  display: flex;
                  flex-direction: column;
                  margin: 0; } }
              footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__right .column__wrapper > div {
                position: relative; }
                footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__right .column__wrapper > div > ul.nav li a {
                  color: white;
                  font-size: 16px;
                  transition: opacity 0.35s ease; }
                  footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__right .column__wrapper > div > ul.nav li a:hover {
                    opacity: 0.5; }
                footer .footer__wrapper .footer__top .footer__top_wrapper .column.column__right .column__wrapper > div > ul.nav li + li {
                  margin-top: 20px; }
  footer .footer__bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #979797;
    height: 60px; }
    @media (max-width: 991px) {
      footer .footer__bottom {
        border-top: none;
        justify-content: center;
        height: auto; } }
    footer .footer__bottom .column__wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between; }
      @media (max-width: 991px) {
        footer .footer__bottom .column__wrapper {
          flex-direction: column-reverse;
          align-items: center; } }
      footer .footer__bottom .column__wrapper .copyright {
        color: #979797;
        font-size: 14px; }
        @media (max-width: 1199px) {
          footer .footer__bottom .column__wrapper .copyright {
            font-size: 13px; } }
        @media (max-width: 991px) {
          footer .footer__bottom .column__wrapper .copyright {
            padding-top: 10px;
            width: 100%;
            text-align: center; } }
        @media (max-width: 575px) {
          footer .footer__bottom .column__wrapper .copyright {
            font-size: 12px; } }
      footer .footer__bottom .column__wrapper > div {
        position: relative; }
        @media (max-width: 991px) {
          footer .footer__bottom .column__wrapper > div {
            border-bottom: 1px solid #979797;
            padding-bottom: 10px;
            width: 100%;
            text-align: center; } }
        footer .footer__bottom .column__wrapper > div > ul.nav {
          display: flex; }
          @media (max-width: 991px) {
            footer .footer__bottom .column__wrapper > div > ul.nav {
              justify-content: center; } }
          footer .footer__bottom .column__wrapper > div > ul.nav li {
            text-align: center; }
            footer .footer__bottom .column__wrapper > div > ul.nav li a {
              color: white;
              font-size: 14px;
              color: #979797;
              transition: opacity 0.35s ease; }
              @media (max-width: 1199px) {
                footer .footer__bottom .column__wrapper > div > ul.nav li a {
                  font-size: 12px; } }
              @media (max-width: 767px) {
                footer .footer__bottom .column__wrapper > div > ul.nav li a {
                  font-size: 10px; } }
              footer .footer__bottom .column__wrapper > div > ul.nav li a:hover {
                opacity: 0.5; }
            footer .footer__bottom .column__wrapper > div > ul.nav li + li {
              margin-left: 40px; }
              @media (max-width: 1199px) {
                footer .footer__bottom .column__wrapper > div > ul.nav li + li {
                  margin-left: 20px; } }
              @media (max-width: 767px) {
                footer .footer__bottom .column__wrapper > div > ul.nav li + li {
                  margin-left: 5px; } }

/**
 *  -----------------------------------------------
 *  layouts/EDITOR
 *
 *  1. Wrapper
 *  2. List
 *  3. Text
 *  4. Titles
 *  5. Other components
 *  -----------------------------------------------
 */
/*
 * 1. Wrapper
 ****************************************************************/
.editor-wrap {
  /*
   * 2. List
   ****************************************************************/
  /*
   * 3. Text
   ****************************************************************/
  /*
   * 4. Titles
   ****************************************************************/
  /*
   * 5. Other components
   ****************************************************************/ }
  .editor-wrap strong {
    font-weight: 700; }
  .editor-wrap em {
    font-style: italic; }
  .editor-wrap ul {
    padding-left: 0.8em; }
    .editor-wrap ul > li {
      list-style: none;
      text-indent: -0.8em; }
      .editor-wrap ul > li::before {
        background: currentColor;
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 6px;
        margin-right: calc(0.8em - 6px);
        vertical-align: middle;
        width: 6px; }
  .editor-wrap ol {
    margin-left: 1em; }
    .editor-wrap ol li {
      list-style-type: decimal; }
  .editor-wrap ul li:not(:last-child), .editor-wrap ol li:not(:last-child) {
    margin-bottom: 5px; }
  .editor-wrap p:not(:last-child), .editor-wrap ul:not(:last-child), .editor-wrap ol:not(:last-child) {
    margin-bottom: 25px; }
  .editor-wrap p:empty {
    display: none; }
  .editor-wrap table {
    width: 100%; }
    .editor-wrap table tr:first-child td {
      padding-bottom: 10px; }
  .editor-wrap img {
    max-width: 100%; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 15px;
    width: 15px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 15px;
      width: 15px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid black;
        font-family: "slick";
        font-size: 0;
        line-height: 20px;
        text-align: center;
        color: transparent;
        opacity: 1;
        background-color: transparent;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: #0080CC;
      opacity: 1;
      background-color: #0080CC;
      border: 1px solid #0080CC; }

/**
 *  -----------------------------------------------
 *  pages/404
 *
 *  1. Wrapper / header
 *  2. Second section
 *  -----------------------------------------------
 */
/*
 * 1. Wrapper / header
 ****************************************************************/
.error404 {
  position: relative; }
  .error404 .wrap {
    height: calc(100vh - 166px);
    background-color: #114277; }
    @media (max-width: 767px) {
      .error404 .wrap {
        height: calc(100vh - 93px); } }
    .error404 .wrap .content {
      height: 100%; }
      .error404 .wrap .content .main {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        color: white;
        padding: 0 20px; }
        .error404 .wrap .content .main .page-header {
          font-size: 58px;
          font-family: "WorkSans-Light";
          padding-bottom: 20px; }
          @media (max-width: 767px) {
            .error404 .wrap .content .main .page-header {
              font-size: 32px; } }
        .error404 .wrap .content .main .alert {
          font-size: 24px;
          font-family: "Roboto-Regular";
          padding-bottom: 20px;
          text-align: center; }
          @media (max-width: 767px) {
            .error404 .wrap .content .main .alert {
              font-size: 18px; } }

/*
* 2. Second section
****************************************************************/
/**
 *  -----------------------------------------------
 *  pages/HOME
 *
 *  1. Wrapper / header
 *  2. Second section
 *  -----------------------------------------------
 */
/*
* 2. Second section
****************************************************************/
body.home {
  position: relative; }

.section__hero_top {
  position: relative;
  width: 100%;
  height: calc(100vh - 155px);
  background-color: #E6E9F1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 500; }
  @media only screen and (max-width: 1280px) {
    .section__hero_top {
      height: 60vh; } }
  @media (max-width: 767px) {
    .section__hero_top {
      height: calc(100vh - 205px); } }
  .home .section__hero_top + section {
    padding-top: 200px; }
  .section__hero_top .hero__breadcrumb {
    position: absolute;
    width: 100%;
    top: 60px;
    left: 0;
    font-family: "Roboto-Regular";
    color: white; }
    @media (max-width: 767px) {
      .section__hero_top .hero__breadcrumb {
        top: 20px; } }
    .section__hero_top .hero__breadcrumb .breadcrumbs {
      position: relative;
      max-width: 80.625%;
      margin: 0 auto;
      padding: 0 20px; }
      @media screen and (min-width: 1200px) and (max-width: 1350px) {
        .section__hero_top .hero__breadcrumb .breadcrumbs {
          max-width: 85%; } }
      @media (max-width: 1199px) {
        .section__hero_top .hero__breadcrumb .breadcrumbs {
          max-width: 90%; } }
      @media (max-width: 991px) {
        .section__hero_top .hero__breadcrumb .breadcrumbs {
          max-width: 100%;
          font-size: 13px; } }
      .section__hero_top .hero__breadcrumb .breadcrumbs > a {
        transition: opacity 0.35s ease; }
        .section__hero_top .hero__breadcrumb .breadcrumbs > a:hover {
          opacity: 0.5; }
    .section__hero_top .hero__breadcrumb ~ .section__wrapper .hero__cta {
      padding-top: 80px; }
      @media (max-width: 767px) {
        .section__hero_top .hero__breadcrumb ~ .section__wrapper .hero__cta {
          padding-top: 40px; } }
  .section__hero_top .section__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 80.625%;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
    z-index: 100; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__hero_top .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__hero_top .section__wrapper {
        max-width: 90%; } }
    .home .section__hero_top .section__wrapper {
      height: calc(100% - 90px); }
    @media (max-width: 991px) {
      .section__hero_top .section__wrapper {
        padding: 0 76px;
        max-width: 100%; } }
    @media (max-width: 767px) {
      .section__hero_top .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__hero_top .section__wrapper {
        padding: 0 20px; } }
    .section__hero_top .section__wrapper .hero__cta {
      width: 100%;
      max-width: 70%;
      padding-right: 20px; }
      @media (max-width: 991px) {
        .section__hero_top .section__wrapper .hero__cta {
          max-width: 100%;
          padding-right: 0; } }
      .section__hero_top .section__wrapper .hero__cta .prefix {
        font-family: "Roboto-Medium";
        color: white;
        text-transform: uppercase;
        font-size: 24px; }
        @media (max-width: 1199px) {
          .section__hero_top .section__wrapper .hero__cta .prefix {
            font-size: 18px; } }
      .section__hero_top .section__wrapper .hero__cta h1 {
        font-size: 48px;
        color: white;
        margin-top: 20px;
        font-family: "WorkSans-Medium"; }
        @media (max-width: 1199px) {
          .section__hero_top .section__wrapper .hero__cta h1 {
            font-size: 36px; } }
        @media (max-width: 991px) {
          .section__hero_top .section__wrapper .hero__cta h1 {
            font-size: 26px; } }
      .section__hero_top .section__wrapper .hero__cta p {
        color: white;
        line-height: 1.4;
        letter-spacing: 1px;
        font-family: "Roboto-Regular";
        margin-top: 20px;
        font-size: 16px; }
        @media (max-width: 767px) {
          .section__hero_top .section__wrapper .hero__cta p {
            font-size: 13px; } }
      .section__hero_top .section__wrapper .hero__cta a.btn, .section__hero_top .section__wrapper .hero__cta a.btn-fx {
        margin-top: 20px; }
  .section__hero_top.hero__small {
    height: 456px;
    display: flex;
    align-items: flex-end; }
    @media only screen and (max-width: 1280px) {
      .section__hero_top.hero__small {
        height: auto;
        min-height: 30vh; } }
    .woocommerce-page:not(.post-type-archive-product) .section__hero_top.hero__small {
      min-height: 228px; }
      @media only screen and (max-width: 1280px) {
        .woocommerce-page:not(.post-type-archive-product) .section__hero_top.hero__small {
          min-height: 228px; } }
      @media (max-width: 767px) {
        .woocommerce-page:not(.post-type-archive-product) .section__hero_top.hero__small {
          min-height: 156px; } }
      .woocommerce-page:not(.post-type-archive-product) .section__hero_top.hero__small .section__wrapper {
        padding: 60px 20px 40px; }
        @media (max-width: 767px) {
          .woocommerce-page:not(.post-type-archive-product) .section__hero_top.hero__small .section__wrapper {
            padding: 60px 36px 20px; } }
        @media (max-width: 575px) {
          .woocommerce-page:not(.post-type-archive-product) .section__hero_top.hero__small .section__wrapper {
            padding: 60px 20px 20px; } }
    .post-type-archive-product .section__hero_top.hero__small {
      min-height: 356px; }
      @media only screen and (max-width: 1280px) {
        .post-type-archive-product .section__hero_top.hero__small {
          min-height: 228px; } }
      @media (max-width: 767px) {
        .post-type-archive-product .section__hero_top.hero__small {
          min-height: 156px; } }
      .post-type-archive-product .section__hero_top.hero__small .section__wrapper {
        padding: 60px 20px 40px; }
        @media (max-width: 767px) {
          .post-type-archive-product .section__hero_top.hero__small .section__wrapper {
            padding: 60px 36px 20px; } }
        @media (max-width: 575px) {
          .post-type-archive-product .section__hero_top.hero__small .section__wrapper {
            padding: 60px 20px 20px; } }
    @media (max-width: 767px) {
      .section__hero_top.hero__small {
        min-height: 156px; } }
    .section__hero_top.hero__small .section__wrapper {
      padding: 60px 20px 80px; }
      @media (max-width: 991px) {
        .section__hero_top.hero__small .section__wrapper {
          max-width: 100%;
          padding: 20px 76px 40px; } }
      @media (max-width: 767px) {
        .section__hero_top.hero__small .section__wrapper {
          padding: 20px 36px 40px; } }
      @media (max-width: 575px) {
        .section__hero_top.hero__small .section__wrapper {
          padding: 20px 20px 20px; } }

.section__cta_image {
  background-color: #E6E9F1;
  display: flex;
  height: auto;
  padding: 90px 0; }
  @media (max-width: 991px) {
    .section__cta_image {
      padding: 40px 0; } }
  .section__cta_image .section__wrapper {
    position: relative;
    margin: 60px auto 20px;
    max-width: 80.625%;
    padding: 0 20px;
    width: 100%; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__cta_image .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__cta_image .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__cta_image .section__wrapper {
        margin: 0 auto;
        max-width: 100%;
        padding: 0 76px; } }
    @media (max-width: 767px) {
      .section__cta_image .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__cta_image .section__wrapper {
        padding: 0 20px; } }

.section__cta_tab_circle {
  background-color: white;
  height: auto;
  padding: 90px 0; }
  @media (max-width: 991px) {
    .section__cta_tab_circle {
      height: auto;
      padding: 40px 0; } }
  .section__cta_tab_circle .section__wrapper {
    position: relative;
    max-width: 80.625%;
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__cta_tab_circle .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__cta_tab_circle .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__cta_tab_circle .section__wrapper {
        max-width: 100%;
        padding: 0 76px; } }
    @media (max-width: 767px) {
      .section__cta_tab_circle .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__cta_tab_circle .section__wrapper {
        padding: 0 20px; } }
    .section__cta_tab_circle .section__wrapper h2 {
      font-family: "WorkSans-Light";
      font-size: 42px;
      margin-bottom: 40px;
      line-height: 1.4; }
      @media (max-width: 1199px) {
        .section__cta_tab_circle .section__wrapper h2 {
          font-size: 32px; } }
      @media (max-width: 991px) {
        .section__cta_tab_circle .section__wrapper h2 {
          font-size: 24px;
          margin-bottom: 20px; } }
    .section__cta_tab_circle .section__wrapper h3 {
      font-family: "Roboto-Regular";
      font-size: 24px;
      margin-bottom: 20px;
      line-height: 1.4; }
      @media (max-width: 1199px) {
        .section__cta_tab_circle .section__wrapper h3 {
          font-size: 18px; } }
    .section__cta_tab_circle .section__wrapper .tab__header {
      position: relative;
      height: auto;
      overflow: hidden;
      overflow-x: auto; }
      .section__cta_tab_circle .section__wrapper .tab__header::-webkit-scrollbar {
        display: none; }
      @media (max-width: 767px) {
        .section__cta_tab_circle .section__wrapper .tab__header {
          margin: 0 -36px 0 0; } }
      @media (max-width: 575px) {
        .section__cta_tab_circle .section__wrapper .tab__header {
          margin: 0 -20px 0 0; } }
      .section__cta_tab_circle .section__wrapper .tab__header_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%; }
        @media (max-width: 767px) {
          .section__cta_tab_circle .section__wrapper .tab__header_wrapper {
            min-width: 768px; } }
        @media (max-width: 575px) {
          .section__cta_tab_circle .section__wrapper .tab__header_wrapper {
            min-width: 575px; } }
        .section__cta_tab_circle .section__wrapper .tab__header_wrapper > a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 20px;
          padding: 30px 20px 30px 0;
          width: 100%;
          cursor: pointer;
          transition: color 0.35s ease;
          overflow: hidden; }
          .section__cta_tab_circle .section__wrapper .tab__header_wrapper > a:hover {
            color: #E6261C; }
          @media (max-width: 1199px) {
            .section__cta_tab_circle .section__wrapper .tab__header_wrapper > a {
              font-size: 16px; } }
          @media (max-width: 991px) {
            .section__cta_tab_circle .section__wrapper .tab__header_wrapper > a {
              padding: 20px 10px 20px 0;
              font-size: 13px;
              max-width: inherit !important; } }
          @media (max-width: 767px) {
            .section__cta_tab_circle .section__wrapper .tab__header_wrapper > a {
              padding: 10px 10px 10px 0; } }
          .section__cta_tab_circle .section__wrapper .tab__header_wrapper > a img {
            padding-right: 20px;
            pointer-events: none; }
            @media (max-width: 991px) {
              .section__cta_tab_circle .section__wrapper .tab__header_wrapper > a img {
                width: 30px;
                padding-right: 10px; } }
      .section__cta_tab_circle .section__wrapper .tab__header .tab__header_line {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: #E6E9F1; }
        @media (max-width: 767px) {
          .section__cta_tab_circle .section__wrapper .tab__header .tab__header_line {
            height: 3px;
            min-width: 768px; } }
        @media (max-width: 575px) {
          .section__cta_tab_circle .section__wrapper .tab__header .tab__header_line {
            min-width: 575px; } }
        .section__cta_tab_circle .section__wrapper .tab__header .tab__header_line .progress {
          position: absolute;
          display: block;
          overflow: hidden;
          top: 0;
          left: 0;
          margin-left: 0;
          height: 100%;
          background-color: #E6261C;
          z-index: 25;
          transition: margin-left 0.35s ease;
          transform: translateZ(0); }
    .section__cta_tab_circle .section__wrapper .tab__container {
      margin-top: 100px;
      position: relative; }
      @media (max-width: 991px) {
        .section__cta_tab_circle .section__wrapper .tab__container {
          margin-top: 20px; } }
      .section__cta_tab_circle .section__wrapper .tab__container .tab_content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 0.35s ease, transform 0.35s ease;
        z-index: 50;
        display: flex; }
        .section__cta_tab_circle .section__wrapper .tab__container .tab_content.js-active-content {
          position: relative;
          opacity: 1;
          transform: translateY(0);
          transition: opacity 0.35s ease, transform 0.35s ease;
          transition-delay: 0.7s;
          z-index: 100; }

.section__cta_circle, .section__cta_square {
  background-color: white;
  height: auto;
  padding: 90px 0; }
  @media (max-width: 991px) {
    .section__cta_circle, .section__cta_square {
      height: auto;
      padding: 40px 0; } }
  .section__cta_circle .section__wrapper, .section__cta_square .section__wrapper {
    position: relative;
    max-width: 80.625%;
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    min-height: 400px; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__cta_circle .section__wrapper, .section__cta_square .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__cta_circle .section__wrapper, .section__cta_square .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__cta_circle .section__wrapper, .section__cta_square .section__wrapper {
        max-width: 100%;
        padding: 0 76px;
        min-height: inherit; } }
    @media (max-width: 767px) {
      .section__cta_circle .section__wrapper, .section__cta_square .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__cta_circle .section__wrapper, .section__cta_square .section__wrapper {
        padding: 0 20px; } }

.section__cta_image_infobox {
  height: auto;
  padding: 90px 0; }
  @media (max-width: 991px) {
    .section__cta_image_infobox {
      height: auto;
      padding: 40px 0; } }
  .section__cta_image_infobox .section__wrapper {
    position: relative;
    max-width: 80.625%;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__cta_image_infobox .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__cta_image_infobox .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__cta_image_infobox .section__wrapper {
        max-width: 100%;
        padding: 0 76px; } }
    @media (max-width: 767px) {
      .section__cta_image_infobox .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__cta_image_infobox .section__wrapper {
        padding: 0 20px; } }

.section__cta_three_item {
  position: relative;
  overflow: hidden;
  height: auto;
  padding: 90px 0; }
  @media (max-width: 991px) {
    .section__cta_three_item {
      height: auto;
      padding: 40px 0; } }
  .section__cta_three_item .section__wrapper {
    position: relative;
    max-width: 80.625%;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__cta_three_item .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__cta_three_item .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__cta_three_item .section__wrapper {
        max-width: 100%;
        padding: 0 76px 40px; } }
    @media (max-width: 767px) {
      .section__cta_three_item .section__wrapper {
        padding: 0 36px 40px; } }
    @media (max-width: 575px) {
      .section__cta_three_item .section__wrapper {
        padding: 0 20px 40px; } }
    .section__cta_three_item .section__wrapper h2 {
      font-family: "WorkSans-Light";
      font-size: 42px; }
      @media (max-width: 1199px) {
        .section__cta_three_item .section__wrapper h2 {
          font-size: 32px; } }
      @media (max-width: 767px) {
        .section__cta_three_item .section__wrapper h2 {
          font-size: 24px;
          margin-bottom: 20px; } }
    .section__cta_three_item .section__wrapper .cta__item_row {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 -40px; }
      @media (max-width: 991px) {
        .section__cta_three_item .section__wrapper .cta__item_row {
          margin: 0 -20px; } }
      @media (max-width: 767px) {
        .section__cta_three_item .section__wrapper .cta__item_row {
          display: none; } }
    .section__cta_three_item .section__wrapper .cta__item_row_mobile {
      display: none; }
      @media (max-width: 767px) {
        .section__cta_three_item .section__wrapper .cta__item_row_mobile {
          margin: 0 -20px;
          display: block; }
          .section__cta_three_item .section__wrapper .cta__item_row_mobile .cta__grid_item {
            padding: 0 10px; } }

.section__cta_full_width {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 300; }
  .section__cta_full_width .section__wrapper {
    position: relative;
    width: 100%; }

.section__cta_read_also {
  position: relative;
  overflow: hidden;
  height: auto;
  padding: 45px 0 90px; }
  @media (max-width: 991px) {
    .section__cta_read_also {
      padding: 20px 0; } }
  .section__cta_read_also .section__wrapper {
    position: relative;
    max-width: 52%;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column; }
    @media (max-width: 991px) {
      .section__cta_read_also .section__wrapper {
        max-width: 100%;
        padding: 0 76px; } }
    @media (max-width: 767px) {
      .section__cta_read_also .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__cta_read_also .section__wrapper {
        padding: 0 20px; } }
    .section__cta_read_also .section__wrapper h2 {
      font-family: "WorkSans-Light";
      font-size: 42px;
      margin-bottom: 40px;
      line-height: 1.4; }
      @media (max-width: 1199px) {
        .section__cta_read_also .section__wrapper h2 {
          font-size: 32px;
          margin-bottom: 20px; } }
      @media (max-width: 991px) {
        .section__cta_read_also .section__wrapper h2 {
          font-size: 24px; } }

.section__cta_related {
  position: relative;
  overflow: hidden;
  height: auto;
  padding: 90px 0; }
  @media (max-width: 991px) {
    .section__cta_related {
      padding: 40px 0; } }
  .section__cta_related .section__wrapper {
    position: relative;
    max-width: 80.625%;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__cta_related .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__cta_related .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__cta_related .section__wrapper {
        max-width: 100%;
        padding: 0 76px; } }
    @media (max-width: 767px) {
      .section__cta_related .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__cta_related .section__wrapper {
        padding: 0 20px; } }
    .section__cta_related .section__wrapper h2 {
      font-family: "WorkSans-Light";
      font-size: 36px;
      line-height: 1.4;
      color: #34396B;
      margin-bottom: 20px; }
      @media (max-width: 1199px) {
        .section__cta_related .section__wrapper h2 {
          font-size: 32px; } }
      @media (max-width: 991px) {
        .section__cta_related .section__wrapper h2 {
          font-size: 24px; } }
    .section__cta_related .section__wrapper ul {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 450px); }
      @media (max-width: 1199px) {
        .section__cta_related .section__wrapper ul {
          width: 100%; } }
      .section__cta_related .section__wrapper ul li {
        display: flex;
        align-items: center;
        word-break: break-word;
        background-image: url("../images/icons/icon-blue-list-arrow.svg");
        background-repeat: no-repeat;
        background-position: 0px center;
        list-style: none;
        padding-left: 40px;
        padding-right: 20px;
        width: 50%;
        margin-top: 20px; }
        @media (max-width: 767px) {
          .section__cta_related .section__wrapper ul li {
            width: 100%;
            font-size: 13px; } }
        .section__cta_related .section__wrapper ul li.bullet {
          background-image: url("../images/icons/icon-blue-bullet.svg");
          padding-left: 20px; }
          .section__cta_related .section__wrapper ul li.bullet a {
            color: #34396B; }
        .section__cta_related .section__wrapper ul li.arrow {
          background-image: url("../images/icons/icon-blue-list-arrow.svg"); }
        .section__cta_related .section__wrapper ul li a {
          opacity: 1;
          transition: opacity 0.35s ease;
          color: #0080CC; }
          .section__cta_related .section__wrapper ul li a:hover {
            opacity: 0.6; }

.section__cta_label_list {
  position: relative;
  overflow: hidden;
  height: auto;
  padding: 90px 0; }
  @media (max-width: 991px) {
    .section__cta_label_list {
      padding: 40px 0; } }
  .section__cta_label_list .section__wrapper {
    position: relative;
    max-width: 80.625%;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__cta_label_list .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__cta_label_list .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__cta_label_list .section__wrapper {
        max-width: 100%;
        padding: 0 76px; } }
    @media (max-width: 767px) {
      .section__cta_label_list .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__cta_label_list .section__wrapper {
        padding: 0 20px; } }
    .section__cta_label_list .section__wrapper h2 {
      font-family: "WorkSans-Medium";
      font-size: 36px;
      line-height: 1.4;
      color: #34396B;
      margin-bottom: 40px; }
      @media (max-width: 1199px) {
        .section__cta_label_list .section__wrapper h2 {
          font-size: 32px; } }
      @media (max-width: 991px) {
        .section__cta_label_list .section__wrapper h2 {
          font-size: 24px; } }
    .section__cta_label_list .section__wrapper ul {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -40px; }
      .section__cta_label_list .section__wrapper ul li {
        display: flex;
        align-items: center;
        word-break: break-all;
        background-repeat: no-repeat;
        background-position: 0px center;
        list-style: none;
        border-top: 1px solid #9ACDEB;
        width: calc(50% - 80px);
        margin: 0 40px; }
        @media (max-width: 767px) {
          .section__cta_label_list .section__wrapper ul li {
            width: 100%;
            font-size: 13px; } }
        .section__cta_label_list .section__wrapper ul li:last-child, .section__cta_label_list .section__wrapper ul li:nth-last-child(2) {
          border-bottom: 1px solid #9ACDEB; }
          @media (max-width: 767px) {
            .section__cta_label_list .section__wrapper ul li:last-child, .section__cta_label_list .section__wrapper ul li:nth-last-child(2) {
              border-bottom: none; } }
        @media (max-width: 767px) {
          .section__cta_label_list .section__wrapper ul li:last-child {
            border-bottom: 1px solid #9ACDEB; } }
        .section__cta_label_list .section__wrapper ul li a {
          position: relative;
          display: flex;
          align-items: center;
          opacity: 1;
          transition: opacity 0.35s ease;
          color: #114277;
          font-size: 24px;
          width: 100%;
          height: 100px;
          padding-right: 60px; }
          @media (max-width: 991px) {
            .section__cta_label_list .section__wrapper ul li a {
              font-size: 16px;
              height: 70px; } }
          @media (max-width: 575px) {
            .section__cta_label_list .section__wrapper ul li a {
              font-size: 13px;
              height: 60px; } }
          .section__cta_label_list .section__wrapper ul li a:hover {
            opacity: 0.6; }
          .section__cta_label_list .section__wrapper ul li a img.icon {
            margin-right: 40px;
            padding: 10px;
            width: 60px; }
            @media (max-width: 991px) {
              .section__cta_label_list .section__wrapper ul li a img.icon {
                margin-right: 0;
                padding-right: 20px;
                width: 45px; } }
          .section__cta_label_list .section__wrapper ul li a img.chevron {
            position: absolute;
            right: 40px;
            top: 50%;
            transform: translateY(-50%); }
            @media (max-width: 991px) {
              .section__cta_label_list .section__wrapper ul li a img.chevron {
                right: 20px; } }

.section__cta_icon {
  position: relative;
  overflow: hidden;
  height: auto;
  padding: 90px 0; }
  @media (max-width: 991px) {
    .section__cta_icon {
      height: auto;
      padding: 40px 0; } }
  .section__cta_icon .section__wrapper {
    position: relative;
    max-width: 80.625%;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__cta_icon .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__cta_icon .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__cta_icon .section__wrapper {
        max-width: 100%;
        padding: 0 76px; } }
    @media (max-width: 767px) {
      .section__cta_icon .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__cta_icon .section__wrapper {
        padding: 0 20px; } }
    .section__cta_icon .section__wrapper h2 {
      font-family: "WorkSans-Light";
      font-size: 42px; }
      @media (max-width: 1199px) {
        .section__cta_icon .section__wrapper h2 {
          font-size: 32px; } }
      @media (max-width: 991px) {
        .section__cta_icon .section__wrapper h2 {
          font-size: 24px; } }
    .section__cta_icon .section__wrapper .cta__icon_row {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -40px;
      justify-content: center; }
      @media (max-width: 991px) {
        .section__cta_icon .section__wrapper .cta__icon_row {
          margin: 0 -20px; } }
      @media (max-width: 767px) {
        .section__cta_icon .section__wrapper .cta__icon_row {
          margin: 0;
          flex-direction: column; } }

.section__cta_product_slider {
  position: relative;
  overflow: hidden;
  height: auto;
  padding: 90px 0; }
  @media (max-width: 991px) {
    .section__cta_product_slider {
      height: auto;
      padding: 40px 0; } }
  .section__cta_product_slider .section__wrapper {
    position: relative;
    max-width: 80.625%;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__cta_product_slider .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__cta_product_slider .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__cta_product_slider .section__wrapper {
        max-width: 100%;
        padding: 0 76px 40px; } }
    @media (max-width: 767px) {
      .section__cta_product_slider .section__wrapper {
        padding: 0 36px 40px; } }
    @media (max-width: 575px) {
      .section__cta_product_slider .section__wrapper {
        padding: 0 20px 0; } }
    .section__cta_product_slider .section__wrapper h2 {
      font-family: "WorkSans-Light";
      font-size: 42px; }
      @media (max-width: 1199px) {
        .section__cta_product_slider .section__wrapper h2 {
          font-size: 32px; } }
      @media (max-width: 767px) {
        .section__cta_product_slider .section__wrapper h2 {
          font-size: 24px;
          margin-bottom: 20px; } }
    .section__cta_product_slider .section__wrapper .slick-list, .section__cta_product_slider .section__wrapper .slick-track {
      width: 100%; }
    .section__cta_product_slider .section__wrapper .cta__product_slider_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px; }
      @media (max-width: 575px) {
        .section__cta_product_slider .section__wrapper .cta__product_slider_top {
          flex-direction: column; } }
      .section__cta_product_slider .section__wrapper .cta__product_slider_top h2 {
        font-family: "WorkSans-Medium";
        margin-bottom: 0; }
        @media (max-width: 575px) {
          .section__cta_product_slider .section__wrapper .cta__product_slider_top h2 {
            margin-bottom: 20px; } }
    .section__cta_product_slider .section__wrapper .cta__product_slider {
      position: relative;
      display: flex;
      justify-content: center;
      margin: 0 -40px;
      opacity: 0;
      transition: opacity 0.35s ease; }
      @media (max-width: 991px) {
        .section__cta_product_slider .section__wrapper .cta__product_slider {
          margin: 0 -20px;
          padding: 0 20px; } }
      .section__cta_product_slider .section__wrapper .cta__product_slider.js-init {
        opacity: 1; }
      .section__cta_product_slider .section__wrapper .cta__product_slider > svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        height: 100%;
        background-color: white;
        z-index: 10; }
        .section__cta_product_slider .section__wrapper .cta__product_slider > svg.prev {
          left: -10px; }
          @media (max-width: 991px) {
            .section__cta_product_slider .section__wrapper .cta__product_slider > svg.prev {
              width: 15px;
              left: 10px; } }
        .section__cta_product_slider .section__wrapper .cta__product_slider > svg.next {
          right: -10px; }
          @media (max-width: 991px) {
            .section__cta_product_slider .section__wrapper .cta__product_slider > svg.next {
              width: 15px;
              right: 10px; } }

.section__logo_gallery {
  background-color: #E6E9F1;
  height: auto;
  padding: 40px 0; }
  .section__logo_gallery .section__wrapper {
    position: relative;
    max-width: 80.625%;
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__logo_gallery .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__logo_gallery .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__logo_gallery .section__wrapper {
        max-width: 100%;
        padding: 0 76px; } }
    @media (max-width: 767px) {
      .section__logo_gallery .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__logo_gallery .section__wrapper {
        padding: 0 20px; } }
    .section__logo_gallery .section__wrapper h3 {
      font-size: 24px;
      margin-bottom: 20px; }
      @media (max-width: 1199px) {
        .section__logo_gallery .section__wrapper h3 {
          font-size: 18px; } }
      @media (max-width: 991px) {
        .section__logo_gallery .section__wrapper h3 {
          text-align: center; } }
    .section__logo_gallery .section__wrapper .gallery {
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      overflow-x: auto;
      margin: 0; }
      .section__logo_gallery .section__wrapper .gallery-logo {
        display: flex;
        align-items: center;
        margin: 20px; }
        .section__logo_gallery .section__wrapper .gallery-logo img {
          filter: url("../images/gray.svg#grayscale");
          filter: gray;
          width: 125px;
          height: auto;
          max-width: fit-content; }
    .section__logo_gallery .section__wrapper .gallery::-webkit-scrollbar {
      height: 5px;
      padding: 2px;
      cursor: pointer; }
      @media (max-width: 575px) {
        .section__logo_gallery .section__wrapper .gallery::-webkit-scrollbar {
          height: 3px; } }
    .section__logo_gallery .section__wrapper .gallery::-webkit-scrollbar-track {
      border-radius: 0; }
    .section__logo_gallery .section__wrapper .gallery::-webkit-scrollbar-thumb {
      background-color: #9ACDEB;
      border-radius: 0; }

.section__text_content {
  position: relative;
  height: auto;
  padding: 90px 0 45px; }
  @media (max-width: 991px) {
    .section__text_content {
      height: auto;
      padding: 40px 0 20px; } }
  .section__text_content .section__wrapper_centered {
    position: relative;
    max-width: 52%;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px; }
    @media (max-width: 991px) {
      .section__text_content .section__wrapper_centered {
        max-width: 100%;
        padding: 0 76px; } }
    @media (max-width: 767px) {
      .section__text_content .section__wrapper_centered {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__text_content .section__wrapper_centered {
        padding: 0 20px; } }
  .section__text_content .section__wrapper {
    position: relative;
    max-width: 80.625%;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
    display: flex; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__text_content .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__text_content .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__text_content .section__wrapper {
        flex-direction: column;
        max-width: 100%;
        padding: 0 76px; } }
    @media (max-width: 767px) {
      .section__text_content .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__text_content .section__wrapper {
        padding: 0 20px; } }
    .section__text_content .section__wrapper .text__content_left {
      position: relative;
      width: 68%;
      padding-right: 100px; }
      @media (max-width: 1199px) {
        .section__text_content .section__wrapper .text__content_left {
          padding-right: 40px; }
          .section__text_content .section__wrapper .text__content_left.none {
            width: 100%;
            padding-right: 0; } }
      @media (max-width: 991px) {
        .section__text_content .section__wrapper .text__content_left {
          padding-right: 0;
          width: 100%;
          margin-bottom: 20px; } }
      .section__text_content .section__wrapper .text__content_left .cta__slider {
        position: relative;
        margin-top: 40px; }
    .section__text_content .section__wrapper .text__content_right {
      position: relative;
      right: 20px;
      width: 32%;
      height: 100%; }
      @media (max-width: 991px) {
        .section__text_content .section__wrapper .text__content_right {
          position: relative;
          right: 0;
          width: 100%;
          height: auto; } }
      .section__text_content .section__wrapper .text__content_right .scrollmagic-pin-spacer {
        width: 100% !important; }
        @media (max-width: 991px) {
          .section__text_content .section__wrapper .text__content_right .scrollmagic-pin-spacer {
            padding: 0 !important; } }
    .section__text_content .section__wrapper.js-sidebar .section__wrapper .text__content_right {
      position: absolute; }
      .section__text_content .section__wrapper.js-sidebar .section__wrapper .text__content_right .cta-sidebar {
        position: absolute; }

.section__form_content {
  position: relative;
  overflow: hidden;
  height: auto;
  padding: 90px 0; }
  @media (max-width: 991px) {
    .section__form_content {
      height: auto;
      padding: 40px 0; } }
  .section__form_content .section__wrapper {
    position: relative;
    max-width: 80.625%;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
    display: flex; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__form_content .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__form_content .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__form_content .section__wrapper {
        flex-direction: column;
        max-width: 100%;
        padding: 0 76px; } }
    @media (max-width: 767px) {
      .section__form_content .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__form_content .section__wrapper {
        padding: 0 20px; } }
    .section__form_content .section__wrapper h2 {
      font-family: "WorkSans-Light";
      font-size: 36px; }
      @media (max-width: 1199px) {
        .section__form_content .section__wrapper h2 {
          font-size: 28px; } }
      @media (max-width: 767px) {
        .section__form_content .section__wrapper h2 {
          font-size: 24px; } }
    .section__form_content .section__wrapper h4 {
      font-family: "Roboto-Bold";
      font-size: 13px; }
    .section__form_content .section__wrapper .form__content_left {
      position: relative;
      width: 68%;
      padding-right: 100px; }
      @media (max-width: 1199px) {
        .section__form_content .section__wrapper .form__content_left {
          padding-right: 40px; } }
      @media (max-width: 991px) {
        .section__form_content .section__wrapper .form__content_left {
          padding-right: 0;
          width: 100%;
          margin-bottom: 20px; } }
      .section__form_content .section__wrapper .form__content_left form {
        display: flex;
        flex-direction: column;
        margin-top: 40px; }
        .section__form_content .section__wrapper .form__content_left form select {
          appearance: none;
          outline: 0;
          border-radius: 0;
          box-shadow: none;
          border: 0;
          border-bottom: 1px solid #979797;
          padding: 10px 0;
          width: 100%;
          font-size: 13px;
          color: #15142F;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: word-wrap;
          padding-right: 15px; }
        .section__form_content .section__wrapper .form__content_left form select::-ms-expand {
          display: none; }
        .section__form_content .section__wrapper .form__content_left form input, .section__form_content .section__wrapper .form__content_left form textarea {
          appearance: none;
          outline: 0;
          border-radius: 0;
          box-shadow: none;
          border: 0;
          padding: 10px 0;
          border-bottom: 1px solid #979797;
          width: 100%;
          font-size: 13px; }
          .section__form_content .section__wrapper .form__content_left form input::placeholder, .section__form_content .section__wrapper .form__content_left form textarea::placeholder {
            font-size: 13px;
            font-family: "Roboto-Medium"; }
        .section__form_content .section__wrapper .form__content_left form input[type="submit"] {
          display: inline-block;
          padding: 10px 20px;
          border-radius: 30px;
          font-family: "Roboto-Medium";
          font-size: 16px;
          transition: background-color 0.35s ease, border 0.35s ease;
          border: 2px solid #E6261C;
          background-color: #E6261C;
          color: white;
          max-width: 200px; }
          @media (max-width: 767px) {
            .section__form_content .section__wrapper .form__content_left form input[type="submit"] {
              max-width: 100%; } }
          .section__form_content .section__wrapper .form__content_left form input[type="submit"]:hover {
            background-color: white;
            color: #E6261C; }
        .section__form_content .section__wrapper .form__content_left form label {
          display: none; }
        .section__form_content .section__wrapper .form__content_left form span[role="alert"] {
          color: red;
          font-size: 13px;
          display: block;
          margin-top: 10px; }
        .section__form_content .section__wrapper .form__content_left form .wpcf7-validation-errors {
          border: 2px solid red;
          margin: 0;
          margin-top: 20px;
          padding: 10px 20px;
          color: red; }
        .section__form_content .section__wrapper .form__content_left form .form-top, .section__form_content .section__wrapper .form__content_left form .form-middle {
          position: relative;
          margin-bottom: 40px; }
          @media (max-width: 767px) {
            .section__form_content .section__wrapper .form__content_left form .form-top, .section__form_content .section__wrapper .form__content_left form .form-middle {
              margin-bottom: 20px; } }
          .section__form_content .section__wrapper .form__content_left form .form-top br, .section__form_content .section__wrapper .form__content_left form .form-middle br {
            display: none; }
          .section__form_content .section__wrapper .form__content_left form .form-top-wrapper, .section__form_content .section__wrapper .form__content_left form .form-middle-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -20px; }
            @media (max-width: 767px) {
              .section__form_content .section__wrapper .form__content_left form .form-top-wrapper, .section__form_content .section__wrapper .form__content_left form .form-middle-wrapper {
                flex-direction: column;
                margin: 0; } }
            .section__form_content .section__wrapper .form__content_left form .form-top-wrapper .group, .section__form_content .section__wrapper .form__content_left form .form-middle-wrapper .group {
              position: relative;
              width: calc(50% - 40px);
              margin: 20px; }
              @media (max-width: 1199px) {
                .section__form_content .section__wrapper .form__content_left form .form-top-wrapper .group, .section__form_content .section__wrapper .form__content_left form .form-middle-wrapper .group {
                  width: 100%; } }
              @media (max-width: 767px) {
                .section__form_content .section__wrapper .form__content_left form .form-top-wrapper .group, .section__form_content .section__wrapper .form__content_left form .form-middle-wrapper .group {
                  width: 100%;
                  margin: 10px 0; } }
              .section__form_content .section__wrapper .form__content_left form .form-top-wrapper .group span, .section__form_content .section__wrapper .form__content_left form .form-middle-wrapper .group span {
                width: 100%; }
              .section__form_content .section__wrapper .form__content_left form .form-top-wrapper .group .icon, .section__form_content .section__wrapper .form__content_left form .form-middle-wrapper .group .icon {
                position: absolute;
                right: 0;
                top: 7px;
                z-index: 100; }
                .section__form_content .section__wrapper .form__content_left form .form-top-wrapper .group .icon:after, .section__form_content .section__wrapper .form__content_left form .form-middle-wrapper .group .icon:after {
                  content: url("../images/icons/icon-blue-select.svg");
                  display: block;
                  width: 25px;
                  height: 25px; }
        .section__form_content .section__wrapper .form__content_left form .form-bottom {
          margin-bottom: 40px; }
          @media (max-width: 767px) {
            .section__form_content .section__wrapper .form__content_left form .form-bottom {
              margin-bottom: 20px; } }
          .section__form_content .section__wrapper .form__content_left form .form-bottom .group {
            position: relative;
            margin-bottom: 40px; }
            .section__form_content .section__wrapper .form__content_left form .form-bottom .group .icon-long-arrow {
              position: absolute;
              right: 0;
              top: 0;
              z-index: 100; }
              @media (max-width: 991px) {
                .section__form_content .section__wrapper .form__content_left form .form-bottom .group .icon-long-arrow {
                  display: none; } }
              .section__form_content .section__wrapper .form__content_left form .form-bottom .group .icon-long-arrow:after {
                content: url("../images/icons/icon-blue-long-arrow.svg");
                display: block;
                width: 70px;
                height: 28px; }
            .section__form_content .section__wrapper .form__content_left form .form-bottom .group h3 {
              padding-right: 90px; }
              @media (max-width: 767px) {
                .section__form_content .section__wrapper .form__content_left form .form-bottom .group h3 {
                  padding-right: 0;
                  text-align: center; } }
            .section__form_content .section__wrapper .form__content_left form .form-bottom .group.file h4 {
              margin-bottom: 20px; }
              @media (max-width: 767px) {
                .section__form_content .section__wrapper .form__content_left form .form-bottom .group.file h4 {
                  width: 100%;
                  text-align: center; } }
            .section__form_content .section__wrapper .form__content_left form .form-bottom .group.file .upload-btn-wrapper {
              position: relative;
              display: inline-block; }
              @media (max-width: 767px) {
                .section__form_content .section__wrapper .form__content_left form .form-bottom .group.file .upload-btn-wrapper {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  text-align: center; } }
              .section__form_content .section__wrapper .form__content_left form .form-bottom .group.file .upload-btn-wrapper .btn-fx {
                min-width: 150px;
                min-height: 44px; }
                .section__form_content .section__wrapper .form__content_left form .form-bottom .group.file .upload-btn-wrapper .btn-fx p {
                  top: 50%;
                  transform: translateY(-50%);
                  height: unset; }
              .section__form_content .section__wrapper .form__content_left form .form-bottom .group.file .upload-btn-wrapper .file-name {
                position: absolute;
                white-space: nowrap;
                width: 100%;
                top: 50%;
                right: calc(-100% - 20px);
                transform: translateY(-50%);
                font-family: "Roboto-Bold";
                font-size: 15px;
                color: #E6261C; }
                @media (max-width: 767px) {
                  .section__form_content .section__wrapper .form__content_left form .form-bottom .group.file .upload-btn-wrapper .file-name {
                    position: relative;
                    white-space: normal;
                    top: 20px;
                    right: 0;
                    transform: none;
                    text-align: center; } }
              .section__form_content .section__wrapper .form__content_left form .form-bottom .group.file .upload-btn-wrapper:hover .btn-fx {
                color: white; }
                .section__form_content .section__wrapper .form__content_left form .form-bottom .group.file .upload-btn-wrapper:hover .btn-fx:before {
                  opacity: 0;
                  transform: scale(0.5, 0.5); }
                .section__form_content .section__wrapper .form__content_left form .form-bottom .group.file .upload-btn-wrapper:hover .btn-fx:after {
                  opacity: 1;
                  transform: scale(1, 1); }
              .section__form_content .section__wrapper .form__content_left form .form-bottom .group.file .upload-btn-wrapper p {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 43px;
                z-index: 2;
                cursor: pointer; }
                .section__form_content .section__wrapper .form__content_left form .form-bottom .group.file .upload-btn-wrapper p span {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%; }
                  .section__form_content .section__wrapper .form__content_left form .form-bottom .group.file .upload-btn-wrapper p span.wpcf7-not-valid-tip {
                    top: 60px;
                    width: max-content;
                    pointer-events: none;
                    cursor: default; }
                .section__form_content .section__wrapper .form__content_left form .form-bottom .group.file .upload-btn-wrapper p input[type="file"] {
                  position: absolute;
                  left: 0;
                  top: 0;
                  border: none;
                  opacity: 0;
                  padding: 0;
                  width: 100%;
                  height: 100%; }
    .section__form_content .section__wrapper .form__content_right {
      position: relative;
      width: 32%;
      height: 100%; }
      @media (max-width: 991px) {
        .section__form_content .section__wrapper .form__content_right {
          position: relative;
          right: 0;
          width: 100%;
          height: auto; } }
      .section__form_content .section__wrapper .form__content_right .cta__sidebar {
        position: relative; }
        .section__form_content .section__wrapper .form__content_right .cta__sidebar_top {
          background-color: #E6E9F1;
          color: #15142F;
          border-radius: 10px; }
        .section__form_content .section__wrapper .form__content_right .cta__sidebar_bottom {
          margin-top: 20px;
          border: 2px solid #E6E9F1;
          border-radius: 10px;
          padding: 40px; }
          @media (max-width: 991px) {
            .section__form_content .section__wrapper .form__content_right .cta__sidebar_bottom {
              padding: 20px; } }
          .section__form_content .section__wrapper .form__content_right .cta__sidebar_bottom h3 {
            color: #15142F;
            font-size: 18px;
            font-family: "Roboto-Regular"; }
            .section__form_content .section__wrapper .form__content_right .cta__sidebar_bottom h3.prefix {
              margin-bottom: 10px; }
          .section__form_content .section__wrapper .form__content_right .cta__sidebar_bottom span {
            display: block;
            font-size: 13px;
            line-height: 1.4;
            color: #15142F; }

.section__accordion {
  position: relative;
  background-color: white;
  display: flex;
  height: auto;
  padding: 90px 0;
  z-index: 100; }
  @media (max-width: 991px) {
    .section__accordion {
      padding: 40px 0; } }
  @media (max-width: 575px) {
    .section__accordion {
      padding: 0; } }
  .section__accordion .section__wrapper {
    position: relative;
    margin: 20px auto;
    max-width: 80.625%;
    padding: 0 20px;
    width: 100%; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__accordion .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__accordion .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__accordion .section__wrapper {
        max-width: 100%;
        padding: 0 76px; } }
    @media (max-width: 767px) {
      .section__accordion .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__accordion .section__wrapper {
        padding: 0 20px; } }

.section__filter_grid {
  background-color: #EEF0F6;
  position: relative;
  z-index: 600; }
  .section__filter_grid_top {
    position: relative;
    top: -40px;
    max-width: 80.625%;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__filter_grid_top {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__filter_grid_top {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__filter_grid_top {
        max-width: 100%;
        padding: 0 76px; } }
    @media (max-width: 767px) {
      .section__filter_grid_top {
        padding: 20px 36px 30px;
        top: 0; } }
    @media (max-width: 575px) {
      .section__filter_grid_top {
        padding: 20px 20px 30px; } }
    .section__filter_grid_top h4 {
      font-family: "Roboto-Regular";
      letter-spacing: 1px;
      margin-bottom: 20px;
      text-transform: uppercase; }
    .section__filter_grid_top .section__filter {
      background-color: white;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 40px 160px 40px 40px;
      border-radius: 7px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.05); }
      @media (max-width: 991px) {
        .section__filter_grid_top .section__filter {
          padding: 40px; } }
      @media (max-width: 767px) {
        .section__filter_grid_top .section__filter {
          margin: 0;
          padding: 20px; } }
      .section__filter_grid_top .section__filter .form__top {
        position: relative;
        display: flex;
        width: 100%; }
        @media (max-width: 767px) {
          .section__filter_grid_top .section__filter .form__top {
            flex-direction: column; } }
        .section__filter_grid_top .section__filter .form__top .section__filter_left {
          width: calc(33.33% - 80px);
          margin: 0 40px 0 0; }
          @media (max-width: 1199px) {
            .section__filter_grid_top .section__filter .form__top .section__filter_left {
              width: calc(45% - 40px);
              margin: 0 20px 0 0; } }
          @media (max-width: 767px) {
            .section__filter_grid_top .section__filter .form__top .section__filter_left {
              width: 100%;
              margin: 20px 0; } }
          .section__filter_grid_top .section__filter .form__top .section__filter_left_top {
            margin-bottom: 50px; }
            @media (max-width: 767px) {
              .section__filter_grid_top .section__filter .form__top .section__filter_left_top {
                margin-bottom: 20px; } }
          .section__filter_grid_top .section__filter .form__top .section__filter_left .filter__select {
            position: relative;
            width: 100%; }
            .section__filter_grid_top .section__filter .form__top .section__filter_left .filter__select .icon-select {
              position: absolute;
              right: 0;
              top: 9px; }
            .section__filter_grid_top .section__filter .form__top .section__filter_left .filter__select select, .section__filter_grid_top .section__filter .form__top .section__filter_left .filter__select input {
              appearance: none;
              outline: 0;
              border-radius: 0;
              box-shadow: none;
              border: 0;
              border-bottom: 1px solid #979797;
              background-color: white;
              padding: 10px 0;
              width: 100%;
              font-size: 16px;
              color: #34396B;
              letter-spacing: 1px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: word-wrap;
              padding-right: 15px; }
            .section__filter_grid_top .section__filter .form__top .section__filter_left .filter__select .input-group {
              position: relative; }
              .section__filter_grid_top .section__filter .form__top .section__filter_left .filter__select .input-group button {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: -2px;
                right: 0;
                border-radius: 50%;
                width: auto;
                height: auto;
                text-align: center;
                padding: 10px 0 10px 20px;
                background-color: white; }
                .section__filter_grid_top .section__filter .form__top .section__filter_left .filter__select .input-group button svg {
                  width: 20px;
                  height: 20px; }
                  .section__filter_grid_top .section__filter .form__top .section__filter_left .filter__select .input-group button svg g {
                    stroke: #0080CC; }
            .section__filter_grid_top .section__filter .form__top .section__filter_left .filter__select select::-ms-expand {
              display: none; }
        .section__filter_grid_top .section__filter .form__top .section__filter_right {
          width: 66.66%;
          padding-left: 40px; }
          @media (max-width: 1199px) {
            .section__filter_grid_top .section__filter .form__top .section__filter_right {
              padding-left: 20px;
              width: 55%; } }
          @media (max-width: 767px) {
            .section__filter_grid_top .section__filter .form__top .section__filter_right {
              width: 100%;
              padding-left: 0; } }
          .section__filter_grid_top .section__filter .form__top .section__filter_right h4 {
            margin-bottom: 5px; }
            @media (max-width: 767px) {
              .section__filter_grid_top .section__filter .form__top .section__filter_right h4 {
                margin-bottom: 20px; } }
          .section__filter_grid_top .section__filter .form__top .section__filter_right .input__wrapper {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            margin-left: -20px; }
            @media (max-width: 767px) {
              .section__filter_grid_top .section__filter .form__top .section__filter_right .input__wrapper {
                margin-left: 0; } }
            .section__filter_grid_top .section__filter .form__top .section__filter_right .input__wrapper .input-group {
              width: calc(33.33% - 40px);
              display: flex;
              align-items: center;
              position: relative;
              margin: 20px; }
              @media (max-width: 1199px) {
                .section__filter_grid_top .section__filter .form__top .section__filter_right .input__wrapper .input-group {
                  width: calc(50% - 40px); } }
              @media (max-width: 767px) {
                .section__filter_grid_top .section__filter .form__top .section__filter_right .input__wrapper .input-group {
                  width: 100%;
                  margin: 10px 0; } }
              .section__filter_grid_top .section__filter .form__top .section__filter_right .input__wrapper .input-group input {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 25px;
                height: 25px;
                appearance: checkbox;
                z-index: 10;
                opacity: 0;
                margin: 0; }
                .section__filter_grid_top .section__filter .form__top .section__filter_right .input__wrapper .input-group input:checked ~ .checkmark img {
                  display: block; }
              .section__filter_grid_top .section__filter .form__top .section__filter_right .input__wrapper .input-group label {
                padding: 0 10px 0 35px; }
              .section__filter_grid_top .section__filter .form__top .section__filter_right .input__wrapper .input-group .checkmark {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                z-index: 5;
                width: 24px;
                height: 24px;
                border: 1px solid #0080CC;
                border-radius: 7px; }
                .section__filter_grid_top .section__filter .form__top .section__filter_right .input__wrapper .input-group .checkmark img {
                  display: none; }
      .section__filter_grid_top .section__filter .form__bottom {
        position: absolute;
        right: 40px;
        bottom: 40px;
        width: auto;
        height: auto;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px; }
        @media (max-width: 991px) {
          .section__filter_grid_top .section__filter .form__bottom {
            justify-content: center;
            position: relative;
            right: inherit;
            bottom: inherit;
            width: 100%;
            height: 100%; } }
  .section__filter_grid_mid {
    background-color: #EEF0F6;
    position: relative;
    padding: 60px 20px 0; }
    @media (max-width: 991px) {
      .section__filter_grid_mid {
        padding: 20px 76px; } }
    @media (max-width: 767px) {
      .section__filter_grid_mid {
        padding: 20px 36px; } }
    @media (max-width: 575px) {
      .section__filter_grid_mid {
        padding: 20px; } }
    .section__filter_grid_mid .wrapper {
      max-width: 80.625%;
      margin: 0 auto;
      width: 100%; }
      @media screen and (min-width: 1200px) and (max-width: 1350px) {
        .section__filter_grid_mid .wrapper {
          max-width: 85%; } }
      @media (max-width: 1199px) {
        .section__filter_grid_mid .wrapper {
          max-width: 90%; } }
      @media (max-width: 991px) {
        .section__filter_grid_mid .wrapper {
          max-width: 100%; } }
      .section__filter_grid_mid .wrapper p {
        max-width: 66.66%;
        line-height: 1.4;
        font-size: 16px;
        letter-spacing: 1px; }
        @media (max-width: 991px) {
          .section__filter_grid_mid .wrapper p {
            max-width: 100%; } }
        @media (max-width: 767px) {
          .section__filter_grid_mid .wrapper p {
            font-size: 13px; } }
  .section__filter_grid_bottom {
    position: relative; }
    .section__filter_grid_bottom .section__grid {
      position: relative;
      max-width: 80.625%;
      margin: 0 auto;
      width: 100%;
      padding: 60px 20px; }
      @media screen and (min-width: 1200px) and (max-width: 1350px) {
        .section__filter_grid_bottom .section__grid {
          max-width: 85%; } }
      @media (max-width: 1199px) {
        .section__filter_grid_bottom .section__grid {
          max-width: 90%; } }
      @media (max-width: 991px) {
        .section__filter_grid_bottom .section__grid {
          max-width: 100%;
          padding: 20px 76px; } }
      @media (max-width: 767px) {
        .section__filter_grid_bottom .section__grid {
          padding: 20px 36px; } }
      @media (max-width: 575px) {
        .section__filter_grid_bottom .section__grid {
          padding: 20px; } }
      .section__filter_grid_bottom .section__grid_wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -40px; }
        @media (max-width: 991px) {
          .section__filter_grid_bottom .section__grid_wrapper {
            margin: 0 -20px; } }
        @media (max-width: 767px) {
          .section__filter_grid_bottom .section__grid_wrapper {
            margin: 0;
            flex-direction: column; } }
      .section__filter_grid_bottom .section__grid_bottom {
        display: flex;
        align-items: center;
        width: 100%; }
        @media (max-width: 991px) {
          .section__filter_grid_bottom .section__grid_bottom {
            flex-direction: column; } }
        .section__filter_grid_bottom .section__grid_bottom .bottom__left {
          width: 33.33%; }
          @media (max-width: 991px) {
            .section__filter_grid_bottom .section__grid_bottom .bottom__left {
              width: 100%;
              text-align: center;
              margin-bottom: 20px; } }
        .section__filter_grid_bottom .section__grid_bottom .bottom__mid {
          width: 33.33%;
          display: flex;
          justify-content: center; }
          @media (max-width: 991px) {
            .section__filter_grid_bottom .section__grid_bottom .bottom__mid {
              width: 100%;
              margin-bottom: 20px; } }
        .section__filter_grid_bottom .section__grid_bottom .bottom__right {
          width: 33.33%;
          display: flex;
          align-items: center;
          justify-content: flex-end; }
          @media (max-width: 991px) {
            .section__filter_grid_bottom .section__grid_bottom .bottom__right {
              width: 100%;
              justify-content: center; } }
          .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper {
            display: flex;
            align-items: center; }
            .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper .pagination {
              margin-right: 20px; }
              @media (max-width: 767px) {
                .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper .pagination {
                  margin-right: 0;
                  margin-bottom: 20px; } }
            @media (max-width: 767px) {
              .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper {
                flex-direction: column;
                justify-content: center; } }
            .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper .btn-paginations {
              display: flex;
              align-items: center; }
              .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper .btn-paginations a + a {
                margin-left: 10px; }
            .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers {
              display: flex;
              align-items: center; }
              .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li + li {
                margin-left: 10px; }
              .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form input {
                width: 42px;
                height: 42px;
                border: none;
                text-align: center;
                appearance: textfield;
                font-size: 16px;
                color: #34396B;
                background-color: transparent; }
              .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form input::-webkit-outer-spin-button,
              .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0; }
              .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form {
                border-radius: 7px;
                border: 1px solid #979797;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.35s ease; }
                .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a svg g, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span svg g, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form svg g {
                  stroke: #979797; }
                .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers {
                  display: none;
                  border: 1px solid #0080CC;
                  color: #0080CC; }
                  .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.prev, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.next, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.current, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.prev, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.next, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.current, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.prev, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.next, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.current {
                    display: flex; }
                  .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.prev:hover, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.next:hover, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.prev:hover, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.next:hover, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.prev:hover, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.next:hover {
                    border: 1px solid #0080CC; }
                    .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.prev:hover svg g, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.next:hover svg g, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.prev:hover svg g, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.next:hover svg g, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.prev:hover svg g, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.next:hover svg g {
                      stroke: #114277; }
                  .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.current, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.current, .section__filter_grid_bottom .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.current {
                    margin: 0 10px; }

.section__search {
  position: relative;
  background-color: white;
  display: flex;
  height: auto;
  padding: 90px 0;
  z-index: 100; }
  @media (max-width: 991px) {
    .section__search {
      padding: 40px 0; } }
  @media (max-width: 575px) {
    .section__search {
      padding: 0; } }
  .section__search .section__wrapper {
    position: relative;
    margin: 20px auto;
    max-width: 80.625%;
    padding: 0 20px;
    width: 100%; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__search .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__search .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__search .section__wrapper {
        max-width: 100%;
        padding: 0 76px; } }
    @media (max-width: 767px) {
      .section__search .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__search .section__wrapper {
        padding: 0 20px; } }
    .section__search .section__wrapper .results {
      position: relative; }
      .section__search .section__wrapper .results h2 {
        font-family: "WorkSans-Light";
        font-size: 42px;
        line-height: 1.4;
        margin-bottom: 40px; }
        @media (max-width: 1199px) {
          .section__search .section__wrapper .results h2 {
            font-size: 32px;
            margin-bottom: 20px; } }
        @media (max-width: 991px) {
          .section__search .section__wrapper .results h2 {
            font-size: 26px; } }
      .section__search .section__wrapper .results h3 {
        font-family: "WorkSans-Light";
        font-size: 24px;
        line-height: 1.4;
        margin-bottom: 20px; }
        @media (max-width: 1199px) {
          .section__search .section__wrapper .results h3 {
            font-size: 18px; } }
      .section__search .section__wrapper .results__top {
        position: relative; }
        .section__search .section__wrapper .results__top .search__page_results {
          position: relative;
          padding: 0;
          display: flex;
          align-items: center;
          width: 100%;
          max-width: 632px; }
          @media (max-width: 991px) {
            .section__search .section__wrapper .results__top .search__page_results {
              max-width: 100%; } }
          .section__search .section__wrapper .results__top .search__page_results .input-group {
            position: relative;
            width: 100%; }
            .section__search .section__wrapper .results__top .search__page_results .input-group input {
              border-radius: 40px;
              border: 1px solid #979797;
              width: 100%;
              padding: 25px 100px 25px 40px;
              font-size: 24px;
              color: #34396B; }
              @media (max-width: 991px) {
                .section__search .section__wrapper .results__top .search__page_results .input-group input {
                  padding: 14px;
                  font-size: 13px; } }
            .section__search .section__wrapper .results__top .search__page_results .input-group button {
              position: absolute;
              right: 0;
              top: 0;
              background-color: #E6261C;
              border-radius: 50%;
              width: 80px;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: background-color 0.35s ease; }
              @media (max-width: 991px) {
                .section__search .section__wrapper .results__top .search__page_results .input-group button {
                  width: 45px;
                  height: 45px; } }
              .section__search .section__wrapper .results__top .search__page_results .input-group button svg {
                width: 28px;
                height: 28px; }
                @media (max-width: 991px) {
                  .section__search .section__wrapper .results__top .search__page_results .input-group button svg {
                    width: 18px;
                    height: 18px; } }
              .section__search .section__wrapper .results__top .search__page_results .input-group button:hover {
                background-color: #34396B; }
      .section__search .section__wrapper .results__bottom .bloc__results {
        position: relative;
        margin-top: 40px; }
        @media (max-width: 991px) {
          .section__search .section__wrapper .results__bottom .bloc__results {
            margin-top: 20px; } }
        .section__search .section__wrapper .results__bottom .bloc__results .results__item {
          margin-top: 20px; }
          .section__search .section__wrapper .results__bottom .bloc__results .results__item strong, .section__search .section__wrapper .results__bottom .bloc__results .results__item b {
            font-family: "WorkSans-Bold"; }
          .section__search .section__wrapper .results__bottom .bloc__results .results__item p ~ .results__link {
            margin-top: 20px; }
          .section__search .section__wrapper .results__bottom .bloc__results .results__item .results__link {
            display: block;
            color: #0080CC;
            text-decoration: underline;
            transition: all 0.35s ease; }
            .section__search .section__wrapper .results__bottom .bloc__results .results__item .results__link:hover {
              opacity: 0.7;
              text-decoration: none; }

.section__quote_slider {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 300;
  background-color: #EEF0F6;
  padding: 90px 0; }
  @media (max-width: 991px) {
    .section__quote_slider {
      padding: 40px 0; } }
  @media (max-width: 767px) {
    .section__quote_slider {
      padding: 20px 0; } }
  .section__quote_slider .section__wrapper {
    position: relative;
    width: 100%; }
  .section__quote_slider .quote__slider {
    position: relative; }
    .section__quote_slider .quote__slider_item {
      position: relative;
      opacity: 0.4;
      transition: opacity 0.35s ease;
      text-align: center;
      margin-bottom: 40px; }
      @media (max-width: 991px) {
        .section__quote_slider .quote__slider_item {
          margin-bottom: 20px; } }
      .section__quote_slider .quote__slider_item.slick-active {
        opacity: 1; }
      .section__quote_slider .quote__slider_item blockquote {
        position: relative;
        margin-bottom: 30px;
        display: flex;
        font-family: "WorkSans-Light";
        line-height: 1.2;
        color: #0080CC;
        text-align: center;
        quotes: "«" "»" "‘" "’";
        font-size: 42px;
        margin-bottom: 40px;
        outline: none; }
        .section__quote_slider .quote__slider_item blockquote:before {
          position: absolute;
          top: 80px;
          left: 70px;
          content: '';
          height: auto;
          content: open-quote;
          font-family: "WorkSans-Light";
          font-size: 24px; }
          @media (max-width: 991px) {
            .section__quote_slider .quote__slider_item blockquote:before {
              font-size: 24px;
              top: 40px;
              left: 30px; } }
          @media (max-width: 575px) {
            .section__quote_slider .quote__slider_item blockquote:before {
              left: 20px;
              top: 20px; } }
        .section__quote_slider .quote__slider_item blockquote:after {
          position: absolute;
          right: 70px;
          bottom: 80px;
          content: '';
          height: auto;
          content: close-quote;
          font-family: "WorkSans-Light";
          font-size: 24px; }
          @media (max-width: 991px) {
            .section__quote_slider .quote__slider_item blockquote:after {
              font-size: 24px;
              right: 30px;
              bottom: 40px; } }
          @media (max-width: 575px) {
            .section__quote_slider .quote__slider_item blockquote:after {
              right: 20px;
              bottom: 20px; } }
        .section__quote_slider .quote__slider_item blockquote p {
          font-family: "Roboto-Regular";
          font-style: italic;
          color: #0080CC;
          line-height: 1.4;
          font-size: 24px;
          padding: 40px 80px; }
          @media (max-width: 991px) {
            .section__quote_slider .quote__slider_item blockquote p {
              padding: 20px 40px;
              line-height: 1.2; } }
          @media (max-width: 575px) {
            .section__quote_slider .quote__slider_item blockquote p {
              font-size: 13px; } }
        .section__quote_slider .quote__slider_item blockquote cite {
          display: block;
          text-align: right;
          margin-top: 20px;
          right: 0;
          bottom: -25px;
          justify-content: flex-end;
          font-family: "Roboto-Regular"; }
          .section__quote_slider .quote__slider_item blockquote cite:before {
            margin-right: 5px;
            content: '-'; }
      .section__quote_slider .quote__slider_item .name {
        font-family: "Roboto-Bold";
        font-size: 16px;
        color: #114277;
        margin-bottom: 5px; }
      .section__quote_slider .quote__slider_item .location {
        font-family: "Roboto-Regular";
        font-size: 16px;
        color: #114277; }

.section__cta_detaillant {
  position: relative;
  overflow: hidden;
  height: auto;
  padding: 90px 0; }
  @media (max-width: 991px) {
    .section__cta_detaillant {
      height: auto;
      padding: 40px 0; } }
  .section__cta_detaillant .section__wrapper {
    position: relative;
    max-width: 80.625%;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
    display: flex; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__cta_detaillant .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__cta_detaillant .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__cta_detaillant .section__wrapper {
        flex-direction: column;
        max-width: 100%;
        padding: 0 76px; } }
    @media (max-width: 767px) {
      .section__cta_detaillant .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__cta_detaillant .section__wrapper {
        padding: 0 20px; } }
    .section__cta_detaillant .section__wrapper .detaillant_left {
      position: relative;
      width: 68%;
      padding-right: 100px; }
      @media (max-width: 1199px) {
        .section__cta_detaillant .section__wrapper .detaillant_left {
          padding-right: 40px; } }
      @media (max-width: 991px) {
        .section__cta_detaillant .section__wrapper .detaillant_left {
          padding-right: 0;
          width: 100%;
          margin-bottom: 20px; } }
      .section__cta_detaillant .section__wrapper .detaillant_left_top {
        position: relative;
        margin-bottom: 40px; }
        @media (max-width: 991px) {
          .section__cta_detaillant .section__wrapper .detaillant_left_top {
            margin-bottom: 20px; } }
        .section__cta_detaillant .section__wrapper .detaillant_left_top p {
          font-size: 24px;
          color: #0080CC;
          margin-bottom: 40px; }
          @media (max-width: 991px) {
            .section__cta_detaillant .section__wrapper .detaillant_left_top p {
              font-size: 16px;
              margin-bottom: 20px; } }
        .section__cta_detaillant .section__wrapper .detaillant_left_top .select-group {
          position: relative; }
          .section__cta_detaillant .section__wrapper .detaillant_left_top .select-group h3 {
            font-size: 16px;
            font-family: "Roboto-Bold";
            color: #15142F;
            margin-bottom: 5px; }
          .section__cta_detaillant .section__wrapper .detaillant_left_top .select-group .filter__select {
            position: relative;
            width: 100%;
            max-width: 50%; }
            @media (max-width: 575px) {
              .section__cta_detaillant .section__wrapper .detaillant_left_top .select-group .filter__select {
                max-width: 100%; } }
            .section__cta_detaillant .section__wrapper .detaillant_left_top .select-group .filter__select .icon-select {
              position: absolute;
              right: 0;
              top: 9px; }
            .section__cta_detaillant .section__wrapper .detaillant_left_top .select-group .filter__select select, .section__cta_detaillant .section__wrapper .detaillant_left_top .select-group .filter__select input {
              appearance: none;
              outline: 0;
              border-radius: 0;
              box-shadow: none;
              border: 0;
              border-bottom: 1px solid #979797;
              background-color: white;
              padding: 10px 0;
              width: 100%;
              font-size: 16px;
              color: #34396B;
              letter-spacing: 1px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: word-wrap;
              padding-right: 15px; }
            .section__cta_detaillant .section__wrapper .detaillant_left_top .select-group .filter__select .input-group {
              position: relative; }
              .section__cta_detaillant .section__wrapper .detaillant_left_top .select-group .filter__select .input-group button {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: -2px;
                right: 0;
                border-radius: 50%;
                width: auto;
                height: auto;
                text-align: center;
                padding: 10px 0 10px 20px;
                background-color: white; }
                .section__cta_detaillant .section__wrapper .detaillant_left_top .select-group .filter__select .input-group button svg {
                  width: 20px;
                  height: 20px; }
                  .section__cta_detaillant .section__wrapper .detaillant_left_top .select-group .filter__select .input-group button svg g {
                    stroke: #E6261C; }
            .section__cta_detaillant .section__wrapper .detaillant_left_top .select-group .filter__select select::-ms-expand {
              display: none; }
      .section__cta_detaillant .section__wrapper .detaillant_left_bottom {
        position: relative; }
        .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          width: auto;
          margin: 0 -40px; }
          @media (max-width: 575px) {
            .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur {
              margin: 0; } }
          .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div {
            margin: 40px;
            width: calc(50% - 80px);
            display: none; }
            .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div.js-show {
              display: block; }
            @media (max-width: 767px) {
              .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div {
                margin: 20px 40px; } }
            @media (max-width: 575px) {
              .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div {
                margin: 10px 0;
                width: 100%; } }
            .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div .dist-title {
              font-family: "Roboto-Bold";
              color: #15142F;
              margin-bottom: 5px; }
            .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div .dist-img {
              margin: 10px 0;
              max-width: 100%; }
            .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div address {
              color: #15142F;
              margin-bottom: 20px; }
              .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div address span {
                display: block; }
              @media (max-width: 575px) {
                .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div address {
                  max-width: 100%; } }
            .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div .dist-tel {
              color: #15142F; }
              .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div .dist-tel a {
                color: #15142F;
                transition: color 0.35s ease; }
                .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div .dist-tel a:hover {
                  color: #0080CC; }
            .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div .dist-site {
              text-decoration: underline;
              color: #15142F;
              transition: color 0.35s ease; }
              .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div .dist-site:hover {
                color: #0080CC; }
            .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div .dist-detail {
              margin-top: 20px; }
              .section__cta_detaillant .section__wrapper .detaillant_left_bottom .contenu_distributeur > div .dist-detail p {
                line-height: 1.4; }
    .section__cta_detaillant .section__wrapper .detaillant_right {
      position: relative;
      width: 32%;
      height: 100%; }
      @media (max-width: 991px) {
        .section__cta_detaillant .section__wrapper .detaillant_right {
          position: relative;
          right: 0;
          width: 100%;
          height: auto; } }
      .section__cta_detaillant .section__wrapper .detaillant_right .cta__officiel {
        position: relative;
        width: 100%;
        display: block;
        padding: 60px 30px;
        background-color: #15142F;
        transition: background-color 0.35s ease; }
        .section__cta_detaillant .section__wrapper .detaillant_right .cta__officiel:hover {
          background-color: #114277; }
        .section__cta_detaillant .section__wrapper .detaillant_right .cta__officiel h3 {
          font-size: 24px;
          color: white;
          margin-bottom: 40px; }
          @media (max-width: 575px) {
            .section__cta_detaillant .section__wrapper .detaillant_right .cta__officiel h3 {
              font-size: 18px; } }
        .section__cta_detaillant .section__wrapper .detaillant_right .cta__officiel p {
          font-size: 16px;
          color: white;
          margin-bottom: 80px;
          line-height: 1.4; }
          @media (max-width: 575px) {
            .section__cta_detaillant .section__wrapper .detaillant_right .cta__officiel p {
              margin-bottom: 40px; } }
        .section__cta_detaillant .section__wrapper .detaillant_right .cta__officiel img {
          position: absolute;
          z-index: 100;
          bottom: 40px;
          right: 40px; }
          @media (max-width: 991px) {
            .section__cta_detaillant .section__wrapper .detaillant_right .cta__officiel img {
              bottom: 20px; } }

.woocommerce-message {
  max-width: 80.625%;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  border: none; }
  @media screen and (min-width: 1200px) and (max-width: 1350px) {
    .woocommerce-message {
      max-width: 85%; } }
  @media (max-width: 1199px) {
    .woocommerce-message {
      max-width: 90%; } }
  @media (max-width: 575px) {
    .woocommerce-message {
      display: flex;
      flex-direction: column-reverse; } }
  .woocommerce-message:before {
    position: relative;
    top: 0;
    left: 0;
    margin-right: 10px;
    margin-top: 10px; }
  @media (max-width: 575px) {
    .woocommerce-message a {
      margin: 10px 0 !important; } }

.woocommerce-breadcrumb {
  background-color: #15142F;
  width: 100%;
  height: auto;
  min-height: 100px;
  z-index: 20;
  display: flex;
  align-items: center;
  margin: 0; }
  @media (max-width: 767px) {
    .woocommerce-breadcrumb {
      min-height: inherit; } }
  .post-type-archive-product .woocommerce-breadcrumb {
    display: none; }
  .woocommerce-breadcrumb .wrapper {
    max-width: 80.625%;
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    color: white; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .woocommerce-breadcrumb .wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .woocommerce-breadcrumb .wrapper {
        max-width: 90%; } }
    @media (max-width: 767px) {
      .woocommerce-breadcrumb .wrapper {
        padding: 20px;
        max-width: 100%;
        font-size: 13px; } }
    .woocommerce-breadcrumb .wrapper > a {
      color: white;
      transition: color 0.35s ease; }
      .woocommerce-breadcrumb .wrapper > a:hover {
        color: #0080CC; }

.hero__archive_product {
  position: relative;
  top: 0;
  left: 0;
  background-color: #15142F;
  width: 100%;
  height: auto;
  min-height: 70px;
  z-index: 200;
  display: flex;
  align-items: center; }
  .hero__archive_product_wrapper {
    display: flex;
    max-width: 80.625%;
    margin: 0 auto;
    width: 100%; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .hero__archive_product_wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .hero__archive_product_wrapper {
        max-width: 90%; } }
    @media (max-width: 575px) {
      .hero__archive_product_wrapper {
        max-width: 100%;
        padding: 0 20px; } }
    .hero__archive_product_wrapper .inner {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      margin: 0; }
      @media (max-width: 991px) {
        .hero__archive_product_wrapper .inner {
          display: none; } }
      .hero__archive_product_wrapper .inner > a {
        width: calc(12.5% - 40px);
        display: block;
        margin: 30px 20px;
        color: white;
        font-family: "Roboto-Regular";
        font-size: 14px;
        opacity: 0.4;
        transition: opacity 0.35s ease; }
        @media (max-width: 1199px) {
          .hero__archive_product_wrapper .inner > a {
            width: calc(20% - 40px);
            padding: 20px; } }
        .hero__archive_product_wrapper .inner > a:hover {
          opacity: 1; }
    .hero__archive_product_wrapper .select-box {
      display: none;
      position: relative;
      width: 100%; }
      @media (max-width: 991px) {
        .hero__archive_product_wrapper .select-box {
          display: block; } }
      .hero__archive_product_wrapper .select-box .icon-select {
        position: absolute;
        right: 0;
        top: 9px; }
      .hero__archive_product_wrapper .select-box select#product_categories {
        background-color: transparent !important;
        color: white; }
      .hero__archive_product_wrapper .select-box select::-ms-expand {
        display: none; }

.woocommerce form .form-row {
  padding: 0;
  margin: 0; }

.woocommerce em {
  font-size: 13px;
  font-style: italic; }

.woocommerce form h3, .woocommerce form legend {
  font-family: "WorkSans-Medium";
  font-size: 24px;
  margin-bottom: 20px;
  color: #114277; }
  @media (max-width: 1199px) {
    .woocommerce form h3, .woocommerce form legend {
      font-size: 16px; } }

.woocommerce span.show-password-input {
  top: 0; }

.woocommerce .woocommerce-info {
  border-top-color: #0080CC; }

.woocommerce .woocommerce-error {
  border-top-color: #E6261C; }

.woocommerce .woocommerce-Message {
  font-size: 16px;
  font-family: "Roboto-Regular"; }
  @media (max-width: 767px) {
    .woocommerce .woocommerce-Message {
      font-size: 13px; } }

.woocommerce .woocommerce-terms-and-conditions-wrapper {
  margin-bottom: 20px; }

.woocommerce input[type="text"],
.woocommerce input[type="tel"],
.woocommerce input[type="password"],
.woocommerce input[type="email"],
.woocommerce input[type="number"],
.woocommerce .select2-selection--single,
.woocommerce select {
  appearance: none;
  outline: none;
  border-radius: 0;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #114277;
  padding: 10px 0;
  width: 100%;
  font-size: 13px;
  color: #114277;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 15px;
  background-color: white !important; }
  .woocommerce input[type="text"] span,
  .woocommerce input[type="tel"] span,
  .woocommerce input[type="password"] span,
  .woocommerce input[type="email"] span,
  .woocommerce input[type="number"] span,
  .woocommerce .select2-selection--single span,
  .woocommerce select span {
    overflow: visible; }
    .woocommerce input[type="text"] span.select2-selection__rendered,
    .woocommerce input[type="tel"] span.select2-selection__rendered,
    .woocommerce input[type="password"] span.select2-selection__rendered,
    .woocommerce input[type="email"] span.select2-selection__rendered,
    .woocommerce input[type="number"] span.select2-selection__rendered,
    .woocommerce .select2-selection--single span.select2-selection__rendered,
    .woocommerce select span.select2-selection__rendered {
      color: #114277;
      line-height: 1;
      position: relative;
      top: -5px;
      padding-left: 0; }
  .woocommerce input[type="text"] .select2-selection__arrow b,
  .woocommerce input[type="tel"] .select2-selection__arrow b,
  .woocommerce input[type="password"] .select2-selection__arrow b,
  .woocommerce input[type="email"] .select2-selection__arrow b,
  .woocommerce input[type="number"] .select2-selection__arrow b,
  .woocommerce .select2-selection--single .select2-selection__arrow b,
  .woocommerce select .select2-selection__arrow b {
    border-color: #114277 transparent transparent transparent; }

.woocommerce textarea {
  padding: 10px;
  border-radius: 0;
  border: 1px solid #114277;
  font-size: 13px;
  outline: none; }

.woocommerce input[type="checkbox"] {
  width: 25px;
  height: 25px;
  border: 1px solid #114277;
  border-radius: 7px; }

.woocommerce .form-row label,
.woocommerce label[for="username"],
.woocommerce label[for="password"] {
  font-size: 16px;
  font-family: "Roboto-Regular";
  color: #114277; }
  .woocommerce .form-row label .required,
  .woocommerce label[for="username"] .required,
  .woocommerce label[for="password"] .required {
    color: #E6261C; }

.woocommerce label.woocommerce-form__label-for-checkbox {
  display: flex !important;
  align-items: center; }

.woocommerce button.button, .woocommerce a.button {
  display: inline-block;
  padding: 12px 20px;
  font-family: "Roboto-Medium";
  font-size: 16px;
  border-radius: 30px;
  color: #E6261C;
  transition: all 0.5s;
  position: relative;
  z-index: 1;
  text-align: center;
  margin: 0;
  font-weight: 100 !important; }
  .woocommerce button.button:before, .woocommerce a.button:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 30px;
    background-color: white;
    border: 2px solid #E6261C;
    transition: all 0.3s; }
  .woocommerce button.button:after, .woocommerce a.button:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    border-radius: 30px;
    transition: all 0.3s;
    background-color: #E6261C;
    transform: scale(1.1, 1.1); }
  .woocommerce button.button:hover, .woocommerce a.button:hover {
    color: white; }
    .woocommerce button.button:hover:before, .woocommerce a.button:hover:before {
      opacity: 0;
      transform: scale(0.5, 0.5); }
    .woocommerce button.button:hover:after, .woocommerce a.button:hover:after {
      opacity: 1;
      transform: scale(1, 1); }
  .woocommerce button.button[type="submit"], .woocommerce a.button[type="submit"] {
    color: white; }
    .woocommerce button.button[type="submit"]:before, .woocommerce a.button[type="submit"]:before {
      background-color: #E6261C;
      border: none; }
    .woocommerce button.button[type="submit"]:after, .woocommerce a.button[type="submit"]:after {
      background-color: transparent;
      border: 2px solid #E6261C; }
    .woocommerce button.button[type="submit"]:hover, .woocommerce a.button[type="submit"]:hover {
      color: #E6261C; }

.woocommerce a.button {
  color: #0080CC;
  background-color: transparent;
  font-weight: 100; }
  .woocommerce a.button:before {
    border: 2px solid #0080CC; }
  .woocommerce a.button:after {
    background-color: #0080CC; }
  .woocommerce a.button:hover {
    color: white !important; }

.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover {
  background-color: white; }

.woocommerce #respond input#submit.disabled,
.woocommerce #respond input#submit:disabled,
.woocommerce #respond input#submit:disabled[disabled],
.woocommerce a.button.disabled,
.woocommerce a.button:disabled,
.woocommerce a.button:disabled[disabled],
.woocommerce button.button.disabled,
.woocommerce button.button:disabled,
.woocommerce button.button:disabled[disabled],
.woocommerce input.button.disabled,
.woocommerce input.button:disabled,
.woocommerce input.button:disabled[disabled] {
  color: white; }

.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
  color: #0080CC; }

.woocommerce table.shop_table th,
.woocommerce table.shop_table tbody th {
  font-weight: 100;
  font-family: "Roboto-Bold";
  padding: 20px; }

.woocommerce table.shop_table td {
  border-top: 1px solid #9ACDEB;
  font-weight: 100;
  padding: 20px; }
  .woocommerce table.shop_table td.product-name {
    color: #009ADA; }

.woocommerce table.shop_table {
  border: 1px solid #9ACDEB; }

.woocommerce table.shop_table tbody th,
.woocommerce table.shop_table tfoot td,
.woocommerce table.shop_table tfoot th {
  border-top: 1px solid #9ACDEB;
  padding: 20px;
  font-weight: 100; }

#add_payment_method #payment ul.payment_methods,
.woocommerce-cart #payment ul.payment_methods,
.woocommerce-checkout #payment ul.payment_methods {
  padding: 20px;
  border-bottom: 1px solid #9ACDEB; }

#add_payment_method #payment div.form-row,
.woocommerce-cart #payment div.form-row,
.woocommerce-checkout #payment div.form-row {
  padding: 20px; }

.woocommerce .quantity .qty {
  width: 100%;
  text-align: left; }

.section__single_product {
  position: relative;
  overflow: hidden;
  height: auto;
  padding: 90px 0; }
  @media (max-width: 991px) {
    .section__single_product {
      height: auto;
      padding: 40px 0; } }
  @media (max-width: 575px) {
    .section__single_product {
      padding: 40px 0 0; } }
  .section__single_product .section__wrapper {
    position: relative;
    max-width: 80.625%;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__single_product .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__single_product .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__single_product .section__wrapper {
        max-width: 100%;
        padding: 0 76px 40px; } }
    @media (max-width: 767px) {
      .section__single_product .section__wrapper {
        padding: 0 36px 40px; } }
    @media (max-width: 575px) {
      .section__single_product .section__wrapper {
        padding: 0 20px 40px; } }
    .section__single_product .section__wrapper .single__product_top {
      position: relative;
      display: flex;
      width: 100%;
      padding-bottom: 90px; }
      @media (max-width: 991px) {
        .section__single_product .section__wrapper .single__product_top {
          flex-direction: column;
          padding-bottom: 40px; } }
      .section__single_product .section__wrapper .single__product_top_left {
        position: relative;
        width: 40%;
        padding-right: 20px; }
        @media (max-width: 991px) {
          .section__single_product .section__wrapper .single__product_top_left {
            width: 100%;
            padding-right: 0;
            margin-bottom: 20px; } }
        .section__single_product .section__wrapper .single__product_top_left .product_title {
          display: flex; }
          .section__single_product .section__wrapper .single__product_top_left .product_title h1 {
            font-family: "WorkSans-Medium";
            font-size: 36px;
            margin-bottom: 20px;
            line-height: 1.2;
            color: #0080CC;
            width: 100%; }
            @media (max-width: 1199px) {
              .section__single_product .section__wrapper .single__product_top_left .product_title h1 {
                font-size: 24px; } }
          .section__single_product .section__wrapper .single__product_top_left .product_title .product-logo {
            width: 100px;
            padding: 10px; }
            .section__single_product .section__wrapper .single__product_top_left .product_title .product-logo img {
              max-width: 100%; }
        .section__single_product .section__wrapper .single__product_top_left .product_code {
          text-transform: uppercase;
          color: #114277;
          font-family: "Roboto-Regular";
          font-size: 16px;
          margin-bottom: 20px; }
        .section__single_product .section__wrapper .single__product_top_left .product_detail {
          margin-bottom: 20px; }
          .section__single_product .section__wrapper .single__product_top_left .product_detail p {
            color: #114277;
            font-family: "Roboto-Regular";
            font-size: 24px; }
            @media (max-width: 1199px) {
              .section__single_product .section__wrapper .single__product_top_left .product_detail p {
                font-size: 16px; } }
        .section__single_product .section__wrapper .single__product_top_left .product_notice {
          color: #E6261C;
          font-family: "Roboto-Bold"; }
      .section__single_product .section__wrapper .single__product_top_right {
        width: 60%;
        position: relative;
        display: flex;
        opacity: 0;
        transition: opacity 0.35s ease; }
        @media (max-width: 991px) {
          .section__single_product .section__wrapper .single__product_top_right {
            width: 100%;
            flex-direction: column; } }
        .section__single_product .section__wrapper .single__product_top_right.js-init {
          opacity: 1; }
        .section__single_product .section__wrapper .single__product_top_right .product_image_main {
          width: 100%;
          max-width: 73%;
          padding-right: 20px;
          margin-left: 40px; }
          @media (max-width: 991px) {
            .section__single_product .section__wrapper .single__product_top_right .product_image_main {
              max-width: 100%;
              padding-right: 0;
              margin-left: 0; } }
          .section__single_product .section__wrapper .single__product_top_right .product_image_main .slide {
            max-width: 100%;
            position: relative;
            overflow: hidden;
            outline: none; }
            .section__single_product .section__wrapper .single__product_top_right .product_image_main .slide:before {
              content: '';
              display: block;
              padding-top: 75%; }
            .section__single_product .section__wrapper .single__product_top_right .product_image_main .slide .wrapper {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0; }
            @media (max-width: 991px) {
              .section__single_product .section__wrapper .single__product_top_right .product_image_main .slide {
                max-width: 100%; } }
            .section__single_product .section__wrapper .single__product_top_right .product_image_main .slide .image {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
              border-radius: 7px; }
        .section__single_product .section__wrapper .single__product_top_right .product_thumb_nav {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          max-width: 27%; }
          @media (max-width: 991px) {
            .section__single_product .section__wrapper .single__product_top_right .product_thumb_nav {
              max-width: 100%;
              display: block; } }
          .section__single_product .section__wrapper .single__product_top_right .product_thumb_nav .product_image_thumbs {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 137px; }
            @media (max-width: 991px) {
              .section__single_product .section__wrapper .single__product_top_right .product_thumb_nav .product_image_thumbs {
                max-width: 100%;
                width: 100%;
                flex-direction: inherit;
                display: block; } }
            .section__single_product .section__wrapper .single__product_top_right .product_thumb_nav .product_image_thumbs .thumb {
              position: relative;
              margin: 10px 0;
              overflow: hidden;
              outline: none;
              cursor: pointer;
              position: relative; }
              .section__single_product .section__wrapper .single__product_top_right .product_thumb_nav .product_image_thumbs .thumb:before {
                content: '';
                display: block;
                padding-top: 75.1824817518%; }
              .section__single_product .section__wrapper .single__product_top_right .product_thumb_nav .product_image_thumbs .thumb .wrapper {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0; }
              @media (max-width: 991px) {
                .section__single_product .section__wrapper .single__product_top_right .product_thumb_nav .product_image_thumbs .thumb {
                  margin: 20px; } }
              @media (max-width: 575px) {
                .section__single_product .section__wrapper .single__product_top_right .product_thumb_nav .product_image_thumbs .thumb {
                  margin: 10px; } }
              .section__single_product .section__wrapper .single__product_top_right .product_thumb_nav .product_image_thumbs .thumb .image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                border-radius: 7px; }
            .section__single_product .section__wrapper .single__product_top_right .product_thumb_nav .product_image_thumbs .prev, .section__single_product .section__wrapper .single__product_top_right .product_thumb_nav .product_image_thumbs .next {
              position: relative;
              transform: rotate(90deg);
              width: 20px;
              height: 20px;
              cursor: pointer; }
              @media (max-width: 991px) {
                .section__single_product .section__wrapper .single__product_top_right .product_thumb_nav .product_image_thumbs .prev, .section__single_product .section__wrapper .single__product_top_right .product_thumb_nav .product_image_thumbs .next {
                  transform: none;
                  position: absolute;
                  top: 0;
                  width: 10px;
                  height: 100%;
                  left: -10px; } }
            @media (max-width: 991px) {
              .section__single_product .section__wrapper .single__product_top_right .product_thumb_nav .product_image_thumbs .next {
                right: -10px;
                left: inherit; } }
    .section__single_product .section__wrapper form.single__product_checkout {
      position: relative;
      border-top: 1px solid #9ACDEB; }
      .section__single_product .section__wrapper form.single__product_checkout .form__inner {
        padding: 40px 0;
        margin: 0 -40px;
        display: flex; }
        @media (max-width: 1199px) {
          .section__single_product .section__wrapper form.single__product_checkout .form__inner {
            margin: 0 -20px; } }
        @media (max-width: 767px) {
          .section__single_product .section__wrapper form.single__product_checkout .form__inner {
            flex-direction: column;
            margin: 0;
            padding: 40px 0 0; } }
        .section__single_product .section__wrapper form.single__product_checkout .form__inner > div {
          width: 33.33%;
          margin: 0 40px; }
          @media (max-width: 1199px) {
            .section__single_product .section__wrapper form.single__product_checkout .form__inner > div {
              margin: 0 20px; } }
          @media (max-width: 767px) {
            .section__single_product .section__wrapper form.single__product_checkout .form__inner > div {
              width: 100%;
              margin: 0 0 20px 0; } }
          .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .label {
            font-size: 13px;
            font-family: "Roboto-Bold";
            color: #114277;
            margin-bottom: 5px; }
          .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group {
            position: relative; }
            .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group + .select-group {
              margin-top: 40px; }
              @media (max-width: 767px) {
                .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group + .select-group {
                  margin-top: 20px; } }
            .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .number-box, .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .select-box {
              position: relative; }
              .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .number-box select, .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .select-box select {
                appearance: none;
                outline: none;
                border-radius: 0;
                box-shadow: none;
                border: 0;
                border-bottom: 1px solid #114277;
                padding: 10px 0;
                width: 100%;
                font-size: 13px;
                color: #114277;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right: 15px; }
              .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .number-box select::-ms-expand, .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .select-box select::-ms-expand {
                display: none; }
              .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .number-box .icon-select, .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .select-box .icon-select {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 10px;
                height: 17px; }
              .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .number-box .quantity, .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .select-box .quantity {
                position: relative; }
                @media (max-width: 1199px) {
                  .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .number-box .quantity, .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .select-box .quantity {
                    padding-bottom: 20px; } }
                .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .number-box .quantity input[type="number"], .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .select-box .quantity input[type="number"] {
                  width: 100%;
                  text-align: left; }
                .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .number-box .quantity ~ .icon-select, .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .select-box .quantity ~ .icon-select {
                  display: none; }
              .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .number-box .price, .section__single_product .section__wrapper form.single__product_checkout .form__inner > div .select-group .select-box .price {
                display: block;
                margin-top: 10px; }
          .section__single_product .section__wrapper form.single__product_checkout .form__inner > div.single__product_checkout_left .label {
            font-family: "Roboto-Regular"; }
          .section__single_product .section__wrapper form.single__product_checkout .form__inner > div.single__product_checkout_left .price {
            font-size: 24px; }
            @media (max-width: 1199px) {
              .section__single_product .section__wrapper form.single__product_checkout .form__inner > div.single__product_checkout_left .price {
                font-size: 16px; } }
          .section__single_product .section__wrapper form.single__product_checkout .form__inner > div.single__product_checkout_right {
            display: flex;
            justify-content: space-between; }
            @media (max-width: 1199px) {
              .section__single_product .section__wrapper form.single__product_checkout .form__inner > div.single__product_checkout_right {
                flex-direction: column; } }
            .section__single_product .section__wrapper form.single__product_checkout .form__inner > div.single__product_checkout_right > div {
              width: calc(50% - 20px); }
              @media (max-width: 1199px) {
                .section__single_product .section__wrapper form.single__product_checkout .form__inner > div.single__product_checkout_right > div {
                  width: 100%; } }
              .section__single_product .section__wrapper form.single__product_checkout .form__inner > div.single__product_checkout_right > div button {
                width: 100%;
                text-align: center; }
                @media (max-width: 767px) {
                  .section__single_product .section__wrapper form.single__product_checkout .form__inner > div.single__product_checkout_right > div button {
                    margin-top: 20px; } }
    .section__single_product .section__wrapper .section__cta_tab_circle {
      position: relative;
      padding: 90px 0 0; }
      @media (max-width: 767px) {
        .section__single_product .section__wrapper .section__cta_tab_circle {
          padding: 40px 0 0; } }
      .section__single_product .section__wrapper .section__cta_tab_circle .tab__header {
        position: relative;
        height: auto;
        overflow: hidden;
        overflow-x: auto; }
        .section__single_product .section__wrapper .section__cta_tab_circle .tab__header::-webkit-scrollbar {
          display: none; }
        @media (max-width: 767px) {
          .section__single_product .section__wrapper .section__cta_tab_circle .tab__header {
            margin: 0 -36px 0 0; } }
        @media (max-width: 575px) {
          .section__single_product .section__wrapper .section__cta_tab_circle .tab__header {
            margin: 0 -20px 0 0; } }
        .section__single_product .section__wrapper .section__cta_tab_circle .tab__header_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 100%; }
          @media (max-width: 767px) {
            .section__single_product .section__wrapper .section__cta_tab_circle .tab__header_wrapper {
              min-width: 768px; } }
          @media (max-width: 575px) {
            .section__single_product .section__wrapper .section__cta_tab_circle .tab__header_wrapper {
              min-width: 575px; } }
          .section__single_product .section__wrapper .section__cta_tab_circle .tab__header_wrapper > a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 20px;
            padding: 30px 20px 30px 0;
            width: 100%;
            cursor: pointer;
            transition: color 0.35s ease; }
            .section__single_product .section__wrapper .section__cta_tab_circle .tab__header_wrapper > a:hover {
              color: #E6261C; }
            @media (max-width: 1199px) {
              .section__single_product .section__wrapper .section__cta_tab_circle .tab__header_wrapper > a {
                font-size: 16px; } }
            @media (max-width: 991px) {
              .section__single_product .section__wrapper .section__cta_tab_circle .tab__header_wrapper > a {
                padding: 20px 10px 20px 0;
                font-size: 13px;
                max-width: inherit !important; } }
            @media (max-width: 767px) {
              .section__single_product .section__wrapper .section__cta_tab_circle .tab__header_wrapper > a {
                padding: 10px 10px 15px 0; } }
            .section__single_product .section__wrapper .section__cta_tab_circle .tab__header_wrapper > a img {
              padding-right: 20px;
              pointer-events: none; }
              @media (max-width: 991px) {
                .section__single_product .section__wrapper .section__cta_tab_circle .tab__header_wrapper > a img {
                  width: 30px;
                  padding-right: 10px; } }
        .section__single_product .section__wrapper .section__cta_tab_circle .tab__header .tab__header_line {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 5px;
          background-color: #E6E9F1; }
          @media (max-width: 767px) {
            .section__single_product .section__wrapper .section__cta_tab_circle .tab__header .tab__header_line {
              height: 3px;
              min-width: 768px; } }
          @media (max-width: 575px) {
            .section__single_product .section__wrapper .section__cta_tab_circle .tab__header .tab__header_line {
              min-width: 575px; } }
          .section__single_product .section__wrapper .section__cta_tab_circle .tab__header .tab__header_line .progress {
            position: absolute;
            left: 0;
            margin-left: 0;
            height: 100%;
            background-color: #E6261C;
            z-index: 25;
            transition: margin-left 0.35s ease; }
      .section__single_product .section__wrapper .section__cta_tab_circle .tab__container {
        margin-top: 100px;
        position: relative; }
        @media (max-width: 991px) {
          .section__single_product .section__wrapper .section__cta_tab_circle .tab__container {
            margin-top: 20px; } }
        .section__single_product .section__wrapper .section__cta_tab_circle .tab__container .tab_content {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          opacity: 0;
          transform: translateY(-10px);
          transition: opacity 0.35s ease, transform 0.35s ease;
          z-index: 50;
          display: flex; }
          .section__single_product .section__wrapper .section__cta_tab_circle .tab__container .tab_content.js-active-content {
            position: relative;
            opacity: 1;
            transform: translateY(0);
            transition: opacity 0.35s ease, transform 0.35s ease;
            transition-delay: 0.7s;
            z-index: 100; }

.section__cta_grid_product {
  background-color: white;
  height: auto;
  padding: 90px 0; }
  @media (max-width: 991px) {
    .section__cta_grid_product {
      height: auto;
      padding: 40px 0; } }
  .section__cta_grid_product .section__wrapper {
    position: relative;
    max-width: 80.625%;
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    min-height: 400px; }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .section__cta_grid_product .section__wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .section__cta_grid_product .section__wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .section__cta_grid_product .section__wrapper {
        max-width: 100%;
        padding: 0 76px;
        min-height: inherit; } }
    @media (max-width: 767px) {
      .section__cta_grid_product .section__wrapper {
        padding: 0 36px; } }
    @media (max-width: 575px) {
      .section__cta_grid_product .section__wrapper {
        padding: 0 20px; } }
    .section__cta_grid_product .section__wrapper .section__grid_filter {
      position: relative;
      width: 100%;
      margin-bottom: 40px; }
      .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header {
        position: relative;
        height: auto;
        overflow: hidden;
        overflow-x: auto; }
        .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header::-webkit-scrollbar {
          display: none; }
        @media (max-width: 767px) {
          .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header {
            margin: 0 -36px 0 0; } }
        @media (max-width: 575px) {
          .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header {
            margin: 0 -20px 0 0; } }
        .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 100%; }
          @media (max-width: 767px) {
            .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header_wrapper {
              min-width: 768px; } }
          @media (max-width: 575px) {
            .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header_wrapper {
              min-width: 575px; } }
          .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header_wrapper > a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 16px;
            color: #114277;
            padding: 25px 20px 25px 0;
            width: 100%;
            cursor: pointer;
            transition: color 0.35s ease; }
            .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header_wrapper > a:hover {
              color: #E6261C; }
            @media (max-width: 1199px) {
              .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header_wrapper > a {
                font-size: 16px; } }
            @media (max-width: 991px) {
              .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header_wrapper > a {
                padding: 20px 10px 20px 0;
                font-size: 13px;
                max-width: inherit !important; } }
            @media (max-width: 767px) {
              .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header_wrapper > a {
                padding: 10px 10px 10px 0; } }
            .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header_wrapper > a img {
              padding-right: 20px;
              pointer-events: none; }
              @media (max-width: 991px) {
                .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header_wrapper > a img {
                  width: 30px;
                  padding-right: 10px; } }
        .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header .filter__header_line {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 5px;
          background-color: #E6E9F1; }
          @media (max-width: 767px) {
            .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header .filter__header_line {
              height: 3px;
              min-width: 768px; } }
          @media (max-width: 575px) {
            .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header .filter__header_line {
              min-width: 575px; } }
          .section__cta_grid_product .section__wrapper .section__grid_filter .filter__header .filter__header_line .progress {
            position: absolute;
            left: 0;
            margin-left: 0;
            height: 100%;
            background-color: #E6261C;
            z-index: 25;
            transition: margin-left 0.35s ease; }
    .section__cta_grid_product .section__wrapper .section__grid {
      position: relative; }
      .section__cta_grid_product .section__wrapper .section__grid ul.products {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -40px; }
        @media (max-width: 1199px) {
          .section__cta_grid_product .section__wrapper .section__grid ul.products {
            margin: 0 -20px; } }
        @media (max-width: 767px) {
          .section__cta_grid_product .section__wrapper .section__grid ul.products {
            flex-direction: column; } }
        .section__cta_grid_product .section__wrapper .section__grid ul.products li {
          width: calc(33.33% - 80px); }
          @media (max-width: 1199px) {
            .section__cta_grid_product .section__wrapper .section__grid ul.products li {
              width: calc(33.33% - 40px); } }
          @media (max-width: 991px) {
            .section__cta_grid_product .section__wrapper .section__grid ul.products li {
              width: calc(50% - 40px); } }
          @media (max-width: 767px) {
            .section__cta_grid_product .section__wrapper .section__grid ul.products li {
              width: calc(100% - 40px); } }
      .section__cta_grid_product .section__wrapper .section__grid_bottom {
        display: flex;
        align-items: center;
        width: 100%; }
        @media (max-width: 991px) {
          .section__cta_grid_product .section__wrapper .section__grid_bottom {
            flex-direction: column; } }
        .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__left {
          width: 33.33%; }
          @media (max-width: 991px) {
            .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__left {
              width: 100%;
              text-align: center;
              margin-bottom: 20px; } }
        .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__mid {
          width: 33.33%;
          display: flex;
          justify-content: center; }
          @media (max-width: 991px) {
            .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__mid {
              width: 100%;
              margin-bottom: 20px; } }
        .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right {
          width: 33.33%;
          display: flex;
          align-items: center;
          justify-content: flex-end; }
          @media (max-width: 991px) {
            .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right {
              width: 100%;
              justify-content: center; } }
          .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper {
            display: flex;
            align-items: center; }
            .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper .pagination {
              margin-right: 20px; }
              @media (max-width: 767px) {
                .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper .pagination {
                  margin-right: 0;
                  margin-bottom: 20px; } }
            @media (max-width: 767px) {
              .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper {
                flex-direction: column;
                justify-content: center; } }
            .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper .btn-paginations {
              display: flex;
              align-items: center; }
              .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper .btn-paginations a + a {
                margin-left: 10px; }
            .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers {
              display: flex;
              align-items: center; }
              .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li + li {
                margin-left: 10px; }
              .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form input {
                width: 42px;
                height: 42px;
                border: none;
                text-align: center;
                appearance: textfield;
                font-size: 16px;
                color: #979797;
                background-color: transparent; }
              .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form input::-webkit-outer-spin-button,
              .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0; }
              .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form {
                border-radius: 7px;
                border: 1px solid #979797;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.35s ease; }
                .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a svg g, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span svg g, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form svg g {
                  stroke: #979797; }
                .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers {
                  display: none;
                  border: 1px solid #979797;
                  color: #0080CC; }
                  .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.prev, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.next, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.current, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.prev, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.next, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.current, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.prev, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.next, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.current {
                    display: flex; }
                  .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.prev:hover, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.next:hover, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.prev:hover, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.next:hover, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.prev:hover, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.next:hover {
                    border: 1px solid #0080CC; }
                    .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.prev:hover svg g, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.next:hover svg g, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.prev:hover svg g, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.next:hover svg g, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.prev:hover svg g, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.next:hover svg g {
                      stroke: #0080CC; }
                  .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li a.page-numbers.current, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li span.page-numbers.current, .section__cta_grid_product .section__wrapper .section__grid_bottom .bottom__right_wrapper ul.page-numbers li form.page-numbers.current {
                    margin: 0 10px; }

.woocommerce-account.my-account .main .woocommerce,
.woocommerce-account.mon-compte .main .woocommerce {
  top: 0;
  box-shadow: none;
  border-radius: 0;
  padding: 90px 0; }
  @media (max-width: 1199px) {
    .woocommerce-account.my-account .main .woocommerce,
    .woocommerce-account.mon-compte .main .woocommerce {
      padding: 90px 0; } }
  @media (max-width: 991px) {
    .woocommerce-account.my-account .main .woocommerce,
    .woocommerce-account.mon-compte .main .woocommerce {
      padding: 40px 0;
      max-width: calc(80.625% + 20px); } }

.woocommerce-account .main .woocommerce {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -40px;
  z-index: 900;
  background-color: white;
  height: auto;
  max-width: calc(80.625% - 40px);
  margin: 0 auto;
  width: 100%;
  padding: 90px;
  border-radius: 7px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.05); }
  @media (max-width: 1199px) {
    .woocommerce-account .main .woocommerce {
      padding: 90px 40px; } }
  @media (max-width: 991px) {
    .woocommerce-account .main .woocommerce {
      padding: 40px 20px;
      max-width: calc(80.625% + 20px); } }
  @media (max-width: 767px) {
    .woocommerce-account .main .woocommerce {
      top: 0;
      max-width: 100%;
      border-radius: 0;
      box-shadow: none;
      padding: 20px;
      z-index: 10; } }
  .woocommerce-account .main .woocommerce .woocommerce-ResetPassword input {
    margin-bottom: 40px; }
  .woocommerce-account .main .woocommerce .u-columns {
    position: relative;
    display: flex;
    width: 100%; }
    @media (max-width: 767px) {
      .woocommerce-account .main .woocommerce .u-columns {
        flex-direction: column; } }
    .woocommerce-account .main .woocommerce .u-columns > div {
      width: 50%;
      float: none;
      max-width: inherit;
      flex: inherit;
      padding: 0 80px; }
      @media (max-width: 1199px) {
        .woocommerce-account .main .woocommerce .u-columns > div {
          padding: 0 40px; } }
      @media (max-width: 767px) {
        .woocommerce-account .main .woocommerce .u-columns > div {
          width: 100%;
          padding: 0; } }
      .woocommerce-account .main .woocommerce .u-columns > div + div {
        border-left: 1px solid #9ACDEB; }
        @media (max-width: 767px) {
          .woocommerce-account .main .woocommerce .u-columns > div + div {
            border-top: 1px solid #9ACDEB;
            padding-top: 20px;
            border-left: none; } }
      .woocommerce-account .main .woocommerce .u-columns > div h2 {
        font-family: "WorkSans-Medium";
        font-size: 36px;
        margin-bottom: 20px;
        color: #114277; }
        @media (max-width: 1199px) {
          .woocommerce-account .main .woocommerce .u-columns > div h2 {
            font-size: 24px; } }
      .woocommerce-account .main .woocommerce .u-columns > div form {
        border: none;
        padding: 0; }
        .woocommerce-account .main .woocommerce .u-columns > div form .woocommerce-form-row {
          margin-bottom: 40px; }
          @media (max-width: 767px) {
            .woocommerce-account .main .woocommerce .u-columns > div form .woocommerce-form-row {
              margin-bottom: 20px; } }
      .woocommerce-account .main .woocommerce .u-columns > div .connexion-bottom {
        position: relative;
        display: inline-flex;
        flex-direction: column; }
        .woocommerce-account .main .woocommerce .u-columns > div .connexion-bottom button {
          margin-bottom: 20px; }
      .woocommerce-account .main .woocommerce .u-columns > div .woocommerce-form__label-for-checkbox {
        margin-bottom: 20px; }
        .woocommerce-account .main .woocommerce .u-columns > div .woocommerce-form__label-for-checkbox input#rememberme {
          margin-right: 10px; }
          .woocommerce-account .main .woocommerce .u-columns > div .woocommerce-form__label-for-checkbox input#rememberme:checked ~ span:before {
            opacity: 1; }
        .woocommerce-account .main .woocommerce .u-columns > div .woocommerce-form__label-for-checkbox span {
          position: relative; }
          .woocommerce-account .main .woocommerce .u-columns > div .woocommerce-form__label-for-checkbox span:before {
            position: absolute;
            opacity: 0;
            top: 4px;
            left: -35px;
            content: url("../images/icons/icon-blue-checkbox.svg");
            display: block;
            width: 25px;
            height: 25px; }
      .woocommerce-account .main .woocommerce .u-columns > div .woocommerce-LostPassword a {
        text-decoration: underline;
        color: #0080CC;
        transition: color 0.35s ease; }
        .woocommerce-account .main .woocommerce .u-columns > div .woocommerce-LostPassword a:hover {
          color: #114277; }
      .woocommerce-account .main .woocommerce .u-columns > div .woocommerce-privacy-policy-text {
        margin-bottom: 40px; }
        @media (max-width: 767px) {
          .woocommerce-account .main .woocommerce .u-columns > div .woocommerce-privacy-policy-text {
            margin-bottom: 20px; } }
      .woocommerce-account .main .woocommerce .u-columns > div .woocommerce-password-strength {
        padding: 10px;
        font-weight: 100; }

.account-dashboard {
  display: flex;
  width: 100%; }
  @media (max-width: 991px) {
    .account-dashboard {
      flex-direction: column; } }
  .account-dashboard nav.woocommerce-MyAccount-navigation {
    width: 30%;
    margin-right: 20px;
    padding-left: 20px; }
    @media (max-width: 991px) {
      .account-dashboard nav.woocommerce-MyAccount-navigation {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        padding-left: 0; } }
    .account-dashboard nav.woocommerce-MyAccount-navigation ul {
      position: relative; }
      @media (max-width: 991px) {
        .account-dashboard nav.woocommerce-MyAccount-navigation ul {
          padding: 0 20px; } }
      .account-dashboard nav.woocommerce-MyAccount-navigation ul li {
        position: relative; }
        @media (max-width: 991px) {
          .account-dashboard nav.woocommerce-MyAccount-navigation ul li {
            margin-bottom: 10px; } }
        .account-dashboard nav.woocommerce-MyAccount-navigation ul li a {
          display: block;
          font-size: 24px;
          color: #0080CC;
          transition: color 0.35s ease; }
          @media (max-width: 991px) {
            .account-dashboard nav.woocommerce-MyAccount-navigation ul li a {
              font-size: 16px; } }
          .account-dashboard nav.woocommerce-MyAccount-navigation ul li a:hover {
            color: #114277; }
        .account-dashboard nav.woocommerce-MyAccount-navigation ul li + li {
          margin-top: 20px; }
          @media (max-width: 991px) {
            .account-dashboard nav.woocommerce-MyAccount-navigation ul li + li {
              margin-top: 0; } }
        .account-dashboard nav.woocommerce-MyAccount-navigation ul li.is-active:before {
          position: absolute;
          bottom: 0;
          left: -20px;
          content: '';
          width: 15px;
          height: 5px;
          background-color: #E6261C; }
        .account-dashboard nav.woocommerce-MyAccount-navigation ul li.is-active a {
          color: #114277; }
  .account-dashboard div.woocommerce-MyAccount-content {
    width: 70%; }
    @media (max-width: 991px) {
      .account-dashboard div.woocommerce-MyAccount-content {
        width: 100%; } }
    .account-dashboard div.woocommerce-MyAccount-content p {
      font-size: 24px;
      margin-bottom: 20px; }
      @media (max-width: 991px) {
        .account-dashboard div.woocommerce-MyAccount-content p {
          font-size: 16px; } }
    .account-dashboard div.woocommerce-MyAccount-content strong {
      font-family: "Roboto-Bold"; }
    .account-dashboard div.woocommerce-MyAccount-content .woocommerce-Addresses {
      position: relative;
      margin-top: 40px; }
      @media (max-width: 767px) {
        .account-dashboard div.woocommerce-MyAccount-content .woocommerce-Addresses {
          margin-top: 20px; } }
      .account-dashboard div.woocommerce-MyAccount-content .woocommerce-Addresses .woocommerce-Address {
        padding: 0 40px; }
        @media (max-width: 767px) {
          .account-dashboard div.woocommerce-MyAccount-content .woocommerce-Addresses .woocommerce-Address {
            padding: 0;
            margin-bottom: 20px; } }
        .account-dashboard div.woocommerce-MyAccount-content .woocommerce-Addresses .woocommerce-Address:first-child {
          padding: 0 40px 0 0; }
          @media (max-width: 767px) {
            .account-dashboard div.woocommerce-MyAccount-content .woocommerce-Addresses .woocommerce-Address:first-child {
              padding: 0; } }
        .account-dashboard div.woocommerce-MyAccount-content .woocommerce-Addresses .woocommerce-Address header h3 {
          font-family: "WorkSans-Medium";
          font-size: 24px;
          margin-bottom: 20px;
          color: #114277; }
          @media (max-width: 1199px) {
            .account-dashboard div.woocommerce-MyAccount-content .woocommerce-Addresses .woocommerce-Address header h3 {
              font-size: 16px; } }
        .account-dashboard div.woocommerce-MyAccount-content .woocommerce-Addresses .woocommerce-Address address {
          font-style: italic;
          font-size: 13px;
          margin-bottom: 20px; }

.shopify-cart,
.woocommerce-cart .main .woocommerce {
  position: relative;
  background-color: white;
  height: auto;
  max-width: calc(80.625% - 40px);
  margin: 0 auto;
  width: 100%;
  padding: 90px 0; }
  @media (max-width: 1199px) {
    .shopify-cart,
    .woocommerce-cart .main .woocommerce {
      max-width: 80.625%;
      padding: 90px 20px; } }
  @media (max-width: 991px) {
    .shopify-cart,
    .woocommerce-cart .main .woocommerce {
      padding: 40px 20px;
      max-width: calc(80.625% + 20px); } }
  @media (max-width: 767px) {
    .shopify-cart,
    .woocommerce-cart .main .woocommerce {
      top: 0;
      max-width: 100%;
      border-radius: 0;
      box-shadow: none;
      padding: 20px;
      z-index: 10; } }
  .shopify-cart form,
  .woocommerce-cart .main .woocommerce form {
    position: relative; }
    .shopify-cart form td, .shopify-cart form tr, .shopify-cart form table,
    .woocommerce-cart .main .woocommerce form td,
    .woocommerce-cart .main .woocommerce form tr,
    .woocommerce-cart .main .woocommerce form table {
      border: none; }
    .shopify-cart form table,
    .woocommerce-cart .main .woocommerce form table {
      border: none;
      border-radius: 0; }
      .shopify-cart form table thead,
      .woocommerce-cart .main .woocommerce form table thead {
        color: #114277;
        font-size: 24px;
        font-family: "Roboto-Regular";
        font-weight: 100; }
        @media (max-width: 1199px) {
          .shopify-cart form table thead,
          .woocommerce-cart .main .woocommerce form table thead {
            font-size: 18px; } }
        .shopify-cart form table thead th.product-remove,
        .woocommerce-cart .main .woocommerce form table thead th.product-remove {
          width: auto; }
        .shopify-cart form table thead th.product-thumbnail,
        .woocommerce-cart .main .woocommerce form table thead th.product-thumbnail {
          width: 30%; }
        .shopify-cart form table thead th.product-name,
        .woocommerce-cart .main .woocommerce form table thead th.product-name {
          width: 30%; }
        .shopify-cart form table thead th.product-price,
        .woocommerce-cart .main .woocommerce form table thead th.product-price {
          width: 10%; }
        .shopify-cart form table thead th.product-quantity,
        .woocommerce-cart .main .woocommerce form table thead th.product-quantity {
          width: 10%; }
        .shopify-cart form table thead th.product-subtotal,
        .woocommerce-cart .main .woocommerce form table thead th.product-subtotal {
          width: 15%; }
      .shopify-cart form table tbody,
      .woocommerce-cart .main .woocommerce form table tbody {
        border-top: 1px solid #9ACDEB; }
        .shopify-cart form table tbody td, .shopify-cart form table tbody th,
        .woocommerce-cart .main .woocommerce form table tbody td,
        .woocommerce-cart .main .woocommerce form table tbody th {
          padding: 20px; }
          @media (max-width: 1199px) {
            .shopify-cart form table tbody td, .shopify-cart form table tbody th,
            .woocommerce-cart .main .woocommerce form table tbody td,
            .woocommerce-cart .main .woocommerce form table tbody th {
              padding: 10px; } }
        .shopify-cart form table tbody .product-thumbnail a,
        .woocommerce-cart .main .woocommerce form table tbody .product-thumbnail a {
          position: relative;
          border-radius: 7px;
          display: block;
          overflow: hidden;
          position: relative; }
          .shopify-cart form table tbody .product-thumbnail a:before,
          .woocommerce-cart .main .woocommerce form table tbody .product-thumbnail a:before {
            content: '';
            display: block;
            padding-top: 75%; }
          .shopify-cart form table tbody .product-thumbnail a .wrapper,
          .woocommerce-cart .main .woocommerce form table tbody .product-thumbnail a .wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0; }
          .shopify-cart form table tbody .product-thumbnail a img,
          .woocommerce-cart .main .woocommerce form table tbody .product-thumbnail a img {
            position: absolute;
            top: 0;
            left: 0;
            max-width: 100%;
            width: 100%;
            height: 100%;
            object-fit: contain; }
        .shopify-cart form table tbody .product-name a,
        .woocommerce-cart .main .woocommerce form table tbody .product-name a {
          font-size: 24px;
          color: #009ADA;
          display: block; }
          @media (max-width: 1199px) {
            .shopify-cart form table tbody .product-name a,
            .woocommerce-cart .main .woocommerce form table tbody .product-name a {
              font-size: 18px; } }
          @media (max-width: 767px) {
            .shopify-cart form table tbody .product-name a,
            .woocommerce-cart .main .woocommerce form table tbody .product-name a {
              font-size: 13px;
              padding-left: 70px;
              line-height: 1.4; } }
        @media (max-width: 767px) {
          .shopify-cart form table tbody .product-name dl,
          .woocommerce-cart .main .woocommerce form table tbody .product-name dl {
            font-size: 13px;
            font-family: "Roboto-Regular"; } }
        .shopify-cart form table tbody .product-remove,
        .woocommerce-cart .main .woocommerce form table tbody .product-remove {
          position: relative; }
          .shopify-cart form table tbody .product-remove a,
          .woocommerce-cart .main .woocommerce form table tbody .product-remove a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px; }
            @media (max-width: 991px) {
              .shopify-cart form table tbody .product-remove a,
              .woocommerce-cart .main .woocommerce form table tbody .product-remove a {
                width: 20px;
                height: 20px; } }
            .shopify-cart form table tbody .product-remove a:hover,
            .woocommerce-cart .main .woocommerce form table tbody .product-remove a:hover {
              background-color: white;
              border: 2px solid #E6261C; }
        .shopify-cart form table tbody td.actions,
        .woocommerce-cart .main .woocommerce form table tbody td.actions {
          position: relative;
          display: flex;
          width: 100%;
          justify-content: flex-end;
          border-top: 1px solid #9ACDEB;
          padding: 20px 0; }
          @media (max-width: 991px) {
            .shopify-cart form table tbody td.actions,
            .woocommerce-cart .main .woocommerce form table tbody td.actions {
              flex-direction: column; } }
          .shopify-cart form table tbody td.actions #coupon_code,
          .woocommerce-cart .main .woocommerce form table tbody td.actions #coupon_code {
            display: inline-block;
            padding: 14px 60px 13px 20px;
            border: 1px solid #114277;
            font-family: "Roboto-Medium";
            font-size: 16px;
            border-radius: 30px;
            position: relative;
            z-index: 1;
            width: 250px;
            position: relative;
            right: -60px; }
            @media (max-width: 991px) {
              .shopify-cart form table tbody td.actions #coupon_code,
              .woocommerce-cart .main .woocommerce form table tbody td.actions #coupon_code {
                width: 170px; } }
            @media (max-width: 991px) {
              .shopify-cart form table tbody td.actions #coupon_code,
              .woocommerce-cart .main .woocommerce form table tbody td.actions #coupon_code {
                width: 100%;
                right: 0;
                margin-bottom: 20px;
                padding: 14px 20px; } }
            .shopify-cart form table tbody td.actions #coupon_code::placeholder,
            .woocommerce-cart .main .woocommerce form table tbody td.actions #coupon_code::placeholder {
              color: #114277; }
              @media (max-width: 991px) {
                .shopify-cart form table tbody td.actions #coupon_code::placeholder,
                .woocommerce-cart .main .woocommerce form table tbody td.actions #coupon_code::placeholder {
                  text-align: center; } }
        .shopify-cart form table tbody tr.bloc-promo-code,
        .woocommerce-cart .main .woocommerce form table tbody tr.bloc-promo-code {
          position: relative;
          height: 77px;
          width: 100%; }
          @media (max-width: 991px) {
            .shopify-cart form table tbody tr.bloc-promo-code,
            .woocommerce-cart .main .woocommerce form table tbody tr.bloc-promo-code {
              height: 187px; } }
          @media (max-width: 767px) {
            .shopify-cart form table tbody tr.bloc-promo-code,
            .woocommerce-cart .main .woocommerce form table tbody tr.bloc-promo-code {
              height: auto; } }
          .shopify-cart form table tbody tr.bloc-promo-code > td,
          .woocommerce-cart .main .woocommerce form table tbody tr.bloc-promo-code > td {
            position: absolute;
            right: 0;
            width: 100%; }
            @media (max-width: 767px) {
              .shopify-cart form table tbody tr.bloc-promo-code > td,
              .woocommerce-cart .main .woocommerce form table tbody tr.bloc-promo-code > td {
                position: relative; } }
            .shopify-cart form table tbody tr.bloc-promo-code > td .promo-group,
            .woocommerce-cart .main .woocommerce form table tbody tr.bloc-promo-code > td .promo-group {
              position: relative;
              display: flex; }
              @media (max-width: 991px) {
                .shopify-cart form table tbody tr.bloc-promo-code > td .promo-group,
                .woocommerce-cart .main .woocommerce form table tbody tr.bloc-promo-code > td .promo-group {
                  flex-direction: column; } }
          .shopify-cart form table tbody tr.bloc-promo-code .coupon,
          .woocommerce-cart .main .woocommerce form table tbody tr.bloc-promo-code .coupon {
            margin-right: 100px; }
            @media (max-width: 991px) {
              .shopify-cart form table tbody tr.bloc-promo-code .coupon,
              .woocommerce-cart .main .woocommerce form table tbody tr.bloc-promo-code .coupon {
                margin-right: 0;
                padding: 0; } }
            .shopify-cart form table tbody tr.bloc-promo-code .coupon button,
            .woocommerce-cart .main .woocommerce form table tbody tr.bloc-promo-code .coupon button {
              padding: 15px 20px;
              white-space: nowrap;
              background-color: white;
              text-align: center; }
              @media (max-width: 991px) {
                .shopify-cart form table tbody tr.bloc-promo-code .coupon button,
                .woocommerce-cart .main .woocommerce form table tbody tr.bloc-promo-code .coupon button {
                  margin-bottom: 20px;
                  width: 100%; } }
            .shopify-cart form table tbody tr.bloc-promo-code .coupon ~ button,
            .woocommerce-cart .main .woocommerce form table tbody tr.bloc-promo-code .coupon ~ button {
              padding: 15px 20px;
              white-space: nowrap;
              text-align: center; }
              @media (max-width: 991px) {
                .shopify-cart form table tbody tr.bloc-promo-code .coupon ~ button,
                .woocommerce-cart .main .woocommerce form table tbody tr.bloc-promo-code .coupon ~ button {
                  margin-bottom: 20px;
                  width: 100%; } }
  .shopify-cart .cart-collaterals,
  .woocommerce-cart .main .woocommerce .cart-collaterals {
    display: flex;
    width: auto;
    margin: 0 -40px; }
    @media (max-width: 1199px) {
      .shopify-cart .cart-collaterals,
      .woocommerce-cart .main .woocommerce .cart-collaterals {
        margin: 0 -20px; } }
    @media (max-width: 991px) {
      .shopify-cart .cart-collaterals,
      .woocommerce-cart .main .woocommerce .cart-collaterals {
        flex-direction: column;
        margin: 0; } }
    .shopify-cart .cart-collaterals .cart_livraison,
    .woocommerce-cart .main .woocommerce .cart-collaterals .cart_livraison {
      width: calc(50% - 80px);
      position: relative;
      margin: 40px; }
      @media (max-width: 1199px) {
        .shopify-cart .cart-collaterals .cart_livraison,
        .woocommerce-cart .main .woocommerce .cart-collaterals .cart_livraison {
          margin: 20px;
          width: calc(50% - 40px); } }
      @media (max-width: 991px) {
        .shopify-cart .cart-collaterals .cart_livraison,
        .woocommerce-cart .main .woocommerce .cart-collaterals .cart_livraison {
          display: none; } }
    .shopify-cart .cart-collaterals .cart_totals,
    .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals {
      position: relative;
      width: calc(50% - 80px);
      margin: 40px; }
      @media (max-width: 1199px) {
        .shopify-cart .cart-collaterals .cart_totals,
        .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals {
          margin: 20px;
          width: calc(50% - 40px); } }
      @media (max-width: 991px) {
        .shopify-cart .cart-collaterals .cart_totals,
        .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals {
          width: 100%;
          margin: 20px 0; } }
      .shopify-cart .cart-collaterals .cart_totals h2,
      .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals h2 {
        font-size: 36px;
        color: #114277;
        font-weight: 100;
        font-family: "WorkSans-Medium";
        margin-bottom: 20px; }
        @media (max-width: 1199px) {
          .shopify-cart .cart-collaterals .cart_totals h2,
          .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals h2 {
            font-size: 24px; } }
        @media (max-width: 767px) {
          .shopify-cart .cart-collaterals .cart_totals h2,
          .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals h2 {
            font-size: 18px; } }
      .shopify-cart .cart-collaterals .cart_totals table,
      .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals table {
        border: 1px solid #9ACDEB;
        border-radius: 7px;
        position: relative;
        overflow: hidden; }
        .shopify-cart .cart-collaterals .cart_totals table tbody,
        .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals table tbody {
          border-collapse: collapse; }
          .shopify-cart .cart-collaterals .cart_totals table tbody > tr + tr,
          .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals table tbody > tr + tr {
            border-top: 1px solid #9ACDEB; }
            .shopify-cart .cart-collaterals .cart_totals table tbody > tr + tr td, .shopify-cart .cart-collaterals .cart_totals table tbody > tr + tr th,
            .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals table tbody > tr + tr td,
            .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals table tbody > tr + tr th {
              border-top: 1px solid #9ACDEB; }
          .shopify-cart .cart-collaterals .cart_totals table tbody tr td, .shopify-cart .cart-collaterals .cart_totals table tbody tr th,
          .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals table tbody tr td,
          .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals table tbody tr th {
            border: none;
            padding: 20px 40px;
            font-size: 24px;
            font-weight: 100; }
            @media (max-width: 1199px) {
              .shopify-cart .cart-collaterals .cart_totals table tbody tr td, .shopify-cart .cart-collaterals .cart_totals table tbody tr th,
              .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals table tbody tr td,
              .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals table tbody tr th {
                font-size: 18px; } }
            @media (max-width: 767px) {
              .shopify-cart .cart-collaterals .cart_totals table tbody tr td, .shopify-cart .cart-collaterals .cart_totals table tbody tr th,
              .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals table tbody tr td,
              .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals table tbody tr th {
                font-size: 14px;
                padding: 20px; } }
          .shopify-cart .cart-collaterals .cart_totals table tbody tr th,
          .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals table tbody tr th {
            text-align: left; }
          .shopify-cart .cart-collaterals .cart_totals table tbody tr td,
          .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals table tbody tr td {
            text-align: right; }
          .shopify-cart .cart-collaterals .cart_totals table tbody tr.order-total th, .shopify-cart .cart-collaterals .cart_totals table tbody tr.order-total td,
          .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals table tbody tr.order-total th,
          .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals table tbody tr.order-total td {
            font-family: "Roboto-Bold"; }
      .shopify-cart .cart-collaterals .cart_totals .shipping-calculator-form button,
      .woocommerce-cart .main .woocommerce .cart-collaterals .cart_totals .shipping-calculator-form button {
        margin-top: 20px; }

.shopify-cart,
.woocommerce-checkout .main .woocommerce {
  position: relative;
  background-color: white;
  height: auto;
  max-width: calc(80.625% - 40px);
  margin: 0 auto;
  width: 100%;
  padding: 90px 0; }
  @media (max-width: 1199px) {
    .shopify-cart,
    .woocommerce-checkout .main .woocommerce {
      max-width: 80.625%;
      padding: 90px 20px; } }
  @media (max-width: 991px) {
    .shopify-cart,
    .woocommerce-checkout .main .woocommerce {
      padding: 40px 20px;
      max-width: calc(80.625% + 20px); } }
  @media (max-width: 767px) {
    .shopify-cart,
    .woocommerce-checkout .main .woocommerce {
      top: 0;
      max-width: 100%;
      border-radius: 0;
      box-shadow: none;
      padding: 20px;
      z-index: 10; } }
  .shopify-cart form.checkout,
  .woocommerce-checkout .main .woocommerce form.checkout {
    position: relative; }
    .shopify-cart form.checkout #customer_details,
    .woocommerce-checkout .main .woocommerce form.checkout #customer_details {
      display: flex;
      width: auto;
      margin: 0 -40px; }
      @media (max-width: 1199px) {
        .shopify-cart form.checkout #customer_details,
        .woocommerce-checkout .main .woocommerce form.checkout #customer_details {
          margin: 0 -20px; } }
      @media (max-width: 991px) {
        .shopify-cart form.checkout #customer_details,
        .woocommerce-checkout .main .woocommerce form.checkout #customer_details {
          flex-direction: column;
          margin: 0; } }
      .shopify-cart form.checkout #customer_details > div,
      .woocommerce-checkout .main .woocommerce form.checkout #customer_details > div {
        width: calc(50% - 80px);
        position: relative;
        margin: 40px;
        flex: inherit;
        max-width: 100%;
        float: none; }
        @media (max-width: 1199px) {
          .shopify-cart form.checkout #customer_details > div,
          .woocommerce-checkout .main .woocommerce form.checkout #customer_details > div {
            margin: 20px;
            width: calc(50% - 40px); } }
        @media (max-width: 991px) {
          .shopify-cart form.checkout #customer_details > div,
          .woocommerce-checkout .main .woocommerce form.checkout #customer_details > div {
            width: 100%;
            margin: 20px 0;
            padding: 0; } }
        .shopify-cart form.checkout #customer_details > div .form-row,
        .woocommerce-checkout .main .woocommerce form.checkout #customer_details > div .form-row {
          margin-bottom: 20px; }
        .shopify-cart form.checkout #customer_details > div table td,
        .woocommerce-checkout .main .woocommerce form.checkout #customer_details > div table td {
          padding: 20px; }
        .shopify-cart form.checkout #customer_details > div table tr td:first-child, .shopify-cart form.checkout #customer_details > div table tr th:first-child,
        .woocommerce-checkout .main .woocommerce form.checkout #customer_details > div table tr td:first-child,
        .woocommerce-checkout .main .woocommerce form.checkout #customer_details > div table tr th:first-child {
          border-top: 1px solid #9ACDEB; }
        .shopify-cart form.checkout #customer_details > div #ship-to-different-address label,
        .woocommerce-checkout .main .woocommerce form.checkout #customer_details > div #ship-to-different-address label {
          position: relative;
          display: flex;
          align-items: flex-start; }
          .shopify-cart form.checkout #customer_details > div #ship-to-different-address label input#ship-to-different-address-checkbox,
          .woocommerce-checkout .main .woocommerce form.checkout #customer_details > div #ship-to-different-address label input#ship-to-different-address-checkbox {
            margin-right: 10px; }
            .shopify-cart form.checkout #customer_details > div #ship-to-different-address label input#ship-to-different-address-checkbox:checked ~ span:before,
            .woocommerce-checkout .main .woocommerce form.checkout #customer_details > div #ship-to-different-address label input#ship-to-different-address-checkbox:checked ~ span:before {
              opacity: 1; }
          .shopify-cart form.checkout #customer_details > div #ship-to-different-address label span,
          .woocommerce-checkout .main .woocommerce form.checkout #customer_details > div #ship-to-different-address label span {
            position: relative;
            display: block; }
            .shopify-cart form.checkout #customer_details > div #ship-to-different-address label span:before,
            .woocommerce-checkout .main .woocommerce form.checkout #customer_details > div #ship-to-different-address label span:before {
              position: absolute;
              opacity: 0;
              top: 3px;
              left: -35px;
              content: url("../images/icons/icon-blue-checkbox.svg");
              display: block;
              width: 25px;
              height: 25px; }
    .shopify-cart form.checkout .cart_livraison,
    .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison {
      width: 100%;
      position: relative;
      margin-bottom: 40px; }
      @media (max-width: 1199px) {
        .shopify-cart form.checkout .cart_livraison,
        .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison {
          margin-bottom: 20px; } }
      @media (max-width: 991px) {
        .shopify-cart form.checkout .cart_livraison,
        .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison {
          width: 100%; } }
      .shopify-cart form.checkout .cart_livraison .wrapper,
      .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper {
        position: relative;
        background-color: #15142F;
        border-radius: 7px;
        overflow: hidden;
        display: flex;
        width: 100%;
        padding: 80px 40px; }
        @media (max-width: 1199px) {
          .shopify-cart form.checkout .cart_livraison .wrapper,
          .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper {
            padding: 40px 20px; } }
        @media (max-width: 767px) {
          .shopify-cart form.checkout .cart_livraison .wrapper,
          .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper {
            flex-direction: column; } }
        .shopify-cart form.checkout .cart_livraison .wrapper .left,
        .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .left {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25%; }
          @media (max-width: 767px) {
            .shopify-cart form.checkout .cart_livraison .wrapper .left,
            .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .left {
              width: 100%;
              padding-bottom: 20px; } }
          .shopify-cart form.checkout .cart_livraison .wrapper .left img,
          .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .left img {
            max-width: 138px;
            width: 100%; }
        .shopify-cart form.checkout .cart_livraison .wrapper .right,
        .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right {
          width: 75%; }
          @media (max-width: 767px) {
            .shopify-cart form.checkout .cart_livraison .wrapper .right,
            .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right {
              width: 100%; } }
          .shopify-cart form.checkout .cart_livraison .wrapper .right .inner,
          .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right .inner {
            padding: 0 20px;
            margin: 0 auto;
            display: table; }
            @media (max-width: 767px) {
              .shopify-cart form.checkout .cart_livraison .wrapper .right .inner,
              .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right .inner {
                padding: 0; } }
            .shopify-cart form.checkout .cart_livraison .wrapper .right .inner h2,
            .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right .inner h2 {
              font-size: 36px;
              color: white;
              font-weight: 100;
              font-family: "WorkSans-Medium";
              margin-bottom: 20px; }
              @media (max-width: 1199px) {
                .shopify-cart form.checkout .cart_livraison .wrapper .right .inner h2,
                .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right .inner h2 {
                  font-size: 24px; } }
              @media (max-width: 767px) {
                .shopify-cart form.checkout .cart_livraison .wrapper .right .inner h2,
                .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right .inner h2 {
                  font-size: 18px; } }
            .shopify-cart form.checkout .cart_livraison .wrapper .right .inner p,
            .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right .inner p {
              color: white;
              font-size: 24px;
              line-height: 1.4;
              margin-bottom: 20px; }
              @media (max-width: 1199px) {
                .shopify-cart form.checkout .cart_livraison .wrapper .right .inner p,
                .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right .inner p {
                  font-size: 18px; } }
              @media (max-width: 767px) {
                .shopify-cart form.checkout .cart_livraison .wrapper .right .inner p,
                .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right .inner p {
                  font-size: 14px; } }
            .shopify-cart form.checkout .cart_livraison .wrapper .right .inner .input-group,
            .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right .inner .input-group {
              position: relative;
              display: flex;
              align-items: flex-start; }
              .shopify-cart form.checkout .cart_livraison .wrapper .right .inner .input-group input,
              .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right .inner .input-group input {
                margin-right: 10px;
                color: #0080CC;
                border: 1px solid white; }
                .shopify-cart form.checkout .cart_livraison .wrapper .right .inner .input-group input:checked ~ label:before,
                .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right .inner .input-group input:checked ~ label:before {
                  opacity: 1; }
              .shopify-cart form.checkout .cart_livraison .wrapper .right .inner .input-group label,
              .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right .inner .input-group label {
                position: relative;
                color: #009ADA;
                font-size: 24px;
                width: 100%; }
                @media (max-width: 1199px) {
                  .shopify-cart form.checkout .cart_livraison .wrapper .right .inner .input-group label,
                  .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right .inner .input-group label {
                    font-size: 18px; } }
                @media (max-width: 767px) {
                  .shopify-cart form.checkout .cart_livraison .wrapper .right .inner .input-group label,
                  .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right .inner .input-group label {
                    font-size: 14px; } }
                .shopify-cart form.checkout .cart_livraison .wrapper .right .inner .input-group label:before,
                .woocommerce-checkout .main .woocommerce form.checkout .cart_livraison .wrapper .right .inner .input-group label:before {
                  position: absolute;
                  opacity: 0;
                  top: 3px;
                  left: -34px;
                  content: url("../images/icons/icon-blue-checkbox.svg");
                  display: block;
                  width: 25px;
                  height: 25px; }

.hero__cta_bottom {
  position: relative;
  width: 100%;
  padding: 0 20px;
  bottom: -20px;
  z-index: 110; }
  @media (max-width: 991px) {
    .hero__cta_bottom {
      padding: 0 76px; } }
  @media (max-width: 767px) {
    .hero__cta_bottom {
      padding: 0 36px; } }
  @media (max-width: 575px) {
    .hero__cta_bottom {
      padding: 0 20px; } }
  .hero__cta_bottom .text {
    display: block;
    font-size: 36px;
    color: #114277;
    font-family: "WorkSans-Regular"; }
    @media (max-width: 1199px) {
      .hero__cta_bottom .text {
        font-size: 24px; } }
    @media (max-width: 991px) {
      .hero__cta_bottom .text {
        font-size: 20px; } }
  .hero__cta_bottom_wrapper {
    display: flex;
    margin: 0 auto;
    max-width: 80.625%;
    width: 100%;
    background-color: white;
    border-radius: 7px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.05); }
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .hero__cta_bottom_wrapper {
        max-width: 85%; } }
    @media (max-width: 1199px) {
      .hero__cta_bottom_wrapper {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .hero__cta_bottom_wrapper {
        display: flex;
        flex-direction: column;
        max-width: 100%; } }
    .hero__cta_bottom_wrapper .hero__cta_bottom_left,
    .hero__cta_bottom_wrapper .hero__cta_bottom_mid {
      display: flex;
      padding: 0 60px;
      margin: 20px 0;
      flex: 1; }
      .hero__cta_bottom_wrapper .hero__cta_bottom_left + .hero__cta_bottom_mid,
      .hero__cta_bottom_wrapper .hero__cta_bottom_mid + .hero__cta_bottom_mid {
        border-left: 1px solid #0080CC; }
        @media (max-width: 991px) {
          .hero__cta_bottom_wrapper .hero__cta_bottom_left + .hero__cta_bottom_mid,
          .hero__cta_bottom_wrapper .hero__cta_bottom_mid + .hero__cta_bottom_mid {
            border-left: none;
            border-top: 1px solid #0080CC; } }
      .hero__cta_bottom_wrapper .hero__cta_bottom_left + .hero__cta_bottom_right,
      .hero__cta_bottom_wrapper .hero__cta_bottom_mid + .hero__cta_bottom_right {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        @media (max-width: 991px) {
          .hero__cta_bottom_wrapper .hero__cta_bottom_left + .hero__cta_bottom_right,
          .hero__cta_bottom_wrapper .hero__cta_bottom_mid + .hero__cta_bottom_right {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 7px; } }
      @media only screen and (max-width: 1280px) {
        .hero__cta_bottom_wrapper .hero__cta_bottom_left,
        .hero__cta_bottom_wrapper .hero__cta_bottom_mid {
          padding: 0 20px; } }
      @media (max-width: 991px) {
        .hero__cta_bottom_wrapper .hero__cta_bottom_left,
        .hero__cta_bottom_wrapper .hero__cta_bottom_mid {
          width: calc(100% - 40px);
          padding: 20px;
          margin: 0 auto; }
          .hero__cta_bottom_wrapper .hero__cta_bottom_left p,
          .hero__cta_bottom_wrapper .hero__cta_bottom_mid p {
            display: none; } }
      .hero__cta_bottom_wrapper .hero__cta_bottom_left .inner,
      .hero__cta_bottom_wrapper .hero__cta_bottom_mid .inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%; }
        @media (max-width: 575px) {
          .hero__cta_bottom_wrapper .hero__cta_bottom_left .inner,
          .hero__cta_bottom_wrapper .hero__cta_bottom_mid .inner {
            justify-content: flex-start; } }
        .hero__cta_bottom_wrapper .hero__cta_bottom_left .inner img,
        .hero__cta_bottom_wrapper .hero__cta_bottom_mid .inner img {
          padding-right: 40px; }
          @media (max-width: 1199px) {
            .hero__cta_bottom_wrapper .hero__cta_bottom_left .inner img,
            .hero__cta_bottom_wrapper .hero__cta_bottom_mid .inner img {
              padding-right: 20px; } }
          @media (max-width: 991px) {
            .hero__cta_bottom_wrapper .hero__cta_bottom_left .inner img,
            .hero__cta_bottom_wrapper .hero__cta_bottom_mid .inner img {
              padding-right: 20px;
              height: 30px; } }
    .hero__cta_bottom_wrapper .hero__cta_bottom_right {
      flex: 1;
      background-color: #E6261C;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
      display: flex;
      align-items: center;
      transition: background-color 0.35s ease; }
      @media (max-width: 991px) {
        .hero__cta_bottom_wrapper .hero__cta_bottom_right {
          width: 100%;
          border-top-right-radius: 0;
          border-bottom-left-radius: 7px;
          border-bottom-right-radius: 7px; } }
      .hero__cta_bottom_wrapper .hero__cta_bottom_right:hover {
        background-color: #34396B; }
      .hero__cta_bottom_wrapper .hero__cta_bottom_right .inner {
        display: block;
        margin: 0 auto;
        max-width: 80%;
        padding: 60px 0; }
        @media (max-width: 1199px) {
          .hero__cta_bottom_wrapper .hero__cta_bottom_right .inner {
            padding: 40px 0; } }
        @media (max-width: 991px) {
          .hero__cta_bottom_wrapper .hero__cta_bottom_right .inner {
            padding: 20px 0; } }
        .hero__cta_bottom_wrapper .hero__cta_bottom_right .inner .cta_soumission {
          display: flex;
          align-items: center;
          height: 100%; }
          @media (max-width: 991px) {
            .hero__cta_bottom_wrapper .hero__cta_bottom_right .inner .cta_soumission {
              justify-content: center;
              text-align: center; } }
          .hero__cta_bottom_wrapper .hero__cta_bottom_right .inner .cta_soumission img {
            width: 60px; }
            @media (max-width: 1199px) {
              .hero__cta_bottom_wrapper .hero__cta_bottom_right .inner .cta_soumission img {
                width: 40px; } }
            @media (max-width: 991px) {
              .hero__cta_bottom_wrapper .hero__cta_bottom_right .inner .cta_soumission img {
                display: none; } }
          .hero__cta_bottom_wrapper .hero__cta_bottom_right .inner .cta_soumission .text {
            display: block;
            color: white;
            margin-bottom: 0;
            padding-left: 40px; }
            @media (max-width: 991px) {
              .hero__cta_bottom_wrapper .hero__cta_bottom_right .inner .cta_soumission .text {
                padding-left: 0; } }

.cta__circle {
  display: flex;
  flex-direction: row;
  width: 100%; }
  @media (max-width: 575px) {
    .cta__circle {
      flex-direction: column; } }
  .cta__circle.side-reverse {
    flex-direction: row-reverse; }
    @media (max-width: 575px) {
      .cta__circle.side-reverse {
        flex-direction: column;
        align-items: center; } }
    .cta__circle.side-reverse .cta__circle_right {
      margin-left: 0;
      margin-right: 9%; }
      @media (max-width: 575px) {
        .cta__circle.side-reverse .cta__circle_right {
          margin-right: 0; } }
  .cta__circle_left {
    position: relative;
    position: absolute;
    z-index: 10;
    width: 26%; }
    .cta__circle_left:before {
      content: '';
      display: block;
      padding-top: 100%; }
    .cta__circle_left .wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    @media screen and (max-width: 330px) {
      .cta__circle_left {
        position: relative;
        margin: 0 auto 20px;
        width: 100%;
        max-width: 75%; } }
    .cta__circle_left .circle {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      background-color: #979797;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 50%;
      transform: translate3d(0, 0, 0);
      max-width: 400px;
      max-height: 400px;
      transition: background-size 0.35s ease; }
      @media (max-width: 991px) {
        .cta__circle_left .circle {
          margin-bottom: 20px; } }
      .cta__circle_left .circle:hover {
        background-size: 110%; }
        .cta__circle_left .circle:hover img {
          transform: scale(1.1); }
  .cta__circle_right {
    position: relative;
    width: 100%;
    padding-left: 36%; }
    @media screen and (max-width: 330px) {
      .cta__circle_right {
        width: 100%;
        padding-left: 0; } }
    .cta__circle_right .wysiwyg h2 {
      font-family: "WorkSans-Light";
      font-size: 42px;
      margin-bottom: 20px;
      line-height: 1.2; }
      @media (max-width: 1199px) {
        .cta__circle_right .wysiwyg h2 {
          font-size: 32px; } }
      @media (max-width: 991px) {
        .cta__circle_right .wysiwyg h2 {
          font-size: 24px; } }
    .cta__circle_right .wysiwyg p {
      font-size: 16px;
      letter-spacing: 1px; }
      @media (max-width: 991px) {
        .cta__circle_right .wysiwyg p {
          font-size: 13px; } }
    .cta__circle_right .wysiwyg ul {
      margin-top: 20px;
      position: relative; }
      .cta__circle_right .wysiwyg ul li {
        background-image: url("../images/icons/icon-list-arrow.svg");
        background-repeat: no-repeat;
        background-position: 0px center;
        list-style: none;
        margin: 0;
        padding-left: 40px; }
        .cta__circle_right .wysiwyg ul li a {
          text-decoration: none;
          opacity: 1;
          transition: opacity 0.35s ease; }
          .cta__circle_right .wysiwyg ul li a:hover {
            color: #0080CC;
            opacity: 0.7; }

.cta__circle_text {
  position: relative;
  width: 100%; }
  .cta__circle_text h2 {
    font-family: "WorkSans-Light";
    font-size: 42px;
    margin-bottom: 40px;
    line-height: 1.4; }
    @media (max-width: 1199px) {
      .cta__circle_text h2 {
        font-size: 32px; } }
    @media (max-width: 991px) {
      .cta__circle_text h2 {
        font-size: 26px; } }
  .cta__circle_text_wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px; }
    @media (max-width: 991px) {
      .cta__circle_text_wrapper {
        flex-direction: column;
        margin: 0; } }
    .cta__circle_text_wrapper .cta__circle_text_item {
      display: flex;
      width: calc(50% - 40px);
      margin: 20px; }
      @media (max-width: 991px) {
        .cta__circle_text_wrapper .cta__circle_text_item {
          width: 100%;
          margin: 0 0 20px; } }
      @media (max-width: 767px) {
        .cta__circle_text_wrapper .cta__circle_text_item {
          flex-direction: column; } }
      .cta__circle_text_wrapper .cta__circle_text_item .cta__circle_text_left {
        width: 183px;
        display: flex;
        justify-content: center;
        margin-right: 40px; }
        @media (max-width: 991px) {
          .cta__circle_text_wrapper .cta__circle_text_item .cta__circle_text_left {
            margin-right: 20px; } }
        @media (max-width: 767px) {
          .cta__circle_text_wrapper .cta__circle_text_item .cta__circle_text_left {
            width: 100%;
            margin-right: 0; } }
        .cta__circle_text_wrapper .cta__circle_text_item .cta__circle_text_left .circle {
          position: relative;
          overflow: hidden;
          border-radius: 50%;
          width: 183px;
          height: 183px;
          background-color: #979797;
          backface-visibility: hidden;
          transform: translate3d(0, 0, 0); }
          @media (max-width: 991px) {
            .cta__circle_text_wrapper .cta__circle_text_item .cta__circle_text_left .circle {
              width: 123px;
              height: 123px; } }
          .cta__circle_text_wrapper .cta__circle_text_item .cta__circle_text_left .circle img {
            transition: transform 0.35s ease;
            transform: scale(1);
            width: 100%;
            height: 100%;
            object-fit: cover;
            backface-visibility: hidden; }
          .cta__circle_text_wrapper .cta__circle_text_item .cta__circle_text_left .circle:hover img {
            transform: scale(1.1); }
      .cta__circle_text_wrapper .cta__circle_text_item .cta__circle_text_right {
        width: 100%; }
        @media (max-width: 767px) {
          .cta__circle_text_wrapper .cta__circle_text_item .cta__circle_text_right {
            margin-top: 20px; } }

.cta__square {
  display: flex;
  flex-direction: row;
  width: 100%; }
  @media screen and (max-width: 375px) {
    .cta__square {
      flex-direction: column; } }
  .cta__square.side-reverse {
    flex-direction: row-reverse; }
    @media (max-width: 575px) {
      .cta__square.side-reverse {
        flex-direction: column;
        align-items: center; } }
    .cta__square.side-reverse .cta__square_right {
      margin-left: 0;
      margin-right: 9%; }
      @media (max-width: 575px) {
        .cta__square.side-reverse .cta__square_right {
          margin-right: 0; } }
  .cta__square_left {
    position: relative;
    position: absolute;
    z-index: 10;
    width: 30%; }
    .cta__square_left:before {
      content: '';
      display: block;
      padding-top: 82.2222222222%; }
    .cta__square_left .wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    @media screen and (max-width: 375px) {
      .cta__square_left {
        position: relative;
        margin: 0 auto 20px;
        width: 100%;
        max-width: 100%; } }
    .cta__square_left .square {
      border-radius: 7px;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      background-color: #979797;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 50%;
      transform: translate3d(0, 0, 0);
      max-width: 450px;
      max-height: 370px;
      transition: background-size 0.35s ease; }
      @media (max-width: 991px) {
        .cta__square_left .square {
          margin-bottom: 20px; } }
      .cta__square_left .square:hover {
        background-size: 110%; }
        .cta__square_left .square:hover img {
          transform: scale(1.1); }
  .cta__square_right {
    position: relative;
    width: 100%;
    padding-left: 38%; }
    @media screen and (max-width: 375px) {
      .cta__square_right {
        width: 100%;
        padding-left: 0; } }
    .cta__square_right .wysiwyg h2 {
      font-family: "WorkSans-Medium";
      font-size: 42px;
      margin-bottom: 40px;
      line-height: 1.2; }
      @media (max-width: 1199px) {
        .cta__square_right .wysiwyg h2 {
          font-size: 32px;
          margin-bottom: 20px; } }
      @media (max-width: 991px) {
        .cta__square_right .wysiwyg h2 {
          font-size: 24px; } }
    .cta__square_right .wysiwyg p {
      font-size: 24px;
      letter-spacing: 1px; }
      @media (max-width: 1199px) {
        .cta__square_right .wysiwyg p {
          font-size: 16px; } }
      @media screen and (max-width: 375px) {
        .cta__square_right .wysiwyg p {
          font-size: 14px; } }
    .cta__square_right .wysiwyg ul {
      margin-top: 40px;
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap; }
      @media (max-width: 991px) {
        .cta__square_right .wysiwyg ul {
          flex-direction: column;
          margin-top: 20px; } }
      .cta__square_right .wysiwyg ul li {
        background-image: url("../images/icons/icon-blue-list-arrow.svg");
        background-repeat: no-repeat;
        background-position: 0px 8px;
        background-size: 15px;
        list-style: none;
        margin: 0;
        padding-left: 40px;
        width: 50%;
        display: flex;
        align-items: flex-start;
        height: 45px; }
        @media (max-width: 1199px) {
          .cta__square_right .wysiwyg ul li {
            font-size: 16px; } }
        @media (max-width: 991px) {
          .cta__square_right .wysiwyg ul li {
            width: 100%;
            font-size: 16px;
            background-position: 0 3px;
            height: auto; } }
        .cta__square_right .wysiwyg ul li + li {
          margin-top: 0; }
          @media (max-width: 991px) {
            .cta__square_right .wysiwyg ul li + li {
              margin-top: 10px; } }
        .cta__square_right .wysiwyg ul li a {
          text-decoration: none;
          opacity: 1;
          transition: opacity 0.35s ease;
          font-size: 24px; }
          @media (max-width: 991px) {
            .cta__square_right .wysiwyg ul li a {
              font-size: 16px; } }
          .cta__square_right .wysiwyg ul li a:hover {
            color: #0080CC;
            opacity: 0.7; }

.cta__side_image {
  position: relative;
  display: flex;
  width: 100%; }
  @media (max-width: 991px) {
    .cta__side_image {
      flex-direction: column; } }
  .cta__side_image.side-reverse {
    flex-direction: row-reverse; }
    @media (max-width: 991px) {
      .cta__side_image.side-reverse {
        flex-direction: column; } }
    .cta__side_image.side-reverse .cta__side_image_left {
      margin-right: 0;
      margin-left: 5%; }
      @media (max-width: 991px) {
        .cta__side_image.side-reverse .cta__side_image_left {
          margin-left: 0; } }
  .cta__side_image_left {
    width: 30%;
    margin-right: 5%; }
    @media (max-width: 1199px) {
      .cta__side_image_left {
        width: 60%; } }
    @media (max-width: 991px) {
      .cta__side_image_left {
        width: 100%;
        margin-right: 0; } }
    .cta__side_image_left h2 {
      font-family: "WorkSans-Light";
      font-size: 42px; }
      @media (max-width: 1199px) {
        .cta__side_image_left h2 {
          font-size: 32px; } }
      @media (max-width: 991px) {
        .cta__side_image_left h2 {
          font-size: 26px; } }
    .cta__side_image_left .subtitle {
      font-size: 24px;
      margin-top: 20px; }
      @media (max-width: 1199px) {
        .cta__side_image_left .subtitle {
          font-size: 18px; } }
    .cta__side_image_left p {
      margin-top: 20px;
      font-size: 16px;
      line-height: 1.4;
      letter-spacing: 1px; }
      @media (max-width: 991px) {
        .cta__side_image_left p {
          font-size: 13px; } }
    .cta__side_image_left a.btn, .cta__side_image_left a.btn-fx {
      margin-top: 20px; }
  .cta__side_image_right {
    position: relative;
    overflow: hidden;
    width: 65%; }
    @media (max-width: 1199px) {
      .cta__side_image_right {
        width: 35%; } }
    @media (max-width: 991px) {
      .cta__side_image_right {
        width: 100%;
        margin-top: 40px; } }
    .cta__side_image_right .image {
      position: relative;
      width: 100%;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 10px;
      overflow: hidden; }
      .cta__side_image_right .image:before {
        content: '';
        display: block;
        padding-top: 49.2%; }
      .cta__side_image_right .image .wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
    .cta__side_image_right .circles {
      position: relative; }
      .cta__side_image_right .circles:before {
        content: '';
        display: block;
        padding-top: 55.5439330544%; }
      .cta__side_image_right .circles .wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
      .cta__side_image_right .circles_wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
        .page-template-page-technologie .cta__side_image_right .circles_wrapper {
          transform: scale(-1); }
          .page-template-page-technologie .cta__side_image_right .circles_wrapper .circle img {
            transform: scale(-1) translateZ(0); }
          .page-template-page-technologie .cta__side_image_right .circles_wrapper .circle:hover img {
            transform: scale(-1.1) translateZ(0); }
        .cta__side_image_right .circles_wrapper .circle {
          position: absolute;
          border-radius: 50%;
          overflow: hidden;
          background-color: #979797;
          backface-visibility: hidden;
          transform: translate3d(0, 0, 0); }
          .cta__side_image_right .circles_wrapper .circle:hover img {
            transform: scale(1.1); }
          .cta__side_image_right .circles_wrapper .circle img {
            transition: transform 0.35s ease;
            transform: scale(1);
            width: 100%;
            height: 100%;
            object-fit: cover;
            backface-visibility: hidden; }
          .cta__side_image_right .circles_wrapper .circle.large {
            top: 0;
            left: 0;
            width: 54.6%;
            height: 98.3%; }
          .cta__side_image_right .circles_wrapper .circle.medium {
            top: 0;
            right: 0;
            width: 32.6%;
            height: 58.7%; }
          .cta__side_image_right .circles_wrapper .circle.small {
            bottom: 0;
            right: 22%;
            width: 22.8%;
            height: 41.1%; }

.cta__image_box {
  padding: 60px 100px;
  border-radius: 7px;
  background-color: #979797;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  @media (max-width: 991px) {
    .cta__image_box {
      padding: 60px 20px; } }
  .cta__image_box .infobox {
    background-color: white;
    padding: 60px;
    max-width: 587px;
    width: 100%; }
    @media (max-width: 991px) {
      .cta__image_box .infobox {
        padding: 30px; } }
    .cta__image_box .infobox h2 {
      font-size: 36px; }
      @media (max-width: 991px) {
        .cta__image_box .infobox h2 {
          padding: 24px; } }
    .cta__image_box .infobox p {
      font-size: 24px; }
      @media (max-width: 991px) {
        .cta__image_box .infobox p {
          font-size: 16px; } }
      @media (max-width: 575px) {
        .cta__image_box .infobox p {
          font-size: 13px; } }

.cta__image_full {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 430px; }
  @media (max-width: 767px) {
    .cta__image_full {
      min-height: inherit; } }
  .cta__image_full_wrapper {
    position: relative;
    z-index: 100;
    text-align: center;
    color: white;
    max-width: 720px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 20px; }
    @media (max-width: 767px) {
      .cta__image_full_wrapper {
        max-width: 100%; } }
    .cta__image_full_wrapper h2 {
      font-family: "WorkSans-Light"; }
    .cta__image_full_wrapper > a.btn-fx.bg-white {
      color: #0080CC; }
      .cta__image_full_wrapper > a.btn-fx.bg-white:hover {
        color: white; }
    .cta__image_full_wrapper form {
      position: relative;
      width: 100%;
      max-width: 312px;
      display: block;
      margin: 60px auto 0; }
      @media (max-width: 575px) {
        .cta__image_full_wrapper form {
          max-width: 100%;
          margin: 0 auto; } }
      .cta__image_full_wrapper form fieldset {
        position: relative;
        width: 100%; }
        @media (max-width: 991px) {
          .cta__image_full_wrapper form fieldset {
            width: 100%; } }
        .cta__image_full_wrapper form fieldset .input-newsletter, .cta__image_full_wrapper form fieldset .input-download {
          border: none;
          height: 50px;
          border-radius: 40px;
          padding: 10px 70px 10px 25px;
          width: 100%;
          font-size: 14px; }
        .cta__image_full_wrapper form fieldset button {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 0;
          right: -2px;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          text-align: center;
          padding: 10px; }
          .cta__image_full_wrapper form fieldset button img {
            width: 15px;
            height: 15px;
            margin-left: 5px; }
          .cta__image_full_wrapper form fieldset button:hover svg #Icon {
            fill: #E6261C; }
          .cta__image_full_wrapper form fieldset button svg {
            width: 15px;
            height: 15px;
            margin-left: 5px; }
            .cta__image_full_wrapper form fieldset button svg #Icon {
              transition: fill 0.35s ease;
              fill: white; }

.cta__grid_item {
  display: block;
  width: calc(33.33% - 80px);
  margin: 40px; }
  @media (max-width: 991px) {
    .cta__grid_item {
      width: calc(50% - 40px);
      margin: 20px; } }
  @media (max-width: 767px) {
    .cta__grid_item {
      width: 100%;
      margin: 20px 0; } }
  .cta__grid_item:hover .cta__grid_item_top .image {
    transform: scale(1.1); }
  .cta__grid_item:hover .cta__grid_item_bottom h3 {
    color: #0080CC; }
  .cta__grid_item_top {
    position: relative;
    overflow: hidden;
    border-radius: 7px;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0); }
    .cta__grid_item_top a {
      display: block;
      background-color: #979797; }
    .cta__grid_item_top .image {
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #979797;
      transform: scale(1);
      transition: transform 0.35s ease;
      backface-visibility: hidden; }
      .cta__grid_item_top .image:before {
        content: '';
        display: block;
        padding-top: 70.3296703297%; }
      .cta__grid_item_top .image .wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
  .cta__grid_item_bottom {
    margin-top: 30px; }
    @media (max-width: 767px) {
      .cta__grid_item_bottom {
        margin-top: 20px; } }
    .cta__grid_item_bottom .tag {
      text-transform: uppercase;
      font-size: 16px;
      font-family: "Roboto-Regular";
      color: #0080CC;
      letter-spacing: 2px;
      display: block; }
      .page-template-page-ressources .cta__grid_item_bottom .tag {
        color: #0080CC; }
      @media (max-width: 767px) {
        .cta__grid_item_bottom .tag {
          font-size: 13px; } }
      .cta__grid_item_bottom .tag + .tag {
        margin-top: 5px; }
    .cta__grid_item_bottom h3 {
      transition: color 0.35s ease;
      margin-top: 20px;
      font-size: 24px;
      line-height: 1.4; }
      @media (max-width: 1199px) {
        .cta__grid_item_bottom h3 {
          font-size: 18px; } }
      @media (max-width: 991px) {
        .cta__grid_item_bottom h3 {
          font-size: 16px;
          margin-top: 10px; } }
    .cta__grid_item_bottom p {
      margin-top: 20px;
      font-size: 16px;
      line-height: 1.2;
      letter-spacing: 1px; }
      @media (max-width: 991px) {
        .cta__grid_item_bottom p {
          font-size: 13px; } }
    .cta__grid_item_bottom a.btn, .cta__grid_item_bottom a.btn-fx {
      margin-top: 20px; }
      .home .cta__grid_item_bottom a.btn, .page-template-page-ressources .cta__grid_item_bottom a.btn, .home .cta__grid_item_bottom a.btn-fx, .page-template-page-ressources .cta__grid_item_bottom a.btn-fx {
        display: none; }

.cta__icon_item {
  display: block;
  width: calc(25% - 80px);
  margin: 40px; }
  @media (max-width: 991px) {
    .cta__icon_item {
      width: calc(50% - 40px);
      margin: 20px; } }
  @media (max-width: 767px) {
    .cta__icon_item {
      width: 100%;
      margin: 10px 0; } }
  .cta__icon_item_top {
    position: relative;
    overflow: hidden;
    border-radius: 7px;
    display: flex;
    justify-content: center; }
    .cta__icon_item_top .icon-circle {
      border-radius: 50%;
      width: 120px;
      height: 120px;
      border: 2px solid #0080CC;
      display: flex;
      align-items: center;
      justify-content: center; }
  .cta__icon_item_bottom {
    margin-top: 30px;
    text-align: center; }
    @media (max-width: 767px) {
      .cta__icon_item_bottom {
        margin-top: 20px; } }
    .cta__icon_item_bottom h3 {
      margin-top: 20px;
      font-size: 24px;
      line-height: 1.4; }
      @media (max-width: 1199px) {
        .cta__icon_item_bottom h3 {
          font-size: 18px; } }
      @media (max-width: 991px) {
        .cta__icon_item_bottom h3 {
          font-size: 16px; } }
    .cta__icon_item_bottom p {
      margin-top: 10px;
      font-size: 16px; }

.cta__slider {
  position: relative; }
  .cta__slider .video-image {
    position: relative;
    padding-bottom: 49.25%;
    height: 0;
    background-color: #A6B4C2;
    border-radius: 7px; }
    .cta__slider .video-image.vimeo {
      background-color: transparent;
      padding-bottom: 59.25%; }
    .cta__slider .video-image iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 7px;
      overflow: hidden; }
      .cta__slider .video-image iframe.js-loaded ~ .thumbnail {
        opacity: 1; }
      .cta__slider .video-image iframe.js-hide-thumb ~ .thumbnail {
        transform: translateY(-110%); }
    .cta__slider .video-image .thumbnail {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;
      cursor: pointer;
      transform: translateY(0);
      transition: transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
      border-radius: 7px; }
      .cta__slider .video-image .thumbnail img {
        pointer-events: none;
        border-radius: 7px;
        width: 65px; }
      .cta__slider .video-image .thumbnail .play-btn {
        transition: opacity 0.35s ease;
        opacity: 1; }
        .cta__slider .video-image .thumbnail .play-btn:hover {
          opacity: 0.6; }
  .cta__slider .image {
    position: relative;
    width: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 7px;
    overflow: hidden; }
    .cta__slider .image:before {
      content: '';
      display: block;
      padding-top: 49.2%; }
    .cta__slider .image .wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .cta__slider .caption {
    position: relative;
    top: -4px;
    padding: 20px;
    background-color: #E6E9F1;
    z-index: 100;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    font-size: 16px; }
    @media (max-width: 767px) {
      .cta__slider .caption {
        padding: 10px 20px;
        font-size: 13px; } }

.cta_read_item {
  display: flex; }
  .cta_read_item + .cta_read_item {
    margin-top: 40px; }
  @media (max-width: 767px) {
    .cta_read_item {
      flex-direction: column; } }
  .cta_read_item_left {
    width: 175px; }
    @media (max-width: 767px) {
      .cta_read_item_left {
        width: 100%; } }
    .cta_read_item_left .image {
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      backface-visibility: hidden;
      transform: translate3d(0, 0, 0);
      background-color: #979797; }
      .cta_read_item_left .image:before {
        content: '';
        display: block;
        padding-top: 64%; }
      .cta_read_item_left .image .wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
      @media (max-width: 767px) {
        .cta_read_item_left .image {
          width: 100%; } }
      .cta_read_item_left .image a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
        .cta_read_item_left .image a img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: scale(1);
          transition: transform 0.35s ease;
          backface-visibility: hidden; }
      .cta_read_item_left .image:hover img {
        transform: scale(1.1); }
  .cta_read_item_right {
    width: calc(100% - 175px);
    padding-left: 40px; }
    @media (max-width: 767px) {
      .cta_read_item_right {
        width: 100%;
        padding-left: 0;
        margin-top: 20px; } }
    .cta_read_item_right .tag {
      text-transform: uppercase;
      font-size: 18px;
      font-family: "Roboto-Regular";
      color: #0080CC;
      letter-spacing: 1px; }
      @media (max-width: 767px) {
        .cta_read_item_right .tag {
          font-size: 16px; } }
      .cta_read_item_right .tag + .tag {
        margin-left: 10px; }
    .cta_read_item_right a {
      display: block;
      margin-top: 10px;
      font-size: 24px;
      line-height: 1.4;
      transition: opacity 0.35s ease; }
      @media (max-width: 1199px) {
        .cta_read_item_right a {
          font-size: 18px; } }
      @media (max-width: 991px) {
        .cta_read_item_right a {
          font-size: 16px; } }
      .cta_read_item_right a:hover {
        opacity: 0.6; }

.cta__sidebar {
  position: relative;
  top: 0;
  width: 492px;
  max-width: 492px; }
  @media only screen and (max-width: 1440px) {
    .cta__sidebar {
      width: 362px;
      max-width: 362px; } }
  @media (max-width: 991px) {
    .cta__sidebar {
      position: relative;
      width: 100%;
      max-width: 100%; } }
  .no-touchevents .cta__sidebar.js-fixed {
    position: fixed;
    top: 160px;
    width: 25.8%; }
    @media (max-width: 1199px) {
      .no-touchevents .cta__sidebar.js-fixed {
        width: 350px; } }
    @media (max-width: 991px) {
      .no-touchevents .cta__sidebar.js-fixed {
        position: relative;
        top: 0;
        width: 100%; } }
    .no-touchevents .cta__sidebar.js-fixed.js-stop {
      position: absolute;
      bottom: -90px;
      top: inherit;
      width: 100%; }
      @media (max-width: 991px) {
        .no-touchevents .cta__sidebar.js-fixed.js-stop {
          position: relative;
          bottom: inherit;
          width: 100%; } }
  .cta__sidebar.form_list .cta__sidebar_top h3 {
    color: #34396B;
    font-size: 16px;
    line-height: 1.4; }
    @media (max-width: 1199px) {
      .cta__sidebar.form_list .cta__sidebar_top h3 {
        font-size: 16px; } }
  .cta__sidebar.form_list .sidebar__list li a {
    color: #34396B;
    font-size: 22px;
    font-family: "Roboto-Regular"; }
    @media (max-width: 1199px) {
      .cta__sidebar.form_list .sidebar__list li a {
        font-size: 16px; } }
    @media (max-width: 767px) {
      .cta__sidebar.form_list .sidebar__list li a {
        font-size: 13px; } }
    .cta__sidebar.form_list .sidebar__list li a img {
      padding-right: 20px;
      max-width: 60px; }
  .cta__sidebar_top {
    width: inherit;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #15142F;
    padding: 60px 40px; }
    @media (max-width: 991px) {
      .cta__sidebar_top {
        padding: 20px; } }
    .cta__sidebar_top h2 {
      font-family: "WorkSans-Light";
      font-size: 36px;
      line-height: 1.4;
      color: #0080CC;
      margin-bottom: 20px; }
      @media (max-width: 1199px) {
        .cta__sidebar_top h2 {
          font-size: 28px; } }
      @media (max-width: 991px) {
        .cta__sidebar_top h2 {
          font-size: 24px; } }
    .cta__sidebar_top h3 {
      font-family: "Roboto-Regular";
      font-size: 24px;
      color: white;
      line-height: 1.2; }
      @media (max-width: 1199px) {
        .cta__sidebar_top h3 {
          font-size: 18px; } }
      @media (max-width: 991px) {
        .cta__sidebar_top h3 {
          font-size: 16px; } }
    .cta__sidebar_top .sidebar__list {
      position: relative;
      margin-top: 40px; }
      @media (max-width: 991px) {
        .cta__sidebar_top .sidebar__list {
          margin-top: 20px; } }
      .cta__sidebar_top .sidebar__list li {
        display: flex;
        align-items: center; }
        .cta__sidebar_top .sidebar__list li + li {
          margin-top: 30px; }
          @media (max-width: 991px) {
            .cta__sidebar_top .sidebar__list li + li {
              margin-top: 20px; } }
        .cta__sidebar_top .sidebar__list li a {
          display: flex;
          align-items: center;
          color: white;
          transition: color 0.35s ease;
          font-family: "Roboto-Medium"; }
          @media (max-width: 767px) {
            .cta__sidebar_top .sidebar__list li a {
              font-size: 13px; } }
          .cta__sidebar_top .sidebar__list li a:hover {
            color: #0080CC; }
          .cta__sidebar_top .sidebar__list li a img {
            padding-right: 10px; }
    .cta__sidebar_top .sidebar__item {
      position: relative; }
      .cta__sidebar_top .sidebar__item + .sidebar__item {
        margin-top: 30px; }
      .cta__sidebar_top .sidebar__item a {
        color: #34396B;
        font-size: 32px;
        font-family: "WorkSans-Light";
        transition: opacity 0.35s ease;
        word-break: break-word; }
        @media (max-width: 1199px) {
          .cta__sidebar_top .sidebar__item a {
            font-size: 24px; } }
        @media (max-width: 767px) {
          .cta__sidebar_top .sidebar__item a {
            font-size: 16px; } }
        .cta__sidebar_top .sidebar__item a:hover {
          opacity: 0.5; }
      .cta__sidebar_top .sidebar__item h3 {
        color: #34396B;
        font-size: 18px;
        font-family: "Roboto-Regular"; }
        .cta__sidebar_top .sidebar__item h3.prefix {
          margin-bottom: 10px; }
      .cta__sidebar_top .sidebar__item span {
        display: block;
        font-size: 13px;
        color: #34396B; }
  .cta__sidebar .cta__bottom {
    position: relative;
    width: 100%;
    height: 332px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 60px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden; }
    @media (max-width: 1199px) {
      .cta__sidebar .cta__bottom {
        padding: 40px; } }
    @media (max-width: 991px) {
      .cta__sidebar .cta__bottom {
        height: 182px;
        padding: 40px; } }
    .cta__sidebar .cta__bottom:hover .overlay-blue {
      opacity: 0.6; }
    .cta__sidebar .cta__bottom span {
      position: relative;
      z-index: 100;
      font-family: "Roboto-Light";
      font-size: 42px;
      color: white; }
      @media (max-width: 1199px) {
        .cta__sidebar .cta__bottom span {
          font-size: 26px; } }
    .cta__sidebar .cta__bottom img {
      position: absolute;
      z-index: 100;
      bottom: 40px;
      right: 40px; }
      @media (max-width: 991px) {
        .cta__sidebar .cta__bottom img {
          bottom: 20px; } }

.acc__item {
  border-top: 1px solid #A6B4C2;
  position: relative; }
  .acc__item:last-child {
    border-bottom: 1px solid #A6B4C2; }
  .acc__item_title {
    padding: 20px 45px 20px 0;
    position: relative;
    cursor: pointer;
    overflow: hidden; }
    .acc__item_title.js-toggle-acc ~ .acc__item_content {
      max-height: 200vh; }
    .acc__item_title.js-toggle-acc .plus-minus-toggle:after {
      transform: rotate(0); }
    .acc__item_title.js-toggle-acc .plus-minus-toggle:before {
      transform: rotate(0); }
    .acc__item_title h2 {
      font-family: "WorkSans-Light";
      font-size: 42px;
      line-height: 1.4;
      pointer-events: none; }
      @media (max-width: 1199px) {
        .acc__item_title h2 {
          font-size: 32px; } }
      @media (max-width: 991px) {
        .acc__item_title h2 {
          font-size: 24px; } }
      @media (max-width: 575px) {
        .acc__item_title h2 {
          font-size: 18px; } }
    .acc__item_title .plus-minus-toggle {
      position: absolute;
      right: 0;
      top: 50%; }
  .acc__item_content {
    position: relative;
    max-height: 0;
    overflow: hidden;
    overflow-y: auto;
    transition: max-height 0.35s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .acc__item_content_wrapper {
      padding-bottom: 60px; }

.cta__product_item {
  position: relative;
  margin: 40px !important;
  border: 1px solid #9ACDEB;
  border-radius: 7px; }
  @media (max-width: 1199px) {
    .cta__product_item {
      margin: 20px !important; } }
  .cta__product_item .product__image {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px; }
    .cta__product_item .product__image:before {
      content: '';
      display: block;
      padding-top: 68.8888888889%; }
    .cta__product_item .product__image .wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .cta__product_item .product__image a.tag {
      position: absolute;
      right: 0;
      top: 0;
      padding: 8px 20px;
      display: block;
      font-family: "Roboto-Medium";
      font-style: italic;
      background-color: #0080CC;
      color: white;
      z-index: 10;
      border-bottom-left-radius: 7px;
      transition: background-color 0.35s ease; }
      .cta__product_item .product__image a.tag:hover {
        background-color: #114277; }
    .cta__product_item .product__image a.image {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%; }
      .cta__product_item .product__image a.image img {
        transform: scale(1);
        transition: transform 0.35s ease;
        backface-visibility: hidden;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        width: 100%;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover; }
    .cta__product_item .product__image:hover a.image img {
      transform: scale(1.1); }
    .cta__product_item .product__image:hover ~ .product__link a {
      background-color: #9ACDEB; }
  .cta__product_item .product__info {
    position: relative;
    padding: 20px;
    background-color: white; }
    .cta__product_item .product__info h3 {
      font-family: "Roboto-Medium";
      color: #0080CC;
      margin-bottom: 20px !important;
      font-size: 24px !important; }
      @media (max-width: 991px) {
        .cta__product_item .product__info h3 {
          font-size: 16px !important; } }
    .cta__product_item .product__info .details {
      font-family: "Roboto-Regular";
      font-size: 16px;
      line-height: 1.4;
      color: #114277;
      margin-bottom: 30px; }
      @media (max-width: 991px) {
        .cta__product_item .product__info .details {
          font-size: 13px; } }
    .cta__product_item .product__info .price {
      text-transform: uppercase;
      color: #114277 !important;
      font-size: 24px !important; }
      @media (max-width: 991px) {
        .cta__product_item .product__info .price {
          font-size: 16px !important; } }
  .cta__product_item .product__link {
    position: relative;
    border-top: 1px solid #9ACDEB; }
    .cta__product_item .product__link a {
      display: block;
      width: 100%;
      padding: 20px;
      text-transform: uppercase;
      background-color: white;
      color: #114277;
      transition: all 0.35s ease;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px; }
      .cta__product_item .product__link a:hover {
        background-color: #9ACDEB; }
      .cta__product_item .product__link a img.chevron {
        position: absolute;
        right: 20px;
        top: 50%;
        width: 10px !important;
        transform: translateY(-50%); }
