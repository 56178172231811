.cta__sidebar{
	position: relative;
	top: 0;
	// width: 100%;
	width: 492px;
	max-width: 492px;
	@media only screen and (max-width:1440px) {
		width: 362px;
		max-width: 362px;
	}
	@include media-breakpoint-down(md) {
		position: relative;
	    width: 100%;
	    max-width: 100%;
	}
	.no-touchevents &{
		&.js-fixed{
			position: fixed;
			top: 160px;
			width: 25.8%;
			@include media-breakpoint-down(lg) {
				width: 350px;
			}
			@include media-breakpoint-down(md) {
				position: relative;
			    top: 0;
			    width: 100%;
			}
			&.js-stop{
				position: absolute;
				bottom: -90px;
				top: inherit;
				width: 100%;
				@include media-breakpoint-down(md) {
					position: relative;
				    bottom: inherit;
				    width: 100%;
				}
			}
		}
	}
	&.form_list{
		.cta__sidebar_top h3{
			color: $blue_navy_2;
			font-size: 16px;
			line-height: 1.4;
			@include media-breakpoint-down(lg) {
			    font-size: 16px;
			}
		}
		.sidebar__list li a{
			color: $blue_navy_2;
			font-size: 22px;
			font-family: $rreg;
			@include media-breakpoint-down(lg) {
			    font-size: 16px;
			}
			@include media-breakpoint-down(sm) {
			    font-size: 13px;
			}
			img{
				padding-right: 20px;
				max-width: 60px;
			}
		}
	}
	&_top{
		width: inherit;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		background-color: $blue_navy_3;
		padding: 60px 40px;
		@include media-breakpoint-down(md) {
		    padding: 20px;
		}
		h2{
		    font-family: $wlight;
		    font-size: 36px;
		    line-height: 1.4;
		    color: $blue;
		    margin-bottom: 20px;
		    @include media-breakpoint-down(lg) {
                font-size: 28px;
            }
            @include media-breakpoint-down(md) {
                font-size: 24px;
            }
		}
		h3{
			font-family: $rreg;
			font-size: 24px;
			color: white;
			line-height: 1.2;
		    @include media-breakpoint-down(lg) {
                font-size: 18px;
            }
			@include media-breakpoint-down(md) {
			   	font-size: 16px;
			}
		}
		.sidebar__list{
			position: relative;
			margin-top: 40px;
			@include media-breakpoint-down(md) {
			   	margin-top: 20px;
			}
			li{
				display: flex;
				align-items: center;
				+ li{
					margin-top: 30px;
					@include media-breakpoint-down(md) {
					   	margin-top: 20px;
					}
				}
				a{
					display: flex;
					align-items: center;
					color: white;
					transition: color $time ease;
					font-family: $rmed;
					@include media-breakpoint-down(sm) {
					    font-size: 13px;
					}
					&:hover{
						color: $blue;
					}
					img{
						padding-right: 10px;
					}
				}
			}
		}
		.sidebar__item{
			position: relative;
			+ .sidebar__item{
				margin-top: 30px;
			}
			a{
				color: $blue_navy_2;
				font-size: 32px;
				font-family: $wlight;
				transition: opacity $time ease;
				word-break: break-word;
				@include media-breakpoint-down(lg) {
					font-size: 24px;
				}
				@include media-breakpoint-down(sm) {
				    font-size: 16px;
				}
				&:hover{
					opacity: 0.5;
				}
			}
			h3{
				color: $blue_navy_2;
				font-size: 18px;
				font-family: $rreg;
				&.prefix{
					margin-bottom: 10px;
				}
			}
			span{
				display: block;
				font-size: 13px;
				color: $blue_navy_2;
			}
		}
	}
	.cta__bottom{
		position: relative;
		width: 100%;
		height: 332px;
		display: block;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 40px 60px;
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;
		overflow: hidden;
		@include media-breakpoint-down(lg) {
			padding: 40px;
		}
		@include media-breakpoint-down(md) {
		   	height: 182px;
		   	padding: 40px;
		}
		&:hover{
			.overlay-blue{
				opacity: 0.6;
			}
		}
		span{
			position: relative;
			z-index: 100;
			font-family: $rlight;
			font-size: 42px;
			color: white;
			@include media-breakpoint-down(lg) {
			   	font-size: 26px;
			}
		}
		img{
			position: absolute;
			z-index: 100;
			bottom: 40px;
			right: 40px;
			@include media-breakpoint-down(md) {
			   	bottom: 20px;
			}
		}
	}
}