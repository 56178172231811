.hero__cta_bottom{
	position: relative;
	width: 100%;
	padding: 0 20px;
	bottom: -20px;
	z-index: 110;
	@include media-breakpoint-down(md) {
		padding: 0 76px;
	}
	@include media-breakpoint-down(sm) {
		padding: 0 36px;
	}
	@include media-breakpoint-down(xs) {
		padding: 0 20px;
	}
	.text{
		display: block;
		font-size: 36px;
		color: $blue2;
		font-family: $wreg;
		@include media-breakpoint-down(lg) {
		 	font-size: 24px;
		}
		@include media-breakpoint-down(md) {
		 	font-size: 20px;
		}
	}
	&_wrapper{
		display: flex;
		margin: 0 auto;
		max-width: $maxW;
		width: 100%;
		background-color: white;
		border-radius: $radius;
		box-shadow:
	    0 5px 10px rgba(0, 0, 0, 0.1),
	    0 20px 20px rgba(0, 0, 0, 0.05);
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		 	display: flex;
		 	flex-direction: column;
		 	max-width: 100%;
		}
		.hero__cta_bottom_left,
		.hero__cta_bottom_mid{
			+ .hero__cta_bottom_mid{
				border-left: 1px solid $blue;
				@include media-breakpoint-down(md) {
					border-left: none;
					border-top: 1px solid $blue;
				}
			}
			+ .hero__cta_bottom_right{
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				@include media-breakpoint-down(md) {
					border-top-left-radius: 0;
					border-top-right-radius: 0;
					border-bottom-left-radius: $radius;
				}
			}
			display: flex;
			padding: 0 60px;
			margin: 20px 0;
			flex: 1;
			@media #{$laptop} {
			 	padding: 0 20px;
			}
			@include media-breakpoint-down(md) {
			 	width: calc(100% - 40px);
			 	padding: 20px;
			 	margin: 0 auto;
			 	// text-align: center;
			 	p{
			 		display: none;
			 	}
			}
			.inner{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				@include media-breakpoint-down(xs) {
					justify-content: flex-start;
				}
				img{
					padding-right: 40px;
					@include media-breakpoint-down(lg) {
						padding-right: 20px;
					}
					@include media-breakpoint-down(md) {
						padding-right: 20px;
						height: 30px;
					}
				}
			}
		}
		.hero__cta_bottom_right{
			flex: 1;
			background-color: $red;
			border-top-right-radius: $radius;
			border-bottom-right-radius: $radius;
			border-top-left-radius: $radius;
			border-bottom-left-radius: $radius;
			display: flex;
			align-items: center;
			transition: background-color $time ease;
			@include media-breakpoint-down(md) {
			 	width: 100%;
			 	border-top-right-radius: 0;
			 	border-bottom-left-radius: $radius;
			 	border-bottom-right-radius: $radius;
			}
			&:hover{
				background-color: $blue_navy_2;
			}
			.inner{
				display: block;
				margin: 0 auto;
				max-width: 80%;
				padding: 60px 0;
				@include media-breakpoint-down(lg) {
				 	padding: 40px 0;
				}
				@include media-breakpoint-down(md) {
				 	padding: 20px 0;
				}
				.cta_soumission{
					display: flex;
					align-items: center;
					height: 100%;
					@include media-breakpoint-down(md) {
						justify-content: center;
						text-align: center;
					}
					img{
						width: 60px;
						@include media-breakpoint-down(lg) {
							width: 40px;
						}
						@include media-breakpoint-down(md) {
							display: none;
						}
					}
					.text{
						display: block;
						color: white;
						margin-bottom: 0;
						padding-left: 40px;
						@include media-breakpoint-down(md) {
							padding-left: 0;
						}
					}
				}
			}
		}
	}
}