/**
 *  -----------------------------------------------
 *  module/UTILITIES
 *
 *  1. Flex
 *  2. Padding / margin utilities
 *  3. Animations
 *  -----------------------------------------------
 */


/*
 * 1. Flex
 ****************************************************************/

.flex-c {
  align-items: center;
  display: flex;
  justify-content: center;
}


/*
 * 2. Padding / margin utilities
 ****************************************************************/

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

/*
 * 3. Animations
 ****************************************************************/

$animation-fade-timing: 0.4s;

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@keyframes pulse {
  0%    { opacity: 1; }
  50%  { opacity: 0.2; }
  100% { opacity: 1; }
}

@keyframes translateYIn {
  from { transform: translateY(100%); }
  to   { transform: translateY(0); }
}

.fade-in {
  animation: fadeIn $animation-fade-timing;
  animation-fill-mode: forwards;
}

.fade-out {
  animation: fadeOut $animation-fade-timing;
  animation-fill-mode: forwards;
}
