.section__search{
	position: relative;
	background-color: white;
	display: flex;
	height: auto;
	padding: 90px 0;
	z-index: 100;
	@include media-breakpoint-down(md) {
	   padding: 40px 0;
	}
	@include media-breakpoint-down(xs) {
	   padding: 0;
	}
	.section__wrapper{
		position: relative;
		margin: 20px auto;
		max-width: $maxW;
		padding: 0 20px;
		width: 100%;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		    max-width: 100%;
			padding: 0 76px;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px;
		}
		.results{
			position: relative;
			h2{
		        font-family: $wlight;
		        font-size: 42px;
		        line-height: 1.4;
		        margin-bottom: 40px;
		        @include media-breakpoint-down(lg) {
		            font-size: 32px;
		            margin-bottom: 20px;
		        }
		        @include media-breakpoint-down(md) {
		            font-size: 26px;
		        }
		    }
		    h3{
		    	font-family: $wlight;
		    	font-size: 24px;
		    	line-height: 1.4;
		    	margin-bottom: 20px;
		    	@include media-breakpoint-down(lg) {
		    	    font-size: 18px;
		    	}
		    }
		    &__top{
		    	position: relative;
			    .search__page_results{
					position: relative;
					padding: 0;
					display: flex;
					align-items: center;
					width: 100%;
					max-width: 632px;
					@include media-breakpoint-down(md) {
					   max-width: 100%;
					}
					.input-group{
						position: relative;
						width: 100%;
						input{
							border-radius: 40px;
							border: 1px solid $grey_light_3;
							width: 100%;
							padding: 25px 100px 25px 40px;
							font-size: 24px;
							color: $blue_navy_2;
							@include media-breakpoint-down(md) {
							   padding: 14px;
							   font-size: 13px;
							}
						}
						button{
							position: absolute;
							right: 0;
							top: 0;
							background-color: $red;
							border-radius: 50%;
							width: 80px;
							height: 80px;
							display: flex;
							align-items: center;
							justify-content: center;
							transition: background-color $time ease;
							@include media-breakpoint-down(md) {
							   width: 45px;
							   height: 45px;
							}
							svg{
								width: 28px;
								height: 28px;
								@include media-breakpoint-down(md) {
								   width: 18px;
								   height: 18px;
								}
							}
							&:hover{
								background-color: $blue_navy_2;
							}
						}
					}
			    }
		    }
		    &__bottom{
				.bloc__results{
					position: relative;
					margin-top: 40px;
					@include media-breakpoint-down(md) {
					   margin-top: 20px;
					}
					.results__item{
						margin-top: 20px;
						strong, b{
							font-family: $wbold;
						}
						p ~ .results__link{
							margin-top: 20px;
						}
						.results__link{
							display: block;
							color: $blue;
							text-decoration: underline;
							transition: all $time ease;
							&:hover{
								opacity: 0.7;
								text-decoration: none;
							}
						}
					}
				}
		    }
		}
	}
}