.cta__slider{
	position: relative;
	.video-image{
		position: relative;
		padding-bottom: 49.25%; 
		height: 0;
		background-color: $grey;
		border-radius: $radius;
		&.vimeo{
			background-color: transparent;
			padding-bottom: 59.25%; 
		}
		iframe{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: $radius;
			overflow: hidden;
			&.js-loaded{
				~ .thumbnail{
					opacity: 1;
				}
			}
			&.js-hide-thumb{
				~ .thumbnail{
					transform: translateY(-110%);
				}
			}
		}
		.thumbnail{
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 100;
			cursor: pointer;
			transform: translateY(0);
			transition: transform $time $easeInOutCubic;
			border-radius: $radius;
			img{
				pointer-events: none;
				border-radius: $radius;
				@include media-breakpoint-down(xl) {
				    width: 65px;
				}
			}
			.play-btn{
				transition: opacity $time ease;
				opacity: 1;
				&:hover{
					opacity: 0.6;
				}
			}
		}
	}
	.image{
		position: relative;
		width: 100%;
		@include keepRatio('1000/492');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		border-radius: $radius;
		overflow: hidden;
	}
	.caption{
		position: relative;
		top: -4px;
		padding: 20px;
		background-color: $grey_light_1;
		z-index: 100;
		border-bottom-right-radius: $radius;
		border-bottom-left-radius: $radius;
		font-size: 16px;
		@include media-breakpoint-down(sm) {
			padding: 10px 20px;
		    font-size: 13px;
		}
	}
}