.cta__square{
	display: flex;
	flex-direction: row;
	width: 100%;
	@media screen and (max-width: 375px) {
		flex-direction: column;
	}
	// class to switch side
	&.side-reverse{
		flex-direction: row-reverse;
		@include media-breakpoint-down(xs) {
		    flex-direction: column;
		    align-items: center;
		}
		.cta__square_right{
			margin-left: 0;
			margin-right: 9%;
			@include media-breakpoint-down(xs) {
			    margin-right: 0;
			}
		}
	}
	&_left{
		@include keepRatio('450/370');
		position: absolute;
		z-index: 10;
		width: 30%;
		@media screen and (max-width: 375px) {
			position: relative;
		    margin: 0 auto 20px;
		    width: 100%;
		    max-width: 100%;
		}
		.square{
			border-radius: $radius;
			position: absolute;
			top: 0;
			left: 0;
			overflow: hidden;
			width: 100%;
			height: 100%;
			backface-visibility: hidden;
			background-color: $grey_light_3;
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: 50%;
			transform: translate3d(0, 0, 0);
			max-width: 450px;
			max-height: 370px;
			transition: background-size $time ease;
			@include media-breakpoint-down(md) {
			   	margin-bottom: 20px;
			}
			&:hover{
				background-size: 110%;
				img{
					transform: scale(1.1);
				}
			}
		}
	}
	&_right{
		position: relative;
		width: 100%;
		padding-left: 38%;
		@media screen and (max-width: 375px) {
		    width: 100%;
		    padding-left: 0;
		}
		.wysiwyg{
			h2{
			    font-family: $wmed;
			    font-size: 42px;
			    margin-bottom: 40px;
			    line-height: 1.2;
			    @include media-breakpoint-down(lg) {
			        font-size: 32px;
			        margin-bottom: 20px;
			    }
			    @include media-breakpoint-down(md) {
			        font-size: 24px;
			    }
			}
			p{
				font-size: 24px;
				letter-spacing: 1px;
				@include media-breakpoint-down(lg) {
				    font-size: 16px;
				}
				@media screen and (max-width: 375px) {
				    font-size: 14px;
				}
			}
			ul{
				margin-top: 40px;
			    position: relative;
			    width: 100%;
			    display: flex;
			    flex-wrap: wrap;
			    @include media-breakpoint-down(md) {
			        flex-direction: column;
			        margin-top: 20px;
			    }
			    li{
			        background-image: url('../images/icons/icon-blue-list-arrow.svg');
			        background-repeat: no-repeat;
			        background-position: 0px 8px;
			        background-size: 15px;
			        list-style: none;
			        margin: 0;
			        padding-left: 40px;
			        width: 50%;
			        display: flex;
			        align-items: flex-start;
			        height: 45px;
			        @include media-breakpoint-down(lg) {
			            font-size: 16px;
			        }
			        @include media-breakpoint-down(md) {
			            width: 100%;
			            font-size: 16px;
			            background-position: 0 3px;
			            height: auto;
			        }
			        + li{
			        	margin-top: 0;
			        	@include media-breakpoint-down(md) {
			        	    margin-top: 10px;
			        	}
			        }
			        a{
			        	text-decoration: none;
			        	opacity: 1;
			        	transition: opacity $time ease;
			        	font-size: 24px;
			        	@include media-breakpoint-down(md) {
			        	    font-size: 16px;
			        	}
			        	&:hover{
			        		color: $blue;
			        		opacity: 0.7;
			        	}
			        }
			    }
			}
		}
	}
}