/**
 *  -----------------------------------------------
 *  LOADER
 *  -----------------------------------------------
 */

.loader {
  transition: opacity 0.4s;
  transition-delay: 0.2s;

  &.is-ajax {
    z-index: 21;

    @include absolute-centering;

    &:not(.is-active) {
      opacity: 0;
      pointer-events: none;
    }

    &.is-active {
      opacity: 1;
    }
  }

  &.is-form {
    .loading-effect {
      @include absolute-centering;
    }
  }
}
