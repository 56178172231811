.cta__icon_item{
	display: block;
	width: calc(25% - 80px);
	margin: 40px;
	@include media-breakpoint-down(md) {
	    width: calc(50% - 40px);
	    margin: 20px;
	}
	@include media-breakpoint-down(sm) {
	    width: 100%;
	    margin: 10px 0;
	}
	&_top{
		position: relative;
		overflow: hidden;
		border-radius: $radius;
		display: flex;
		justify-content: center;
		.icon-circle{
			border-radius: 50%;
			width: 120px;
			height: 120px;
			border: 2px solid $blue;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	&_bottom{
		margin-top: 30px;
		text-align: center;
		@include media-breakpoint-down(sm) {
		    margin-top: 20px;
		}
		h3{
			margin-top: 20px;
			font-size: 24px;
	        line-height: 1.4;
	        @include media-breakpoint-down(lg) {
	            font-size: 18px;
	        }
	        @include media-breakpoint-down(md) {
	            font-size: 16px;
	        }
		}
		p{
			margin-top: 10px;
			font-size: 16px;
		}
	}
}