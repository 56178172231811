.section__cta_three_item{
	position: relative;
	overflow: hidden;
	height: auto;
	padding: 90px 0;
	@include media-breakpoint-down(md) {
	    height: auto;
	    padding: 40px 0;
	}
	.section__wrapper{
		position: relative;
		max-width: $maxW;
		margin: 0 auto;
		width: 100%;
		padding: 0 20px;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		  	max-width: 100%;
			padding: 0 76px 40px;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px 40px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px 40px;
		}
		h2{
		    font-family: $wlight;
		    font-size: 42px;
		    @include media-breakpoint-down(lg) {
                font-size: 32px;
            }
		    @include media-breakpoint-down(sm) {
		        font-size: 24px;
		        margin-bottom: 20px;
		    }
		}
		.cta__item_row{
			position: relative;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0 -40px;
			@include media-breakpoint-down(md) {
			    margin: 0 -20px;
			}
			@include media-breakpoint-down(sm) {
			    display: none;
			}
		}
		.cta__item_row_mobile{
			display: none;
			@include media-breakpoint-down(sm) {
			    margin: 0 -20px;
			    display: block;
			    .cta__grid_item{
			    	padding: 0 10px;
			    }
			}
		}
	}
}