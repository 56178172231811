.section__cta_related{
	position: relative;
	overflow: hidden;
	height: auto;
	padding: 90px 0;
	@include media-breakpoint-down(md) {
	    padding: 40px 0;
	}
	.section__wrapper{
		position: relative;
		max-width: $maxW;
		margin: 0 auto;
		width: 100%;
		padding: 0 20px;
		display: flex;
		flex-direction: column;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		    max-width: 100%;
			padding: 0 76px;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px;
		}
		h2{
		    font-family: $wlight;
		    font-size: 36px;
		    line-height: 1.4;
		    color: $blue_navy_2;
		    margin-bottom: 20px;
		    @include media-breakpoint-down(lg) {
		        font-size: 32px;
		    }
		    @include media-breakpoint-down(md) {
		        font-size: 24px;
		    }
		}
		ul{
		    position: relative;
		    display: flex;
		    flex-wrap: wrap;
		    width: calc(100% - 450px);
		    @include media-breakpoint-down(lg) {
		       	width: 100%;
		    }
		    li{
		    	display: flex;
		    	align-items: center;
		    	word-break: break-word;
		        background-image: url('../images/icons/icon-blue-list-arrow.svg');
		        background-repeat: no-repeat;
		        background-position: 0px center;
		        list-style: none;
		        padding-left: 40px;
		        padding-right: 20px;
		        width: 50%;
	        	margin-top: 20px;
	        	@include media-breakpoint-down(sm) {
	        	   	width: 100%;
	        	   	font-size: 13px;
	        	}
		    	&.bullet{
		    		background-image: url('../images/icons/icon-blue-bullet.svg');
		    		padding-left: 20px;
		    		a{
		    			color: $blue_navy_2;
		    		}
		    	}
		    	&.arrow{
		    		background-image: url('../images/icons/icon-blue-list-arrow.svg');
		    	}
		        a{
		        	opacity: 1;
		        	transition: opacity $time ease;
		        	color: $blue;
		        	&:hover{
		        		opacity: 0.6;
		        	}
		        }
		    }
		}
	}
}