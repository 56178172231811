.shopify-cart,
.woocommerce-cart .main .woocommerce{
	position: relative;
	background-color: white;
	height: auto;
	max-width: calc(80.625% - 40px);
	margin: 0 auto;
	width: 100%;
	padding: 90px 0;
	@include media-breakpoint-down(lg) {
		max-width: 80.625%;
	    padding: 90px 20px;
	}
	@include media-breakpoint-down(md) {
	    padding: 40px 20px;
	    max-width: calc(80.625% + 20px);
	}
	@include media-breakpoint-down(sm) {
	    top: 0;
	    max-width: 100%;
	    border-radius: 0;
	    box-shadow: none;
	    padding: 20px;
	    z-index: 10;
	}
	form{
		position: relative;
		td,tr,table{
			border: none;
		}
		table{
			border: none;
			border-radius: 0;
			thead{
				color: $blue2;
				font-size: 24px;
				font-family: $rreg;
				font-weight: 100;
				@include media-breakpoint-down(lg) {
				    font-size: 18px;
				}
				th{
					&.product-remove{
						width: auto;
					}
					&.product-thumbnail{
						width: 30%;
					}
					&.product-name{
						width: 30%;
					}
					&.product-price{
						width: 10%;
					}
					&.product-quantity{
						width: 10%;
					}
					&.product-subtotal{
						width: 15%;
					}
				}
			}
			tbody{
				border-top: 1px solid $lightblue;
				td, th{
					padding: 20px;
					@include media-breakpoint-down(lg) {
					    padding: 10px;
					}
				}
				.product-thumbnail{
					a{
						@include keepRatio('400/300');
						border-radius: $radius;
						display: block;
						overflow: hidden;
						position: relative;
						img{
							position: absolute;
							top: 0;
							left: 0;
							max-width: 100%;
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}
				}
				.product-name{
					a{
						font-size: 24px;
						color: $blue3;
						display: block;
						@include media-breakpoint-down(lg) {
						    font-size: 18px;
						}
						@include media-breakpoint-down(sm) {
						   	font-size: 13px;
						   	padding-left: 70px;
						   	line-height: 1.4;
						}
					}
					dl{
						@include media-breakpoint-down(sm) {
						   	font-size: 13px;
						   	font-family: $rreg;
						}
					}
				}
				.product-remove{
					position: relative;
					a{
						display: flex;
						align-items: center;
						justify-content: center;
						width: 60px;
						height: 60px;
						@include media-breakpoint-down(md) {
						    width: 20px;
						    height: 20px;
						}
						&:hover{
							background-color: white;
							border: 2px solid $red;
						}
					}
				}
				td.actions{
					position: relative;
					display: flex;
					width: 100%;
					justify-content: flex-end;
					border-top: 1px solid $lightblue;
					padding: 20px 0;
					@include media-breakpoint-down(md) {
					    flex-direction: column;
					}
					#coupon_code{
						display: inline-block;
						padding: 14px 60px 13px 20px;
						border: 1px solid $blue2;
						font-family: $rmed;
						font-size: 16px;
						border-radius: 30px;
						position: relative;
						z-index: 1;
						width: 250px;
						position: relative;
						right: -60px;
						@include media-breakpoint-down(md) {
						    width: 170px;
						}
						@include media-breakpoint-down(md) {
						    width: 100%;
						   	right: 0;
						   	margin-bottom: 20px;
						   	padding: 14px 20px;
						}
						&::placeholder{
							color: $blue2;
							@include media-breakpoint-down(md) {
								text-align: center;
							}
						}
					}
				}
				tr.bloc-promo-code{
					position: relative;
					height: 77px;
					width: 100%;
					@include media-breakpoint-down(md) {
					    height: 187px;
					}
					@include media-breakpoint-down(sm) {
					    height: auto;
					}
					> td{
						position: absolute;
						right: 0;
						width: 100%;
						@include media-breakpoint-down(sm) {
						    position: relative;
						}
						.promo-group{
							position: relative;
							display: flex;
							@include media-breakpoint-down(md) {
							    flex-direction: column;
							}
						}
					}
					.coupon{
						margin-right: 100px;
						@include media-breakpoint-down(md) {
						    margin-right: 0;
						    padding: 0;
						}
						button{
							padding: 15px 20px;
							white-space: nowrap;
							background-color: white;
							text-align: center;
							@include media-breakpoint-down(md) {
							    margin-bottom: 20px;
							    width: 100%;
							}
						}
						~ button{
							padding: 15px 20px;
							white-space: nowrap;
							text-align: center;
							@include media-breakpoint-down(md) {
							    margin-bottom: 20px;
							    width: 100%;
							}
						}
					}
				}
			}
		}
	}
	.cart-collaterals{
		display: flex;
		width: auto;
		margin: 0 -40px;
		@include media-breakpoint-down(lg) {
		   	margin: 0 -20px;
		}
		@include media-breakpoint-down(md) {
		    flex-direction: column;
		    margin: 0;
		}
		.cart_livraison{
			width: calc(50% - 80px);
			position: relative;
			margin: 40px;
			@include media-breakpoint-down(lg) {
			   margin: 20px;
			   width: calc(50% - 40px);
			}
			@include media-breakpoint-down(md) {
			    display: none;
			}
		}
		.cart_totals{
			position: relative;
			width: calc(50% - 80px);
			margin: 40px;
			@include media-breakpoint-down(lg) {
			   margin: 20px;
			   width: calc(50% - 40px);
			}
			@include media-breakpoint-down(md) {
			    width: 100%;
			    margin: 20px 0;
			}
			h2{
				font-size: 36px;
				color: $blue2;
				font-weight: 100;
				font-family: $wmed;
				margin-bottom: 20px;
				@include media-breakpoint-down(lg) {
				    font-size: 24px;
				}
				@include media-breakpoint-down(sm) {
				  	font-size: 18px;
				}
			}
			table{
				border: 1px solid $lightblue;
				border-radius: $radius;
				position: relative;
				overflow: hidden;
				tbody{
					border-collapse: collapse;
					> tr{
						+ tr{
							border-top: 1px solid $lightblue;
							td, th{
								border-top: 1px solid $lightblue;
							}
						}
					}
					tr{
						td, th{
							border: none;
							padding: 20px 40px;
							font-size: 24px;
							font-weight: 100;
							@include media-breakpoint-down(lg) {
							    font-size: 18px;
							}
							@include media-breakpoint-down(sm) {
							  	font-size: 14px;
							  	padding: 20px;
							}
						}
						th{
							text-align: left;
						}
						td{
							text-align: right;
						}
						&.order-total{
							th, td{
								font-family: $rbold;
							}
						}
					}
				}
			}
			.shipping-calculator-form{
				button{
					margin-top: 20px;
				}
			}
		}
	}
}