.woocommerce-message{
	max-width: $maxW;
	padding: 20px;
	margin: 0 auto;
	width: 100%;
	text-align: center;
	border: none;
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
        max-width: $maxWR-85;
    }
    @include media-breakpoint-down(lg) { // 992 to 1199
        max-width: $maxWR-90;
    }
	@include media-breakpoint-down(xs) {
	    display: flex;
	    flex-direction: column-reverse;
	}
	&:before{
		position: relative;
		top: 0;
		left: 0;
		margin-right: 10px;
		margin-top: 10px;
	}
   	a{
    	@include media-breakpoint-down(xs) {
		    margin: 10px 0 !important;
		}
    }
}

.woocommerce-breadcrumb{
    background-color: $blue_navy_3;
    width: 100%;
    height: auto;
    min-height: 100px;
    z-index: 20;
    display: flex;
    align-items: center;
    margin: 0;
    @include media-breakpoint-down(sm) {
        min-height: inherit;
    }
    .post-type-archive-product &{
    	display: none;
    }
    .wrapper{
    	max-width: $maxW;
    	padding: 0 20px;
    	margin: 0 auto;
    	width: 100%;
    	color: white;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(sm) {
		    padding: 20px;
		    max-width: 100%;
		    font-size: 13px;
		}
    	> a{
    		color: white;
    		transition: color $time ease;
    		&:hover{
    			color: $blue;
    		}
    	}
    }
}
.hero__archive_product{
	position: relative;
	top: 0;
	left: 0;
	background-color: $blue_navy_3;
	width: 100%;
	height: auto;
	min-height: 70px;
	z-index: 200;
	display: flex;
	align-items: center;
	&_wrapper{
		display: flex;
		max-width: $maxW;
		margin: 0 auto;
		width: 100%;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(xs) {
		 	max-width: 100%;
		 	padding: 0 20px;
		}
		.inner{
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			margin: 0;
			@include media-breakpoint-down(md) {
			 	display: none;
			}
			> a{
				width: calc(12.5% - 40px);
				display: block;
				margin: 30px 20px;
				color: white;
				font-family: $rreg;
				font-size: 14px;
				opacity: 0.4;
				transition: opacity $time ease;
				@include media-breakpoint-down(lg) {
					width: calc(20% - 40px);
				 	padding: 20px;
				}
				&:hover{
					opacity: 1;
				}
			}
		}
		.select-box{
			display: none;
			@include media-breakpoint-down(md) {
			 	display: block;
			}
			position: relative;
			width: 100%;
			.icon-select{
				position: absolute;
				right: 0;
				top: 9px;
			}
			select#product_categories{
				background-color: transparent !important;
				color: white;
			}
			select::-ms-expand {
				display: none;
			}
		}
	}
}
.woocommerce{
	form .form-row{
		padding: 0;
		margin: 0;
	}
	em{
		font-size: 13px;
		font-style: italic;
	}
	form h3, form legend{
		font-family: $wmed;
		font-size: 24px;
		margin-bottom: 20px;
		color: $blue2;
		@include media-breakpoint-down(lg) {
		    font-size: 16px;
		}
	}
	span.show-password-input{
		top: 0;
	}
	.woocommerce-info{
		border-top-color: $blue;
	}
	.woocommerce-error{
		border-top-color: $red;
	}
	.woocommerce-Message{
		font-size: 16px;
		font-family: $rreg;
		@include media-breakpoint-down(sm) {
			font-size: 13px;
		}
	}
	.woocommerce-terms-and-conditions-wrapper{
		margin-bottom: 20px;
	}
	input[type="text"], 
	input[type="tel"], 
	input[type="password"], 
	input[type="email"],
	input[type="number"],
	.select2-selection--single, 
	select{
		appearance: none;
		outline: none;
		border-radius: 0;
		box-shadow: none;
		border: 0;
		border-bottom: 1px solid $blue2;
		padding: 10px 0;
		width: 100%;
		font-size: 13px;
		color: $blue2;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-right: 15px;
		background-color: white !important;
		span{
			overflow: visible;
			&.select2-selection__rendered{
				color: $blue2;
				line-height: 1;
				position: relative;
				top: -5px;
				padding-left: 0;
			}
		}
		.select2-selection__arrow b{
			border-color: $blue2 transparent transparent transparent;
		}
	}
	textarea{
		padding: 10px;
		border-radius: 0;
		border: 1px solid $blue2;
		font-size: 13px;
		outline: none;
	}
	input[type="checkbox"]{
		width: 25px;
		height: 25px;
		border: 1px solid $blue2;
		border-radius: $radius;
	}
	.form-row label,
	label[for="username"],
	label[for="password"]{
		font-size: 16px;
		font-family: $rreg;
		color: $blue2;
		.required{
			color: $red;
		}
	}
	label.woocommerce-form__label-for-checkbox{
		display: flex !important;
		align-items: center;
	}
	button.button, a.button{
		display: inline-block;
		padding: 12px 20px;
		font-family: $rmed;
		font-size: 16px;
		border-radius: 30px;
		color: $red;
		transition: all 0.5s;
		position: relative;
		z-index: 1;
		text-align: center;
		margin: 0;
		font-weight: 100 !important;
		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			border-radius: 30px;
			background-color: white;
			border: 2px solid $red;
			transition: all 0.3s;
		}
		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			opacity: 0;
			border-radius: 30px;
			transition: all 0.3s;
			background-color: $red;
			transform: scale(1.1,1.1);
		}
		&:hover{
			color: white;
			&:before{
				opacity: 0;
				transform: scale(0.5,0.5);
			}
			&:after{
				opacity: 1;
				transform: scale(1,1);
			}
		}
		&[type="submit"]{
			color: white;
			&:before{
				background-color: $red;
				border: none;
			}
			&:after{
				background-color: transparent;
				border: 2px solid $red;
			}
			&:hover{
				color: $red;
			}
		}
	}
	a.button{
		color: $blue;
		background-color: transparent;
		font-weight: 100;
		&:before{
			border: 2px solid $blue;
		}
		&:after{
			background-color: $blue;
		}
		&:hover{
			color: white !important;
		}
	}
}

// Overwrites

.woocommerce #respond input#submit:hover, 
.woocommerce a.button:hover, 
.woocommerce button.button:hover, 
.woocommerce input.button:hover{
	background-color: white;
}

.woocommerce #respond input#submit.disabled, 
.woocommerce #respond input#submit:disabled, 
.woocommerce #respond input#submit:disabled[disabled], 
.woocommerce a.button.disabled, 
.woocommerce a.button:disabled, 
.woocommerce a.button:disabled[disabled], 
.woocommerce button.button.disabled, 
.woocommerce button.button:disabled, 
.woocommerce button.button:disabled[disabled], 
.woocommerce input.button.disabled, 
.woocommerce input.button:disabled, 
.woocommerce input.button:disabled[disabled]{
	color: white;
}

.woocommerce #respond input#submit.alt, 
.woocommerce a.button.alt, 
.woocommerce button.button.alt, 
.woocommerce input.button.alt{
	color: $blue;
}

.woocommerce table.shop_table th, 
.woocommerce table.shop_table tbody th{
	font-weight: 100;
	font-family: $rbold;
	padding: 20px;
}

.woocommerce table.shop_table td{
	border-top: 1px solid $lightblue;
	font-weight: 100;
	padding: 20px;
	&.product-name{
		color: $blue3;
	}
}

.woocommerce table.shop_table{
	border: 1px solid $lightblue;
}

.woocommerce table.shop_table tbody th, 
.woocommerce table.shop_table tfoot td, 
.woocommerce table.shop_table tfoot th{
	border-top: 1px solid $lightblue;
	padding: 20px;
	font-weight: 100;
}

#add_payment_method #payment ul.payment_methods, 
.woocommerce-cart #payment ul.payment_methods, 
.woocommerce-checkout #payment ul.payment_methods{
	padding: 20px;
	border-bottom: 1px solid $lightblue;
}

#add_payment_method #payment div.form-row, 
.woocommerce-cart #payment div.form-row, 
.woocommerce-checkout #payment div.form-row{
	padding: 20px;
}

.woocommerce .quantity .qty{
	width: 100%;
	text-align: left;
}