.cta__image_box{
	padding: 60px 100px;
	border-radius: $radius;
	background-color: $grey_light_3;
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	@include media-breakpoint-down(md) {
	    padding: 60px 20px;
	}
	.infobox{
		background-color: white;
		padding: 60px;
		max-width: 587px;
		width: 100%;
		@include media-breakpoint-down(md) {
		    padding: 30px;
		}
		h2 {
			font-size: 36px;
			@include media-breakpoint-down(md) {
			    padding: 24px;
			}
		}
		p{
			font-size: 24px;
			@include media-breakpoint-down(md) {
			    font-size: 16px;
			}
			@include media-breakpoint-down(xs) {
			    font-size: 13px;
			}
		}
	}
}