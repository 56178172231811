.btn{
	display: inline-block;
	padding: 10px 20px;
	border-radius: 30px;
	font-family: $rmed;
	font-size: 16px;
	transition: background-color $time ease, border $time ease;
	&.btn_green{
		border: 2px solid $green;
		background-color: $green;
		color: white;
		&:hover{
			background-color: white;
			color: $green;
		}
	}
	&.btn_white{
		border: 2px solid white;
		background-color: white;
		color: $blue_navy_1;
		&:hover{
			background-color: transparent;
			color: white;
		}
	}
	&.btn_border_green{
		border: 2px solid $green;
		background-color: transparent;
		color: $green;
		&:hover{
			background-color: $green;
			color: white;
		}
	}
	&.btn_border_white{
		border: 2px solid white;
		background-color: transparent;
		color: white;
		&:hover{
			background-color: white;
			color: $blue_navy_3;
		}
	}
	&.btn_green_form{
		border: 2px solid $green;
		background-color: $green;
		color: white;
		&:hover{
			border: 2px solid $blue_navy_3;
			background-color: $blue_navy_3;
			color: white;
		}
	}
}

.btn-fx{
	display: inline-block;
	padding: 12px 20px;
	font-family: $rmed;
	font-size: 16px;
	border-radius: 30px;
	color: $red;
	transition: all 0.5s;
	position: relative;
	text-align: center;
	z-index: 1;
	&.prefix-hide{
		@include media-breakpoint-down(xs) {
			text-transform: capitalize;
			span{
				display: none;
			}
		}
	}
	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		border-radius: 30px;
		border: 2px solid $red;
		transition: all 0.3s;
	}
	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		opacity: 0;
		border-radius: 30px;
		transition: all 0.3s;
		background-color: $red;
		transform: scale(1.1,1.1);
	}
	&:hover{
		color: white;
		&:before{
			opacity: 0;
			transform: scale(0.5,0.5);
		}
		&:after{
			opacity: 1;
			transform: scale(1,1);
		}
	}
	&.white{
		color: white;
		&:before{
			border: 2px solid white;
		}
		&:after{
			background-color: white;
		}
		&:hover{
			color: $blue_navy_3;
		}
	}
	&.green{
		color: $green;
		&:before{
			border: 2px solid $green;
		}
		&:after{
			background-color: $green;
		}
		&:hover{
			color: white;
		}
	}
	&.blue{
		color: $blue;
		&:before{
			border: 2px solid $blue;
		}
		&:after{
			background-color: $blue;
		}
		&:hover{
			color: white;
		}
	}
	&.bg-green{
		color: white;
		&:before{
			background-color: $green;
			border: none;
		}
		&:after{
			background-color: transparent;
			border: 2px solid $green;
		}
		&:hover{
			color: $green;
		}
	}
	&.bg-red{
		color: white;
		&:before{
			background-color: $red;
			border: none;
		}
		&:after{
			background-color: transparent;
			border: 2px solid $red;
		}
		&:hover{
			color: $red;
		}
	}
	&.bg-white{
		color: $red;
		&:before{
			background-color: white;
			border: none;
		}
		&:after{
			background-color: transparent;
			border: 2px solid white;
		}
		&:hover{
			color: white;
		}
	}
}

.btn-page{
	border-radius: 7px;
	border: 1px solid $grey_light_3;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all $time ease;
	svg{
		g{
			stroke: $grey_light_3;
		}
	}
	&:hover{
		border: 1px solid $green;
		svg{
			g{
				stroke: $green;
			}
		}
	}
	&.page-number{
		border: 1px solid $blue_navy_3;
		color: $blue_navy_3;
	}
}

.plus-minus-toggle {
	cursor: pointer;
	height: 100%;
	position: relative;
	width: 25px;
	pointer-events: none;
	@include media-breakpoint-down(xs) {
	   	width: 20px;
	}
	&:before,
	&:after{
		background: $red;
		content: '';
		height: 2px;
		left: 0;
		position: absolute;
		top: 0;
		width: 25px;
		transition: transform 500ms ease;
		@include media-breakpoint-down(xs) {
		   	width: 20px;
		}
	}
	&:after {
		transform-origin: center;
		transform: rotate(90deg);
	}
	&:before {
		transform: rotate(180deg);
	}
}


// Burger Menu Mobile --------------------------------------------

.icon-burger-circle{
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border: 1px solid $red;
	width: 42px;
	height: 42px;
	#icon-burger{
		width: 20px;
		height: 16px;
		position: relative;
		transform: rotate(0deg);
		transition: .5s ease-in-out;
		cursor: pointer;
		pointer-events: none;
		span{
			display: block;
			position: absolute;
			height: 2px;
			width: 100%;
			background: $red;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: .25s ease-in-out;
			&:nth-child(1){
				top: 0px;
			}
			&:nth-child(2){
				top: 7px;
			}
			&:nth-child(3){
				top: 7px;
			}
			&:nth-child(4){
				top: 14px;
			}
		}
	}
	&.js-open-burger{
		#icon-burger span{
			&:nth-child(1){
				top: 7px;
				width: 0%;
				left: 50%;
			}
			&:nth-child(2){
				transform: rotate(45deg);
			}
			&:nth-child(3){
				transform: rotate(-45deg);
			}
			&:nth-child(4){
				top: 7px;
				width: 0%;
				left: 50%;
			}
		}
	}
}