/**
 *  -----------------------------------------------
 *  FOOTER
 *
 *  1. Wrappers
 *  -----------------------------------------------
 */

footer{
	position: relative;
	background-color: $blue_navy_3;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	@include media-breakpoint-down(md) {
		height: auto;
	}
	.footer__wrapper{
		position: relative;
		width: 100%;
		max-width: $maxW;
		margin: 80px auto 0;
		padding: 0 20px;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
			max-width: 100%;
			margin: 20px auto 0;
			padding: 20px;
		}
		.footer__top{
			display: flex;
			align-items: flex-end;
			height: auto;
			margin-bottom: 40px;
			@include media-breakpoint-down(md) {
				height: auto;
				flex-direction: column;
			}
			.footer__top_wrapper{
				display: table;
				width: 100%;
				@include media-breakpoint-down(lg) {
					display: flex;
				 	flex-direction: column;
				}
				.column{
					display: table-cell;
					vertical-align: top;
					@include media-breakpoint-down(md) {
						display: flex;
					 	text-align: center;
					 	justify-content: center;
					 	margin-bottom: 40px;
					}
					p{
						font-family: $rreg;
						color: white;
						font-size: 24px;
						line-height: 1.4;
						margin-bottom: 20px;
						@include media-breakpoint-down(lg) {
							font-size: 18px;
						}
					}
					&.column__left{
						width: 40%;
						padding-right: 40px;
						@include media-breakpoint-down(md) {
							width: 100%;
							padding-right: 0;
						}
						.column__left_top{
							form{
								position: relative;
								&.js-hidden{
									display: none;
								}
								fieldset{
									position: relative;
									width: 440px;
									@include media-breakpoint-down(md) {
										width: 100%;
									}
									input[type="email"]{
										border: none;
										height: 50px;
										border-radius: 40px;
										padding: 10px 155px 10px 25px;
										width: 100%;
										font-size: 16px;
									}
									input[type="submit"], button{
										position: absolute;
										top: 0;
										right: -1px;
										height: 50px;
										border-radius: 40px;
										width: 140px;
										text-align: center;
										font-size: 16px;
										cursor: pointer;
									}
								}
							}
							.message-loader{
								display: block;
								font-size: 16px;
								color: white;
								width: 100%;
								margin-top: 20px;
								&.js-hidden{
									display: none;
								}
								&.js-success{
									color: $green;
								}
								&.js-error{
									color: red;
								}
							}
						}
						.column__left_bottom{
							display: flex;
							position: relative;
							margin-top: 40px;
							@include media-breakpoint-down(lg) {
							    flex-direction: column;
							    margin-top: 20px;
							}
							@include media-breakpoint-down(md) {
								margin-top: 40px;
							    align-items: center;
							}
							p{
								margin-bottom: 0;
								margin-right: 20px;
								@include media-breakpoint-down(lg) {
								    margin-bottom: 10px;
								}
								@include media-breakpoint-down(md) {
								    margin-right: 0;
								    margin-bottom: 10px;
								}
							}
							.footer__socials{
								display: flex;
								> a{
									display: flex;
									align-items: center;
									&:hover{
										svg{
											.icon-color{
												fill: white;
											}
										}
									}
									+ a{
										margin-left: 20px;
									}
									svg{
										max-width: 100%;
										width: 30px;
										height: 22px;
										.icon-color{
											transition: fill $time ease;
											fill: #5A637A;
										}
									}
								}
							}
						}
					}		
					&.column__mid{
						width: 30%;
						@include media-breakpoint-down(lg) {
							margin-top: 20px;
						}
						@include media-breakpoint-down(md) {
							margin-top: 0;
							width: 100%;
						}
						.column__wrapper{
							display: flex;
							flex-direction: column;
							.footer__number{
								display: flex;
								flex-direction: column;
								a{
									display: block;
									color: white;
									font-size: 36px;
									font-family: $wlight;
									transition: opacity $time ease;
									@media screen and (max-width: 1350px) {
									  	font-size: 28px;
									}
									@include media-breakpoint-down(lg) {
										font-size: 24px;
									}
									&:hover{
										opacity: 0.5;
									}
								}
								span{
									display: block;
									font-size: 14px;
									color: $grey_light_3;
								}
								+ .footer__number{
									margin-top: 40px;
									@include media-breakpoint-down(lg) {
										margin-top: 20px;
									}
								}
							}
						}
					}
					&.column__right{
						width: 30%;
						@include media-breakpoint-down(lg) {
							margin-top: 20px;
						}
						@include media-breakpoint-down(md) {
							margin-top: 0;
							width: 100%;
						}
						.column__wrapper{
							display: table;
							margin: 0 auto;
							@include media-breakpoint-down(lg) {
								margin: 0;
							}
							@include media-breakpoint-down(md) {
								display: flex;
							 	flex-direction: column;
							 	margin: 0;
							}
							> div{
								position: relative;
								> ul.nav{
									li{
										a{
											color: white;
											font-size: 16px;
											transition: opacity $time ease;
											&:hover{
												opacity: 0.5;
											}
										}
										+ li{
											margin-top: 20px;
										}
									}
								}
							}
						}
					}		
				}
			}
		}
	}
	.footer__bottom{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		border-top: 1px solid $grey_light_3;
		height: 60px;
		@include media-breakpoint-down(md) {
			border-top: none;
			justify-content: center;
			height: auto;
		}
		.column__wrapper{
			display: flex;
			width: 100%;
			justify-content: space-between;
			@include media-breakpoint-down(md) {
				flex-direction: column-reverse;
				align-items: center;
			}
			.copyright{
				color: $grey_light_3;
				font-size: 14px;
				@include media-breakpoint-down(lg) {
					font-size: 13px;
				}
				@include media-breakpoint-down(md) {
					padding-top: 10px;
					width: 100%;
					text-align: center;
				}
				@include media-breakpoint-down(xs) {
					font-size: 12px;
				}
			}
			> div{
				position: relative;
				@include media-breakpoint-down(md) {
					border-bottom: 1px solid $grey_light_3;
					padding-bottom: 10px;
					width: 100%;
					text-align: center;
				}
				> ul.nav{
					display: flex;
					@include media-breakpoint-down(md) {
						justify-content: center;
					}
					li{
						text-align: center;
						a{
							color: white;
							font-size: 14px;
							color: $grey_light_3;
							transition: opacity $time ease;
							@include media-breakpoint-down(lg) {
								font-size: 12px;
							}
							@include media-breakpoint-down(sm) {
								font-size: 10px;
							}
							&:hover{
								opacity: 0.5;
							}
						}
						+ li{
							margin-left: 40px;
							@include media-breakpoint-down(lg) {
								margin-left: 20px;
							}
							@include media-breakpoint-down(sm) {
								margin-left: 5px;
							}
						}
					}
				}
			}
		}
	}
}