/**
 *  -----------------------------------------------
 *  layouts/EDITOR
 *
 *  1. Wrapper
 *  2. List
 *  3. Text
 *  4. Titles
 *  5. Other components
 *  -----------------------------------------------
 */

/*
 * 1. Wrapper
 ****************************************************************/

.editor-wrap {
  $ul-padding: 0.8em;
  $ol-padding: 1em;
  $bullet-size: 6px;
  $margin-bottom: 25px;

  strong { font-weight: 700; }
  em { font-style: italic; }

  a {
    // color: $red;

    // &:hover { text-decoration: underline; }
  }

  /*
   * 2. List
   ****************************************************************/

  ul {
    padding-left: $ul-padding;

    > li {
      list-style: none;
      text-indent: -$ul-padding;

      // Bullet
      &::before {
        background: currentColor;
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: $bullet-size;
        margin-right: calc(#{$ul-padding} - #{$bullet-size});
        vertical-align: middle;
        width: $bullet-size;
      }
    }
  }

  ol {
    margin-left: $ol-padding;

    li {
      list-style-type: decimal;
    }
  }

  ul, ol {
    li:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  /*
   * 3. Text
   ****************************************************************/

  p, ul, ol {
    &:not(:last-child) {
      margin-bottom: $margin-bottom;
    }
  }

  p:empty { display: none; }


  /*
   * 4. Titles
   ****************************************************************/

  h2 {

  }

  h3 {

  }

  h4 {

  }

  /*
   * 5. Other components
   ****************************************************************/

  table {
    width: 100%;

    tr:first-child {
      td { padding-bottom: 10px; }
    }
  }

  img {
    max-width: 100%;
  }
}
