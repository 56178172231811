.woocommerce-account.my-account .main .woocommerce,
.woocommerce-account.mon-compte .main .woocommerce{
	top: 0;
	box-shadow: none;
	border-radius: 0;
	padding: 90px 0;
	@include media-breakpoint-down(lg) {
	    padding: 90px 0;
	}
	@include media-breakpoint-down(md) {
	    padding: 40px 0;
	    max-width: calc(80.625% + 20px);
	}
}
.woocommerce-account .main .woocommerce{
	display: flex;
	flex-direction: column;
	position: relative;
	top: -40px;
	z-index: 900;
	background-color: white;
	height: auto;
	max-width: calc(80.625% - 40px);
	margin: 0 auto;
	width: 100%;
	padding: 90px;
	border-radius: $radius;
	box-shadow:
	    0 5px 10px rgba(0, 0, 0, 0.1),
	    0 20px 20px rgba(0, 0, 0, 0.05);
	@include media-breakpoint-down(lg) {
	    padding: 90px 40px;
	}
	@include media-breakpoint-down(md) {
	    padding: 40px 20px;
	    max-width: calc(80.625% + 20px);
	}
	@include media-breakpoint-down(sm) {
	    top: 0;
	    max-width: 100%;
	    border-radius: 0;
	    box-shadow: none;
	    padding: 20px;
	    z-index: 10;
	}
	.woocommerce-ResetPassword{
		input{
			margin-bottom: 40px;
		}
	}
	.u-columns{
		position: relative;
		display: flex;
		width: 100%;
		@include media-breakpoint-down(sm) {
		   flex-direction: column;
		}
		> div{
			width: 50%;
			float: none;
			max-width: inherit;
			flex: inherit;
			padding: 0 80px;
			@include media-breakpoint-down(lg) {
			    padding: 0 40px;
			}
			@include media-breakpoint-down(sm) {
			   width: 100%;
			   padding: 0;
			}
			+ div{
				border-left: 1px solid $lightblue;
				@include media-breakpoint-down(sm) {
				   border-top: 1px solid $lightblue;
				   padding-top: 20px;
				   border-left: none;
				}
			}
			h2{
				font-family: $wmed;
				font-size: 36px;
				margin-bottom: 20px;
				color: $blue2;
				@include media-breakpoint-down(lg) {
				    font-size: 24px;
				}
			}
			form{
				border: none;
				padding: 0;
				.woocommerce-form-row{
					margin-bottom: 40px;
					@include media-breakpoint-down(sm) {
					    margin-bottom: 20px;
					}
				}
			}
			.connexion-bottom{
				position: relative;
				display: inline-flex;
				flex-direction: column;
				button{
					margin-bottom: 20px;
				}
			}
			.woocommerce-form__label-for-checkbox{
				margin-bottom: 20px;
				input#rememberme{
					margin-right: 10px;
					&:checked{
						~ span{
							&:before{
								opacity: 1;
							}
						}
					}
				}
				span{
					position: relative;
					&:before{
						position: absolute;
						opacity: 0;
						top: 4px;
						left: -35px;
						content: url('../images/icons/icon-blue-checkbox.svg');
						display: block;
						width: 25px;
						height: 25px;
					}
				}
			}
			.woocommerce-LostPassword{
				a{
					text-decoration: underline;
					color: $blue;
					transition: color $time ease;
					&:hover{
						color: $blue2;
					}
				}
			}
			.woocommerce-privacy-policy-text{
				margin-bottom: 40px;
				@include media-breakpoint-down(sm) {
				    margin-bottom: 20px;
				}
			}
			.woocommerce-password-strength{
				padding: 10px;
				font-weight: 100;
			}
		}
	}
}