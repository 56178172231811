.cta__image_full{
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 430px;
	@include media-breakpoint-down(sm) {
	 	min-height: inherit;
	}
	&_wrapper{
		position: relative;
		z-index: 100;
		text-align: center;
		color: white;
		max-width: 720px;
		// margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 60px 20px;
		@include media-breakpoint-down(sm) {
		 	max-width: 100%;
		}
		h2{
			font-family: $wlight;
		}
		> a.btn-fx.bg-white{
			color: $blue;
			&:hover{
				color: white;
			}
		}
		form{
			position: relative;
			width: 100%;
			max-width: 312px;
			display: block;
			margin: 60px auto 0;
			@include media-breakpoint-down(xs) {
			 	max-width: 100%;
			 	margin: 0 auto;
			}
			fieldset{
				position: relative;
				width: 100%;
				@include media-breakpoint-down(md) {
					width: 100%;
				}
				.input-newsletter, .input-download{
					border: none;
					height: 50px;
					border-radius: 40px;
					padding: 10px 70px 10px 25px;
					width: 100%;
					font-size: 14px;
				}
				button{
					position: absolute;
					display: flex;
					align-items: center;
					justify-content: center;
					top: 0;
					right: -2px;
					border-radius: 50%;
					width: 50px;
					height: 50px;
					text-align: center;
					padding: 10px;
					img{
						width: 15px;
						height: 15px;
						margin-left: 5px;
					}
					&:hover{
						svg{
							#Icon{
								fill: $red;
							}
						}
					}
					svg{
						width: 15px;
						height: 15px;
						margin-left: 5px;
						#Icon{
							transition: fill $time ease;
							fill: white;
						}
					}
				}
			}
		}
	}
}