.section__cta_tab_circle{
	background-color: white;
	height: auto;
	padding: 90px 0;
	@include media-breakpoint-down(md) {
	    height: auto;
	    padding: 40px 0;
	}
	.section__wrapper{
		position: relative;
		max-width: $maxW;
		padding: 0 20px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		    max-width: 100%;
			padding: 0 76px;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px;
		}
		h2{
		    font-family: $wlight;
		    font-size: 42px;
		    margin-bottom: 40px;
		    line-height: 1.4;
		    @include media-breakpoint-down(lg) {
                font-size: 32px;
            }
		    @include media-breakpoint-down(md) {
		        font-size: 24px;
		        margin-bottom: 20px;
		    }
		}
		h3{
		    font-family: $rreg;
		    font-size: 24px;
		    margin-bottom: 20px;
		    line-height: 1.4;
		    @include media-breakpoint-down(lg) {
		        font-size: 18px;
		    }
		}
		.tab__header{
			position: relative;
			height: auto;
			overflow: hidden;
			overflow-x: auto;
			&::-webkit-scrollbar {
			    display: none;
			}
			@include media-breakpoint-down(sm) {
			    margin: 0 -36px 0 0;
			}
			@include media-breakpoint-down(xs) {
			    margin: 0 -20px 0 0;
			}
			&_wrapper{
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 100%;
				@include media-breakpoint-down(sm) {
				   	min-width: 768px;
				}
				@include media-breakpoint-down(xs) {
				   	min-width: 575px;
				}
				> a{
					display: flex;
					align-items: center;
					justify-content: flex-start;
					font-size: 20px;
					padding: 30px 20px 30px 0;
					width: 100%;
					cursor: pointer;
					transition: color $time ease;
					overflow: hidden;
					&:hover{
						color: $red;
					}
					@include media-breakpoint-down(lg) {
					   	font-size: 16px;
					}
					@include media-breakpoint-down(md) {
					   	padding: 20px 10px 20px 0;
					   	font-size: 13px;
					   	max-width: inherit !important;
					}
					@include media-breakpoint-down(sm) {
					   	padding: 10px 10px 10px 0;
					}
					img{
						padding-right: 20px;
						pointer-events: none;
						@include media-breakpoint-down(md) {
						   	width: 30px;
						   	padding-right: 10px;
						}
					}
				}
			}
			.tab__header_line{
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 5px;
				background-color: $grey_light_1;
				@include media-breakpoint-down(sm) {
				   	height: 3px;
				   	min-width: 768px;
				}
				@include media-breakpoint-down(xs) {
				   	min-width: 575px;
				}
				.progress{
					position: absolute;
					display: block;
					overflow: hidden;
					top: 0;
					left: 0;
					margin-left: 0;
					height: 100%;
					background-color: $red;
					z-index: 25;
					transition: margin-left $time ease;
					transform: translateZ(0);
				}
			}
		}
		.tab__container{
			margin-top: 100px;
			position: relative;
			@include media-breakpoint-down(md) {
			    margin-top: 20px;
			}
			.tab_content{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				opacity: 0;
				transform: translateY(-10px);
				transition: opacity $time ease, transform $time ease;
				z-index: 50;
				display: flex;
				&.js-active-content{
					position: relative;
					opacity: 1;
					transform: translateY(0);
					transition: opacity $time ease, transform $time ease;
					transition-delay: 0.7s;
					z-index: 100;
				}
			}
		}
	}
}