/**
 *  -----------------------------------------------
 *  FONTS
 *  -----------------------------------------------
 */

@font-face {
	font-family: 'Roboto-Bold';
	src: 	url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),  
			url('../fonts/Roboto-Bold.woff') format('woff'), 
			url('../fonts/Roboto-Bold.ttf')  format('truetype'), 
			url('../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto-Medium';
	src: 	url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),  
			url('../fonts/Roboto-Medium.woff') format('woff'), 
			url('../fonts/Roboto-Medium.ttf')  format('truetype'), 
			url('../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto-Light';
	src: 	url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),  
			url('../fonts/Roboto-Light.woff') format('woff'), 
			url('../fonts/Roboto-Light.ttf')  format('truetype'), 
			url('../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto-Regular';
	src: 	url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),  
			url('../fonts/Roboto-Regular.woff') format('woff'), 
			url('../fonts/Roboto-Regular.ttf')  format('truetype'), 
			url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'WorkSans-Bold';
	src: 	url('../fonts/WorkSans-Bold.eot?#iefix') format('embedded-opentype'),  
			url('../fonts/WorkSans-Bold.woff') format('woff'), 
			url('../fonts/WorkSans-Bold.ttf')  format('truetype'), 
			url('../fonts/WorkSans-Bold.svg#WorkSans-Bold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'WorkSans-Light';
	src: 	url('../fonts/WorkSans-Light.eot?#iefix') format('embedded-opentype'),  
			url('../fonts/WorkSans-Light.woff') format('woff'), 
			url('../fonts/WorkSans-Light.ttf')  format('truetype'), 
			url('../fonts/WorkSans-Light.svg#WorkSans-Light') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'WorkSans-Medium';
	src: 	url('../fonts/WorkSans-Medium.eot?#iefix') format('embedded-opentype'),  
			url('../fonts/WorkSans-Medium.woff') format('woff'), 
			url('../fonts/WorkSans-Medium.ttf')  format('truetype'), 
			url('../fonts/WorkSans-Medium.svg#WorkSans-Medium') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'WorkSans-Regular';
	src: 	url('../fonts/WorkSans-Regular.eot?#iefix') format('embedded-opentype'),  
			url('../fonts/WorkSans-Regular.woff') format('woff'), 
			url('../fonts/WorkSans-Regular.ttf')  format('truetype'), 
			url('../fonts/WorkSans-Regular.svg#WorkSans-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}
