.cta__circle_text{
	position: relative;	
	width: 100%;
	h2{
        font-family: $wlight;
        font-size: 42px;
        margin-bottom: 40px;
        line-height: 1.4;
        @include media-breakpoint-down(lg) {
            font-size: 32px;
        }
        @include media-breakpoint-down(md) {
            font-size: 26px;
        }
    }
	&_wrapper{
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin: 0 -20px;
		@include media-breakpoint-down(md) {
		    flex-direction: column;
		    margin: 0;
		}
		.cta__circle_text_item{
			display: flex;
			width: calc(50% - 40px);
			margin: 20px;
			@include media-breakpoint-down(md) {
			    width: 100%;
			    margin: 0 0 20px;
			}
			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}
			.cta__circle_text_left{
				width: 183px;
				display: flex;
				justify-content: center;
				margin-right: 40px;
				@include media-breakpoint-down(md) {
					margin-right: 20px;
				}
				@include media-breakpoint-down(sm) {
					width: 100%;
					margin-right: 0;
				}
				.circle{
					position: relative;
					overflow: hidden;
					border-radius: 50%;
					width: 183px;
					height: 183px;
					background-color: $grey_light_3;
					backface-visibility: hidden;
					transform: translate3d(0, 0, 0);
					@include media-breakpoint-down(md) {
						width: 123px;
						height: 123px;
					}
					img{
						transition: transform $time ease;
						transform: scale(1);
						width: 100%;
						height: 100%;
						object-fit: cover;
						backface-visibility: hidden;
					}
					&:hover{
						img{
							transform: scale(1.1);
						}
					}
				}
			}
			.cta__circle_text_right{
				width: 100%;
				@include media-breakpoint-down(sm) {
					margin-top: 20px;
				}
			}
		}
	}
}