.section__cta_detaillant{
	position: relative;
	overflow: hidden;
	height: auto;
	padding: 90px 0;
	@include media-breakpoint-down(md) {
	    height: auto;
	    padding: 40px 0;
	}
	.section__wrapper{
		position: relative;
		max-width: $maxW;
		margin: 0 auto;
		width: 100%;
		padding: 0 20px;
		display: flex;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		    flex-direction: column;
		    max-width: 100%;
			padding: 0 76px;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px;
		}
		.detaillant_left{
			position: relative;
			width: 68%;
			padding-right: 100px;
			@include media-breakpoint-down(lg) {
			    padding-right: 40px;
			}
			@include media-breakpoint-down(md) {
			    padding-right: 0;
			    width: 100%;
			    margin-bottom: 20px;
			}
			&_top{
				position: relative;
				margin-bottom: 40px;
				@include media-breakpoint-down(md) {
					margin-bottom: 20px;
				}
				p{
					font-size: 24px;
					color: $blue;
					margin-bottom: 40px;
					@include media-breakpoint-down(md) {
						font-size: 16px;
						margin-bottom: 20px;
					}
				}
				.select-group{
					position: relative;
					h3{
						font-size: 16px;
						font-family: $rbold;
						color: $blue_navy_3;
						margin-bottom: 5px;
					}
					.filter__select{
						position: relative;
						width: 100%;
						max-width: 50%;
						@include media-breakpoint-down(xs) {
							max-width: 100%;
						}
						.icon-select{
							position: absolute;
							right: 0;
							top: 9px;
						}
						select, input{
							appearance: none;
							outline: 0;
							border-radius: 0;
							box-shadow: none;
							border: 0;
							border-bottom: 1px solid $grey_light_3;
							background-color: white;
							padding: 10px 0;
							width: 100%;
							font-size: 16px;
							color: $blue_navy_2;
							letter-spacing: 1px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: word-wrap;
							padding-right: 15px;
						}
						.input-group{
							position: relative;
							button{
								position: absolute;
								display: flex;
								align-items: center;
								justify-content: center;
								top: -2px;
								right: 0;
								border-radius: 50%;
								width: auto;
								height: auto;
								text-align: center;
								padding: 10px 0 10px 20px;
								background-color: white;
								svg{
									width: 20px;
									height: 20px;
									g{
										stroke: $red;
									}
								}
							}
						}
						select::-ms-expand {
							display: none;
						}
					}
				}
			}
			&_bottom{
				position: relative;
				.contenu_distributeur{
					position: relative;
					display: flex;
					flex-wrap: wrap;
					width: auto;
					margin: 0 -40px;
					@include media-breakpoint-down(xs) {
						margin: 0;
					}
					> div{
						margin: 40px;
						width: calc(50% - 80px);
						display: none;
						&.js-show{
							display: block;
						}
						@include media-breakpoint-down(sm) {
							margin: 20px 40px;
						}
						@include media-breakpoint-down(xs) {
							margin: 10px 0;
							width: 100%;
						}
						.dist-title{
							font-family: $rbold;
							color: $blue_navy_3;
							margin-bottom: 5px;
						}
						.dist-img{
							margin: 10px 0;
							max-width: 100%;
						}
						address{
							color: $blue_navy_3;
							margin-bottom: 20px;
							span{
								display: block;
							}
							@include media-breakpoint-down(xs) {
								max-width: 100%;
							}
						}
						.dist-tel{
							color: $blue_navy_3;
							a{
								color: $blue_navy_3;
								transition: color $time ease;
								&:hover{
									color: $blue;
								}
							}
						}
						.dist-site{
							text-decoration: underline;
							color: $blue_navy_3;
							transition: color $time ease;
							&:hover{
								color: $blue;
							}
						}
						.dist-detail{
							margin-top: 20px;
							p {
								line-height: 1.4;
							}
						}
					}
				}
			}
		}
		.detaillant_right{
			position: relative;
			width: 32%;
			height: 100%;
			@include media-breakpoint-down(md) {
				position: relative;
				right: 0;
			    width: 100%;
			    height: auto;
			}
			.cta__officiel{
				position: relative;
				width: 100%;
				display: block;
				padding: 60px 30px;
				background-color: $blue_navy_3;
				transition: background-color $time ease;
				&:hover{
					background-color: $blue2;
				}
				h3{
					font-size: 24px;
					color: white;
					margin-bottom: 40px;
					@include media-breakpoint-down(xs) {
						font-size: 18px;
					}
				}
				p{
					font-size: 16px;
					color: white;
					margin-bottom: 80px;
					line-height: 1.4;
					@include media-breakpoint-down(xs) {
						margin-bottom: 40px;
					}
				}
				img{
					position: absolute;
					z-index: 100;
					bottom: 40px;
					right: 40px;
					@include media-breakpoint-down(md) {
					   	bottom: 20px;
					}
				}
			}
		}
	}
}