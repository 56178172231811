.shopify-cart,
.woocommerce-checkout .main .woocommerce {
	position: relative;
	background-color: white;
	height: auto;
	max-width: calc(80.625% - 40px);
	margin: 0 auto;
	width: 100%;
	padding: 90px 0;
	@include media-breakpoint-down(lg) {
		max-width: 80.625%;
	    padding: 90px 20px;
	}
	@include media-breakpoint-down(md) {
	    padding: 40px 20px;
	    max-width: calc(80.625% + 20px);
	}
	@include media-breakpoint-down(sm) {
	    top: 0;
	    max-width: 100%;
	    border-radius: 0;
	    box-shadow: none;
	    padding: 20px;
	    z-index: 10;
	}
	form.checkout{
		position: relative;
		#customer_details{
			display: flex;
			width: auto;
			margin: 0 -40px;
			@include media-breakpoint-down(lg) {
			   	margin: 0 -20px;
			}
			@include media-breakpoint-down(md) {
			    flex-direction: column;
			    margin: 0;
			}
			> div{
				width: calc(50% - 80px);
				position: relative;
				margin: 40px;
				flex: inherit;
				max-width: 100%;
				float: none;
				@include media-breakpoint-down(lg) {
				   margin: 20px;
				   width: calc(50% - 40px);
				}
				@include media-breakpoint-down(md) {
				    width: 100%;
				    margin: 20px 0;
				    padding: 0;
				}
				.form-row{
					margin-bottom: 20px;
				}
				table{
					td{
						padding: 20px;
					}
					tr{
						td:first-child, th:first-child{
							border-top: 1px solid $lightblue;
						}
					}
				}
				#ship-to-different-address{
					label{
						position: relative;
						display: flex;
						align-items: flex-start;
						input#ship-to-different-address-checkbox{
							margin-right: 10px;
							&:checked{
								~ span{
									&:before{
										opacity: 1;
									}
								}
							}
						}
						span{
							position: relative;
							display: block;
							&:before{
								position: absolute;
								opacity: 0;
								top: 3px;
								left: -35px;
								content: url('../images/icons/icon-blue-checkbox.svg');
								display: block;
								width: 25px;
								height: 25px;
							}
						}
					}
				}
			}
		}
		.cart_livraison{
			width: 100%;
			position: relative;
			margin-bottom: 40px;
			@include media-breakpoint-down(lg) {
			   margin-bottom: 20px;
			}
			@include media-breakpoint-down(md) {
			    width: 100%;
			}
			.wrapper{
				position: relative;
				background-color: $blue_navy_3;
				border-radius: $radius;
				overflow: hidden;
				display: flex;
				width: 100%;
				padding: 80px 40px;
				@include media-breakpoint-down(lg) {
				    padding: 40px 20px;
				}
				@include media-breakpoint-down(sm) {
				   	flex-direction: column;
				}
				.left{
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 25%;
					@include media-breakpoint-down(sm) {
					  	width: 100%;
					  	padding-bottom: 20px;
					}
					img{
						max-width: 138px;
						width: 100%;
					}
				}
				.right{
					width: 75%;
					@include media-breakpoint-down(sm) {
					  	width: 100%;
					}
					.inner{
						padding: 0 20px;
						margin: 0 auto;
						display: table;
						@include media-breakpoint-down(sm) {
						  	padding: 0;
						}
						h2{
							font-size: 36px;
							color: white;
							font-weight: 100;
							font-family: $wmed;
							margin-bottom: 20px;
							@include media-breakpoint-down(lg) {
							    font-size: 24px;
							}
							@include media-breakpoint-down(sm) {
							  	font-size: 18px;
							}
						}
						p{
							color: white;
							font-size: 24px;
							line-height: 1.4;
							margin-bottom: 20px;
							@include media-breakpoint-down(lg) {
							    font-size: 18px;
							}
							@include media-breakpoint-down(sm) {
							  	font-size: 14px;
							}
						}
						.input-group{
							position: relative;
							display: flex;
							align-items: flex-start;
							input{
								margin-right: 10px;
								color: $blue;
								border: 1px solid white;
								&:checked{
									~ label{
										&:before{
											opacity: 1;
										}
									}
								}
							}
							label{
								position: relative;
								color: $blue3;
								font-size: 24px;
								width: 100%;
								@include media-breakpoint-down(lg) {
								    font-size: 18px;
								}
								@include media-breakpoint-down(sm) {
								  	font-size: 14px;
								}
								&:before{
									position: absolute;
									opacity: 0;
									top: 3px;
									left: -34px;
									content: url('../images/icons/icon-blue-checkbox.svg');
									display: block;
									width: 25px;
									height: 25px;
								}
							}
						}
					}
				}
			}
		}
	}
}