.cta__grid_item{
	display: block;
	width: calc(33.33% - 80px);
	margin: 40px;
	@include media-breakpoint-down(md) {
	    width: calc(50% - 40px);
	    margin: 20px;
	}
	@include media-breakpoint-down(sm) {
	    width: 100%;
	    margin: 20px 0;
	}
	&:hover{
		.cta__grid_item_top .image{
			transform: scale(1.1);
		}
		.cta__grid_item_bottom h3{
			color: $blue;
		}
	}
	&_top{
		position: relative;
		overflow: hidden;
		border-radius: $radius;
		backface-visibility: hidden;
		transform: translate3d(0, 0, 0);
		a{
			display: block;
			background-color: $grey_light_3;
		}
		.image{
			@include keepRatio('455/320');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			background-color: $grey_light_3;
			transform: scale(1);
			transition: transform $time ease;
			backface-visibility: hidden;
		}
	}
	&_bottom{
		margin-top: 30px;
		@include media-breakpoint-down(sm) {
		    margin-top: 20px;
		}
		.tag{
			text-transform: uppercase;
			font-size: 16px;
			font-family: $rreg;
			color: $blue;
			letter-spacing: 2px;
			display: block;
			.page-template-page-ressources &{
				color: $blue;
			}
			@include media-breakpoint-down(sm) {
			    font-size: 13px;
			}
			+ .tag{
				margin-top: 5px;
			}
		}
		h3{
			transition: color $time ease;
			margin-top: 20px;
			font-size: 24px;
	        line-height: 1.4;
	        @include media-breakpoint-down(lg) {
	            font-size: 18px;
	        }
	        @include media-breakpoint-down(md) {
	            font-size: 16px;
	            margin-top: 10px;
	        }
		}
		p{
			margin-top: 20px;
			font-size: 16px;
	        line-height: 1.2;
	        letter-spacing: 1px;
	        @include media-breakpoint-down(md) {
	            font-size: 13px;
	        }
		}
		a.btn, a.btn-fx{
			margin-top: 20px;
			.home &, .page-template-page-ressources &{
				display: none;
			}
		}
	}
}