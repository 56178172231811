.section__logo_gallery{
	background-color: $grey_light_1;
	height: auto;
	padding: 40px 0;
	// @include media-breakpoint-down(md) {
	//     height: auto;
	// }
	.section__wrapper{
		position: relative;
		max-width: $maxW;
		padding: 0 20px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		    max-width: 100%;
			padding: 0 76px;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px;
		}
		h3{
			font-size: 24px;
			margin-bottom: 20px;
			@include media-breakpoint-down(lg) {
			    font-size: 18px;
			}
			@include media-breakpoint-down(md) {
			    text-align: center;
			}
		}
		.gallery{
			display: flex;
			align-items: center;
			justify-content: space-between;
			// margin: 40px 0;
			// @include media-breakpoint-down(sm) {
			// 	overflow: hidden;
			// 	overflow-x: auto;
			// 	margin: 0;
			// }
			overflow: hidden;
			overflow-x: auto;
			margin: 0;
			&-logo{
				display: flex;
				align-items: center;
				margin: 20px;
				img{
					filter: grayscale(100%);
					filter: url('../images/gray.svg#grayscale');
					filter: gray;
			  		width: 125px;
			  		height: auto;
			  		max-width: fit-content;
				  	// @include media-breakpoint-down(sm) {
				  	// 	max-width: fit-content;
				  	// }
				}
			}
		}
		.gallery::-webkit-scrollbar {
		    height: 5px;
		    padding: 2px;
		    cursor: pointer;
		    @include media-breakpoint-down(xs) {
		    	height: 3px;
		    }
		}
		.gallery::-webkit-scrollbar-track{
		    border-radius: 0;
		}
		.gallery::-webkit-scrollbar-thumb {
		    background-color: $lightblue;
		    border-radius: 0;
		}
	}
}