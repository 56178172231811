/**
 *  -----------------------------------------------
 *  pages/404
 *
 *  1. Wrapper / header
 *  2. Second section
 *  -----------------------------------------------
 */


/*
 * 1. Wrapper / header
 ****************************************************************/

.error404 {
	position: relative;
	.wrap{
		height: calc(100vh - 166px);
		background-color: $blue2;
		@include media-breakpoint-down(sm) {
		   	height: calc(100vh - 93px);
		}
		.content{
			height: 100%;
			.main{
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				height: 100%;
				color: white;
				padding: 0 20px;
				.page-header{
					font-size: 58px;
					font-family: $wlight;
					padding-bottom: 20px;
					@include media-breakpoint-down(sm) {
					   	font-size: 32px;
					}
				}
				.alert{
					font-size: 24px;
					font-family: $rreg;
					padding-bottom: 20px;
					text-align: center;
					@include media-breakpoint-down(sm) {
					   	font-size: 18px;
					}
				}
			}
		}
	}
}

/*
* 2. Second section
****************************************************************/

