.section__accordion{
	position: relative;
	background-color: white;
	display: flex;
	height: auto;
	padding: 90px 0;
	z-index: 100;
	@include media-breakpoint-down(md) {
	   padding: 40px 0;
	}
	@include media-breakpoint-down(xs) {
	   padding: 0;
	}
	.section__wrapper{
		position: relative;
		margin: 20px auto;
		max-width: $maxW;
		padding: 0 20px;
		width: 100%;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		    max-width: 100%;
			padding: 0 76px;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px;
		}
	}
}