.section__quote_slider{
	width: 100%;
	height: auto;
	position: relative;
	z-index: 300;
	background-color: $grey_light_2;
	padding: 90px 0;
	@include media-breakpoint-down(md) {
	    padding: 40px 0;
	}
	@include media-breakpoint-down(sm) {
	    padding: 20px 0;
	}
	.section__wrapper{
		position: relative;
		width: 100%;
	}
	.quote__slider{
		position: relative;
		&_item{
			position: relative;
			opacity: 0.4;
			transition: opacity $time ease;
			text-align: center;
			margin-bottom: 40px;
			@include media-breakpoint-down(md) {
			    margin-bottom: 20px;
			}
			&.slick-active{
				opacity: 1;
			}
			blockquote{
			    position: relative;
			    margin-bottom: 30px;
			    display: flex;
			    font-family: $wlight;
			    line-height: 1.2;
			    color: $blue;
			    text-align: center;
			    quotes: "\00AB""\00BB""\2018""\2019";
			    font-size: 42px;
			    margin-bottom: 40px;
			    outline: none;
			    &:before{
			        position: absolute;
			        top: 80px;
			        left: 70px;
			        content: '';
			        height: auto;
			        content: open-quote;
			        font-family: $wlight;
			        font-size: 24px;
			        @include media-breakpoint-down(md) {
			            font-size: 24px;
			            top: 40px;
			            left: 30px;
			        }
			        @include media-breakpoint-down(xs) {
			            left: 20px;
			            top: 20px;
			        }
			    }
			    &:after{
			        position: absolute;
			        right: 70px;
			        bottom: 80px;
			        content: '';
			        height: auto;
			        content: close-quote;
			        font-family: $wlight;
			        font-size: 24px;
			        @include media-breakpoint-down(md) {
			            font-size: 24px;
			            right: 30px;
			            bottom: 40px;
			        }
			        @include media-breakpoint-down(xs) {
			            right: 20px;
			            bottom: 20px;
			        }
			    }
			    p{
			        font-family: $rreg;
			        font-style: italic;
			        color: $blue;
			        line-height: 1.4;
			        font-size: 24px;
			        padding: 40px 80px;
			        @include media-breakpoint-down(md) {
			            padding: 20px 40px;
			            line-height: 1.2;
			        }
			        @include media-breakpoint-down(xs) {
			            font-size: 13px;
			        }
			    }
			    cite{
			        &:before{
			            margin-right: 5px;
			            content: '-';
			        }
			        display:block;
			        text-align:right;
			        margin-top:20px;
			        right: 0;
			        bottom: -25px;
			        justify-content: flex-end;
			        font-family: $rreg;
			    }
			}
			.name{
				font-family: $rbold;
				font-size: 16px;
				color: $blue2;
				margin-bottom: 5px;
			}
			.location{
				font-family: $rreg;
				font-size: 16px;
				color: $blue2;
			}
		}
	}
}