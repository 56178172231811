.cta__circle{
	display: flex;
	flex-direction: row;
	width: 100%;
	@include media-breakpoint-down(xs) {
	    flex-direction: column;
	}
	// class to switch side
	&.side-reverse{
		flex-direction: row-reverse;
		@include media-breakpoint-down(xs) {
		    flex-direction: column;
		    align-items: center;
		}
		.cta__circle_right{
			margin-left: 0;
			margin-right: 9%;
			@include media-breakpoint-down(xs) {
			    margin-right: 0;
			}
		}
	}
	&_left{
		@include keepRatio('400/400');
		position: absolute;
		z-index: 10;
		width: 26%;
		@media screen and (max-width: 330px) {
			position: relative;
		    margin: 0 auto 20px;
		    width: 100%;
		    max-width: 75%;
		}
		.circle{
			position: absolute;
			top: 0;
			left: 0;
			overflow: hidden;
			border-radius: 50%;
			width: 100%;
			height: 100%;
			backface-visibility: hidden;
			background-color: $grey_light_3;
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: 50%;
			transform: translate3d(0, 0, 0);
			max-width: 400px;
			max-height: 400px;
			transition: background-size $time ease;
			@include media-breakpoint-down(md) {
			   	margin-bottom: 20px;
			}
			&:hover{
				background-size: 110%;
				img{
					transform: scale(1.1);
				}
			}
		}
	}
	&_right{
		position: relative;
		width: 100%;
		padding-left: 36%;
		@media screen and (max-width: 330px) {
		    width: 100%;
		    padding-left: 0;
		}
		.wysiwyg{
			h2{
			    font-family: $wlight;
			    font-size: 42px;
			    margin-bottom: 20px;
			    line-height: 1.2;
			    @include media-breakpoint-down(lg) {
			        font-size: 32px;
			    }
			    @include media-breakpoint-down(md) {
			        font-size: 24px;
			    }
			}
			p{
				font-size: 16px;
				letter-spacing: 1px;
				@include media-breakpoint-down(md) {
				    font-size: 13px;
				}
			}
			ul{
				margin-top: 20px;
			    position: relative;
			    li{
			        background-image: url('../images/icons/icon-list-arrow.svg');
			        background-repeat: no-repeat;
			        background-position: 0px center;
			        list-style: none;
			        margin: 0;
			        padding-left: 40px;
			        a{
			        	text-decoration: none;
			        	opacity: 1;
			        	transition: opacity $time ease;
			        	&:hover{
			        		color: $blue;
			        		opacity: 0.7;
			        	}
			        }
			    }
			}
		}
	}
}