/**
 *  -----------------------------------------------
 *  HEADER
 *
 *  1. Wrappers
 *  -----------------------------------------------
 */

header.header__desktop{
	display: block;
	@include media-breakpoint-down(md) {
	 	display: none;
	}
	position: absolute;
	top: 0;
	left: 0;
	background-color: white;
	width: 100%;
	z-index: 1000;
	transform: translateZ(0);
	&.sticky{
		position: fixed;
		top: -94px;
		box-shadow:
		    0 5px 10px rgba(0, 0, 0, 0.1),
		    0 20px 20px rgba(0, 0, 0, 0.05);
		~ .header__submenu{
			position: fixed;
			top: 72px;
		}
	}
	.header__link{
		background-color: $blue_navy_3;
		height: 36px;
		text-align: center;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		> a{
			color: white;
			font-family: $rmed;
			display: flex;
			align-items: center;
			font-size: 15px;
			transition: opacity $time ease;
			&:hover{
				opacity: 0.7;
			}
			img{
				width: 30px;
				height: 20px;
				padding-right: 10px;
			}
		}
	}
	.header__wrapper{
		position: relative;
		width: 100%;
		max-width: $maxW;
		margin: 0 auto;
		padding: 0 15px;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		.header__menu_top{
			background-color: white;
			display: flex;
			width: 100%;
			height: 55px;
			align-items: center;
			justify-content: flex-end;
			position: relative;
			z-index: 1000;
			@include media-breakpoint-down(md) {
			    max-width: 100%;
				padding: 0 76px;
			}
			@include media-breakpoint-down(sm) {
				padding: 0 36px;
			}
			@include media-breakpoint-down(xs) {
				padding: 0 20px;
			}
			a{
				font-family: $rreg;
				font-size: 14px;
				display: block;
				padding-bottom: 5px;
				transition: color $time ease;
				color: $blue;
				&:hover{
					color: $red;
				}
				+ a{
					margin-right: 20px;
				}
			}
			> div{
				> ul.nav{
					display: flex;
					align-items: center;
					> li{
						position: relative;
						margin-right: 40px;
						&.menu-item-has-children{
							> a{
								padding: 0 20px 5px 15px;
							}
							&::before{
								border-style: solid;
								border-width: 1px 1px 0 0;
								content: '';
								display: inline-block;
								position: absolute;
								top: 3px;
								right: 2px;
								transform: rotate(135deg) translateZ(0);
								width: 7px;
								height: 7px;
								color: $blue;
							}
							&:hover{
								&::before{
									top: 6px;
									transform: rotate(315deg);
								}
								> a{
									~ .sub-menu{
										max-height: 100vh;
										transition: max-height 0.7s $Power2EaseInOut;
									}
								}
							}
						}
						.sub-menu{
							background-color: white;
							border-radius: $radius;
							position: absolute;
							top: 100%;
							width: 200px;
							max-height: 0;
							overflow: hidden;
							z-index: 100;
							transition: max-height $time $Power2EaseInOut;
							box-shadow:
							    0 5px 10px rgba(0, 0, 0, 0.1),
							    0 20px 20px rgba(0, 0, 0, 0.05);
							padding: 0 20px;
							> li{
								position: relative;
								> a{
									padding: 15px 0;
									color: $blue;
									transition: all $time ease;
									&:hover{
										background-color: white;
										color: $red;
									}
								}
								+ li{
									border-top: 1px solid $grey_light_2;
								}
							}
						}
					}
				}
			}
			.header__lang{
				margin-right: 40px;
				text-transform: uppercase;
			}
			.header__account{
				svg{
					path{
						transition: fill $time ease;
						fill: $blue;
					}
					&:hover{
						path{
							fill: $red;
						}
					}
				}
			}
			.header__cart{
				svg{
					path, g{
						transition: stroke $time ease;
						stroke: $blue;
					}
				}
				&:hover{
					svg{
						path, g{
							stroke: $red;
						}
					}
				}
			}
			.header__search{
				display: flex;
				align-items: center;
				justify-content: center;
				a.header__search_btn{
					display: flex;
					align-items: center;
					justify-content: center;
					&.js-toggle-search{
						svg{
							path, g{
								stroke: $red;
							}
						}
						~ .search__form{
							width: 200px;
						}
					}
					&:hover{
						svg{
							path, g{
								stroke: $red;
							}
						}
					}
					svg{
						width: 18px;
						height: 18px;
						pointer-events: none;
						path, g{
							transition: stroke $time ease;
							stroke: $blue;
						}
					}
				}
				.search__form{
					position: relative;
					overflow: hidden;
					width: 0;
					.input-group{
						margin-left: 10px;
						padding-bottom: 5px;
						width: auto;
						input{
							border-radius: 40px;
							border: 1px solid $blue;
							height: 25px;
							width: 100%;
							padding: 5px 10px;
						}
					}
				}
			}
		}
		.header__menu_main{
			background-color: white;
			display: flex;
			width: 100%;
			height: auto;
			align-items: center;
			justify-content: space-between;
			position: relative;
			@include media-breakpoint-down(md) {
			    max-width: 100%;
				padding: 0 76px;
			}
			@include media-breakpoint-down(sm) {
				padding: 0 36px;
			}
			@include media-breakpoint-down(xs) {
				padding: 0 20px;
			}
			a:not(.btn-fx){
				font-family: $rreg;
				font-size: 15px;
				display: block;
				color: $blue_navy_3;
				&.header__menu_logo{
					opacity: 1;
					transition: opacity $time ease;
					padding-right: 20px;
					max-width: 225px;
					width: 100%;
					&:hover{
						opacity: 0.5;
					}
					@include media-breakpoint-down(lg) {
						max-width: 200px;
				   }
				}
			}
			&_right{
				display: flex;
				align-items: center;
				> div{
					+ a.btn-menu{
						margin-left: 60px;
						white-space: nowrap;
						@media screen and (min-width: 1200px) and (max-width: 1375px) {
							margin-left: 20px;
							font-size: 14px;
						}
						@include media-breakpoint-down(lg) {
						 	margin-left: 10px;
						 	// padding: 12px;
						 	font-size: 14px;
						}
					}
					// Main Menu ----------------------------------------
					> ul.nav{
						display: flex;
						align-items: center;
						> li{
							position: relative;
							cursor: pointer;
							+ li{
								// margin-left: 30px;
								margin-left: 20px;
								@include media-breakpoint-down(lg) {
								 	margin-left: 10px;
								}
							}
							> a{
								display: flex;
								align-items: center;
								height: 75px;
								pointer-events: none;
								font-size: 16px;
								@media screen and (min-width: 1200px) and (max-width: 1375px) {
									font-size: 14px;
								}
								@include media-breakpoint-down(lg) {
								 	font-size: 14px;
								 	// font-size: 13px;
								}
							}
							&::after{
								position: absolute;
								bottom: 0;
								content: '';
								display: block;
								height: 5px;
								width: 0;
								left: 50%;
								right: 50%;
								background-color: $red;
								transition: all $time ease;
							}
							&.btn-menu{
								background-color: blue;
								&::after{
									content: none;
								}
							}
							&.current_page_item{
								// overflow: hidden;
								&::after{
									width: 100%;
									left: 0;
									right: 0;
								}
							}
							&:hover, &.js-active-menu{
								&::after{
									width: 100%;
									left: 0;
									right: 0;
								}
							}
						}
					}
				}
			}
		}
		
	}
}

// Submenu ------------------------------------
.sub__container {
	position: absolute;
	top: 130px;
	bottom: 0;
	left: 0;
	width: 100%;
	display: none;
	z-index: 0;
	.sub__container-bg {
        background: rgba(52, 57, 107, 0.6);
        height: 100vh;
        left: 50%;
        position: absolute;
        opacity: 0;
        top: 0;
        transform: translate3d(-50%, 0, 0);
        width: 100vw;
        z-index: 0;
    }

    .sub__container-inner {
        height: 0;
        position: relative;
        overflow: hidden;
        width: 100%;
        will-change: height;
    }
	.header__submenu{
		position: relative;
		width: calc(100% - 40px);
		height: auto;
		top: 0;
		left: 0;
		opacity: 1;
		z-index: 900;
		margin: 0 20px;
		display: block;
		will-change: opacity;
		transition: opacity $time ease;
		@include media-breakpoint-down(md) {
		 	display: none;
		}
		&.js-show-submenu{
			opacity: 1;
			transform: translateY(0);
			z-index: 900;
		}
		&_wrapper{
			background-color: white;
			position: relative;
			max-width: 100%;
			width: 100%;
			margin: 0 auto;
			border-bottom-left-radius: 7px;
			border-bottom-right-radius: 7px;
			overflow: hidden;
			.inner {
				position: relative;
				> div{
					padding: 0;
					background-color: white;
					border-radius: $radius;
					box-shadow:
					    0 -5px 10px rgba(0, 0, 0, 0.1),
					    0 20px 20px rgba(0, 0, 0, 0.05);
					position: relative;
					width: 100%;
					height: auto;
					display: none;
					&.js-active-tab, &.js-active-sub{
						display: flex;
					}
					.column {
						opacity: 0;
					}
					.column-wrapper{
						display: flex;
						width: 100%;
						padding: 40px 0;
						@media #{$large} {
						 	padding: 20px 0;
						}
						.column{
							width: 33.33%;
							height: auto;
						}
					}
					.sub_cta{
						max-width: 80%;
						@media #{$large} {
						 	max-width: 100%;
						}
					}
					.sub_cta_image{
						width: 100%;
						display: flex;
						flex-direction: column;
						.image{
							width: 100%;
							height: 226px;
							border-top-right-radius: 10px; 
							border-top-left-radius: 10px;
							background-size: cover;
							background-repeat: no-repeat;
							background-position: center center;
							background-color: $grey_light_3;
							@media #{$large} {
							 	height: 146px;
							}
						}
						.caption{
							width: 100%;
							height: 20%;
							padding: 20px;
							background-color: $grey_light_1;
							border-bottom-right-radius: 10px; 
							border-bottom-left-radius: 10px;
							h4{
								font-size: 16px;
								text-transform: uppercase;
								letter-spacing: 1px;
								color: $blue;
							}
							p{
								margin-top: 10px;
								margin-bottom: 0;
							}
						}
					}
					h2{
						font-size: 32px;
						font-family: $rreg;
						margin-bottom: 20px;
						max-width: 100%;
						@media #{$large} {
						 	font-size: 28px;
						}
						@include media-breakpoint-down(md) {
						 	font-size: 24px;
						}
					}
					h3{
						font-size: 24px;
						margin-bottom: 20px;
					}
					p{
						font-family: $rreg;
						font-size: 16px;
						margin-bottom: 20px;
						line-height: 1.2;
					}
					ul{
						li{
							margin-top: 20px;
							font-size: 16px;
							display: flex;
							align-items: center;
							@media #{$large} {
							 	margin-top: 15px;
							}
							img{
								width: 25px;
								margin-right: 20px;
							}
						}
					}
					&.tab__section{
						.sub_left{
							position: relative;
							width: 33.33%;
							height: auto;
							display: flex;
							flex-direction: column;
							> a{
								display: flex;
								align-items: center;
								width: 100%;
								flex: 1;
								padding: 10px 40px;
								font-size: 20px;
								background-color: white;
								transition: background-color $time ease;
								@media #{$large}{
									flex: auto;
									padding: 10px 20px;
									font-size: 16px;
								}
								&:hover, &.js-active-tab{
									background-color: $grey_light_1;
								}
								img{
									width: 20px;
									margin-right: 20px;
									pointer-events: none;
								}
								+ a{
									border-top: 1px solid $grey_light_1;
								}
							}
						}
						.sub_right{
							position: relative;
							width: 66.66%;
							height: auto;
							background-color: $grey_light_1;
							.tech_content{
								position: relative;
								width: 100%;
								display: none;
								min-height: 427px;
								@media #{$large}{
								 	min-height: 327px;
								}
								&.js-active-content{
									display: flex;
								}
								> div{
									display: flex;
									width: 50%;
									flex-direction: column;
									justify-content: center;
									font-family: $rreg;
									padding: 0 40px;
									margin: 40px 0;
									overflow: hidden;
									overflow-y: auto;
									// @include media-breakpoint-down(lg) {
									//  	padding: 0 20px;
									//  	margin: 20px 0;
									// }
									&.subtab_right{
										justify-content: center;
									}
									&::-webkit-scrollbar {
									    width: 3px;
									    padding: 2px;
									}
									&::-webkit-scrollbar-track{
									    border-radius: 10px;
									}
									&::-webkit-scrollbar-thumb {
									    background-color: $lightblue;
									    border-radius: 10px;
									}
									@media #{$large}{
									 	padding: 0 20px;
									 	margin: 20px 0;
									}
									+ div{
										border-left: 1px solid $grey_light_3;
									}
									.sublink{
										margin-top: 40px;
										display: flex;
										align-items: center;
										font-family: $rbold;
										transition: opacity $time ease;
										font-size: 16px;
										@media #{$large}{
										 	margin-top: 20px;
										}
										&:hover{
											opacity: 0.6;
										}
										img{
											margin-right: 10px;
										}
									}
									a:not(.btn-fx){
										transition: color $time ease;
										&:hover{
											color: $red;
										}
									}
								}
							}
						}
					}
					&.sub__section:not(.tab__section){
						.column{
							padding: 0 40px;
							@media #{$large}{
							 	padding: 0 20px;
							}
							+ .column{
								border-left: 1px solid $grey_light_3;
							}
							a:not(.btn-fx){
								transition: color $time ease;
								&:hover{
									color: $blue;
								}
							}
						}
					}
				}

			}
		}
	}
}

// Menu Mobile ------------------------------------------
header.header__mobile{
	display: none;
	@include media-breakpoint-down(md) {
	 	display: block;
	}
	position: fixed;
	top: 0;
	left: 0;
	background-color: white;
	width: 100%;
	z-index: 1000;
	.header__mobile_link{
		padding: 5px 20px 8px;
		background-color: $blue_navy_3;
		text-align: center;
		> a{
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
			font-family: $rmed;
			font-size: 14px;
			img {
				width: 20px;
				margin-right: 7px;
			}
		}
	}
	.header__mobile_wrapper{
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		.menu__mobile_top{
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include media-breakpoint-down(md) {
			    max-width: 100%;
				padding: 10px 76px;
			}
			@include media-breakpoint-down(sm) {
				padding: 10px 36px;
			}
			@include media-breakpoint-down(xs) {
				padding: 10px 20px;
			}
			.menu__mobile_logo{
				position: relative;
				max-width: 160px;
				img, svg{
					width: 100%;
					padding-right: 10px;
				}
			}
			&_right{
				display: flex;
				.bg-red{
					margin-right: 10px;
					white-space: nowrap;
				}
			}
		}
		.menu__mobile{
			background-color: white;
			position: relative;
			max-height: 0;
			overflow: hidden;
			overflow-y: auto;
			transition: max-height $time $Power2EaseInOut;
			&.js-open-menu{
				max-height: calc(90vh - 85px);
			}
			.nav__mobile{
				position: relative;
				padding: 0 40px;
				.nav__mobile_wc{
					display: flex;
					align-items: center;
					justify-content: flex-end;
					> a{
						padding: 15px 0;
						display: block;
						font-family: $rreg;
						color: $blue_navy_3;
						font-size: 14px;
						text-align: right;
						text-transform: uppercase;
						+ a{
							margin-left: 10px;
						}
						svg{
							height: 14px;
							color: $blue_navy_3;
							font-weight: 100;
						}
						&.header__account{
							svg path{
								fill: $blue_navy_3;
							}
						}
						&.header__cart{
							svg g{
								stroke: $blue_navy_3;
							}
						}
					}
				}
				> div{
					position: relative;
					> ul{
						position: relative;
						background-color: white;
						&.nav_mobile_menu, &.nav_mobile_top{
							li{
								+ li{
									border-top: 1px solid $grey_light_1;
								}
								> a{
									color: $blue_navy_3;
									font-size: 14px;
									&.current{
										color: $red;
									}
								}
							}
						}
						&.nav_mobile_top{
							border-bottom: 1px solid $grey_light_1;
						}
						li{
							position: relative;
							border-top: 1px solid $grey_light_1;
							&.menu-item-has-children{
								> a{
									&::before{
										border-style: solid;
										border-color: $red;
										border-width: 1px 1px 0 0;
										content: '';
										display: inline-block;
										position: absolute;
										top: 18px;
										right: 10px;
										transform: rotate(135deg) translateZ(0);
										width: 7px;
										height: 7px;
									}
									&.js-toggle-sub{
										&::before{
											top: 22px;
											transform: rotate(315deg);
										}
										~ .sub-menu{
											max-height: 100vh;
										}
									}
								}
							}
							> a{
								padding: 15px 0;
								display: block;
								font-family: $rreg;
								color: $blue_navy_3;
								font-size: 14px;
							}
							.sub-menu{
								position: relative;
								background-color: white;
								max-height: 0;
								overflow: hidden;
								transition: max-height $time $Power2EaseInOut;
								> li{
									position: relative;
									&.sub-menu-item{
										color: $blue_navy_2;
										font-size: 14px;
										display: flex;
										align-items: center;
										.sub-icon{
											height: 20px;
											margin-right: 20px;
										}
										> a{
											display: flex;
											width: 100%;
											align-items: center;
											padding: 12px 20px 12px 0;
										}
									}
									> a{
										padding: 15px 20px;
										color: $blue_navy_2;
										transition: all $time ease;
										&:hover{
											background-color: white;
											color: $red;
										}
									}
								}
							}
						}
					}
				}
				.search__form_mobile{
					position: relative;
					padding: 40px 0;
					display: flex;
					align-items: center;
					width: 100%;
					
					&.focused {
						background: rgba(52, 57, 107, 0.8);
						position: fixed;
						align-items: flex-start;
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
						z-index: 5;
						padding: 150px 50px;
						transition: all $time $Power2EaseInOut;
					}

					.input-group{
						position: relative;
						width: 100%;
						input{
							border-radius: 40px;
							border: 1px solid $grey_light_1;
							width: 100%;
							padding: 15px;
						}
					}
					button{
						position: absolute;
						right: 0;
						top: 1px;
						background-color: $red;
						border-radius: 50%;
						width: 45px;
						height: 45px;
						display: flex;
						align-items: center;
						justify-content: center;
						svg{
							width: 18px;
							height: 18px;
						}
					}
				}
			}
		}
	}
}