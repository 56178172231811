.section__cta_read_also{
	position: relative;
	overflow: hidden;
	height: auto;
	padding: 45px 0 90px;
	@include media-breakpoint-down(md) {
	    padding: 20px 0;
	}
	.section__wrapper{
		position: relative;
		max-width: 52%;
		margin: 0 auto;
		width: 100%;
		padding: 0 20px;
		display: flex;
		flex-direction: column;
		@include media-breakpoint-down(md) {
		    max-width: 100%;
			padding: 0 76px;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px;
		}
		h2{
		    font-family: $wlight;
		    font-size: 42px;
		    margin-bottom: 40px;
		    line-height: 1.4;
		    @include media-breakpoint-down(lg) {
		        font-size: 32px;
		        margin-bottom: 20px;
		    }
		    @include media-breakpoint-down(md) {
		        font-size: 24px;
		    }
		}
	}
}