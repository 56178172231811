.section__hero_top{
	position: relative;
	width: 100%;
	height: calc(100vh - 155px);
	background-color: $grey_light_1;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	z-index: 500;
	@media #{$laptop}{
		height: 60vh;
	}
	@include media-breakpoint-down(sm) {
	 	height: calc(100vh - 205px);
	}
	.home &{
		+ section{
			padding-top: 200px;
			// @include media-breakpoint-down(md) {
			//  	padding-top: 60px;
			// }
			// @include media-breakpoint-down(xs) {
			//  	padding-top: 140px;
			// }
		}
	}
	.hero__breadcrumb{
		position: absolute;
		width: 100%;
		top: 60px;
		left: 0;
		font-family: $rreg;
		color: white;
		@include media-breakpoint-down(sm) {
		 	top: 20px;
		}
		.breadcrumbs{
			position: relative;
			max-width: $maxW;
			margin: 0 auto;
			padding: 0 20px;
			@media screen and (min-width: 1200px) and (max-width: 1350px) {
				max-width: $maxWR-85;
			}
			@include media-breakpoint-down(lg) { // 992 to 1199
				max-width: $maxWR-90;
			}
			@include media-breakpoint-down(md) {
			  	max-width: 100%;
			  	font-size: 13px;
			}
			> a{
				transition: opacity $time ease;
				&:hover{
					opacity: 0.5;
				}
			}
		}
		~ .section__wrapper .hero__cta{
			padding-top: 80px;
			@include media-breakpoint-down(sm) {
			 	padding-top: 40px;
			}
		}
	}
	.section__wrapper{
		position: relative;
		display: flex;
		align-items: center;
		max-width: $maxW;
		margin: 0 auto;
		width: 100%;
		padding: 0 20px;
		// height: 100%;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		.home &{
			height: calc(100% - 90px);
		}
		z-index: 100;
		@include media-breakpoint-down(md) {
			padding: 0 76px;
		  	max-width: 100%;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px;
		}
		.hero__cta{
			width: 100%;
			max-width: 70%;
			padding-right: 20px;
			@include media-breakpoint-down(md) {
			 	max-width: 100%;
			 	padding-right: 0;
			}
			> *{
				// opacity: 0;
			}
			.prefix{
				font-family: $rmed;
				color: white;
				text-transform: uppercase;
				font-size: 24px;
				@include media-breakpoint-down(lg) {
				 	font-size: 18px;
				}
			}
			h1{
				font-size: 48px;
				color: white;
				margin-top: 20px;
				font-family: $wmed;
				@include media-breakpoint-down(lg) {
				 	font-size: 36px;
				}
				@include media-breakpoint-down(md) {
				 	font-size: 26px;
				}
			}
			p{
				color: white;
				line-height: 1.4;
				letter-spacing: 1px;
				font-family: $rreg;
				margin-top: 20px;
				font-size: 16px;
				@include media-breakpoint-down(sm) {
				   font-size: 13px;
				}
			}
			a.btn, a.btn-fx{
				margin-top: 20px;
			}
		}
	}
	&.hero__small{
		height: 456px;
		display: flex;
		align-items: flex-end;
		@media #{$laptop}{
			height: auto;
			min-height: 30vh;
		}
		.woocommerce-page:not(.post-type-archive-product) &{
			min-height: 228px;
			@media #{$laptop}{
				min-height: 228px;
			}
			@include media-breakpoint-down(sm) {
			 	min-height: 156px;
			}
			.section__wrapper{
				padding: 60px 20px 40px;
				@include media-breakpoint-down(sm) {
					padding: 60px 36px 20px;
				}
				@include media-breakpoint-down(xs) {
					padding: 60px 20px 20px;
				}
			}
		}
		.post-type-archive-product &{
			min-height: 356px;
			@media #{$laptop}{
				min-height: 228px;
			}
			@include media-breakpoint-down(sm) {
			 	min-height: 156px;
			}
			.section__wrapper{
				padding: 60px 20px 40px;
				@include media-breakpoint-down(sm) {
					padding: 60px 36px 20px;
				}
				@include media-breakpoint-down(xs) {
					padding: 60px 20px 20px;
				}
			}
		}
		@include media-breakpoint-down(sm) {
		 	min-height: 156px;
		}
		.section__wrapper{
			padding: 60px 20px 80px;
			@include media-breakpoint-down(md) {
			    max-width: 100%;
				padding: 20px 76px 40px;
			}
			@include media-breakpoint-down(sm) {
				padding: 20px 36px 40px;
			}
			@include media-breakpoint-down(xs) {
				padding: 20px 20px 20px;
			}
		}
	}
}