/**
 *  -----------------------------------------------
 *  pages/HOME
 *
 *  1. Wrapper / header
 *  2. Second section
 *  -----------------------------------------------
 */

/*
* 2. Second section
****************************************************************/

body.home{
	position: relative;
}