.cta__side_image{
	position: relative;
	display: flex;
	width: 100%;
	@include media-breakpoint-down(md) {
	    flex-direction: column;
	}
	// Class to switch side
	&.side-reverse{
		flex-direction: row-reverse;
		@include media-breakpoint-down(md) {
		    flex-direction: column;
		}
		.cta__side_image_left{
			margin-right: 0;
			margin-left: 5%;
			@include media-breakpoint-down(md) {
			    margin-left: 0;
			}
		}
	}
	&_left{
		width: 30%;
		margin-right: 5%;
		@include media-breakpoint-down(lg) {
		    width: 60%;
		}
		@include media-breakpoint-down(md) {
		    width: 100%;
		    margin-right: 0;
		}
		h2{
			font-family: $wlight;
			font-size: 42px;
			@include media-breakpoint-down(lg) {
			    font-size: 32px;
			}
			@include media-breakpoint-down(md) {
			    font-size: 26px;
			}
		}
		.subtitle{
			font-size: 24px;
			margin-top: 20px;
			@include media-breakpoint-down(lg) {
			    font-size: 18px;
			}
		}
		p{
			margin-top: 20px;
			font-size: 16px;
			line-height: 1.4;
			letter-spacing: 1px;
			@include media-breakpoint-down(md) {
			    font-size: 13px;
			}
		}
		a.btn, a.btn-fx{
			margin-top: 20px;
		}
	}
	&_right{
		position: relative;
		overflow: hidden;
		width: 65%;
		@include media-breakpoint-down(lg) {
		    width: 35%;
		}
		@include media-breakpoint-down(md) {
		    width: 100%;
		    margin-top: 40px;
		}
		.image{
			position: relative;
			width: 100%;
			@include keepRatio('1000/492');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			border-radius: 10px;
			overflow: hidden;
		}
		.circles{
			@include keepRatio('956/531');
			&_wrapper{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				.page-template-page-technologie &{
					transform: scale(-1);
					.circle{
						img{
							transform: scale(-1) translateZ(0);
						}
						&:hover{
							img{
								transform: scale(-1.1) translateZ(0);
							}
						}
					}
				}
				.circle{
					position: absolute;
					border-radius: 50%;
					overflow: hidden;
					background-color: $grey_light_3;
					backface-visibility: hidden;
					transform: translate3d(0, 0, 0);
					&:hover{
						img{
							transform: scale(1.1);
						}
					}
					img{
						transition: transform $time ease;
						transform: scale(1);
						width: 100%;
						height: 100%;
						object-fit: cover;
						backface-visibility: hidden;
					}
					&.large{
						top: 0;
						left: 0;
						width: 54.6%;
						height: 98.3%;
					}
					&.medium{
						top: 0;
						right: 0;
						width: 32.6%;
						height: 58.7%;
					}
					&.small{
						bottom: 0;
						right: 22%;
						width: 22.8%;
						height: 41.1%;
					}
				}
			}
		}
	}
}