.account-dashboard{
	display: flex;
	width: 100%;
	@include media-breakpoint-down(md) {
		flex-direction: column;
	}
	nav.woocommerce-MyAccount-navigation{
		width: 30%;
		margin-right: 20px;
		padding-left: 20px;
		@include media-breakpoint-down(md) {
			width: 100%;
			margin-right: 0;
			margin-bottom: 20px;
			padding-left: 0;
		}
		ul{
			position: relative;
			@include media-breakpoint-down(md) {
				padding: 0 20px;
			}
			li{
				position: relative;
				@include media-breakpoint-down(md) {
					margin-bottom: 10px;
				}
				a{
					display: block;
					font-size: 24px;
					color: $blue;
					transition: color $time ease;
					@include media-breakpoint-down(md) {
						font-size: 16px;
					}
					&:hover{
						color: $blue2;
					}
				}
				+ li{
					margin-top: 20px;
					@include media-breakpoint-down(md) {
						margin-top: 0;
					}
				}
				&.is-active{
					&:before{
						position: absolute;
						bottom: 0;
						left: -20px;
						content: '';
						width: 15px;
						height: 5px;
						background-color: $red;
					}
					a{
						color: $blue2;
					}
				}
			}
		}
	}
	div.woocommerce-MyAccount-content{
		width: 70%;
		@include media-breakpoint-down(md) {
			width: 100%;
		}
		p{
			font-size: 24px;
			margin-bottom: 20px;
			@include media-breakpoint-down(md) {
				font-size: 16px;
			}
		}
		strong{
			font-family: $rbold;
		}
		.woocommerce-Addresses{
			position: relative;
			margin-top: 40px;
			@include media-breakpoint-down(sm) {
				margin-top: 20px;
			}
			.woocommerce-Address{
				padding: 0 40px;
				@include media-breakpoint-down(sm) {
					padding: 0;
					margin-bottom: 20px;
				}
				&:first-child{
					padding: 0 40px 0 0;
					@include media-breakpoint-down(sm) {
						padding: 0;
					}
				}
				header{
					h3{
						font-family: $wmed;
						font-size: 24px;
						margin-bottom: 20px;
						color: $blue2;
						@include media-breakpoint-down(lg) {
						    font-size: 16px;
						}
					}
				}
				address{
					font-style: italic;
					font-size: 13px;
					margin-bottom: 20px;
				}
			}
		}
	}
}