.section__cta_icon{
	position: relative;
	overflow: hidden;
	height: auto;
	padding: 90px 0;
	@include media-breakpoint-down(md) {
	    height: auto;
	    padding: 40px 0;
	}
	.section__wrapper{
		position: relative;
		max-width: $maxW;
		margin: 0 auto;
		width: 100%;
		padding: 0 20px;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		    max-width: 100%;
			padding: 0 76px;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px;
		}
		h2{
		    font-family: $wlight;
		    font-size: 42px;
		    @include media-breakpoint-down(lg) {
                font-size: 32px;
            }
		    @include media-breakpoint-down(md) {
		        font-size: 24px;
		    }
		}
		.cta__icon_row{
			position: relative;
			display: flex;
			flex-wrap: wrap;
			margin: 0 -40px;
			justify-content: center;
			@include media-breakpoint-down(md) {
			    margin: 0 -20px;
			}
			@include media-breakpoint-down(sm) {
			    margin: 0;
			    flex-direction: column;
			}
		}
	}
}