.form {

}

.form__box {
}

.form__field {
  font-family: inherit;
  font-size: inherit;
}