.cta_read_item{
	display: flex;
	+ .cta_read_item{
		margin-top: 40px;
	}
	@include media-breakpoint-down(sm) {
	    flex-direction: column;
	}
	&_left{
		width: 175px;
		@include media-breakpoint-down(sm) {
		    width: 100%;
		}
		.image{
			@include keepRatio('175/112');
			border-radius: 10px;
			overflow: hidden;
			backface-visibility: hidden;
			transform: translate3d(0, 0, 0);
			background-color: $grey_light_3;
			@include media-breakpoint-down(sm) {
			    width: 100%;
			}
			a{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				img{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					transform: scale(1);
					transition: transform $time ease;
					backface-visibility: hidden;
				}
			}
			&:hover{
				img{
					transform: scale(1.1);
				}
			}
		}
	}
	&_right{
		width: calc(100% - 175px);
		padding-left: 40px;
		@include media-breakpoint-down(sm) {
		    width: 100%;
		    padding-left: 0;
		    margin-top: 20px;
		}
		.tag{
			text-transform: uppercase;
			font-size: 18px;
			font-family: $rreg;
			color: $blue;
			letter-spacing: 1px;
			@include media-breakpoint-down(sm) {
			    font-size: 16px;
			}
			+ .tag{
				margin-left: 10px;
			}
		}
		a{
			display: block;
			margin-top: 10px;
			font-size: 24px;
	        line-height: 1.4;
	        transition: opacity $time ease;
	        @include media-breakpoint-down(lg) {
	            font-size: 18px;
	        }
	        @include media-breakpoint-down(md) {
	            font-size: 16px;
	        }
	        &:hover{
	        	opacity: 0.6;
	        }
		}
	}
}