.section__cta_product_slider{
	position: relative;
	overflow: hidden;
	height: auto;
	padding: 90px 0;
	@include media-breakpoint-down(md) {
	    height: auto;
	    padding: 40px 0;
	}
	.section__wrapper{
		position: relative;
		max-width: $maxW;
		margin: 0 auto;
		width: 100%;
		padding: 0 20px;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		  	max-width: 100%;
			padding: 0 76px 40px;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px 40px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px 0;
		}
		h2{
		    font-family: $wlight;
		    font-size: 42px;
		    @include media-breakpoint-down(lg) {
                font-size: 32px;
            }
		    @include media-breakpoint-down(sm) {
		        font-size: 24px;
		        margin-bottom: 20px;
		    }
		}
		.slick-list, .slick-track {
			width: 100%;
		}
		.cta__product_slider_top{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;
			@include media-breakpoint-down(xs) {
			    flex-direction: column;
			}
			h2{
				font-family: $wmed;
				margin-bottom: 0;
				@include media-breakpoint-down(xs) {
				    margin-bottom: 20px;
				}
			}
		}
		.cta__product_slider{
			position: relative;
			display: flex;
			justify-content: center;
			margin: 0 -40px;
			opacity: 0;
			transition: opacity $time ease;
			@include media-breakpoint-down(md) {
			    margin: 0 -20px;
			    padding: 0 20px;
			}
			&.js-init{
				opacity: 1;
			}
			> svg{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
				height: 100%;
				background-color: white;
				z-index: 10;
				&.prev{
					left: -10px;
					@include media-breakpoint-down(md) {
					    width: 15px;
					    left: 10px;
					}
				}
				&.next{
					right: -10px;
					@include media-breakpoint-down(md) {
					    width: 15px;
					    right: 10px;
					}
				}
			}
		}
	}
}