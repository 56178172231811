.section__single_product{
	position: relative;
	overflow: hidden;
	height: auto;
	padding: 90px 0;
	@include media-breakpoint-down(md) {
	    height: auto;
	    padding: 40px 0;
	}
	@include media-breakpoint-down(xs) {
	    padding: 40px 0 0;
	}
	.section__wrapper{
		position: relative;
		max-width: $maxW;
		margin: 0 auto;
		width: 100%;
		padding: 0 20px;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		  	max-width: 100%;
			padding: 0 76px 40px;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px 40px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px 40px;
		}
		.single__product_top{
			position: relative;
			display: flex;
			width: 100%;
			padding-bottom: 90px;
			@include media-breakpoint-down(md) {
			   	flex-direction: column;
			   	padding-bottom: 40px;
			}
			&_left{
				position: relative;
				width: 40%;
				padding-right: 20px;
				@include media-breakpoint-down(md) {
				   	width: 100%;
				   	padding-right: 0;
				   	margin-bottom: 20px;
				}
				.product_title{
					display: flex;
					h1{
						font-family: $wmed;
						font-size: 36px;
						margin-bottom: 20px;
						line-height: 1.2;
						color: $blue;
						@include media-breakpoint-down(lg) {
						    font-size: 24px;
						}
						width: 100%;
					}
					.product-logo{
						width: 100px;
						padding: 10px;
						img{
							max-width: 100%;
						}
					}
				}
				.product_code{
					text-transform: uppercase;
					color: $blue2;
					font-family: $rreg;
					font-size: 16px;
					margin-bottom: 20px;
				}
				.product_detail{
					margin-bottom: 20px;
					p{
						color: $blue2;
						font-family: $rreg;
						font-size: 24px;
						@include media-breakpoint-down(lg) {
						    font-size: 16px;
						}
					}
				}
				.product_notice{
					color: $red;
					font-family: $rbold;
				}
			}
			&_right{
				width: 60%;
				position: relative;
				display: flex;
				opacity: 0;
				transition: opacity $time ease;
				@include media-breakpoint-down(md) {
				   	width: 100%;
				   	flex-direction: column;
				}
				&.js-init{
					opacity: 1;
				}
				.product_image_main{
					width: 100%;
					max-width: 73%;
					padding-right: 20px;
					margin-left: 40px;
					@include media-breakpoint-down(md) {
					    max-width: 100%;
					    padding-right: 0;
					    margin-left: 0;
					}
					.slide{
						max-width: 100%;
						@include keepRatio('588/441');
						overflow: hidden;
						outline: none;
						@include media-breakpoint-down(md) {
						    max-width: 100%;
					   	}
						.image{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;	
							background-repeat: no-repeat;
							background-position: center center;
							background-size: cover;
							border-radius: $radius;
						}
					}
				}
				.product_thumb_nav{
					position: relative;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					max-width: 27%;
					@include media-breakpoint-down(md) {
					    max-width: 100%;
					    display: block;
				   	}
					.product_image_thumbs{
						position: relative;
						display: flex;
						flex-direction: column;
						align-items: center;
						max-width: 137px;
						@include media-breakpoint-down(md) {
						    max-width: 100%;
						    width: 100%;
						    flex-direction: inherit;
						    display: block;
					   	}
						.thumb{
							position: relative;
							margin: 10px 0;
							overflow: hidden;
							outline: none;
							cursor: pointer;
							@include keepRatio('137/103');
							@include media-breakpoint-down(md) {
							    margin: 20px;
						   	}
					   		@include media-breakpoint-down(xs) {
					   		    margin: 10px;
					   	   	}
							.image{
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background-repeat: no-repeat;
								background-position: center center;
								background-size: cover;
								border-radius: $radius;
							}
						}
						.prev, .next{
							position: relative;
							transform: rotate(90deg);
							width: 20px;
							height: 20px;
							cursor: pointer;
							@include media-breakpoint-down(md) {
							    transform: none;
							   	position: absolute;
							   	top: 0;
							   	width: 10px;
							   	height: 100%;
							   	left: -10px;
						   	}
						}
						.next{
							@include media-breakpoint-down(md) {
								right: -10px;
								left: inherit;
							}
						}	
					}
				}
			}
		}
		form.single__product_checkout{
			position: relative;
			border-top: 1px solid $lightblue;	
			.form__inner{
				padding: 40px 0;
				margin: 0 -40px;
				display: flex;
				@include media-breakpoint-down(lg) {
				    margin: 0 -20px;
				}
				@include media-breakpoint-down(sm) {
				    flex-direction: column;
				    margin: 0;
				    padding: 40px 0 0;
				}
				> div{
					width: 33.33%;
					margin: 0 40px;
					@include media-breakpoint-down(lg) {
					    margin: 0 20px;
					}
					@include media-breakpoint-down(sm) {
					    width: 100%;
					    margin: 0 0 20px 0;
					}
					.label{
						font-size: 13px;
						font-family: $rbold;
						color: $blue2;
						margin-bottom: 5px;
					}
					.select-group{
						position: relative;
						+ .select-group{
							margin-top: 40px;
							@include media-breakpoint-down(sm) {
							    margin-top: 20px;
							}
						}
						.number-box, .select-box{
							position: relative;
							select{
								appearance: none;
								outline: none;
								border-radius: 0;
								box-shadow: none;
								border: 0;
								border-bottom: 1px solid $blue2;
								padding: 10px 0;
								width: 100%;
								font-size: 13px;
								color: $blue2;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								padding-right: 15px;
							}
							select::-ms-expand {
								display: none;
							}
							.icon-select{
								position: absolute;
								top: 10px;
								right: 10px;
								width: 10px;
								height: 17px;
							}
							.quantity{
								position: relative;
								@include media-breakpoint-down(lg) {
								    padding-bottom: 20px;
								}
								input[type="number"]{
									width: 100%;
									text-align: left;
								}
								~ .icon-select{
									display: none;
								}
							}
							.price{
								display: block;
								margin-top: 10px;
							}
						}
					}
					&.single__product_checkout_left{
						.label{
							font-family: $rreg;
						}
						.price{
							font-size: 24px;
							@include media-breakpoint-down(lg) {
							    font-size: 16px;
							}
						}
					}
					&.single__product_checkout_right{
						display: flex;
						justify-content: space-between;
						@include media-breakpoint-down(lg) {
						    flex-direction: column;
						}
						> div{
							width: calc(50% - 20px);
							@include media-breakpoint-down(lg) {
							    width: 100%;
							}
							button{
								width: 100%;
								text-align: center;
								@include media-breakpoint-down(sm) {
								    margin-top: 20px;
								}
							}
						}
					}
				}
			}
		}
		.section__cta_tab_circle{
			position: relative;
			padding: 90px 0 0;
			@include media-breakpoint-down(sm) {
			    padding: 40px 0 0;
			}
			.tab__header{
				position: relative;
				height: auto;
				overflow: hidden;
				overflow-x: auto;
				&::-webkit-scrollbar {
				    display: none;
				}
				@include media-breakpoint-down(sm) {
				    margin: 0 -36px 0 0;
				}
				@include media-breakpoint-down(xs) {
				    margin: 0 -20px 0 0;
				}
				&_wrapper{
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					height: 100%;
					@include media-breakpoint-down(sm) {
					   	min-width: 768px;
					}
					@include media-breakpoint-down(xs) {
					   	min-width: 575px;
					}
					> a{
						display: flex;
						align-items: center;
						justify-content: flex-start;
						font-size: 20px;
						padding: 30px 20px 30px 0;
						width: 100%;
						cursor: pointer;
						transition: color $time ease;
						&:hover{
							color: $red;
						}
						@include media-breakpoint-down(lg) {
						   	font-size: 16px;
						}
						@include media-breakpoint-down(md) {
						   	padding: 20px 10px 20px 0;
						   	font-size: 13px;
						   	max-width: inherit !important;
						}
						@include media-breakpoint-down(sm) {
						   	padding: 10px 10px 15px 0;
						}
						img{
							padding-right: 20px;
							pointer-events: none;
							@include media-breakpoint-down(md) {
							   	width: 30px;
							   	padding-right: 10px;
							}
						}
					}
				}
				.tab__header_line{
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 5px;
					background-color: $grey_light_1;
					@include media-breakpoint-down(sm) {
					   	height: 3px;
					   	min-width: 768px;
					}
					@include media-breakpoint-down(xs) {
					   	min-width: 575px;
					}
					.progress{
						position: absolute;
						left: 0;
						margin-left: 0;
						height: 100%;
						background-color: $red;
						z-index: 25;
						transition: margin-left $time ease;
					}
				}
			}
			.tab__container{
				margin-top: 100px;
				position: relative;
				@include media-breakpoint-down(md) {
				    margin-top: 20px;
				}
				.tab_content{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					opacity: 0;
					transform: translateY(-10px);
					transition: opacity $time ease, transform $time ease;
					z-index: 50;
					display: flex;
					&.js-active-content{
						position: relative;
						opacity: 1;
						transform: translateY(0);
						transition: opacity $time ease, transform $time ease;
						transition-delay: 0.7s;
						z-index: 100;
					}
				}
			}
		}
	}
}