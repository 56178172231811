.section__text_content{
	position: relative;
	// overflow: hidden;
	height: auto;
	padding: 90px 0 45px;
	@include media-breakpoint-down(md) {
	    height: auto;
	    padding: 40px 0 20px;
	}
	.section__wrapper_centered{
		position: relative;
		max-width: 52%;
		margin: 0 auto;
		width: 100%;
		padding: 0 20px;
		@include media-breakpoint-down(md) {
		    max-width: 100%;
			padding: 0 76px;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px;
		}
	}
	.section__wrapper{
		position: relative;
		max-width: $maxW;
		margin: 0 auto;
		width: 100%;
		padding: 0 20px;
		display: flex;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		    flex-direction: column;
		    max-width: 100%;
			padding: 0 76px;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px;
		}
		.text__content_left{
			position: relative;
			width: 68%;
			padding-right: 100px;
			@include media-breakpoint-down(lg) {
			    padding-right: 40px;
			    &.none{
			    	width: 100%;
			    	padding-right: 0;
			    }
			}
			@include media-breakpoint-down(md) {
			    padding-right: 0;
			    width: 100%;
			    margin-bottom: 20px;
			}
			.cta__slider{
				position: relative;
				margin-top: 40px;
			}
		}
		.text__content_right{
			position: relative;
			right: 20px;
			width: 32%;
			height: 100%;
			@include media-breakpoint-down(md) {
				position: relative;
				right: 0;
			    width: 100%;
			    height: auto;
			}
			.scrollmagic-pin-spacer {
				width: 100% !important;
				@include media-breakpoint-down(md) {
				   padding: 0 !important;
				}
			}
		}

        &.js-sidebar {
            .section__wrapper .text__content_right {
                position: absolute;
                .cta-sidebar {
                    position: absolute;
                }
            }
        }
	}
}