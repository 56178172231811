.section__form_content{
	position: relative;
	overflow: hidden;
	height: auto;
	padding: 90px 0;
	@include media-breakpoint-down(md) {
	    height: auto;
	    padding: 40px 0;
	}
	.section__wrapper{
		position: relative;
		max-width: $maxW;
		margin: 0 auto;
		width: 100%;
		padding: 0 20px;
		display: flex;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		    flex-direction: column;
		    max-width: 100%;
			padding: 0 76px;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px;
		}
		h2{
		    font-family: $wlight;
		    font-size: 36px;
		    @include media-breakpoint-down(lg) {
		        font-size: 28px;
		    }
		    @include media-breakpoint-down(sm) {
		        font-size: 24px;
		    }
		}
		h4{
		    font-family: $rbold;
		    font-size: 13px;
		}
		.form__content_left{
			position: relative;
			width: 68%;
			padding-right: 100px;
			@include media-breakpoint-down(lg) {
			    padding-right: 40px;
			}
			@include media-breakpoint-down(md) {
			    padding-right: 0;
			    width: 100%;
			    margin-bottom: 20px;
			}
			form{
				display: flex;
				flex-direction: column;
				margin-top: 40px;
				select{
					appearance: none;
					outline: 0;
					border-radius: 0;
					box-shadow: none;
					border: 0;
					border-bottom: 1px solid $grey_light_3;
					padding: 10px 0;
					width: 100%;
					font-size: 13px;
					color: $blue_navy_3;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: word-wrap;
					padding-right: 15px;
				}
				select::-ms-expand {
					display: none;
				}
				input, textarea{
					appearance: none;
					outline: 0;
					border-radius: 0;
					box-shadow: none;
					border: 0;
					padding: 10px 0;
					border-bottom: 1px solid $grey_light_3;
					width: 100%;
					font-size: 13px;
					&::placeholder{
						font-size: 13px;
						font-family: $rmed;
					}
				}
				input[type="submit"]{
					display: inline-block;
					padding: 10px 20px;
					border-radius: 30px;
					font-family: $rmed;
					font-size: 16px;
					transition: background-color $time ease, border $time ease;
					border: 2px solid $red;
					background-color: $red;
					color: white;
					max-width: 200px;
					@include media-breakpoint-down(sm) {
					   max-width: 100%;
					}
					&:hover{
						background-color: white;
						color: $red;
					}
				}
				label{
					display: none;
				}
				span[role="alert"]{
					color: red;
					font-size: 13px;
					display: block;
					margin-top: 10px;
				}
				.wpcf7-validation-errors{
					border: 2px solid red;
					margin: 0;
					margin-top: 20px;
					padding: 10px 20px;
					color: red;
				}
				.form-top, .form-middle{
					position: relative;
					margin-bottom: 40px;
					@include media-breakpoint-down(sm) {
					   	margin-bottom: 20px;
					}
					br{
						display: none;
					}
					&-wrapper{
						display: flex;
						flex-wrap: wrap;
						margin: 0 -20px;
						@include media-breakpoint-down(sm) {
						   	flex-direction: column;
						   	margin: 0;
						}
						.group{
							position: relative;
							width: calc(50% - 40px);
							margin: 20px;
							@include media-breakpoint-down(lg) {
						    	width: 100%;
							}
							@include media-breakpoint-down(sm) {
							   	width: 100%;
							   	margin: 10px 0;
							}
							span{
								width: 100%;
							}
							.icon{
								position: absolute;
								right: 0;
								top: 7px;
								z-index: 100;
								&:after{
									content: url('../images/icons/icon-blue-select.svg');
									display: block;
									width: 25px;
									height: 25px;
								}
							}
						}
					}
				}
				.form-bottom{
					margin-bottom: 40px;
					@include media-breakpoint-down(sm) {
					   	margin-bottom: 20px;
					}
					.group{
						position: relative;
						margin-bottom: 40px;
						.icon-long-arrow{
							position: absolute;
							right: 0;
							top: 0;
							z-index: 100;
							@include media-breakpoint-down(md) {
								display: none;
							}
							&:after{
								content: url('../images/icons/icon-blue-long-arrow.svg');
								display: block;
								width: 70px;
								height: 28px;
							}
						}
						h3{
							padding-right: 90px;
							@include media-breakpoint-down(sm) {
							   	padding-right: 0;
							   	text-align: center;
							}
						}
						&.file{
							h4{
								margin-bottom: 20px;
								@include media-breakpoint-down(sm) {
									width: 100%;
									text-align: center;
								}
							}
							.upload-btn-wrapper{
								position: relative;
								display: inline-block;
								@include media-breakpoint-down(sm) {
									display: flex;
									flex-direction: column;
									width: 100%;
									text-align: center;

								}
								.btn-fx {
									min-width: 150px;
    								min-height: 44px;
									p {
										top: 50%;
										transform: translateY(-50%);
										height: unset;
									}
								}
								.file-name{
									position: absolute;
									white-space: nowrap;
									width: 100%;
									top: 50%;
									right: calc(-100% - 20px);
									transform: translateY(-50%);
									font-family: $rbold;
									font-size: 15px;
									color: $red;
									@include media-breakpoint-down(sm) {
										position: relative;
										white-space: normal;
										top: 20px;
										right: 0;
										transform: none;
										text-align: center;
									}
								}
								&:hover{
									.btn-fx{
										color: white;
										&:before{
											opacity: 0;
											transform: scale(0.5,0.5);
										}
										&:after{
											opacity: 1;
											transform: scale(1,1);
										}
									}
								}
								p{
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 43px;
									z-index: 2;
									cursor: pointer;
									span{
										position: absolute;
										left: 0;
										top: 0;
										width: 100%;
										height: 100%;

										&.wpcf7-not-valid-tip {
											top: 60px;
											width: max-content;
											pointer-events: none;
											cursor: default;
										}
									}
									input[type="file"]{
										position: absolute;
										left: 0;
										top: 0;
										border: none;
										opacity: 0;
										padding: 0;
										width: 100%;
										height: 100%;
									}
								}
							}
						}
					}
				}
			}
		}
		.form__content_right{
			position: relative;
			width: 32%;
			height: 100%;
			@include media-breakpoint-down(md) {
				position: relative;
				right: 0;
			    width: 100%;
			    height: auto;
			}
			.cta__sidebar{
				position: relative;
				&_top{
					background-color: $grey_light_1;
					color: $blue_navy_3;
					border-radius: 10px;
				}
				&_bottom{
					margin-top: 20px;
					border: 2px solid $grey_light_1;
					border-radius: 10px;
					padding: 40px;
					@include media-breakpoint-down(md) {
						padding: 20px;
					}
					h3{
						color: $blue_navy_3;
						font-size: 18px;
						font-family: $rreg;
						&.prefix{
							margin-bottom: 10px;
						}
					}
					span{
						display: block;
						font-size: 13px;
						line-height: 1.4;
						color: $blue_navy_3;
					}
				}
			}

		}
	}
}