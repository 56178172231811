/**
 *  -----------------------------------------------
 *  module/MIXINS AND UTILITIES
 *
 *  1. Transitions
 *  2. Placeholders
 *  3. Absolute centering
 *  4. Triangle
 *  5. Lines
 *  6. Vertical-line
 *  -----------------------------------------------
 */

/*
 * 1. Transitions
 ****************************************************************/

@mixin transition($propriety: all, $duration: 0.5s) {
  transition: $propriety $duration ease;
}


/*
 * 2. Placeholders
 ****************************************************************/

/* stylelint-disable selector-no-vendor-prefix */

@mixin placeholder($color: #bab9b9) {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */

  }

  &::-moz-placeholder { /* Firefox 19+ */

  }

  &:-ms-input-placeholder { /* IE 10+ */

  }

  &:-moz-placeholder { /* Firefox 18- */

  }
}

/* stylelint-enable selector-no-vendor-prefix */


/*
 * 3. Absolute centering
 ****************************************************************/

@mixin absolute-centering() {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

/*
 * 4. Triangle
 ****************************************************************/

@mixin triangle($direction, $sizeH, $sizeV, $color){
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  width: 0;

  @if $direction == top {
    border-color: transparent transparent $color transparent;
    border-width: 0 $sizeV $sizeH $sizeV;
  }

  @if $direction == bottom {
    border-color: $color transparent transparent transparent;
    border-width: $sizeV $sizeH 0 $sizeV;
  }

  @if $direction == left {
    border-color: transparent $color transparent transparent;
    border-width: $sizeV $sizeH $sizeV 0;
  }

  @if $direction == right {
    border-color: transparent transparent transparent $color;
    border-width: $sizeV 0 $sizeV $sizeH;
  }

  @if $direction == topright {
    border-color: transparent $color transparent transparent;
    border-width: 0 $sizeH $sizeV 0;
  }

  @if $direction == bottomright {
    border-color: transparent transparent $color transparent;
    border-width: 0 0 $sizeH $sizeV;
  }

  @if $direction == bottomleft {
    border-color: transparent transparent transparent $color;
    border-width: $sizeH 0 0 $sizeV;
  }

  @if $direction == topleft {
    border-color: $color transparent transparent transparent;
    border-width: $sizeH $sizeV 0 0;
  }
}

/*
 * 5. Lines
 ****************************************************************/

@mixin line-content($color: null, $margin: null, $height: null, $width: null) {
  border-bottom: $height solid $color;
  content: '';
  display: block;
  margin: $margin;
  width: $width;
}

@mixin line($position: before, $color: currentColor, $margin: 0.2em, $height: 1px, $width: 100%) {
  @if $position == before {
    &::before {
      @include line-content($color, $margin, $height, $width);
    }
  }

  @if $position == after {
    &::after {
      @include line-content($color, $margin, $height, $width);
    }
  }
}

/*
 * 6. Vertical-line
 ****************************************************************/

@mixin vertical-line-content($margin: null, $height: null, $color: null) {
  border-left: 1px solid $color;
  content: '';
  display: inline-block;
  height: $height;
  margin: 0 $margin;
}

@mixin vertical-line($position: before, $margin: 0.6em, $height: 1.2em, $color: currentColor) {
  align-items: center;
  display: flex;

  @if $position == before {
    &::before {
      @include vertical-line-content($margin, $height, $color);
    }
  }

  @if $position == after {
    &::after {
      @include vertical-line-content($margin, $height, $color);
    }
  }
}


/*
 * 7. Mobile toggle button
 ****************************************************************/
@mixin toggle-button($color, $color-hover, $spacing: 4px, $width: 30px, $border: 1px, $transition-timing: 0.4s) {

  .header-toggle__box {
    display: inline-block;
    height: $border * 3 + $spacing * 2;
    position: relative;
    vertical-align: middle;
    width: $width;
  }

  .header-toggle__inner {
    display: block;
    margin-top: $border / -2;
    top: 50%;
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: transform 0.15s ease, background $transition-timing;

    &,
    &::before,
    &::after {
      background: $color;
      border-radius: 4px;
      height: $border;
      position: absolute;
      width: 100%;
    }

    &::before,
    &::after {
      content: '';
      display: block;
    }

    &::before {
      top: ($spacing + $border) * -1;
      transition: top 0.1s 0.14s ease, opacity 0.1s ease, background $transition-timing;
    }

    &::after {
      bottom: ($spacing + $border) * -1;
      transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), background $transition-timing;
    }
  }

  // Hover
  &:hover {
    .header-toggle__inner {
      &,
      &::before,
      &::after {
        background: $color-hover;
      }
    }
  }

  // Active
  &.is-active {
    .header-toggle__inner {
      transform: rotate(45deg) scale(0.7);
      transition-delay: 0.14s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease, opacity 0.1s 0.14s ease, background $transition-timing;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background $transition-timing;
      }
    }
  }
}


/*
 * 8. Tab character (+ / -)
 ****************************************************************/

@mixin tab-character($right) {
  &::after {
    content: '+';
    font-size: 20px;
    position: absolute;
    right: $right;
    top: 50%;
    transform: translateY(-50%);
  }

  &.is-active {
    &::after {
      content: '-';
      margin-right: 0.1em;
    }
  }
}

/*
 * 8. Keep Ratio (+ / -)
 ****************************************************************/

//Ratio
@function gcd($a,$b){
    @if $b == 0{
        @return $a;
    }@else{
        @return gcd($b,$a % $b);
    }
}

@function getRatio($w,$h){
    $r:gcd($w,$h);
    @return "#{$w / $r}/#{$h/$r}";
}

@mixin keepRatio($ratio:'1/1'){
    // Usage @include keepRatio('16/9')
    $ratio1:str-slice($ratio,1,(str-index($ratio,'/') - 1));
    $ratio2:str-slice($ratio,(str-index($ratio,'/') + 1));
    position: relative;
    &:before{
        content:'';
        display: block;
        padding-top: to-number($ratio2) * 100 / to-number($ratio1) * 1%;
    }
    .wrapper{
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
    }
}

//Helper
@function to-number($value) {
    @if type-of($value) == 'number' {
        @return $value;
    } @else if type-of($value) != 'string' {
        $_: log('Value for `to-number` should be a number or a string.');
    }
  
    $result: 0;
    $digits: 0;
    $minus: str-slice($value, 1, 1) == '-';
    $numbers: ('0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9);
  
    @for $i from if($minus, 2, 1) through str-length($value) {
        $character: str-slice($value, $i, $i);
    
        @if not (index(map-keys($numbers), $character) or $character == '.') {
          @return to-length(if($minus, -$result, $result), str-slice($value, $i))
        }
    
        @if $character == '.' {
            $digits: 1; 
        } @else if $digits == 0 {
            $result: $result * 10 + map-get($numbers, $character);  
        } @else {
            $digits: $digits * 10;
            $result: $result + map-get($numbers, $character) / $digits;
        }
    }
    @return if($minus, -$result, $result);
}

@mixin smooth {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    line-height: 1;
}
