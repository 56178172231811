.section__cta_grid_product{
	background-color: white;
	height: auto;
	padding: 90px 0;
	@include media-breakpoint-down(md) {
	    height: auto;
	    padding: 40px 0;
	}
	.section__wrapper{
		position: relative;
		max-width: $maxW;
		padding: 0 20px;
		margin: 0 auto;
		width: 100%;
		min-height: 400px;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		    max-width: 100%;
			padding: 0 76px;
			min-height: inherit;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px;
		}
		.section__grid_filter{
			position: relative;
			width: 100%;
			margin-bottom: 40px;
			.filter__header{
				position: relative;
				height: auto;
				overflow: hidden;
				overflow-x: auto;
				&::-webkit-scrollbar {
				    display: none;
				}
				@include media-breakpoint-down(sm) {
				    margin: 0 -36px 0 0;
				}
				@include media-breakpoint-down(xs) {
				    margin: 0 -20px 0 0;
				}
				&_wrapper{
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					height: 100%;
					@include media-breakpoint-down(sm) {
					   	min-width: 768px;
					}
					@include media-breakpoint-down(xs) {
					   	min-width: 575px;
					}
					> a{
						display: flex;
						align-items: center;
						justify-content: flex-start;
						font-size: 16px;
						color: $blue2;
						padding: 25px 20px 25px 0;
						width: 100%;
						cursor: pointer;
						transition: color $time ease;
						&:hover{
							color: $red;
						}
						@include media-breakpoint-down(lg) {
						   	font-size: 16px;
						}
						@include media-breakpoint-down(md) {
						   	padding: 20px 10px 20px 0;
						   	font-size: 13px;
						   	max-width: inherit !important;
						}
						@include media-breakpoint-down(sm) {
						   	padding: 10px 10px 10px 0;
						}
						img{
							padding-right: 20px;
							pointer-events: none;
							@include media-breakpoint-down(md) {
							   	width: 30px;
							   	padding-right: 10px;
							}
						}
					}
				}
				.filter__header_line{
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 5px;
					background-color: $grey_light_1;
					@include media-breakpoint-down(sm) {
					   	height: 3px;
					   	min-width: 768px;
					}
					@include media-breakpoint-down(xs) {
					   	min-width: 575px;
					}
					.progress{
						position: absolute;
						left: 0;
						margin-left: 0;
						height: 100%;
						background-color: $red;
						z-index: 25;
						transition: margin-left $time ease;
					}
				}
			}
		}
		.section__grid{
			position: relative;
			ul.products{
				position: relative;
				display: flex;
				flex-wrap: wrap;
				margin: 0 -40px;
				@include media-breakpoint-down(lg) {
				    margin: 0 -20px;
				}
				@include media-breakpoint-down(sm) {
				    flex-direction: column;
				}
				li{
					width: calc(33.33% - 80px);
					@include media-breakpoint-down(lg) {
					    width: calc(33.33% - 40px);
					}
					@include media-breakpoint-down(md) {
					    width: calc(50% - 40px);
					}
					@include media-breakpoint-down(sm) {
					    width: calc(100% - 40px);
					}
				}
			}
			&_bottom{
				display: flex;
				align-items: center;
				width: 100%;
				@include media-breakpoint-down(md) {
				   flex-direction: column;
				}
				.bottom__left{
					width: 33.33%;
					@include media-breakpoint-down(md) {
					   width: 100%;
					   text-align: center;
					   margin-bottom: 20px;
					}
				}
				.bottom__mid{
					width: 33.33%;
					display: flex;
					justify-content: center;
					@include media-breakpoint-down(md) {
					   width: 100%;
					   margin-bottom: 20px;
					}
				}
				.bottom__right{
					width: 33.33%;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					@include media-breakpoint-down(md) {
					   width: 100%;
					   justify-content: center;
					}
					&_wrapper{
						display: flex;
						align-items: center;
						.pagination{
							margin-right: 20px;
							@include media-breakpoint-down(sm) {
								margin-right: 0;
								margin-bottom: 20px;
							}
						}
						@include media-breakpoint-down(sm) {
							flex-direction: column;
						   	justify-content: center;
						}
						.btn-paginations{
							display: flex;
							align-items: center;
							a + a{
								margin-left: 10px;
							}
						}
						ul.page-numbers{
							display: flex;
							align-items: center;
							li{
								+ li{
									margin-left: 10px;
								}
								form{
									input{
										width: 42px;
										height: 42px;
										border: none;
										text-align: center;
										appearance: textfield;
										font-size: 16px;
										color: $grey_light_3;
										background-color: transparent;
									}
									input::-webkit-outer-spin-button,
									input::-webkit-inner-spin-button {
									  	-webkit-appearance: none;
									  	margin: 0;
									}
								}
								a, span, form{
									border-radius: 7px;
									border: 1px solid $grey_light_3;
									width: 50px;
									height: 50px;
									display: flex;
									align-items: center;
									justify-content: center;
									transition: all $time ease;
									svg{
										g{
											stroke: $grey_light_3;
										}
									}
									&.page-numbers{
										display: none;
										border: 1px solid $grey_light_3;
										color: $blue;
										&.prev, &.next, &.current{
											display: flex;
										}
										&.prev, &.next{
											&:hover{
												border: 1px solid $blue;
												svg{
													g{
														stroke: $blue;
													}
												}
											}
										}
										&.current{
											margin: 0 10px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}