/**
 *  -----------------------------------------------
 *  GLOBAL
 *
 *  1. Wrappers
 *  2. Custom resets
 *  3. JavaScript
 *  -----------------------------------------------
 */


/*
 * 1. Wrappers
 ****************************************************************/


html{
    font-family: $rreg;
    font-weight: 100;
    color: $blue_navy_2;
    &.scroll-disabled {
        overflow: hidden;

        body {
            overflow: hidden;
        }
    }
}

body{
    position: relative;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-size-adjust: 100%;
    line-height: normal;
    margin: 0;
    padding: 0;
    transition: opacity $time ease;
    &.js-freeze{
        position: fixed;
        overflow: hidden;
        .overlay-mobile{
            opacity: 0.6;
            z-index: 750;
        }
    }
}

.main{
    position: relative;
    overflow: hidden;
}

.overlay-mobile{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $blue_navy_2;
    opacity: 0;
    z-index: -100;
    @include media-breakpoint-down(lg) {
        display: block;
    }
}

.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.6);
    display: none;
}

.overlay-green{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $green;
    opacity: 0.9;
    transition: opacity $time ease;
}

.overlay-blue{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $blue;
    opacity: 0.9;
    transition: opacity $time ease;
}

.container{
    position: relative;
    width: 100%;
    max-width: $maxW;
    margin: 0 auto;
    padding: 0 20px;
    @media screen and (min-width: 1200px) and (max-width: 1350px) {
        max-width: $maxWR-85;
    }
    @include media-breakpoint-down(lg) { // 992 to 1199
        max-width: $maxWR-90;
    }
}

.wrap{
    padding-top: 96px;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    @include media-breakpoint-down(md) {
        padding-top: 0;
        margin-top: 93px;
    }
}

img{
    max-width: 100%;
}

.scroll-indicator{
    position: fixed;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    background-color: #f1f1f1;
    .progress-container {
        width: 100%;
        height: 8px;
        background-color: #ccc;
        .progress-bar {
            height: 8px;
            background-color: $red;
            transition: width $time ease;
            width: 0%;
        }
    }
}

.tweenX, .tweenY {
    opacity: 0;
}

.wysiwyg{
    h2{
        font-family: $wmed;
        font-size: 42px;
        margin-bottom: 20px;
        line-height: 1.2;
        @include media-breakpoint-down(lg) {
            font-size: 32px;
        }
        @include media-breakpoint-down(md) {
            font-size: 24px;
        }
    }
    h3{
        font-family: $rreg;
        font-size: 24px;
        margin-bottom: 20px;
        line-height: 1.4;
        @include media-breakpoint-down(lg) {
            font-size: 18px;
        }
    }
    h4{
        font-family: $rbold;
        font-size: 18px;
        margin-bottom: 20px;
        line-height: 1.4;
        @include media-breakpoint-down(md) {
            font-size: 16px;
        }
    }
    h5{
        font-family: $rbold;
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 1.4;
        @include media-breakpoint-down(md) {
            font-size: 14px;
        }
    }
    h6{
        font-family: $rbold;
        font-size: 14px;
        margin-bottom: 20px;
        line-height: 1.4;
        @include media-breakpoint-down(md) {
            font-size: 12px;
        }
    }
    p{
        font-size: 16px;
        line-height: 1.4;
        letter-spacing: 1px;
        @include media-breakpoint-down(md) {
            font-size: 13px;
        }
        + *{
            margin-top: 30px;
        }
        a{
            text-decoration: underline;
        }
    }
    strong, b{
        font-family: $rbold;
    }
    ul:not(.slick-dots){
        position: relative;
        li{
            background-image: url('../images/icons/icon-blue-bullet.svg');
            background-repeat: no-repeat;
            background-position: 0 6px;
            font-size: 16px;
            padding-left: 20px;
            color: $blue_navy_2;
            width: 50%;
            margin-top: 15px;
            @include media-breakpoint-down(md) {
               width: 100%;
               font-size: 13px;
            }
        }
    }

    ul:not(.slick-dots), ol{
        font-family: $rreg;
        letter-spacing: 1px;
        line-height: 1.4;
        // color: $blue;
        // font-size: 24px;
        @include media-breakpoint-down(md) {
            font-size: 13px;
        }
        + *{
            margin-top: 20px;
        }
        li{
            line-height: 1.4;
            + li{
                margin-top: 10px;
            }
        }
    }

    ol {
        list-style: none; 
        counter-reset: li;
      
        & > li {
            position: relative;
            counter-increment: li;
            padding-left: 40px;
            margin-bottom: 40px;
        
            &:before {
                position: absolute;
                top: 2px;
                left: 10px;
                content: counter(li)'.';
                font-family: $rreg;
                color: $blue;
                margin: 0;
                padding-left: 0;
            }
        
            ul:not(.slick-dots) {
                // list-style-type: none;
                counter-reset: li; /* Reset counter for nested ul */
                padding-left: 20px; /* Reset padding for nested ul */
                
                li {
                    counter-reset: none; /* Reset counter for nested li */
                    position: relative;
                    padding-left: 20px;

                    background-image: url(../images/icons/icon-blue-bullet.svg);
                    background-repeat: no-repeat;
                    background-position: 0 6px;
                    font-size: 16px;
                    // padding-left: 20px;
                    color: #34396b;
                    width: 85%;
                    
                    &:before {
                       content: '';
                    }
                }
            }
        }
    }
    img{
        // width: 100%;
        &.alignleft {
            margin: 20px 20px 20px 0;
            float: left;
        }
        &.alignright {
            margin: 20px 0 20px 20px;
            float: right;
        }
        &.aligncenter {
            display: table;
            margin: 20px auto;
        }
    }
    blockquote{
        position: relative;
        margin-bottom: 30px;
        display: flex;
        font-family: $wlight;
        line-height: 1.2;
        color: $blue_navy_2;
        text-align: center;
        border: 1px solid $grey_light_3;
        quotes: "\00AB""\00BB""\2018""\2019";
        font-size: 42px;
        &:before{
            position: absolute;
            top: 80px;
            left: 70px;
            content: '';
            height: auto;
            content: open-quote;
            font-family: $wlight;
            font-size: 42px;
            @include media-breakpoint-down(md) {
                font-size: 24px;
                top: 40px;
                left: 30px;
            }
            @include media-breakpoint-down(xs) {
                left: 20px;
                top: 20px;
            }
        }
        &:after{
            position: absolute;
            right: 70px;
            bottom: 80px;
            content: '';
            height: auto;
            content: close-quote;
            font-family: $wlight;
            font-size: 42px;
            @include media-breakpoint-down(md) {
                font-size: 24px;
                right: 30px;
                bottom: 40px;
            }
            @include media-breakpoint-down(xs) {
                right: 20px;
                bottom: 20px;
            }
        }
        p{
            font-family: $wlight;
            color: $blue_navy_2;
            line-height: 1.2;
            font-size: 42px;
            padding: 80px;
            @include media-breakpoint-down(md) {
                font-size: 24px;
                padding: 40px;
            }
            @include media-breakpoint-down(xs) {
                font-size: 13px;
                padding: 20px 40px;
            }
        }
        cite{
            &:before{
                margin-right: 5px;
                content: '-';
            }
            display:block;
            text-align:right;
            margin-top:20px;
            right: 0;
            bottom: -25px;
            justify-content: flex-end;
            font-family: $rreg;
        }
    }
    q{
        display: block;
        padding: 20px 40px;
        color: $blue_navy_2;
    }
    iframe, video{
        width: 100%;
    }
    .iframe-container {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;
        margin-bottom: 20px;
        iframe{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
    a:not(.btn-fx){
        font-family: $rreg;
        color: $blue;
        transition: color $time ease;
        &:hover{
            color: $blue;
        }
    }
    &.wys-acc{
        width: 70%;
        @include media-breakpoint-down(md) {
           width: 100%;
        }
        ul:not(.slick-dots){
            position: relative;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 20px;
            li{
                background-image: url('../images/icons/icon-blue-bullet.svg');
                font-size: 16px;
                padding-left: 20px;
                color: $blue_navy_2;
                width: 50%;
                margin-top: 15px;
                @include media-breakpoint-down(md) {
                   width: 100%;
                   font-size: 13px;
                }
                &.pdf{
                    background-image: none;
                    padding-left: 0;
                    a{
                        display: flex;
                        align-items: center;
                        color: $blue;
                        font-size: 16px;
                        transition: opacity $time ease;
                        opacity: 1;
                        @include media-breakpoint-down(md) {
                           font-size: 13px;
                        }
                        &:before{
                            content: url('../images/icons/icon-blue-pdf.svg');
                            display: inline-block;
                            padding-right: 15px;
                        }
                        &:hover{
                            opacity: 0.8;
                        }
                        + a{
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
        ol{
            position: relative;
            li{
                font-size: 16px;
                padding-left: 20px;
                color: $blue_navy_2;
                @include media-breakpoint-down(md) {
                   font-size: 13px;
                }
                + li{
                    margin-top: 15px;
                }
            }
        }
    }
}

// Scrollbar custom
.scrb::-webkit-scrollbar {
    height: 3px;
    padding: 2px;
}
.scrb::-webkit-scrollbar-track{
    border-radius: 10px;
}
.scrb::-webkit-scrollbar-thumb {
    background-color: $red;
    border-radius: 10px;
}


/*
 * 2. Custom resets
 ****************************************************************/

*, *::before, *::after { box-sizing: border-box; }

h1, h2, h3, h4, h5 {
  outline: none;
}

a {
  color: inherit;

  &,
  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
  }
}

input, textarea, select, button {
  &:focus {
    outline: none;
  }
}

// Remove Safari shadow
input, textarea {
    appearance: none;
    border-radius: 0;
}

/* Remove yellow autocomplete color */
input:-webkit-autofill { -webkit-box-shadow: 0 0 0 30px white inset; }

button {
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.14;
  outline: 0;
  padding: 0;
  text-align: left;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

sup,
sub {
  font-size: 0.7em;
  position: relative;
}

sup { top: -0.7em; }
sub { bottom: -0.2em; }

::-ms-clear { display: none; } // Prevents "x" to clear field in explorer 11+


/*
 * 3. JavaScript
 ****************************************************************/

.js-hide {
  display: none;
}

.js-hidden {
  visibility: hidden;
}

// Loader wrapper
[data-module='ajax-form'],
.js-article-wrap,
.js-button-wrap {
  position: relative;
}

[data-module='ajax-form'] .js-main-messages-form {
  display: none;
}

// Class on form when messages are displayed
.js-form-message-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;

  > div {
    width: 100%;
  }
}

.js-prevent-default {
  cursor: default;
}

.js-block-link {
  cursor: pointer;
}
