.cta__product_item{
	position: relative;
	margin: 40px !important;
	border: 1px solid $lightblue;
	border-radius: $radius;
	@include media-breakpoint-down(lg) {
	   margin: 20px !important;
	}
	.product__image{
		@include keepRatio('450/310');
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
		border-top-left-radius: $radius;
		border-top-right-radius: $radius;
		a.tag{
			position: absolute;
			right: 0;
			top: 0;
			padding: 8px 20px;
			display: block;
			font-family: $rmed;
			font-style: italic;
			background-color: $blue;
			color: white;
			z-index: 10;
			border-bottom-left-radius: $radius;
			transition: background-color $time ease;
			&:hover{
				background-color: $blue2;
			}
		}
		a.image{
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			img{
				transform: scale(1);
				transition: transform $time ease;
				backface-visibility: hidden;
				border-top-left-radius: $radius;
				border-top-right-radius: $radius;
				width: 100%;
				height: 100% !important;
				position: absolute;
				top: 0;
				left: 0;
				object-fit: cover;
			}
		}
		&:hover{
			a.image img{
				transform: scale(1.1);
			}
			~ .product__link a{
				background-color: $lightblue;
			}
		}
	}
	.product__info{
		position: relative;
		padding: 20px;
		background-color: white;
		h3{
			font-family: $rmed;
			color: $blue;
			margin-bottom: 20px !important;
			font-size: 24px !important;
			@include media-breakpoint-down(md) {
			    font-size: 16px !important;
			}
		}
		.details{
			font-family: $rreg;
			font-size: 16px;
			line-height: 1.4;
			color: $blue2;
			margin-bottom: 30px;
			@include media-breakpoint-down(md) {
			    font-size: 13px;
			}
		}
		.price{
			text-transform: uppercase;
			color: $blue2 !important;
			font-size: 24px !important;
			@include media-breakpoint-down(md) {
			    font-size: 16px !important;
			}
		}
	}
	.product__link{
		position: relative;
		border-top: 1px solid $lightblue;
		a{
			display: block;
			width: 100%;
			padding: 20px;
			text-transform: uppercase;
			background-color: white;
			color: $blue2;
			transition: all $time ease;
			border-bottom-left-radius: $radius;
			border-bottom-right-radius: $radius;
			&:hover{
				background-color: $lightblue;
			}
			img.chevron{
				position: absolute;
				right: 20px;
				top: 50%;
				width: 10px !important;
				transform: translateY(-50%);
			}
		}
	}
}