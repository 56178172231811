.section__cta_circle, .section__cta_square{
	background-color: white;
	height: auto;
	padding: 90px 0;
	@include media-breakpoint-down(md) {
	    height: auto;
	    padding: 40px 0;
	}
	.section__wrapper{
		position: relative;
		max-width: $maxW;
		padding: 0 20px;
		margin: 0 auto;
		width: 100%;
		min-height: 400px;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		    max-width: 100%;
			padding: 0 76px;
			min-height: inherit;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px;
		}
	}
}