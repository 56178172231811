.section__filter_grid{
	background-color: $grey_light_2;
	position: relative;
	z-index: 600;
	&_top{
		position: relative;
		top: -40px;
		max-width: $maxW;
		margin: 0 auto;
		width: 100%;
		padding: 0 20px;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		    max-width: 100%;
			padding: 0 76px;
		}
		@include media-breakpoint-down(sm) {
			padding: 20px 36px 30px;
		   	top: 0;
		}
		@include media-breakpoint-down(xs) {
			padding: 20px 20px 30px;
		}
		h4{
			font-family: $rreg;
			letter-spacing: 1px;
			margin-bottom: 20px;
			text-transform: uppercase;
		}
		.section__filter{
			background-color: white;
			position: relative;
			display: flex;
			flex-direction: column;
			padding: 40px 160px 40px 40px;
			border-radius: $radius;
			box-shadow:
			    0 5px 10px rgba(0, 0, 0, 0.1),
			    0 20px 20px rgba(0, 0, 0, 0.05);
		    @include media-breakpoint-down(md) {
		       	padding: 40px;
		    }
			@include media-breakpoint-down(sm) {
			   	margin: 0;
			   	padding: 20px;
			}
			.form__top{
				position: relative;
				display: flex;
				width: 100%;
				@include media-breakpoint-down(sm) {
				   flex-direction: column;
				}
				.section__filter_left{
					width: calc(33.33% - 80px);
					margin: 0 40px 0 0;
					@include media-breakpoint-down(lg) {
						width: calc(45% - 40px);
						margin: 0 20px 0 0;
					}
					@include media-breakpoint-down(sm) {
					   width: 100%;
					   margin: 20px 0;
					}
					&_top{
						margin-bottom: 50px;
						@include media-breakpoint-down(sm) {
						   margin-bottom: 20px;
						}
					}
					.filter__select{
						position: relative;
						width: 100%;
						.icon-select{
							position: absolute;
							right: 0;
							top: 9px;
						}
						select, input{
							appearance: none;
							outline: 0;
							border-radius: 0;
							box-shadow: none;
							border: 0;
							border-bottom: 1px solid $grey_light_3;
							background-color: white;
							padding: 10px 0;
							width: 100%;
							font-size: 16px;
							color: $blue_navy_2;
							letter-spacing: 1px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: word-wrap;
							padding-right: 15px;
						}
						.input-group{
							position: relative;
							button{
								position: absolute;
								display: flex;
								align-items: center;
								justify-content: center;
								top: -2px;
								right: 0;
								border-radius: 50%;
								width: auto;
								height: auto;
								text-align: center;
								padding: 10px 0 10px 20px;
								background-color: white;
								svg{
									width: 20px;
									height: 20px;
									g{
										stroke: $blue;
									}
								}
							}
						}
						select::-ms-expand {
							display: none;
						}
					}
				}
				.section__filter_right{
					width: 66.66%;
					padding-left: 40px;
					@include media-breakpoint-down(lg) {
					    padding-left: 20px;
					    width: 55%;
					}
					@include media-breakpoint-down(sm) {
					   width: 100%;
					   padding-left: 0;
					}
					h4{
						margin-bottom: 5px;
						@include media-breakpoint-down(sm) {
						   margin-bottom: 20px;
						}
					}
					.input__wrapper{
						position: relative;
						display: flex;
						flex-wrap: wrap;
						margin-left: -20px;
						@include media-breakpoint-down(sm) {
						   	margin-left: 0;
						}
						.input-group{
							width: calc(33.33% - 40px);
							display: flex;
							align-items: center;
							position: relative;
							margin: 20px;
							@include media-breakpoint-down(lg) {
							    width: calc(50% - 40px);
							}
							@include media-breakpoint-down(sm) {
							   	width: 100%;
							   	margin: 10px 0;
							}
							input{
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								width: 25px;
								height: 25px;
								appearance: checkbox;
							  	z-index: 10;
							  	opacity: 0;
							  	margin: 0;
							  	&:checked{
							  		~ .checkmark img{
							  			display: block;
							  		}
							  	}
							}
							label{
								padding: 0 10px 0 35px;
							}
							.checkmark{
								position: absolute;
								left: 0;
								top: 50%;
								transform: translateY(-50%);
								z-index: 5;
								width: 24px;
								height: 24px;
								border: 1px solid $blue;
								border-radius: 7px;
								img{
									display: none;
								}
							}
						}
					}
				}
			}
			.form__bottom{
				position: absolute;
				right: 40px;
				bottom: 40px;
				width: auto;
				height: auto;
				display: flex;
				justify-content: flex-end;
				margin-top: 20px;
				@include media-breakpoint-down(md) {
				   	justify-content: center;
				   	position: relative;
				   	right: inherit;
				   	bottom: inherit;
				   	width: 100%;
				   	height: 100%;
				}
			}
		}
	}
	&_mid{
		background-color: $grey_light_2;
		position: relative;
		padding: 60px 20px 0;
		@include media-breakpoint-down(md) {
			padding: 20px 76px;
		}
		@include media-breakpoint-down(sm) {
			padding: 20px 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 20px;
		}
		.wrapper{
			max-width: $maxW;
			margin: 0 auto;
			width: 100%;
			@media screen and (min-width: 1200px) and (max-width: 1350px) {
				max-width: $maxWR-85;
			}
			@include media-breakpoint-down(lg) { // 992 to 1199
				max-width: $maxWR-90;
			}
			@include media-breakpoint-down(md) {
			   	max-width: 100%;
			}
			p{
				max-width: 66.66%;
				line-height: 1.4;
				font-size: 16px;
				letter-spacing: 1px;
				@include media-breakpoint-down(md) {
				   	max-width: 100%;
				}
				@include media-breakpoint-down(sm) {
				   	font-size: 13px;
				}
			}
		}
	}
	&_bottom{
		position: relative;
		.section__grid{
			position: relative;
			max-width: $maxW;
			margin: 0 auto;
			width: 100%;
			padding: 60px 20px;
			@media screen and (min-width: 1200px) and (max-width: 1350px) {
				max-width: $maxWR-85;
			}
			@include media-breakpoint-down(lg) { // 992 to 1199
				max-width: $maxWR-90;
			}
			@include media-breakpoint-down(md) {
			   	max-width: 100%;
				padding: 20px 76px;
			}
			@include media-breakpoint-down(sm) {
				padding: 20px 36px;
			}
			@include media-breakpoint-down(xs) {
				padding: 20px;
			}
			&_wrapper{
				position: relative;
				display: flex;
				flex-wrap: wrap;
				margin: 0 -40px;
				@include media-breakpoint-down(md) {
				    margin: 0 -20px;
				}
				@include media-breakpoint-down(sm) {
				    margin: 0;
				    flex-direction: column;
				}
			}
			&_bottom{
				display: flex;
				align-items: center;
				width: 100%;
				@include media-breakpoint-down(md) {
				   flex-direction: column;
				}
				.bottom__left{
					width: 33.33%;
					@include media-breakpoint-down(md) {
					   width: 100%;
					   text-align: center;
					   margin-bottom: 20px;
					}
				}
				.bottom__mid{
					width: 33.33%;
					display: flex;
					justify-content: center;
					@include media-breakpoint-down(md) {
					   width: 100%;
					   margin-bottom: 20px;
					}
				}
				.bottom__right{
					width: 33.33%;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					@include media-breakpoint-down(md) {
					   width: 100%;
					   justify-content: center;
					}
					&_wrapper{
						display: flex;
						align-items: center;
						.pagination{
							margin-right: 20px;
							@include media-breakpoint-down(sm) {
								margin-right: 0;
								margin-bottom: 20px;
							}
						}
						@include media-breakpoint-down(sm) {
							flex-direction: column;
						   	justify-content: center;
						}
						.btn-paginations{
							display: flex;
							align-items: center;
							a + a{
								margin-left: 10px;
							}
						}
						ul.page-numbers{
							display: flex;
							align-items: center;
							li{
								+ li{
									margin-left: 10px;
								}
								form{
									input{
										width: 42px;
										height: 42px;
										border: none;
										text-align: center;
										appearance: textfield;
										font-size: 16px;
										color: $blue_navy_2;
										background-color: transparent;
									}
									input::-webkit-outer-spin-button,
									input::-webkit-inner-spin-button {
									  	-webkit-appearance: none;
									  	margin: 0;
									}
								}
								a, span, form{
									border-radius: 7px;
									border: 1px solid $grey_light_3;
									width: 50px;
									height: 50px;
									display: flex;
									align-items: center;
									justify-content: center;
									transition: all $time ease;
									svg{
										g{
											stroke: $grey_light_3;
										}
									}
									&.page-numbers{
										display: none;
										border: 1px solid $blue;
										color: $blue;
										&.prev, &.next, &.current{
											display: flex;
										}
										&.prev, &.next{
											&:hover{
												border: 1px solid $blue;
												svg{
													g{
														stroke: $blue2;
													}
												}
											}
										}
										&.current{
											margin: 0 10px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}