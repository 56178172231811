.section__cta_label_list{
	position: relative;
	overflow: hidden;
	height: auto;
	padding: 90px 0;
	@include media-breakpoint-down(md) {
	    padding: 40px 0;
	}
	.section__wrapper{
		position: relative;
		max-width: $maxW;
		margin: 0 auto;
		width: 100%;
		padding: 0 20px;
		display: flex;
		flex-direction: column;
		@media screen and (min-width: 1200px) and (max-width: 1350px) {
			max-width: $maxWR-85;
		}
		@include media-breakpoint-down(lg) { // 992 to 1199
			max-width: $maxWR-90;
		}
		@include media-breakpoint-down(md) {
		    max-width: 100%;
			padding: 0 76px;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 36px;
		}
		@include media-breakpoint-down(xs) {
			padding: 0 20px;
		}
		h2{
		    font-family: $wmed;
		    font-size: 36px;
		    line-height: 1.4;
		    color: $blue_navy_2;
		    margin-bottom: 40px;
		    @include media-breakpoint-down(lg) {
		        font-size: 32px;
		    }
		    @include media-breakpoint-down(md) {
		        font-size: 24px;
		    }
		}
		ul{
		    position: relative;
		    display: flex;
		    flex-wrap: wrap;
		    margin: 0 -40px;
		    li{
		    	display: flex;
		    	align-items: center;
		    	word-break: break-all;
		        background-repeat: no-repeat;
		        background-position: 0px center;
		        list-style: none;
	        	border-top: 1px solid $lightblue;
		        width: calc(50% - 80px);
		        margin: 0 40px;
	        	@include media-breakpoint-down(sm) {
	        	   	width: 100%;
	        	   	font-size: 13px;
	        	}
	        	&:last-child, &:nth-last-child(2){
	        		border-bottom: 1px solid $lightblue;
	        		@include media-breakpoint-down(sm) {
	        		    border-bottom: none;
	        		}
	        	}
	        	&:last-child{
				    @include media-breakpoint-down(sm) {
				        border-bottom: 1px solid $lightblue;
				    }
	        	}
		        a{
		        	position: relative;
		        	display: flex;
		        	align-items: center;
		        	opacity: 1;
		        	transition: opacity $time ease;
		        	color: $blue2;
		        	font-size: 24px;
		        	width: 100%;
		        	height: 100px;
		        	padding-right: 60px;
		        	@include media-breakpoint-down(md) {
		        	    font-size: 16px;
		        	    height: 70px;
		        	}
		        	@include media-breakpoint-down(xs) {
		        	    font-size: 13px;
		        	    height: 60px;
		        	}
		        	&:hover{
		        		opacity: 0.6;
		        	}
		        	img.icon{
		        		margin-right: 40px;
		        		padding: 10px;
		        		width: 60px;
		        		@include media-breakpoint-down(md) {
		        			margin-right: 0;
		        		    padding-right: 20px;
		        		    width: 45px;
		        		}
		        	}
		        	img.chevron{
		        		position: absolute;
		        		right: 40px;
		        		top: 50%;
		        		transform: translateY(-50%);
		        		@include media-breakpoint-down(md) {
		        		    right: 20px;
		        		}
		        	}
		        }
		    }
		}
	}
}